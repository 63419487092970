//Moudle
import "../../../css/userModal.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";

//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetUser from "../../../cmm/cmmGetUser";
import cmmchangeFormat from "../../../cmm/cmmchangeFormat"; // 시간 포맷
import PageBtn from "../../../cmm/cmmPageBtn";
import PageBtn2 from "../../../cmm/cmmPageBtn2";
import Swal from "sweetalert2";
import LoadingModal from "../../../cmm/cmmLoadingModal";

const VerifyWeight = (props) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;
    const [downLoadBtn, setDownLoadBtn] = useState(false);

    const [pageUser, setPageUser] = useState(1);
    const [countUser, setCountUser] = useState(5);
    const [btnRefreshUser, setBtnRefreshUser] = useState(true);
    const maxPageUser = pageUser * countUser;
    const minpageUser = pageUser * countUser - countUser;

    const [modalShowLoading, setModalShowLoading] = useState(false);
    // 구분 타입

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [searchResult, setSearchResult] = useState([]);
    const [result, setResult] = useState([]);
    const [selectWeightID, setSelectWeightID] = useState();

    const [detailImgs, setDetailImgs] = useState([
        `${process.env.PUBLIC_URL}/testSet/E_1.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_2.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_3.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_4.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_5.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_6.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_7.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_8.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_9.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_10.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_11.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_12.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_13.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_14.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_15.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_16.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_17.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_18.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_19.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_20.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_21.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_22.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_23.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_24.jpg`,
        `${process.env.PUBLIC_URL}/testSet/E_25.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_1.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_2.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_3.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_4.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_5.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_6.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_7.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_8.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_9.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_10.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_11.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_12.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_13.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_14.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_15.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_16.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_17.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_18.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_19.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_20.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_21.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_22.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_23.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_24.jpg`,
        `${process.env.PUBLIC_URL}/testSet/F_25.jpg`,
    ]);

    const [inferResult, setInferResult] = useState([]);
    useEffect(() => {
        axios
            .post(`${server}/api/weight/searchVerify`, {
                sessionid: sessionStorage.getItem("SESSION"),
                tf: true,
            })
            .then((res) => {
                let tempArray = new Array();
                tempArray = res.data;

                for (var i = res.data.length % 10; i < count; i++) {
                    tempArray.push({
                        weightid: null,
                        accuracy: null,
                        segmentationfileurl: null,
                        createtm: null,
                        temp: true,
                    });
                }

                setSearchResult(tempArray);
            })
            .catch((err) => {});
    }, []);

    const onSubmit = async (data) => {};

    const reName = (name) => {
        if (name) {
            let rnm = name.split("/");
            return rnm[rnm.length - 1];
        }
        return null;
    };

    const handleImageUpload = (e) => {
        setBtnRefreshUser((val) => !val);
        setInferResult([]);
        const fileArr = e.target.files;

        let fileURLs = [];

        let file;
        let maxFile = 50;
        let filesLength = fileArr.length > maxFile ? maxFile : fileArr.length;

        if (fileArr.length > maxFile) alert(`한번에 추론 가능한 사진은 최대 ${maxFile}장 까지 입니다.`);

        for (let i = 0; i < filesLength; i++) {
            file = fileArr[i];

            if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
                setDetailImgs([]);
                alert(`JPG 사진 파일만 가능합니다.`);
                break;
            } else {
                let reader = new FileReader();

                reader.onload = () => {
                    fileURLs[i] = reader.result;

                    setDetailImgs([...fileURLs]);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleDownloadPdf = (e) => {
        window.open(`${server}/verify/Result_${sessionStorage.getItem("SESSION")}.pdf`, "_blank");
    };

    const handleDownloadExcel = (e) => {
        window.open(`${server}/verify/Result_${sessionStorage.getItem("SESSION")}.xlsx`, "_blank");
    };

    const onClickHandler = () => {
        if (selectWeightID && detailImgs) {
            axios
                .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "Q701" })
                .then((response) => {
                    Swal.fire({
                        icon: "warning",
                        title: response.data[0].title,
                        text: response.data[0].msgbody,
                        showCancelButton: true,
                        confirmButtonText: "확인",
                        cancelButtonText: "취소",
                        width: "750px",
                    })
                        .then((res) => {
                            if (res.isConfirmed) {
                                setModalShowLoading(true);
                                axios
                                    .post(`${process.env.REACT_APP_INFERENCE2_SERVER_HOST}/api/inferenceSub`, {
                                        sessionid: sessionStorage.getItem("SESSION"),
                                        detailImgs,
                                        weightid: selectWeightID,
                                    })
                                    .then((res) => {
                                        if (res.data) {
                                            setInferResult(res.data);
                                            setModalShowLoading(false);
                                            setDownLoadBtn(true);
                                        } else {
                                            setAlertMessage("E200");
                                        }
                                    })
                                    .catch((err) => {});
                            } else {
                                setAlertMessage("I009");
                            }
                        })
                        .catch((err) => {
                            setAlertMessage("I009");
                        });
                })
                .catch((err) => {
                    setAlertMessage("E200");
                });
        } else {
            setAlertMessage("I014");
        }
    };

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={() => props.setShow(false)}
            dialogClassName="outbox-limit-weightinfo" //modal outbox CustomCSSTag 크기 조정
        >
            <div className="outbox-weightinfo">
                <div className="txt-title-weightinfo">
                    <span> 정확도 검증 </span>
                </div>
                <hr className="headerline-long-weightinfo"></hr>
                <Modal.Body>
                    <div style={{ display: "flex", width: "100%", height: "700px" }}>
                        <div style={{ width: "51%", marginLeft: "2%", marginRight: "2%", height: "600px" }}>
                            <p style={{ fontWeight: "bold" }}>◎ List </p>
                            <Table bordered responsive style={{ height: "600px" }}>
                                <thead className="style-tHeader">
                                    <tr style={{ verticalAlign: "middle" }}>
                                        <th>ID</th>
                                        <th>WeightFile</th>
                                        <th>정확도</th>
                                        <th>생성시각</th>
                                        <th>검증</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchResult.slice(minpage, maxPage).map((data, index) => {
                                        return (
                                            <>
                                                <tr
                                                    key={index}
                                                    style={
                                                        data.confirmYN === "Y"
                                                            ? data.weightid === selectWeightID
                                                                ? {
                                                                      color: "red",
                                                                      height: "55px",
                                                                      backgroundColor: "yellow",
                                                                  }
                                                                : {
                                                                      color: "black",
                                                                      height: "55px",
                                                                      backgroundColor: "yellow",
                                                                  }
                                                            : data.weightid === selectWeightID
                                                            ? { color: "red", height: "55px" }
                                                            : { color: "black", height: "55px" }
                                                    }
                                                >
                                                    <td width="10%">{data.weightid}</td>
                                                    <td width="40%">{reName(data.classificationfileurl)}</td>
                                                    <td width="15%">{data.accuracy ? data.accuracy + "%" : ""}</td>

                                                    <td width="20%">
                                                        <pre
                                                            style={{
                                                                fontFamily: "NotoSansKR-DemiLight",
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                            }}
                                                        >
                                                            {data.createtm
                                                                ? cmmchangeFormat(data.createtm, "yy-MM-DD hh:mm:ss")
                                                                : ""}
                                                        </pre>
                                                    </td>

                                                    <td width="15%">
                                                        {data.temp ? null : (
                                                            <Button
                                                                className="btn-table"
                                                                variant="secondary"
                                                                type="button"
                                                                onClick={() => {
                                                                    setSelectWeightID(data.weightid);
                                                                }}
                                                            >
                                                                선택
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="12" style={{ borderBottom: "none" }}>
                                            <PageBtn
                                                data={searchResult ? searchResult : 0}
                                                page={page}
                                                setPage={setPage}
                                                count={count}
                                                btnRefresh={btnRefresh}
                                            />
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                            {downLoadBtn ? (
                                <>
                                    {" "}
                                    <div style={{ float: "left" }}>
                                        <label class="btn btn-secondary border-0 bg_grey" onClick={handleDownloadPdf}>
                                            PDF 다운로드
                                        </label>
                                    </div>
                                    <div style={{ marginLeft: "20px", float: "left" }}>
                                        <label class="btn btn-secondary border-0 bg_grey" onClick={handleDownloadExcel}>
                                            EXCEL 다운로드
                                        </label>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div style={{ width: "41%", marginLeft: "2%", marginRight: "2%", height: "650px" }}>
                            <div>
                                <div style={{ display: "flex" }}>
                                    {" "}
                                    <p style={{ fontWeight: "bold" }}>◎ 결과</p>
                                </div>

                                <Table bordered responsive style={{ height: "685px" }}>
                                    <thead className="style-tHeader">
                                        <tr style={{ verticalAlign: "middle" }}>
                                            <th width="10%">No.</th>
                                            <th width="50%">이미지</th>
                                            <th width="20%">결과</th>
                                            <th width="20%">정확도</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {detailImgs
                                            ? detailImgs.slice(minpageUser, maxPageUser).map((data, index) => {
                                                  return (
                                                      <tr>
                                                          <td>{(pageUser - 1) * 5 + index + 1}</td>
                                                          <td width="50%" height="110.3">
                                                              {data ? (
                                                                  <img
                                                                      style={{ width: "276.5px", height: "93.4px" }}
                                                                      src={data}
                                                                  ></img>
                                                              ) : null}
                                                          </td>

                                                          <td width="25%">
                                                              {" "}
                                                              {inferResult[(pageUser - 1) * countUser + index]
                                                                  ? inferResult[(pageUser - 1) * countUser + index]
                                                                        .labelnm
                                                                  : ""}{" "}
                                                          </td>
                                                          <td width="25%">
                                                              {" "}
                                                              {inferResult[(pageUser - 1) * countUser + index]
                                                                  ? inferResult[(pageUser - 1) * countUser + index]
                                                                        .propb + "%"
                                                                  : ""}
                                                          </td>
                                                      </tr>
                                                  );
                                              })
                                            : null}
                                    </tbody>

                                    {detailImgs ? (
                                        <>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="5" style={{ borderBottom: "none" }}>
                                                        <PageBtn2
                                                            dataUser={detailImgs ? detailImgs : 0}
                                                            pageUser={pageUser}
                                                            setPageUser={setPageUser}
                                                            countUser={countUser}
                                                            btnRefreshUser={btnRefreshUser}
                                                        />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </>
                                    ) : null}
                                </Table>
                            </div>

                            <div style={{ float: "right" }}>
                                <input
                                    type="file"
                                    multiple
                                    style={{ display: "none" }}
                                    id="photo_file"
                                    name="photo_file"
                                    accept=".jpg"
                                    onChange={handleImageUpload}
                                />
                                <label class="btn btn-secondary border-0 bg_grey" for="photo_file">
                                    사진 추가
                                </label>
                            </div>
                        </div>
                    </div>

                    <center style={{ marginTop: "35px", marginRight: "400px" }}>
                        <Button
                            className="btn-positive"
                            variant="secondary"
                            type="button"
                            onClick={() => onClickHandler()}
                        >
                            검증
                        </Button>
                        <Button
                            className="btn-negative"
                            variant="secondary"
                            type="button"
                            onClick={() => props.setShow(false)}
                        >
                            취소
                        </Button>
                    </center>
                </Modal.Body>
                <div className="modal-box">
                    {modalShowLoading ? (
                        <LoadingModal show={modalShowLoading} setShow={setModalShowLoading}></LoadingModal>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};

export default VerifyWeight;
