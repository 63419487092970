import "../../css/userModal.css";
import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import React, { useState, useEffect, useRef } from "react";
import { Button, Table } from "react-bootstrap";
import { DatePickerMonthly } from "../../cmm/cmmDateFunction";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import Select from "react-select";

// chartjs
import { Bar } from "react-chartjs-2";
import axios from "axios";
import cmmGetUser from "../../cmm/cmmGetUser";

import cmmGetData from "../../cmm/cmmGetData"; //Config 가져오기

// 인쇄용
import ReactToPrint from 'react-to-print';

const addrgrp1Options = cmmGetData("region1", null, null, null, null, "Y"); // 유저 상태 config

const StatRegion = () => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;

    const server = process.env.REACT_APP_API_SERVER_HOST;

    ////////////// 인쇄 버튼 관련 /////////////////////////////////
    const bodyRef = useRef();
    
    const getPrintBtn = () =>{
        return (
            <Button className="btn-searchStat">
                <FontAwesomeIcon icon={faPrint} /> 출력
            </Button>
        );
    }

    /////////////// 업무 로직 ///////////////////////////////////////////
    const [addr, setAddr] = useState();
    const [regions, setRegions] = useState([
        "서울",
        "부산",
        "대구",
        "인천",
        "광주",
        "대전",
        "울산",
        "세종",
        "경기",
        "강원",
        "충북",
        "충남",
        "전북",
        "전남",
        "경북",
        "경남",
        "제주",
    ]);
    const [date, setDate] = useState();
    const [colwidthSum, setColwidthSum] = useState("5%");   //// 컬럼 폭 (합계)
    const [colwidthEach, setColwidthEach] = useState("5%"); //// 컬럼 폭 (개별 지역)
    
    // 합계 기본
    const [cntTotal, setCntTotal] = useState([0, 0, 0, 0]);

    // 조직 or 날짜 선택시 미리 데이터 가져오기
    const [cdItem, setCdItem] = useState([new Array(17).fill(0), new Array(17).fill(0), new Array(17).fill(0), new Array(17).fill(0)]);
    
    const Chart = () => {
        if (date && ID) {
            if (date.begindt > date.enddt){
                setAlertMessage("E904");
                return;
            }

            axios
                .post(`${server}/api/stat/StatRegion`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    addr: addr == undefined ? "" : addr,
                    startDate: date.begindt,
                    lvl: addr == undefined || addr == "" ? "1" : "2",
                    endDate: date.enddt,
                })
                .then((response) => {
                    let newRegion = response.data.labels;
                    setRegions(newRegion);  //// 바로 setRegions(response.data.labels); 라고 하면 반영 안 됨
                    
                    let newColwidthSum = "0%";    //// 합계는 조금 크게
                    let newColwidthEach = "0%";   //// 개별 컬럼은 조금 작게
                    if (response.data.labels.length>0) {
                        newColwidthSum = (Math.ceil(95/(response.data.labels.length+1)*10)/10)+"%";
                        newColwidthEach = (Math.floor(95/(response.data.labels.length+1)*10)/10)+"%";
                    }
                    setColwidthSum(newColwidthSum);
                    setColwidthEach(newColwidthEach);
                    
                    
                    let emptyArr = new Array(response.data.labels.length).fill(0);
                    let newCdItem = new Array(4).fill([]);
                    let newTotal = new Array(4).fill(0);
                    let firetypes = ["-", "12", "3", "9"];
                    for (let i = 0; i < firetypes.length; i++) {
                        let idx = -1;
                        for (let j = 0; j < response.data.data.length; j++) {
                            if (response.data.data[j].itemcd == firetypes[i]) {
                                idx = j;
                                break;
                            }
                        }
                        if (idx == -1) {
                            newCdItem[i] = emptyArr;
                        } else {
                            newCdItem[i] = [];
                            for (let k = 0; k < response.data.labels.length; k++) {
                                newCdItem[i].push(response.data.data[idx]["col" + k]);
                            }
                            newTotal[i] = response.data.data[idx].cnt;
                        }
                    }
                    setCntTotal(newTotal);
                    setCdItem(newCdItem);

                })
                .catch((err) => {});
        }
    };

    const options = {
        animation: {
            duration: 600,
        },
        scales: {
            yAxes: [
                {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "A",
                    ticks: {
                        beginAtZero: true,
                        // max: max > 1 ? max * 0.9 : 2,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "(단락흔, 열흔, 측정불가)",
                    },
                },
                {
                    type: "linear",
                    display: true,
                    position: "right",
                    gridLines: {
                        display: false,
                    },
                    id: "B",
                    ticks: {
                        beginAtZero: true,
                        // max: max > 1 ? max * 1.7 : 2,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "(Total)",
                    },
                },
            ],
        },
        legend: {
            labels: { boxWidth: 24 },
            position: "bottom", //  or 'left', 'bottom', 'right'(default)
        },
        tooltips: {
            displayColors: true,
            callbacks: {
                mode: "single",
                labelColor: function (tooltipItem, chart) {
                    return {
                        borderColor: "transparent",
                        backgroundColor: barData.datasets[tooltipItem.datasetIndex].backgroundColor,
                    };
                },
                label: function (tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${
                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === null
                            ? 0
                            : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    }`;
                },
            },
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                display: "",
                // formatter: (value, ctx) => {
                //     return ctx.dataset.data[ctx.dataIndex] === 0 ? null : ctx.dataset.data[ctx.dataIndex];
                // },
                // color: "black",
            },
        },
    };

    const barData = {
        labels: regions,
        datasets: [
            {
                label: "단락흔",
                data: cdItem[1],
                backgroundColor: "#005cc5",
                type: "bar", order: 1, yAxisID: "A",
            },
            {
                label: "열흔",
                data: cdItem[2],
                backgroundColor: "#ff7f00",
                type: "bar", order: 1, yAxisID: "A",
            },
            {
                label: "판별불가",
                data: cdItem[3],
                backgroundColor: "#9c9c9c",
                type: "bar", order: 1, yAxisID: "A",
            },
            {
                label: "Total",
                data: cdItem[0],
                backgroundColor: "#ffc502",
                borderColor: "#ffc502",
                fill: false,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 5,
                type: "line",
                tension: 0,
                yAxisID: "B",
            },
        ],
    };

    useEffect(() => {
        Chart();
    }, [date, addr, ID]);

    return (
        <div ref={bodyRef}>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <div style={{ backgroundColor: "#F5F5F5" }}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td>
                                    <label style={{ marginTop: "5px" }}>지역</label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-tripleUp"
                                        options={addrgrp1Options}
                                        onChange={(e) => {
                                            setAddr(e.value);
                                        }}
                                        defaultValue={{
                                            label: "전국",
                                            value: "",
                                        }}
                                        style={{ marginLeft: "10px" }}
                                    />
                                </td>
                                <td>
                                    <label style={{ marginTop: "5px" }}>기간</label>
                                </td>
                                <td>
                                    <DatePickerMonthly setDate={setDate} date={date} />
                                </td>

                                <td>
                                    <ReactToPrint
                                        trigger={() => getPrintBtn()}
                                        content={() => bodyRef.current}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div className="graph-stat" style={{ marginLeft: "100px" }}>
                <Bar data={barData} options={options} height="60"/>
            </div>
            <br />
            <br />
            <Table triped bordered responsive>
                <thead className="style-tHeader">
                    <tr>
                        <th style={{ backgroundColor: "#f7f9fa"}}>구분</th>
                        <th style={{ backgroundColor: "#f7f9fa", width: colwidthSum }}>합계</th>
                        {regions.map((label, index) => {
                            return (
                                <th style={{ backgroundColor: "#f7f9fa", width: colwidthEach }} key={index}>
                                    {label}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>합계</td>
                        <td>{cntTotal[0]}건</td>
                        {regions.map((n, index) => {
                            return <td key={index}>{cdItem[0][index] ? cdItem[0][index] : 0}건</td>;
                        })}
                    </tr>
                    <tr>
                        <td>단락흔</td>
                        <td>{cntTotal[1]}건</td>
                        {regions.map((n, index) => {
                            return <td key={index}>{cdItem[1][index] ? cdItem[1][index] : 0}건</td>;
                        })}
                    </tr>
                    <tr>
                        <td>열흔</td>
                        <td>{cntTotal[2]}건</td>
                        {regions.map((n, index) => {
                            return <td key={index}>{cdItem[2][index] ? cdItem[2][index] : 0}건</td>;
                        })}
                    </tr>
                    <tr>
                        <td>판별불가</td>
                        <td>{cntTotal[3]}건</td>
                        {regions.map((n, index) => {
                            return <td key={index}>{cdItem[3][index] ? cdItem[3][index] : 0}건</td>;
                        })}
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default StatRegion;
