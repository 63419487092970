import axios from "axios";
const server = process.env.REACT_APP_API_SERVER_HOST;

function cmmGetData(code = "error", lvl = "null", pdeptcd = "null", orgid2 = "null", pdeptnm = "", allyn = "") {
    const id = orgid2;

    /*
   getData 가 비동기라서 처음에 호출됬을때, 
   아직 db에서 호출되지 않은 상태로 값이 먼저 넘어감
   그래서 select-box에 기본값이 db에러 방지용값인 null이 보임
  */

    switch (code) {
        case "statuscd":
            //db 에러로,  조회되지않았을 경우  에러 방지
            let statuscd = [{ value: "null", label: "null" }];
            getItemCdData("SU100")
                .then((data) => {
                    data.forEach((element) => {
                        statuscd.push({
                            value: element.itemcd,
                            label: element.itemnm,
                        });
                    });
                    statuscd.shift(); // 에러방지값 pop()
                })
                .catch((err) => {
                    statuscd = [{ value: "null", label: "null" }];
                })
                .finally(() => {
                    //마지막으로 에러 방지 처리해야할 것이 있으면 사용
                });

            return statuscd;
        case "statuscdApply":
            //db 에러로,  조회되지않았을 경우  에러 방지
            let statuscdApply = [{ value: "null", label: "null" }];
            getItemCdData("SU110")
                .then((data) => {
                    data.forEach((element) => {
                        statuscdApply.push({
                            value: element.itemcd,
                            label: element.itemnm,
                        });
                    });
                    statuscdApply.shift(); // 에러방지값 pop()
                })
                .catch((err) => {
                    statuscdApply = [{ value: "null", label: "null" }];
                })
                .finally(() => {
                    //마지막으로 에러 방지 처리해야할 것이 있으면 사용
                });

            return statuscdApply;

        case "orgcd":
            //db 에러로,  조회되지않았을 경우  에러 방지
            let deptcd = [{ value: "null", label: "null" }];
            axios
                .get(`${server}/api/cmm/orgcd`, {})
                .then((response) => {
                    response.data.forEach((element) => {
                        deptcd.push({
                            value: element.deptcd,
                            label: element.deptnm,
                        });
                    });
                    deptcd.shift(); // 에러방지값 pop()
                })
                .catch((err) => {
                    deptcd = [{ value: "null", label: "null" }];
                })
                .finally(() => {});
            return deptcd;

        case "orgid":
            //db 에러로,  조회되지않았을 경우  에러 방지
            let orgid = [{ value: "", label: "전체" }];
            axios
                .post(`${server}/api/cmm/orgid`, { sessionid: sessionStorage.getItem("SESSION") })
                .then((response) => {
                    response.data.forEach((element) => {
                        orgid.push({
                            value: element.orgid,
                            label: element.orgnm + "(" + element.orgid + ")",
                        });
                    });
                    //orgid.pop(); // 에러방지값 pop()
                })
                .catch((err) => {
                    orgid = [{ value: "null", label: "null" }];
                })
                .finally(() => {});
            return orgid;

        case "orgidY":
            //db 에러로,  조회되지않았을 경우  에러 방지
            let orgidY = [{ value: "", label: "필수선택" }];
            axios
                .post(`${server}/api/cmm/orgidY`, { sessionid: sessionStorage.getItem("SESSION") })
                .then((response) => {
                    response.data.forEach((element) => {
                        orgidY.push({
                            value: element.orgid,
                            label: element.orgnm + "(" + element.orgid + ")",
                        });
                    });
                    orgidY.shift(); // 에러방지값 pop()
                })
                .catch((err) => {
                    orgidY = [{ value: "null", label: "null" }];
                })
                .finally(() => {});
            return orgidY;

        case "deptcd":
            let searchData = [{ value: "", label: "다시 선택해 주세요" }];
            // 부서 검색
            axios
                .post(`${server}/api/cmm/search`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    lvl: lvl,
                    id: id,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        searchData.push({
                            value: element.deptcd,
                            label: element.deptnm,
                        });
                    });
                    searchData.shift(); // 에러방지값 pop()
                    searchData.unshift({ label: "전체", value: "A" });
                })
                .catch((err) => {});
            return searchData;

        case "deptcdAll":
            let searchData2 = [{ value: "null", label: "null" }];

            // 부서 검색_ BulletIn 공지 범위 설정
            axios
                .post(`${server}/api/cmm/searchAll`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    lvl: lvl,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        searchData2.push({
                            value: element.deptcd,
                            label: element.deptnm,
                        });
                    });
                    searchData2.shift(); // 에러방지값 pop()
                })
                .catch((err) => {});
            return searchData2;

        case "deptcdAll2":
            let searchData3 = [{ value: "null", label: "null" }];

            // 부서 검색_UserApply 사용
            axios
                .post(`${server}/api/cmm/searchAll2`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    lvl: lvl,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        searchData3.push({
                            value: element.deptcd,
                            label: element.deptnm,
                        });
                    });
                    searchData3.shift(); // 에러방지값 pop()
                })
                .catch((err) => {});
            return searchData3;

        // 접속한 사용자 권한 상관없이 조직도 전체 검색 필요 (*계정 승인, 계정 업데이트)
        case "deptcdAll4":
            let searchData4 = [{ value: "null", label: "null" }];

            axios
                .post(`${server}/api/cmm/searchAll4`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    lvl: lvl,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        searchData4.push({
                            value: element.deptcd,
                            label: element.deptnm,
                        });
                    });
                    searchData4.shift(); // 에러방지값 pop()
                })
                .catch((err) => {});
            return searchData4;

        case "deptcdAllOrgid":
            let deptcdAllOrgid = [{ value: "null", label: "null" }];
            // 부서 검색
            axios
                .post(`${server}/api/orgDept/Search`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid: orgid2,
                    lvl: lvl,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        deptcdAllOrgid.push({
                            value: element.deptcd,
                            label: element.deptnm,
                        });
                    });
                    deptcdAllOrgid.shift(); // 에러방지값 pop()
                })
                .catch((err) => {});
            return deptcdAllOrgid;

        case "deptcdPmenu":
            let deptcdPmenu = [{ value: "null", label: "변경할 상위 부서가 없습니다." }];
            // 부서 검색
            axios
                .post(`${server}/api/orgDept/SelectPemnu`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid: orgid2,
                    lvl: lvl,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        if (element.deptnm === pdeptnm) {
                        } else {
                            deptcdPmenu.push({
                                value: element.deptcd,
                                label: element.deptnm,
                            });
                        }
                    });
                    deptcdPmenu.shift(); // 에러방지값 pop()
                })
                .catch((err) => {});

            return deptcdPmenu;

        case "deptcdPmenuSeq":
            let deptcdPmenuSeq = [{ value: 1, label: 1 }];
            // 부서 검색
            axios
                .post(`${server}/api/orgDept/SelectPemnuSeq`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid: orgid2,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        deptcdPmenuSeq.push({
                            value: element.seq + 1,
                            label: element.seq + 1,
                        });
                    });
                })
                .catch((err) => {});

            return deptcdPmenuSeq.length;

        case "deptcdPmenuSeq2":
            let deptcdPmenuSeq2 = [{ value: 1, label: 1 }];
            // 부서 검색
            axios
                .post(`${server}/api/orgDept/SelectPemnuSeq`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid: orgid2,
                    pdeptcd: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        deptcdPmenuSeq2.push({
                            value: element.seq,
                            label: element.seq,
                        });
                    });
                    deptcdPmenuSeq2.shift();
                })
                .catch((err) => {})
                .finally(() => {
                    deptcdPmenuSeq2.sort();
                });

            return deptcdPmenuSeq2;

        case "region1":
            let regioncd1 = [{ value: "null", label: "null" }];
            // 부서 검색
            axios
                .post(`${server}/api/cmm/region1`, { sessionid: sessionStorage.getItem("SESSION") })
                .then((res) => {
                    if (allyn == "Y") regioncd1.push({ value: "", label: "전국" });
                    res.data.forEach((element) => {
                        regioncd1.push({
                            value: element.itemcd,
                            label: element.freecol1,
                        });
                    });
                    regioncd1.shift();
                })
                .catch((err) => {});
            return regioncd1;

        case "region2":
            let regioncd2 = [{ value: "null", label: "null" }];
            // 부서 검색
            axios
                .post(`${server}/api/cmm/region2`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    region: pdeptcd,
                })
                .then((res) => {
                    res.data.forEach((element) => {
                        regioncd2.push({
                            value: element.itemcd,
                            label: element.itemnm,
                        });
                    });
                    regioncd2.shift(); // 에러방지값 pop()
                })
                .catch((err) => {});
            return regioncd2;

        case "mdmcd":
            //db 에러로,  조회되지않았을 경우  에러 방지
            let mdmcd = [{ value: "null", label: "null" }];
            getItemCdData("SU200")
                .then((data) => {
                    data.forEach((element) => {
                        mdmcd.push({
                            value: element.itemcd,
                            label: element.itemnm,
                        });
                    });
                    mdmcd.shift(); // 에러방지값 pop()
                })
                .catch((err) => {
                    mdmcd = [{ value: "null", label: "null" }];
                })
                .finally(() => {
                    //마지막으로 에러 방지 처리해야할 것이 있으면 사용
                });

            return mdmcd;

        case "usergrpcd":
            //db 에러로,  조회되지않았을 경우  에러 방지
            let usergrpcd = [{ value: "null", label: "null" }];
            getItemCdData("SU120")
                .then((data) => {
                    data.forEach((element) => {
                        usergrpcd.push({
                            value: element.itemcd,
                            label: element.itemnm,
                        });
                    });
                    usergrpcd.shift(); // 에러방지값 pop()
                })
                .catch((err) => {
                    usergrpcd = [{ value: "null", label: "null" }];
                })
                .finally(() => {
                    //마지막으로 에러 방지 처리해야할 것이 있으면 사용
                });

            return usergrpcd;

        case "expert":
            let expertpcd = [
                { value: "Y", label: "해당" },
                { value: "N", label: "미해당" },
            ];
            return expertpcd;

        default:
            let errorData = [{ value: "null", label: "null" }];
            return errorData;
    }
}

function getItemCdData(code = "SU100") {
    return axios
        .post(`${server}/api/cmm/itemcd`, { sessionid: sessionStorage.getItem("SESSION"), code: code })
        .then((response) => response.data)
        .catch((err) => {});
}

export default cmmGetData;
