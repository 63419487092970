import axios from "axios";
const server = process.env.REACT_APP_API_SERVER_HOST;

function cmmGetTerm() {
    let terms = [{ useterm: "", copyrightterm: "", perinfoterm: "" }];
    cmmGetData()
        .then((data) => {
            data.forEach((element) => {
                terms.unshift({
                    useterm: element.useterm,
                    copyrightterm: element.copyrightterm,
                    perinfoterm: element.perinfoterm,
                });
            });
            terms.pop(); // 에러방지값 pop()
        })
        .catch((err) => {
            terms = [{ useterm: "", copyrightterm: "", perinfoterm: "" }];
        })
        .finally(() => {
            //마지막으로 에러 방지 처리해야할 것이 있으면 사용
        });
    return terms;
}

function cmmGetData() {
    return axios
        .post(`${server}/api/useTerms/getTerm`, {})
        .then((response) => response.data)
        .catch((err) => err);
}

export default cmmGetTerm;
