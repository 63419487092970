import React from "react";

function NotFound({ pathName }) {
    return (
        <center>
            <div style={{ marginTop: "8%" }}>
                <h2>Page Not Found</h2>
                <p>해당 페이지를 찾을 수 없습니다.</p>
                <img alt="404" src="https://media.giphy.com/media/14uQ3cOFteDaU/giphy.gif" />
            </div>
        </center>
    );
}

export default NotFound;
