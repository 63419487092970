import React, { useEffect, useRef, useState } from "react";
//메뉴 관리
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import cmmGetUser from "../../../cmm/cmmGetUser";

//공통함수

//비고 보기
function Remarks(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    let remarksValue = props.updateData.remarks;
    const {
        formState: { errors },
        reset,
    } = useForm();

    const onChangeHanler = (e) => {
        remarksValue = e.target.value;
    };
    return (
        <Modal
            {...props}
            show={props.show}
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            {" "}
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 비고 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <form className="LoginBox">
                        <>
                            <div
                                className="form-group"
                                style={{ width: "500px", height: "500px", marginLeft: "45px", marginBottom: "-40px" }}
                            >
                                <textarea
                                    id="remarks"
                                    defaultValue={remarksValue}
                                    style={{ width: "100%", height: "90%" }}
                                    onChange={onChangeHanler}
                                    maxLength="2000"
                                ></textarea>
                            </div>

                            <div style={{ marginTop: "15px" }}>
                                <Button
                                    className="btn-positive"
                                    variant="secondary"
                                    type="button"
                                    onClick={() => {
                                        axios.post(`${server}/api/menuInfo/remarks`, {
                                            sessionid: sessionStorage.getItem("SESSION"),
                                            menuid: props.updateData.menuid,
                                            remarks: remarksValue,
                                            userid: ID,
                                        });
                                        refreshState
                                            ? dispatch({ type: "refreshFalse" })
                                            : dispatch({ type: "refreshTrue" });
                                        reset();
                                        props.setShow(false);
                                    }}
                                >
                                    수정
                                </Button>

                                <Button
                                    className="btn-negative"
                                    variant="secondary"
                                    onClick={() => {
                                        props.setShow(false);
                                    }}
                                >
                                    취소
                                </Button>
                            </div>
                        </>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default Remarks;
