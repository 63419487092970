import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { Bar, Pie } from "react-chartjs-2"; // chartjs

// 공통함수
import { cmmDateLabels, DatePickerMonthly } from "../../cmm/cmmDateFunction";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import SelectOrg from "../../cmm/cmmSelectOrg";
import cmmGetUser from "../../cmm/cmmGetUser";

// 인쇄용
import ReactToPrint from "react-to-print";

const StatOrg = () => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();

    useEffect(() => {
        (async () => {
            setSessionData(await cmmGetUser());
        })();
        /* home 화면에 셋팅_ 초기화된 비밀번호로 로그인한 계정인지 */
        axios
            .post(`${server}/api/cmm/getStatuscd`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                if (res) {
                    if (res.data[0]) {
                        if (res.data[0].statuscd === "Y1") {
                            /*비밀번호 초기화 화면 */
                            window.location.replace("/updatePwd");
                        }
                    }
                }
            })
            .catch((err) => {});
    }, []);

    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    const [orgnms, setOrgnms] = useState([]); //// 라벨로 쓰이는 기관
    const [barLabels, setBarLabels] = useState([]); //// 상단 좌측 누적 바 년월
    const [barChartData, setBarChartData] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ]); //// 상단 좌측 누적 바 데이터
    const [pieLabels, setPieLabels] = useState([]); //// 상단 우측 파이 레이블
    const [pieChartData, setPieChartData] = useState([]); //// 상단 우측 파이 차트
    const [tableSum, setTableSum] = useState([]); //// 하단 표 데이터
    const [tableData, setTableData] = useState([]); //// 하단 표 데이터

    // 검색조건
    const [lvl, setLvl] = useState(); // 부서
    const [date, setDate] = useState(); // 날짜
    const color = ["rgb(65, 105, 255)", "rgb(255, 165, 0)", "rgb(192, 192, 192)", "rgb(255, 215, 0)"];

    ///////// 인쇄 관련 ////////////////////
    const bodyRef = useRef();

    const getPrintBtn = () => {
        return (
            <Button className="btn-searchStat">
                <FontAwesomeIcon icon={faPrint} /> 출력
            </Button>
        );
    };

    ////////// 업무 로직 ////////////////////////////////////
    useEffect(() => {
        Chart();
    }, [date, lvl, ID]);

    const Chart = () => {
        if (date && lvl && ID) {
            if (
                date.enddt != undefined &&
                date.enddt.includes("-") &&
                date.begindt != undefined &&
                date.begindt.includes("-")
            ) {
                if (date.begindt > date.enddt) {
                    setAlertMessage("E904");
                    return;
                }
                axios
                    .post(`${server}/api/stat/StatOrg`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        startDate: date.begindt,
                        endDate: date.enddt,
                        lvl: lvl.lvl,
                        id: ID,
                    })
                    .then((res) => {
                        //// 하단 표: 가로축
                        let neworgnms = res.data.orgnms;
                        setOrgnms(neworgnms);

                        //// 하단 표: 세로축 및 데이터
                        let newTableSum = res.data.data[0];
                        setTableSum(newTableSum);

                        let newTabData = res.data.data.slice(1, res.data.data.length);
                        setTableData(newTabData);

                        //// 상단 누적 바 차트
                        let newBarLabels = []; //// X축 (년/월)
                        let newBarChartData = new Array().fill([]);
                        for (let i = 1; i < res.data.data.length; i++) {
                            //// 쿼리에서 0은 총합이므로 1부터
                            if (i == 1 || (i > 0 && res.data.data[i - 1].yr != res.data.data[i].yr)) {
                                newBarLabels.push(res.data.data[i].yr + "년\n" + res.data.data[i].mm + "월");
                            } else {
                                newBarLabels.push(res.data.data[i].mm + "월");
                            }
                        }

                        //// 위의 loop 안에서 하려 했는데 마지막 값으로 덮어쳐져서 별도 처리
                        for (let j = 0; j < res.data.orgnms.length; j++) {
                            let newArr = new Array();
                            for (let i = 1; i < res.data.data.length; i++) {
                                //// 쿼리에서 0은 총합이므로 1부터
                                newArr.push(res.data.data[i]["cnt" + j]);
                            }
                            newBarChartData.push(newArr);
                            newArr = null;
                        }

                        setBarLabels(newBarLabels);
                        setBarChartData(newBarChartData);

                        //// 상단 우측 누적 파이 차트
                        let newPieChartData = new Array();
                        if (res.data.data != null && res.data.data.length > 0) {
                            //// 쿼리에서 0은 총합이므로 1부터
                            for (let j = 0; j < neworgnms.length; j++) {
                                newPieChartData.push(res.data.data[0]["cnt" + j]);
                            }
                        }
                        setPieLabels(neworgnms);
                        setPieChartData(newPieChartData);
                    })
                    .catch((err) => {});
            }
        }
    };

    // 누적막대 차트 데이터
    const barData = {
        labels: barLabels,
        datasets: orgnms.map((e, index) => ({
            label: e,
            data: barChartData[index],
            backgroundColor: color[index],
            order: 1,
        })),
    };

    const barOption = {
        tooltips: {
            displayColors: true,
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        legend: {
            labels: { boxWidth: 12 },
            position: "bottom",
        },
        scales: {
            stacked: true,
            xAxes: [
                {
                    stacked: true,
                    ticks: { min: 0, beginAtZero: true },
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    ticks: { min: 0, beginAtZero: true },
                },
            ],
        },
        plugins: {
            datalabels: {
                font: { weight: "bold", size: 16 },
                color: "#000",
                formatter: (value, ctx) => {
                    return ctx.dataset.data[ctx.dataIndex] === 0 ? null : ctx.dataset.data[ctx.dataIndex];
                },
            },
        },
    };

    // 원형 차트 데이터
    const pieData = {
        labels: pieLabels,
        datasets: [
            {
                data: pieChartData,
                backgroundColor: color,
                fill: true,
            },
        ],
    };

    const pieOption = {
        scales: {
            xAxes: [
                {
                    ticks: { display: false },
                    gridLines: { display: false },
                },
            ],
            yAxes: [
                {
                    ticks: { display: false },
                    gridLines: { display: false },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
            position: "bottom",
        },
        tooltips: {
            displayColors: true,
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;

                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                        let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                        let percentage =
                            Math.round((value / sum) * 100) > 0 ? Math.round((value / sum) * 100) + "%" : null;
                        // return `${value}`;
                        return percentage !== null ? percentage + ` (${value})` : "";
                    }
                },
                font: { weight: "bold", size: 16 },
                color: "#000",
            },
        },
    };

    return (
        <div ref={bodyRef}>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <div style={{ backgroundColor: "#F5F5F5" }}>
                <SelectOrg setLvl={setLvl} type="table" />
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td className="style-td">기간</td>
                                <td>
                                    <DatePickerMonthly setDate={setDate} date={date} />
                                </td>
                                <td>
                                    <ReactToPrint trigger={() => getPrintBtn()} content={() => bodyRef.current} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div style={{ display: "flex", margin: "40px 0px 80px 0px" }}>
                <div style={{ width: "50%", height: "30%" }}>
                    <h6 style={{ textAlign: "center" }}>기관별 통계</h6>
                    <div className="graph-stat">
                        <Bar data={barData} options={barOption} id="barCanvas1" />
                    </div>
                </div>

                <div style={{ width: "50%", height: "30%" }}>
                    <h6 style={{ textAlign: "center" }}>기관별 점유율</h6>
                    <div className="graph-stat">
                        <Pie data={pieData} options={pieOption} id="pieCanvas1" />
                    </div>
                </div>
            </div>

            <Table triped bordered responsive>
                <thead className="style-tHeader">
                    <tr style={{ backgroundColor: "#f7f9fa" }}>
                        <th style={{ width: "5%" }}>년</th>
                        <th style={{ width: "5%" }}>월</th>
                        <th style={{ width: "5%" }}>합계</th>
                        {orgnms.map((data, idx) => (
                            <th key={idx} style={{ width: "15%" }}>
                                {data}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>합계</td>
                        <td>-</td>
                        <td>{tableSum.cntsum}&nbsp;건</td>
                        {orgnms.map((e, idx) => {
                            return <td key={idx}>{tableSum["cnt" + idx]}&nbsp;건</td>;
                        })}
                    </tr>
                    {tableData.map((data, rowidx) => (
                        <tr key={rowidx}>
                            {data.rn == "1" ? <td rowSpan={data.rowspan}>{data.yr}년</td> : ""}
                            <td>{data.mm}월</td>
                            <td>{data.cntsum}&nbsp;건</td>
                            {orgnms.map((e, idx) => {
                                return <td key={idx}>{data["cnt" + idx]}&nbsp;건</td>;
                            })}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default StatOrg;
