//Moudle
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { useEffect, useState } from "react";

//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetUser from "../../../cmm/cmmGetUser";

function UpdateStatus(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    let [statCode, setStatCode] = useState("");
    const onSubmitHandler = (e) => {
        switch (statCode) {
            case "C1":
                //휴면 전환
                axios
                    .post(`${server}/api/userInfo/statusC1`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        updateid: ID,
                        userid: props.updateUserData.userid,
                    })
                    .then((res) => {
                        if (res.data) setAlertMessage("I006");
                        else setAlertMessage("E010");
                    })
                    .catch((err) => {
                        setAlertMessage("E010");
                    })
                    .finally(() => {
                        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
                        props.setShow(false);
                    });
                break;
            case "C2":
                //사용중단
                axios
                    .post(`${server}/api/userInfo/statusC2`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        updateid: ID,
                        userid: props.updateUserData.userid,
                    })
                    .then((res) => {
                        if (res.data) setAlertMessage("I006");
                        else setAlertMessage("E010");
                    })
                    .catch((err) => {
                        setAlertMessage("E010");
                    })
                    .finally(() => {
                        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
                        props.setShow(false);
                    });
                break;
            case "C3":
                //계정삭제
                axios
                    .post(`${server}/api/userInfo/statusC3`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        usernm: props.updateUserData.usernm,
                        updateid: ID,
                        userid: props.updateUserData.userid,
                    })
                    .then((res) => {
                        if (res.data) setAlertMessage("I006");
                        else setAlertMessage("E010");
                    })
                    .catch((err) => {
                        setAlertMessage("E010");
                    })
                    .finally(() => {
                        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
                        props.setShow(false);
                    });
                break;
            default:
                break;
        }

        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
    };
    const handleOptionChange = (changeEvent) => {
        setStatCode(changeEvent.target.value);
    };
    return (
        <div>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="outbox-updateStatus">
                    <div className="txt-title">
                        <span> 계정 상태 변경 </span>
                        {/* <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">계정 상태 변경</Modal.Title>
                        </Modal.Header> */}
                    </div>
                    <hr className="headerline"></hr>
                    <Modal.Body>
                        <>
                            <form>
                                <div className="inbox-updateStatus">
                                    <input type="radio" value="C1" name="changeStatus" onChange={handleOptionChange} />
                                    <span className="txt-updateStatus"> 휴면 전환</span>
                                    <span className="txt-explanation">
                                        {" "}
                                        (30일 이상 로그인하지 않은 경우, 계정이 휴면 전환됩니다.){" "}
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <input type="radio" value="C2" name="changeStatus" onChange={handleOptionChange} />
                                    <span className="txt-updateStatus"> 사용 중단</span>
                                    <span className="txt-explanation"> (휴직의 경우, 계정이 중단됩니다.) </span>
                                    <br></br>
                                    <br></br>
                                    <input type="radio" value="C3" name="changeStatus" onChange={handleOptionChange} />
                                    <span className="txt-updateStatus"> 계정 삭제</span>
                                    <span className="txt-explanation"> (퇴사의 경우, 계정을 삭제합니다.) </span>
                                    <br></br>
                                </div>
                                <div>
                                    {/* <Button   className="btn-positive"  style={{ color:"#00005c",   backgroundColor: "#aa182c",margin: "5%"}} type="button" onClick={onSubmitHandler}>
                                확인
                            </Button> */}

                                    <Button className="btn-positive" type="button" onClick={onSubmitHandler}>
                                        확인
                                    </Button>
                                    <Button
                                        className="btn-negative"
                                        type="button"
                                        onClick={() => {
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        </>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
}
export default UpdateStatus;
