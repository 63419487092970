import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../../css/userModal.css";
import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

// 부트스트랩
import { Button, Table } from "react-bootstrap";
// 공통함수
import { cmmDateLabels, DatePickerMonthly } from "../../cmm/cmmDateFunction";
import SelectOrg from "../../cmm/cmmSelectOrg";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
// chartjs
import { HorizontalBar, Pie, Bar } from "react-chartjs-2";
import cmmGetUser from "../../cmm/cmmGetUser";

// 인쇄
import ReactToPrint from 'react-to-print';



// 화재 원인별 통계
function StatReason() {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    const colors = ['#005cc5', '#ff7f00', '#9c9c9c'];

    ////////////// 인쇄 버튼 관련 /////////////////////////////////
    const bodyRef = useRef();
        
    const getPrintBtn = () =>{
        return (
            <Button className="btn-searchStat">
                <FontAwesomeIcon icon={faPrint} /> 출력
            </Button>
        );
    }

    /////////////// 업무 로직 ///////////////////////////////////////////
    //// 검색조건
    const [lvl, setLvl] = useState(); // 조직
    const [date, setDate] = useState(); // 날짜
    const [aiBtn, setAiBtn] = useState("A"); // AI 집계 기준 radio 버튼 값

    //// 화재 종류 (단락흔/열흔/판단불가)
    const [firetypes, setFiretypes] = useState([]);

    //// 컬럼폭: 연/월 5%씩, AI/전문가 불일치 6%로 잡고, 나머지 컬럼수로 균등분할
    const [colwidth, setColwidth] = useState([]);
    const [monthlabels, setMonthlabels] = useState([]); //// 그래프 기간
    const [matchrate, setMatchrate] = useState([]);     //// 일치율 데이터 값
    const [tablesum, setTablesum] = useState([]);       //// 하단 표 합계 열 (= 상단 12개월 종합 결과 데이터)
    const [tabledata, setTabledata] = useState([]);     //// 하단 표 몸통
    const [monthlyfinal, setMonthlyfinal] = useState([]);   //// 월별건수 - 최종
    const [monthlyexp, setMonthlyexp] = useState([]);       //// 월별건수 - 전문가
    const [monthlyai, setMonthlyai] = useState([]);         //// 월별건수 - AI
    
    const Chart = () => {
        // 12개월 종합 – 최종
        axios
            .post(`${server}/api/stat/statReason/`, {
                sessionid: sessionStorage.getItem("SESSION"),
                startDate: date.begindt,
                endDate: date.enddt,
                lvl: lvl.lvl,
                ai: aiBtn,
                id: ID,
            })
            .then((res) => {
                //// 화재 탐지결과 종류
                let newFiretypes = res.data.firetypes;
                setFiretypes(newFiretypes);

                //// 하단 데이터 폭
                let newColwidth =
                    res.data.firetypes != null && res.data.firetypes.length > 0
                        ? Math.floor((84 / (res.data.firetypes.length + 1) / 3) * 10) / 10 + "%"
                        : "0%";
                setColwidth(newColwidth);

                let newMatchrate = [];
                let newTablesum = [];
                let newTabledata = [];
                let newMonthlabels = [];
                let newMonthlyfinal = new Array(res.data.firetypes.length);
                let newMonthlyexp = new Array(res.data.firetypes.length);
                let newMonthlyai = new Array(res.data.firetypes.length);
                if (res.data.data!=null){
                    newTablesum = res.data.data[0];
                    newMatchrate.push(res.data.data[0].cnt_exp_sum - res.data.data[0].cntdiff);
                    newMatchrate.push(res.data.data[0].cntdiff);
                    setMatchrate(newMatchrate);

                    if (res.data.data.length > 1) {
                        newTabledata = Array.from(res.data.data).splice(1);
                        for (let i = 1; i < res.data.data.length; i++) {
                            if (res.data.data[i].rn == "1")
                                newMonthlabels.push(res.data.data[i].yr + "년\n" + res.data.data[i].mm + "월");
                            else newMonthlabels.push(res.data.data[i].mm + "월");
                        }
                    }

                    for (let j = 0; j < res.data.firetypes.length; j++) {
                        newMonthlyfinal[j] = [];
                        newMonthlyexp[j] = [];
                        newMonthlyai[j] = [];
                        for (let i = 1; i < res.data.data.length; i++) {
                            newMonthlyfinal[j].push(res.data.data[i]["cnt_final_" + j]);
                            newMonthlyexp[j].push(res.data.data[i].cnt_exp_sum);
                            newMonthlyai[j].push(res.data.data[i].cnt_ai_sum);
                        }
                    }
                }

                setTablesum(newTablesum);
                setTabledata(newTabledata);
                setMonthlabels(newMonthlabels);
                setMonthlyfinal(newMonthlyfinal);
                setMonthlyexp(newMonthlyexp);
                setMonthlyai(newMonthlyai);
            });
    };

    useEffect(() => {
        if (date && ID) {
            Chart();
        }
    }, [aiBtn, date, lvl, ID]);

    //// 12개월 종합결과
    const horizontalBarData = {
        labels: ["최종", "전문가", "AI"],
        datasets: firetypes.map((e, idx) => ({
            label: e.itemnm,
            data: [tablesum["cnt_final_" + idx], tablesum["cnt_exp_" + idx], tablesum["cnt_ai_" + idx]],
            backgroundColor: e.freecol1,
            borderColor: "transparent",
        })),
    };

    //// 전문가-AI 일치율
    const pieData = {
        labels: ["일치", "불일치"],
        datasets: [
            {
                label: ["일치", "불일치"],
                data: matchrate,
                backgroundColor: ["#005cc5", "#ff7f00"],
            },
        ],
    };

    //// 월별 건수 - 최종
    const finalBarData = {
        labels: monthlabels,
        datasets: firetypes.map((e, idx) => ({
            label: e.itemnm,
            data: monthlyfinal[idx],
            backgroundColor: e.freecol1,
            borderColor: "transparent",
        })),
    };

    //// 월별 건수 - 전문가 차트
    const expBarData = {
        labels: monthlabels,
        datasets: firetypes.map((e, idx) => ({
            label: e.itemnm,
            data: monthlyexp[idx],
            backgroundColor: e.freecol1,
            borderColor: "transparent",
        })),
    };

    // 월별 건수 - AI차트
    const aiBarData = {
        labels: monthlabels,
        datasets: firetypes.map((e, idx) => ({
            label: e.itemnm,
            data: monthlyai[idx],
            backgroundColor: e.freecol1,
            borderColor: "transparent",
        })),
    };

    // 12개월 종합 결과, 전문가-AI일치율 차트 옵션
    const horizontalBarOption = {
        scales: {
            xAxes: [
                {
                    stacked: true,
                    ticks: {
                        fontSize: 14,
                        display: false,
                        beginAtZero: true,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
            position: "left",
        },
        tooltips: {
            displayColors: true,
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                font: {
                    weight: "bold",
                    size: 16,
                },
                color: "#fff",
                formatter: (value, ctx) => {
                    return ctx.dataset.data[ctx.dataIndex] === 0 ? null : ctx.dataset.data[ctx.dataIndex];
                },
            },
        },
    };
    const pieOption = {
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: false,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        display: false,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
            position: "bottom",
        },
        tooltips: {
            displayColors: true,
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;

                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                        let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                        let percentage =
                            Math.round((value / sum) * 100) > 0 ? Math.round((value / sum) * 100) + "%" : null;
                        return percentage;
                    }
                },
                font: {
                    weight: "bold",
                    size: 16,
                },
                color: "#fff",
            },
        },
    };

    // 월변건수 차트 옵션
    const options = {
        animation: {
            duration: 600,
        },
        scales: {
            xAxes: [
                {
                    stacked: true,
                    ticks: {
                        stepSize: 1,
                        fontSize: 14,
                        display: true,
                    },
                    gridLines: {
                        display: true,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        min: 0,
                    },
                    stacked: true,
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
        },
        tooltips: {
            displayColors: true,
            callbacks: {
                mode: "single",
                labelColor: function (tooltipItem, chart) {
                    return {
                        borderColor: "transparent",
                        backgroundColor: horizontalBarData.datasets[tooltipItem.datasetIndex].backgroundColor,
                    };
                },
                label: function (tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${
                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === null
                            ? 0
                            : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    }`;
                },
            },
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    return ctx.dataset.data[ctx.dataIndex] === 0 ? null : ctx.dataset.data[ctx.dataIndex];
                },
                color: "#fff",
            },
        },
    };

    return (
        <div ref={bodyRef}>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <div style={{ backgroundColor: "#F5F5F5" }}>
                <SelectOrg setLvl={setLvl} type="table" />
                <div className="style-searchBoxSolo">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td className="style-td">기간 &nbsp;&nbsp;</td>
                                <td style={{ width: "700px" }}>
                                    <DatePickerMonthly setDate={setDate} date={date} />
                                </td>
                                <td className="style-td" style={{ width: "145px" }}>
                                    AI 집계 기준
                                </td>
                                <td className="style-td" style={{ width: "500px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name="ai"
                                            value="A"
                                            defaultChecked={true}
                                            onChange={(e) => {
                                                setAiBtn(e.target.value);
                                            }}
                                            style={{ marginLeft: "-20px" }}
                                        />
                                        <span style={{ marginLeft: "20px" }}>전체</span>
                                    </label>
                                    &nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="radio"
                                            name="ai"
                                            value="B"
                                            onChange={(e) => {
                                                setAiBtn(e.target.value);
                                            }}
                                            style={{ marginLeft: "50px" }}
                                        />
                                        <span style={{ marginLeft: "20px" }}>AI기준으로 확정된 값만</span>
                                    </label>
                                </td>
                                <td>
                                    <ReactToPrint
                                        trigger={() => getPrintBtn()}
                                        content={() => bodyRef.current}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "55%" }}>
                        <h4 className="style-h4">* 기간내 결과 종합</h4>
                        <div className="graph-stat">
                            <HorizontalBar data={horizontalBarData} options={horizontalBarOption} height="100px" />
                        </div>
                    </div>
                    <div style={{ width: "45%" }}>
                        <h4 className="style-h4">* 전문가-AI 일치율</h4>
                        <div className="graph-stat">
                            <Pie data={pieData} options={pieOption} height="120px" />
                        </div>
                    </div>
                </div>
                <br />
                <div>
                    <h4 className="style-h4" style={{ marginBottom: "15px" }}>
                        * 월별 건수
                    </h4>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "33%" }}>
                            <h4 className="style-h4">최종</h4>
                            <div className="graph-stat">
                                <Bar data={finalBarData} options={options} />
                            </div>
                        </div>
                        <div style={{ width: "33%" }}>
                            <h4 className="style-h4">전문가</h4>
                            <div className="graph-stat">
                                <Bar data={expBarData} options={options} />
                            </div>
                        </div>
                        <div style={{ width: "33%" }}>
                            <h4 className="style-h4">AI</h4>
                            <div className="graph-stat">
                                <Bar data={aiBarData} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {/* 데이터가 있으면 표가 보임 */}
            <div>
                <h4 className="style-h4">* 종합 집계</h4>
                <Table triped bordered responsive>
                    <thead className="style-tHeader">
                        <tr style={{ backgroundColor: "#f7f9fa" }}>
                            <th colSpan="2">
                                <label>기간</label>
                            </th>
                            <th colSpan={firetypes.length + 1}>
                                <label>인공지능 판별 결과</label>
                            </th>
                            <th colSpan={firetypes.length + 1}>
                                <label>전문가 판별 결과</label>
                            </th>
                            <th colSpan={firetypes.length + 1}>
                                <label>최종 판별 결과</label>
                            </th>
                            <th rowSpan="2">전문가 ≠ AI</th>
                        </tr>
                        <tr style={{ backgroundColor: "#f7f9fa" }}>
                            {/* 기간 */}
                            <th style={{ width: "5%" }}>
                                <label>년</label>
                            </th>
                            <th style={{ width: "5%" }}>
                                <label>월</label>
                            </th>

                            {[...Array(3)].map((e1, idx1) => (
                                <>
                                    {firetypes.map((e2, idx2) => {
                                        return (
                                            <th key={idx1 * 10 + idx2} style={{ width: colwidth }}>
                                                <label>{e2.itemnm}</label>
                                            </th>
                                        );
                                    })}
                                    <th key={idx1} style={{ width: colwidth }}>
                                        <label>합계</label>
                                    </th>
                                </>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>합계</td>
                            <td>-</td>
                            {/* 인공지능 판별 결과 */}
                            {firetypes.map((e, idx) => {
                                return <td key={idx}>{tablesum["cnt_ai_" + idx]}건</td>;
                            })}
                            <td>{tablesum.cnt_ai_sum}&nbsp;건</td>

                            {/* 전문가 판별 결과 */}
                            {firetypes.map((e, idx) => {
                                return <td key={idx}>{tablesum["cnt_exp_" + idx]}건</td>;
                            })}
                            <td>{tablesum.cnt_exp_sum}&nbsp;건</td>

                            {/* 최종 판별 결과 */}
                            {firetypes.map((e, idx) => {
                                return <td key={idx}>{tablesum["cnt_final_" + idx]}건</td>;
                            })}
                            <td>{tablesum.cnt_final_sum}&nbsp;건</td>

                            {/* 전문가 ≠ AI */}
                            <td>{tablesum.cntdiff}건</td>
                        </tr>
                        {tabledata.map((e, ridx) => (
                            <>
                                <tr key={ridx}>
                                    {e.rn == 1 ? <td rowSpan={e.span}>{e.yr}년</td> : ""}
                                    <td>{e.mm}월</td>

                                    {firetypes.map((f, idx) => {
                                        return <td key={ridx * 1000 + idx}>{e["cnt_ai_" + idx]}건</td>;
                                    })}
                                    <td>{e.cnt_ai_sum}&nbsp;건</td>

                                    {/* 전문가 판별 결과 */}
                                    {firetypes.map((f, idx) => {
                                        return <td key={ridx * 1000 + 100 + idx}>{e["cnt_exp_" + idx]}건</td>;
                                    })}
                                    <td>{e.cnt_exp_sum}&nbsp;건</td>

                                    {/* 최종 판별 결과 */}
                                    {firetypes.map((f, idx) => {
                                        return <td key={ridx * 1000 + 200 + idx}>{e["cnt_final_" + idx]}건</td>;
                                    })}
                                    <td>{e.cnt_final_sum}&nbsp;건</td>

                                    {/* 전문가 ≠ AI */}
                                    <td>{e.cntdiff}건</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default StatReason;
