import "../../css/Sign.css";
import "../../css/userModal.css";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import setAlertMessage from "../../cmm/cmmAlertMessage";
import cmmGetData from "../../cmm/cmmGetData";
import cmmGetTerm from "../../cmm/cmmGetTerm";
import { useDispatch } from "react-redux";
import cmmGetUser from "../../cmm/cmmGetUser";

function SignUp(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; // .env 파일에서 가져온 db_config

    const orgcdOptions = cmmGetData("orgcd"); //소속 기관
    const [orgcd, setOrg] = useState(orgcdOptions[0].value); // 소속기관
    let [watchPwd, setWatchPwd] = useState("");
    let terms = cmmGetTerm(); //이용약관 array
    //useterm ,copyrightterm , perinfoterm view 체크
    let [useCheck, setUseCheck] = useState(false);
    let [copyCheck, setCopyCheck] = useState(false);
    let [perinCheck, setPerinCheck] = useState(false);

    const refLvl2 = useRef();
    const refLvl3 = useRef();
    const refLvl4 = useRef();

    // 부서코드
    const [lvl, SetLvl] = useState(null);
    const [lvl3, SetLvl3] = useState(null);

    const dispatch = useDispatch();
    //input 관련 hooks
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm({});

    const onClickHandler = (event) => {
        let title = event.target.value;
        let index = 0;
        let titleMsg = "";
        let msgBody = "";

        switch (title) {
            case "useterm":
                titleMsg = "이용 약관";
                msgBody = terms[0].useterm ? terms[0].useterm : "이용약관이 없습니다.";
                index = 0;
                break;
            case "copyrightterm":
                titleMsg = "저작권 보호 정책";
                msgBody = terms[0].copyrightterm ? terms[0].copyrightterm : "저작권 보호 정책이 없습니다.";

                index = 1;
                break;
            case "perinfoterm":
                titleMsg = "개인정보 이용 정책";
                msgBody = terms[0].perinfoterm ? terms[0].perinfoterm : "개인정보 이용 정책이 없습니다.";
                index = 2;
                break;
            default:
                title = "에러";
                break;
        }

        Swal.fire({
            icon: "warning",
            title: titleMsg,
            html: `
                <pre style ="
                overflow-y: initial !important;
                white-space: pre-wrap;
                height : 550px;
                text-align: left;
                font-family: 'NotoSansKR-Demilight';
                ">${msgBody}
                </pre>`,

            confirmButtonText: "동의",
            cancelButtonText: "거절",
            width: "50%",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                //동의 버튼 누를 경우
                switch (index) {
                    case 0:
                        setUseCheck(true);
                        break;
                    case 1:
                        setCopyCheck(true);
                        break;
                    case 2:
                        setPerinCheck(true);
                        break;
                    default:
                        break;
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                //거절버튼 누를 경우
                switch (index) {
                    case 0:
                        setUseCheck(false);
                        break;
                    case 1:
                        setCopyCheck(false);
                        break;
                    case 2:
                        setPerinCheck(false);
                        break;
                    default:
                        break;
                }
            }
        });
    };
    // submit 이벤트 처리
    const onSubmit = async (data) => {
        if (useCheck && copyCheck && perinCheck && lvl && orgcd) {
            axios
                .post(`${server}/api/sign/checkId`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    userid: data.userid,
                })
                .then((check) => {
                    //아이디 중복 아님
                    if (check.data) {
                        axios
                            .post(`${server}/api/userApply/apply`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                userid: data.userid,
                                usernm: data.usernm,
                                cellphone: data.cellphone,
                                email: data.email,
                                orgcd: orgcd,
                                deptcd: lvl,
                                birthdt: data.birthdt,
                                pwd: data.pwd,
                            })
                            .then((response) => {
                                //회원가입 요청 성공
                                if (response.data) {
                                    setAlertMessage("I002").then(() => {
                                        reset();
                                        setCopyCheck(false);
                                        setPerinCheck(false);
                                        setUseCheck(false);
                                        window.location.replace("/");
                                    });
                                    //ID중복 검사 및 회원가입 요청 성공시에만 reset
                                }
                                //회원가입 요청 실패(어떠한 이유던지 쿼리 비정상 실행)
                                else {
                                    setAlertMessage("E003");
                                }
                            })
                            .catch(() => {
                                //연결 실패
                                setAlertMessage("E000");
                            });
                    }
                    //아이디 중복
                    else {
                        setAlertMessage("E002");
                    }
                })
                .catch(() => {
                    //db연결 실패
                    setAlertMessage("E000");
                });
        } else {
            // setAlertMessage("E007"); //모든약관을 확인해주세요

            if (useCheck === false) {
                setAlertMessage("E004"); //이용약관을 확인해주세요
            } else if (copyCheck === false) {
                setAlertMessage("E005"); //저작권 약관을 확인해주세요
            } else if (perinCheck === false) {
                setAlertMessage("E006"); //개인정보 이용정책을 확인해주세요
            } else {
                setAlertMessage("E011"); //개인정보 이용정책을 확인해주세요
            }
        }
    };

    return (
        <div>
            {" "}
            <form className="form-wrap-signUp" onSubmit={handleSubmit(onSubmit)}>
                <div className="txt-title">
                    <span>계정 신청</span>
                </div>
                <hr className="headline-signUp"></hr>
                <div className="form-group">
                    <label class="required">ID</label>
                    <input
                        {...register("userid", {
                            required: "필수정보입니다.",
                            pattern: {
                                value: /^[a-zA-Z0-9]{6,8}$/,
                                message: "6~8 자리의 영문 대 소문자, 숫자를 사용하세요. (특수기호, 공백 사용 불가)",
                            },
                        })}
                        type="text"
                        className="form-control"
                        placeholder="영문과 숫자만을 포함한 6 ~ 8 자리를 입력해주세요"
                    />
                </div>
                {errors.userid && <span className="span-box">{errors.userid.message}</span>}

                <div className="form-group">
                    <label class="required">성명</label>
                    <input
                        {...register("usernm", {
                            required: "필수정보입니다.",
                            pattern: {
                                value: /^[가-힣a-zA-Z]+$/,
                                message: "한글과 영문 대 소문자를 사용하세요. (특수기호, 공백 사용 불가)",
                            },
                        })}
                        type="text"
                        className="form-control"
                        placeholder="성명을 입력해주세요"
                        maxLength={20}
                    />
                </div>
                {errors.usernm && <span className="span-box">{errors.usernm.message}</span>}
                <div className="form-group">
                    <label class="required">핸드폰 번호</label>
                    <input
                        {...register("cellphone", {
                            required: "필수정보입니다.",
                            pattern: {
                                value: /^[0-9]{11}$/,
                                message: "올바른 형식의 전화번호를 입력하세요",
                            },
                        })}
                        type="Number"
                        className="form-control"
                        placeholder=" - 없이 핸드폰 번호를 입력해주세요"
                    />
                </div>
                {errors.cellphone && <span className="span-box">{errors.cellphone.message}</span>}

                <div className="form-group">
                    <label class="required">이메일</label>
                    <input
                        {...register("email", {
                            required: "필수정보입니다.",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "올바른 형식의 이메일을 입력하세요",
                            },
                        })}
                        type="email"
                        className="form-control"
                        placeholder="메일 주소를 입력해주세요"
                        maxLength={100}
                    />
                </div>
                {errors.email && <span className="span-box">{errors.email.message}</span>}

                <div className="form-group">
                    <label class="required">소속</label>
                    <Select
                        className="selector-single2"
                        options={orgcdOptions}
                        onChange={(e) => {
                            setOrg(e.value);
                            if (refLvl3.current) {
                                if (refLvl3.current.state.value) {
                                    refLvl3.current.state.value.label = "*선택";
                                    SetLvl3(null);
                                    SetLvl(null);
                                }
                                if (refLvl4.current) {
                                    if (refLvl4.current.state.value) {
                                        refLvl4.current.state.value.label = "*선택";
                                    }
                                }
                            }
                        }}
                        defaultValue={{ label: "선택" }}
                    />
                </div>
                <div className="form-group">
                    <label class="required">부서</label>

                    <Select
                        className="selector-double"
                        options={cmmGetData("deptcdAll4", 3, orgcd)}
                        onChange={(e) => {
                            SetLvl3(e.value);
                            SetLvl(null);
                            if (refLvl4.current) {
                                if (refLvl4.current.state.value) {
                                    refLvl4.current.state.value.label = "*선택";
                                }
                            }
                        }}
                        defaultValue={{ label: "*선택" }}
                        ref={refLvl3}
                    />
                    <Select
                        className="selector-double"
                        options={cmmGetData("deptcdAll4", 4, lvl3)}
                        onChange={(e) => {
                            SetLvl(e.value);
                        }}
                        defaultValue={{ label: "*선택" }}
                        ref={refLvl4}
                    />
                </div>

                <div className="form-group">
                    <label class="required">생년월일</label>
                    <input
                        {...register("birthdt", {
                            required: "필수정보입니다.",
                            pattern: {
                                value: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
                                message: "올바른 형식의 생년월일을 입력하세요",
                            },
                        })}
                        type="date"
                        className="form-control"
                        placeholder="1900-01-01 형식으로 생년월일을 입력해주세요"
                    />
                </div>
                {errors.birthdt && <span className="span-box">{errors.birthdt.message}</span>}

                <div className="form-group">
                    <label class="required">비밀번호</label>
                    <input
                        {...register("pwd", {
                            required: "필수정보입니다.",

                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                                message: "1개 이상의 특수문자 포함 8 ~ 16 자로 입력해주세요",
                            },
                        })}
                        onChange={() => {
                            setWatchPwd(watch("pwd"));
                        }}
                        type="password"
                        className="form-control"
                        placeholder="1개 이상의 특수문자 포함 8 ~ 16 자로 입력해주세요"
                    />
                </div>
                {errors.pwd && <span className="span-box">{errors.pwd.message}</span>}

                <div className="form-group">
                    <label class="required">비밀번호 확인</label>
                    <input
                        {...register("checkPwd", {
                            required: "필수정보입니다.",
                            validate: {
                                check: (value) => value === watchPwd || "비밀번호가 일치하지 않습니다.",
                            },
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                                message: "위의 비밀번호와 일치한 값을 입력하세요",
                            },
                        })}
                        onChange={(e) => {
                            setWatchPwd(watch("pwd"));
                        }}
                        type="password"
                        className="form-control"
                        placeholder="위의 비밀번호와 일치한 값을 입력해주세요"
                    />
                </div>
                {errors.checkPwd && <span className="span-box">{errors.checkPwd.message}</span>}

                <div className="div-wrap-btn">
                    <div className="div-textAlign">
                        <ul className="style-ul">
                            <li className="style-li">
                                <span className="txt-policy">이용 약관</span>
                                <Button className="btn-policy" variant="link" value="useterm" onClick={onClickHandler}>
                                    보기
                                </Button>
                            </li>
                            <li className="style-li">
                                <span className="txt-policy">저작권 보호 정책</span>
                                <Button
                                    className="btn-policy"
                                    variant="link"
                                    value="copyrightterm"
                                    onClick={onClickHandler}
                                >
                                    보기
                                </Button>
                            </li>
                            <li className="style-li">
                                <span className="txt-policy">개인정보 이용 정책</span>
                                <Button
                                    className="btn-policy"
                                    variant="link"
                                    value="perinfoterm"
                                    onClick={onClickHandler}
                                >
                                    보기
                                </Button>
                            </li>
                        </ul>
                    </div>
                    <div className="div-wrap-btn">
                        <Button className="btn-register" type="submit">
                            계정신청
                        </Button>
                        <Button
                            className="btn-registerNo"
                            onClick={() => {
                                window.location.replace("/");
                            }}
                        >
                            취소
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SignUp;
