import "./css/App.css";

import { Navbar, Container, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

//review
import ReviewExpert from "./pages/review/ReviewExpert";
import ReviewReport from "./pages/review/ReviewReport";
//sign
import Logout from "./pages/sign/Logout";
import SignIn from "./pages/sign/SignIn";

//stat
import StatRegion from "./pages/stat/StatRegion";
import StatOrg from "./pages/stat/StatOrg";
import StatReason from "./pages/stat/StatReason";
import StatFacility from "./pages/stat/StatFacility";
import StatUser from "./pages/stat/StatUser";
//sys
import MenuInfo from "./pages/sys/MenuInfo";
import MenuAuth from "./pages/sys/MenuAuth";
import OrgHis from "./pages/sys/OrgHis";
import OrgDept from "./pages/sys/OrgDept";
import OrgAuth from "./pages/sys/OrgAuth";
import WeightInfo from "./pages/sys/WeightInfo";
import MsgInfo from "./pages/sys/MsgInfo";
//user
import UserInfo from "./pages/user/UserInfo";
import UserApply from "./pages/user/UserApply";
import UseTerms from "./pages/user/UseTerms";

import SignUp from "./pages/sign/SignUp";

// Bulletin
import Bulletin from "./pages/bullet/Bulletin";
import BulletinContent from "./pages/bullet/BulletinContent";
import BulletinAdd from "./pages/bullet/BulletinAdd";
import BulletinReply from "./pages/bullet/BulletinReply";
import BulletinModify from "./pages/bullet/BulletinModify";

// LabelTool
import LabelTool from "./pages/sys/LabelTool";

// Upload
import FileStorageMenu from "./pages/sys/FileStorageMenu";

import NotFound from "./cmm/cmmNotFound";
import UpdatePwd from "./pages/user/UpdatePwd";

import cmmGetMenuName from "./cmm/cmmGetMenuName";
import cmmGetUser from "./cmm/cmmGetUser";

import { useEffect, useState } from "react";
import axios from "axios";

const server = process.env.REACT_APP_API_SERVER_HOST; // .env 파일에서 가져온 db_config

function App() {
  /*=====사용자 인증====*/
  const [sessionData, setSessionData] = useState();

  useEffect(() => {
    (async () => {
      setSessionData(await cmmGetUser());
      await cmmGetMenuName(window.location.pathname).then((data) => {
        sessionStorage.setItem("name", data[1]);
        sessionStorage.setItem("name2", data[0]);
      });
    })();
  }, [sessionStorage.getItem("SESSION")]);

  const ID = sessionData ? sessionData.user : null;
  const AUTH = sessionData ? sessionData.usergrpcd : null;
  // 새로고침
  const [refresh, setRefresh] = useState(true);
  /*=============*/
  const HeaderNav = () => {
    let [NavData, SetNavData] = useState([{ menunm: "", url: "" }]);
    let checkID = "";
    //로그인 성공 시(세션에 아이디값 저장)
    useEffect(() => {
      axios
        .post(`${server}/api/cmm/getNavData`, {
          sessionid: sessionStorage.getItem("SESSION"),
          userid: ID,
        })
        .then((res) => {
          SetNavData(res.data);
        })
        .catch((err) => {});
    }, []);
    return (
      <>
        <Navbar
          variant="light"
          style={{
            //background: "#282828",
            border: "#ffffff",
          }}
        >
          <Container className="justify-content-start" style={{ width: "400px", margin: "20px 0 0 5px" }}>
            <Navbar.Brand href={sessionStorage.getItem("SESSION") ? "/statOrg" : "/"}>
              {" "}
              <img className="brand-logo" src={process.env.PUBLIC_URL + "/logo/logo.png"} alt="copy url" />
              {/* <span className="brand-title">한국전기안전공사</span> */}
              {/*
                            NavDropdown (ex 게시판)
                            Nav.Item(ex logout )
                            2개 css 주면 nav menu 셋팅 됨 
                            */}
            </Navbar.Brand>
          </Container>
          <Container className="container-dd">
            <Nav variant="pills">
              {NavData
                ? NavData.map((data, index) => {
                    {
                      checkID = data.menuid + "";
                    }
                    return (
                      <div key={index}>
                        {data.lvl === 1 ? (
                          <>
                            <NavDropdown
                              title={
                                <span
                                  style={{
                                    color:
                                      window.sessionStorage.getItem("name") === data.menunm
                                        ? "rgb(255, 0, 0)"
                                        : "rgb(68, 68, 68)",
                                    fontWeight:
                                      window.sessionStorage.getItem("name") === data.menunm ? "bold" : "nomal",
                                  }}
                                >
                                  {data.menunm}
                                </span>
                              }
                              id="nav-dropdown"
                              style={{ marginLeft: "40px" }}
                            >
                              {NavData.map((data2, index) => {
                                return (
                                  <>
                                    {data2.lvl === 2 && (data2.menuid + "").substring(0, 2) === checkID ? (
                                      <NavDropdown.Item
                                        key={data2.menuid}
                                        as={Link}
                                        to={"/" + data2.url}
                                        onClick={() => {
                                          window.sessionStorage.setItem("name", data.menunm);
                                          window.sessionStorage.setItem("name2", data2.menunm);
                                          setRefresh(!refresh);
                                        }}
                                      >
                                        <span
                                          style={{
                                            color:
                                              window.sessionStorage.getItem("name2") === data2.menunm
                                                ? "rgb(255, 0, 0)"
                                                : "rgb(68, 68, 68)",
                                            fontWeight:
                                              window.sessionStorage.getItem("name2") === data2.menunm
                                                ? "bold"
                                                : "nomal",
                                          }}
                                        >
                                          {data2.menunm}
                                        </span>
                                      </NavDropdown.Item>
                                    ) : null}
                                  </>
                                );
                              })}
                            </NavDropdown>
                          </>
                        ) : null}
                      </div>
                    );
                  })
                : null}
              {/* <Nav.Item>
                                <Nav.Link>{ID}</Nav.Link>
                            </Nav.Item> */}
            </Nav>
          </Container>
          <Nav.Item
            className="btn-table"
            style={{
              width: "91px",
              borderRadius: "2px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            <Nav.Link as={Link} to={"/logOut"} style={{ color: "rgb(61, 61, 61)", textAlign: "center", padding: "5px" }}>
              {ID ? "로그아웃" : null}
            </Nav.Link>
          </Nav.Item>
        </Navbar>
      </>
    );
  };

  return (
    <div className="root" id="root">
      <Helmet>
        {/* 홈페이지 이름 */}
        <title>전기안전공사</title>
      </Helmet>

      <div className="wrap">
        <header>{sessionData ? <HeaderNav /> : null}</header>
        <hr />
        <section>
          <Route exact path="/" component={sessionStorage.getItem("SESSION") ? StatOrg : SignIn} />

          <Switch>
            {/* ===============게시판================*/}
            <RouteCheck path="/bulletin" component={Bulletin} id={ID} auth={AUTH} url={"bulletin"} />
            {sessionData ? <Route path="/bulletinContent" component={BulletinContent} url={"bulletinContent"} /> : null}
            {sessionData ? <Route path="/bulletinAdd" component={BulletinAdd} url={"bulletinAdd"} /> : null}
            {sessionData ? <Route path="/bulletinReply" component={BulletinReply} url={"bulletinReply"} /> : null}
            {sessionData ? <Route path="/bulletinModify" component={BulletinModify} url={"bulletinModify"} /> : null}

            {/* ===================================== */}

            {/* ========화재 상세 정보====== */}
            <RouteCheck path="/reviewExpert" component={ReviewExpert} id={ID} auth={AUTH} url={"reviewExpert"} />
            <RouteCheck path="/reviewReport" component={ReviewReport} id={ID} auth={AUTH} url={"reviewReport"} />
            {/* =========================== */}

            {/* ========시스템 관리====== */}
            <RouteCheck path="/menuInfo" component={MenuInfo} id={ID} auth={AUTH} url={"menuInfo"} />
            <RouteCheck path="/menuAuth" component={MenuAuth} id={ID} auth={AUTH} url={"menuAuth"} />
            <RouteCheck path="/orgHis" component={OrgHis} id={ID} auth={AUTH} url={"orgHis"} />
            <RouteCheck path="/orgDept" component={OrgDept} id={ID} auth={AUTH} url={"orgDept"} />
            <RouteCheck path="/orgAuth" component={OrgAuth} id={ID} auth={AUTH} url={"orgAuth"} />
            <RouteCheck path="/weightInfo" component={WeightInfo} id={ID} auth={AUTH} url={"weightInfo"} />
            <RouteCheck path="/msgInfo" component={MsgInfo} id={ID} auth={AUTH} url={"msgInfo"} />
            {/* =========================== */}

            {/* ========화재 통계====== */}
            <RouteCheck path="/statFacility" component={StatFacility} id={ID} auth={AUTH} url={"statFacility"} />
            <RouteCheck path="/statOrg" component={StatOrg} id={ID} auth={AUTH} url={"statOrg"} />
            <RouteCheck path="/statReason" component={StatReason} id={ID} auth={AUTH} url={"statReason"} />
            <RouteCheck path="/statRegion" component={StatRegion} id={ID} auth={AUTH} url={"statRegion"} />
            <RouteCheck path="/statUser" component={StatUser} id={ID} auth={AUTH} url={"statUser"} />
            {/* =========================== */}

            {/* =============계정 관리================ */}
            <RouteCheck path="/userApply" component={UserApply} id={ID} auth={AUTH} url={"userApply"} />
            <RouteCheck path="/userInfo" component={UserInfo} id={ID} auth={AUTH} url={"userInfo"} />
            <RouteCheck path="/useTerms" component={UseTerms} id={ID} auth={AUTH} url={"useTerms"} />
            {/* ====================================== */}

            {/* 공통 */}
            <Route path="/signUp" component={SignUp} auth={AUTH} url={"signUp"} />
            <Route path="/logOut" component={Logout} auth={AUTH} url={"logOut"} />
            <Route path="/notFound" component={NotFound} url={"notFound"} />
            <RouteCheck path="/labelTool" component={LabelTool} auth={AUTH} url={"labelTool"} />
            <RouteCheck path="/fileStorageMenu" component={FileStorageMenu} auth={AUTH} url={"fileStorageMenu"} />
            <RouteCheck path="/updatePwd" component={UpdatePwd} id={ID} auth={AUTH} url={"UpdatePwd"} />
          </Switch>
        </section>
        <footer>
          {sessionData ? (
            <div className="div-wrapper-copyright">
              <p>
                <small> Copyright © KESCO Corp. All Rights Reserved. </small>
              </p>
            </div>
          ) : null}
        </footer>
      </div>
    </div>
  );
}

const RouteCheck = ({ id, auth, url, component: Component, ...rest }) => {
  //redux 불러오기

  const [check, setCheck] = useState(true);
  const [show, setShow] = useState(false);

  return (
    <Route
      {...rest}
      render={(props) => {
        // DB에 Session이 있는지 체크

        axios
          .post(`${server}/api/cmm/sessionCheck`, { sessionid: sessionStorage.getItem("SESSION") })
          .then((response) => {
            setCheck(response.data);
          })
          .catch((err) => {})
          .finally(() => {});

        // DB에서 권한 체크
        axios
          .post(`${server}/api/cmm/auth`, {
            sessionid: sessionStorage.getItem("SESSION"),
            auth: auth,
            url: url,
          })
          .then((response) => {
            setShow(response.data);
          })
          .catch((err) => {})
          .finally(() => {});
        if (show) {
          return <Component {...props} ID={id} AUTH={auth} />;
        }
        if (check) {
        } else {
          return <Logout />;
          //window.location.replace("/");
          //return <SignIn {...props} />;
        }
      }}
    />
  );
};

export default App;
