import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import axios from "axios";
import cmmGetUser from "../../cmm/cmmGetUser";
import { useForm } from "react-hook-form";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
function UpdatePwd(props) {
    const [sessionData, setSessionData] = useState();

    useEffect(() => {
        (async () => {
            setSessionData(await cmmGetUser());
        })();
    }, [sessionStorage.getItem("SESSION")]);

    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    const [testResult, setTestResult] = useState({ label: "dd", propb: "123" });

    let [watchPwd, setWatchPwd] = useState("");

    //input 관련 hooks
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm({});

    // submit 이벤트 처리
    const onSubmit = (data) => {
        //password 변경
        axios
            .post(`${server}/api/userinfo/changePwd`, {
                sessionid: sessionStorage.getItem("SESSION"),
                pwd: data.pwd,
            })
            .then((response) => {
                if (response) {
                    setAlertMessage("I007");
                    setTimeout(() => {
                        window.location.replace("/");
                    }, 800);
                    sessionStorage.setItem("name", "화제상세정보");
                    sessionStorage.setItem("name2", "기관별 감정서 건수");
                }
            })
            .catch((err) => {});
    };

    if (sessionStorage.getItem("SESSION")) {
        return (
            <>
                <div>
                    {" "}
                    <form className="form-wrap-signUp" onSubmit={handleSubmit(onSubmit)}>
                        <div className="txt-title">
                            <span>비밀번호 변경</span>
                        </div>
                        <hr className="headline-signUp"></hr>

                        <div className="form-group">
                            <label class="required">비밀번호</label>
                            <input
                                {...register("pwd", {
                                    required: "필수정보입니다.",

                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                                        message: "1개 이상의 특수문자 포함 8 ~ 16 자로 입력해주세요",
                                    },
                                })}
                                onChange={() => {
                                    setWatchPwd(watch("pwd"));
                                }}
                                type="password"
                                className="form-control"
                                placeholder="1개 이상의 특수문자 포함 8 ~ 16 자로 입력해주세요"
                            />
                        </div>
                        {errors.pwd && <span className="span-box">{errors.pwd.message}</span>}

                        <div className="form-group">
                            <label class="required">비밀번호 확인</label>
                            <input
                                {...register("checkPwd", {
                                    required: "필수정보입니다.",
                                    validate: {
                                        check: (value) => value === watchPwd || "비밀번호가 일치하지 않습니다.",
                                    },
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                                        message: "위의 비밀번호와 일치한 값을 입력하세요",
                                    },
                                })}
                                onChange={(e) => {
                                    setWatchPwd(watch("pwd"));
                                    e.target.blur();
                                    e.target.focus();
                                }}
                                type="password"
                                className="form-control"
                                placeholder="위의 비밀번호와 일치한 값을 입력해주세요"
                            />
                        </div>
                        {errors.checkPwd && <span className="span-box">{errors.checkPwd.message}</span>}

                        <div className="div-wrap-btn">
                            <center className="div-wrap-btn">
                                <Button className="btn-register" type="submit">
                                    비밀번호 변경
                                </Button>
                            </center>
                        </div>
                    </form>
                </div>
            </>
        );
    } else {
        window.location.replace("/");
    }
}

export default UpdatePwd;
