import axios from "axios";
import Swal from "sweetalert2";

/*
{
    DEFAULT : "Q"

    S: success, //성공 
    E: error, // 실패 
    W: warning, // 경고
    I: info, // 정보
    Q: question, // 에러

}
Config로 미리 만들어놓고 수정 가능하게 할지(테스트를 위해 일단 Config로 작성)

*/
const server = process.env.REACT_APP_API_SERVER_HOST;

async function cmmAlertMessage(code = "Q000", msg = "", bt1 = "저장", bt2 = "취소", n = null) {
    let result = false;
    code = code + "";
    //redux 값 변경을 위한 dispatch 사용을 위해

    let iconcd = "question";
    switch (code.charAt(0)) {
        case "S":
            iconcd = "success";
            break;
        case "E":
            iconcd = "error";
            break;
        case "W":
            iconcd = "warning";
            break;
        case "I":
            iconcd = "info";
            break;
        case "Q":
            iconcd = "question";
            break;
        default:
            break;
    }

    await getData(code)
        .then(async (data) => {
            if (data[0]) {
                const btntype = data[0].btntype;
                const confirmBtn =
                    btntype === "1"
                        ? null
                        : btntype === "2" || btntype === "3"
                        ? "확인"
                        : btntype === "4" || btntype === "5"
                        ? "예"
                        : null;
                const cancelBtn = btntype === "3" ? "취소" : btntype === "4" || btntype === "5" ? "아니요" : null;
                const denyBtn = btntype === "5" ? "취소" : null;

                let msgbody = data[0].msgbody;
                if (msgbody.indexOf("${n}") !== -1) {
                    msgbody = msgbody.replace("${n}", n);
                }
                await Swal.fire({
                    icon: iconcd,
                    title: data[0].title,
                    html: msgbody + msg,
                    showConfirmButton: confirmBtn !== null ? true : false,
                    timer: confirmBtn !== null ? null : 1500,
                    timerProgressBar: confirmBtn !== null ? false : true,
                    showCancelButton: cancelBtn !== null ? true : false,
                    showDenyButton: denyBtn !== null ? true : false,
                    confirmButtonText: confirmBtn,
                    cancelButtonText: cancelBtn,
                    denyButtonText: denyBtn,
                    allowOutsideClick: false,
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        result = true;
                    } else {
                        result = false;
                    }
                });
            } else {
                await Swal.fire({
                    icon: "warning",
                    text: `없는 메시지 오류입니다.`,
                    allowOutsideClick: false,
                }).then((res) => {
                    result = false;
                });
            }
        })
        .catch(async (err) => {
            await Swal.fire({
                icon: "warning",
                text: `데이터베이스 연결 오류입니다.`,
                allowOutsideClick: false,
            }).then((res) => {
                result = false;
            });
        });

    return result;
}

async function getData(code = "") {
    return await axios
        .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: code })
        .then((res) => res.data);
}

export default cmmAlertMessage;
