import React from "react";
import "../../css/User.css";
import "../../css/userModal.css";
import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2"; // .env 파일에서 가져온 db_config
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../cmm/cmmGetData";
import PageBtn from "../../cmm/cmmPageBtn";
import PageBtn2 from "../../cmm/cmmPageBtn2";
import cmmGetUser from "../../cmm/cmmGetUser";

//부서권한관리
function OrgAuth() {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    /*
  .
  .
  .
  .
  공통
  */
    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    const orgcdOptions = cmmGetData("orgcd"); // 소속 기관 config
    const usergrpcdOptions = cmmGetData("usergrpcd"); // 권한그룹 기관 config
    let [usergrpcd, setUsergrpcd] = useState(null); // 권한그룹
    let [orgcd, setOrg] = useState(null); // 소속 기관

    // 페이지당 보이는 갯수_pagination

    const [pageUser, setPageUser] = useState(1);
    const [countUser, setCountUser] = useState(10);
    const [btnRefreshUser, setBtnRefreshUser] = useState(true);
    const maxPageUser = pageUser * countUser;
    const minpageUser = pageUser * countUser - countUser;

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;

    const [useynUpdate, setUseynUpdate] = useState([]);

    const useynConfig2 = [
        { value: "Y", label: "사용" },
        { value: "N", label: "미사용" },
    ];
    const [hiddenCheck, setHiddenCheck] = useState(false);
    const [doubleCheck, setDoubleCheck] = useState(true);

    const [useynCheck, setUseynCheck] = useState(false);
    //조회 요청할 데이터 저장
    let [inquiryData, setInquiryData] = useState([
        {
            orgcd: "",
            userid: "",
            usergrpcd: "",
        },
    ]);

    //조회 결과 데이터 저장
    let [inquiryResultData, setInquiryResultData] = useState(false);

    let [inquiryResultData2, setInquiryResultData2] = useState(false);

    //업데이트시 사용할 데이터
    let [updateData, setUpdateData] = useState([
        {
            orgcd: "",
            userid: "",
            usergrpcd: "",
        },
    ]);

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용
    let updateKeyTemp = "";
    const [checkUser, setCheckUser] = useState("");

    useEffect(() => {
        hiddenCheck ? setHiddenCheck(false) : setHiddenCheck(true);
    }, [page]);

    //useForm 관련
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        setInquiryData({
            orgcd: orgcd,
            userid: data.userid,
            usergrpcd: usergrpcd,
        });

        await axios
            .post(`${server}/api/orgAuth/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                orgcd: orgcd,
                userid: data.userid,
                usergrpcd: usergrpcd,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setInquiryResultData2(null);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                }
            })
            .catch((err) => {});
    };

    const onClickHandler = (data) => {
        axios
            .post(`${server}/api/orgAuth/inquiry2`, {
                sessionid: sessionStorage.getItem("SESSION"),
                orgcd: orgcd,
                userid: updateKeyTemp,
                usergrpcd: usergrpcd,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData2(response.data);
                    // setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                }
            })
            .catch((err) => {});

        // doubleCheck ? setDoubleCheck(false) : setDoubleCheck(true);
    };

    const inquiryHandler = (data) => {
        axios
            .post(`${server}/api/orgAuth/inquiry2`, {
                sessionid: sessionStorage.getItem("SESSION"),
                orgcd: orgcd,
                userid: checkUser === "" ? updateKeyTemp : checkUser,
                usergrpcd: usergrpcd,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData2(response.data);
                    // setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                }
            })
            .catch((err) => {});
    };

    /*useEffect는
[]안에 값 선언시, [] 값이 바뀌거나,
페이지가 refresh될때 함수가 실행됨!
*/
    useEffect(() => {
        axios
            .post(`${server}/api/orgAuth/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                orgcd: orgcd,
                userid: inquiryData.userid,
                usergrpcd: usergrpcd,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                }
            })
            .catch((err) => {});
    }, [refreshState, updateData]);

    return (
        <>
            {" "}
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%", height: "74px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <label style={{ marginBottom: "8px", marginLeft: "20px" }}>권한그룹</label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-doubleTr"
                                        //   defaultValue={{ value: lvlConfig[0].value, label: lvlConfig[0].label }}
                                        options={usergrpcdOptions}
                                        onChange={(e) => {
                                            setUsergrpcd(e.value);
                                        }}
                                        defaultValue={{ label: "*필수 선택" }}
                                    />
                                </td>
                                <td>
                                    <label style={{ marginBottom: "6px" }}>기관</label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-doubleTr"
                                        options={orgcdOptions}
                                        onChange={(e) => {
                                            setOrg(e.value);
                                        }}
                                        defaultValue={{ label: "*필수 선택" }}
                                    />
                                </td>
                                <td>
                                    <label style={{ marginRight: "40px", marginBottom: "4px" }}>사용자</label>
                                </td>
                                <td>
                                    <input
                                        {...register("userid", {})}
                                        type="text"
                                        className="input-soloTr"
                                        style={{ height: "35px", margin: "2px -50px 10px 0" }}
                                        maxLength="20"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <Button className="btn-search" variant="secondary" type="submit" style={{ marginLeft: "94.2%" }}>
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
                <p>
                    ● 상단 검색조건을 지정해서 조회하신 후, 좌측 표에서 사용자를 클릭하시면 우측에 해당 사용자의 부서
                    권한이 나옵니다.
                </p>
            </form>
            <hr />
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%", marginRight: "3%", marginLeft: "1%" }}>
                    <p style={{ fontWeight: "bold" }}>◎ 사용자</p>
                    <p>&nbsp;</p>
                    <Table bordered hover style={{ marginTop: "20px", overflow: "scroll", fontSize: "16px" }}>
                        <thead className="style-tHeader">
                            <tr>
                                <th style={{ width: "5%" }}>No.</th>
                                <th style={{ width: "12%" }}>사용자ID</th>
                                <th style={{ width: "17%" }}>권한그룹명</th>
                                <th style={{ width: "33%" }}>기관</th>
                                <th style={{ width: "33%" }}>부서명</th>
                            </tr>
                        </thead>
                        <tbody style={{ cursor: "pointer" }}>
                            {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                            {inquiryResultData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                                ? inquiryResultData.slice(minpageUser, maxPageUser).map((data, index) => {
                                      // Slice 된 만큼 반복문 실행

                                      return (
                                          <>
                                              <tr
                                                  style={
                                                      checkUser === data.userid ? { color: "red" } : { color: "black" }
                                                  }
                                                  onClick={() => {
                                                      updateKeyTemp = data.userid;
                                                      //setCheckUser(data.userid);
                                                      setCheckUser(updateKeyTemp);
                                                      //setState는 비동기성이라서, data 는 state로 저장(수정 기능 중 default value에 사용할 값 화면 전환이 일어나서 비동기 처리가 이루어짐)
                                                      setUpdateData(data);
                                                      onClickHandler();
                                                  }}
                                              >
                                                  <td>{minpageUser + index + 1}</td>
                                                  <td>{data.userid}</td>
                                                  <td>{data.usergrpnm}</td>
                                                  <td>{data.orgnm}</td>
                                                  <td>{data.deptnm}</td>
                                              </tr>
                                          </>
                                      );
                                  })
                                : null}
                        </tbody>

                        {inquiryResultData ? (
                            <>
                                <tfoot>
                                    <tr>
                                        <td colSpan="5" style={{ borderBottom: "none" }}>
                                            <PageBtn2
                                                dataUser={inquiryResultData ? inquiryResultData : 0}
                                                pageUser={pageUser}
                                                setPageUser={setPageUser}
                                                countUser={countUser}
                                                btnRefreshUser={btnRefreshUser}
                                            />
                                        </td>
                                    </tr>
                                </tfoot>
                            </>
                        ) : null}
                    </Table>
                </div>
                <div style={{ width: "56%", marginLeft: "2%", marginRight: "2%" }}>
                    <p style={{ fontWeight: "bold" }}>◎ 부서 </p>
                    <p> &nbsp; * 소속 부서 및 하위 부서의 경우 수정이 불가능합니다.</p>

                    <Table striped bordered hover style={{ overflow: "scroll", fontSize: "16px" }}>
                        <thead className="style-tHeader">
                            <tr>
                                <th style={{ width: "5%" }}>No.</th>
                                <th style={{ width: "15%" }}>부서코드</th>
                                <th style={{ width: "40%" }}>부서명</th>
                                <th style={{ width: "5%" }}>단계</th>
                                <th style={{ width: "5%" }}>순서</th>
                                <th style={{ width: "15%" }}>권한</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inquiryResultData2 // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                                ? inquiryResultData2.slice(minpage, maxPage).map((data, index) => {
                                      // Slice 된 만큼 반복문 실행

                                      return (
                                          <>
                                              <tr>
                                                  <td>{minpage + index + 1}</td>
                                                  <td>{data.deptcd}</td>
                                                  <td>{data.deptnm}</td>
                                                  <td>{data.lvl}</td>
                                                  <td>{data.seq}</td>
                                                  <td>
                                                      {data.bbuildid ? (
                                                          <Button className="btn-table_delete" variant="secondary">
                                                              수정불가
                                                          </Button>
                                                      ) : data.cbuildid ? (
                                                          <>
                                                              <Button
                                                                  className="btn-table"
                                                                  variant="secondary"
                                                                  onClick={() => {
                                                                      axios
                                                                          .post(`${server}/api/orgAuth/delete`, {
                                                                              sessionid:
                                                                                  sessionStorage.getItem("SESSION"),
                                                                              userid: checkUser,
                                                                              deptcd: data.deptcd,
                                                                          })
                                                                          .then((data) => {
                                                                              if (data) {
                                                                                  setAlertMessage("I002");
                                                                                  refreshState
                                                                                      ? dispatch({
                                                                                            type: "refreshFalse",
                                                                                        })
                                                                                      : dispatch({
                                                                                            type: "refreshTrue",
                                                                                        });
                                                                                  inquiryHandler();
                                                                              }
                                                                          });
                                                                  }}
                                                              >
                                                                  사용
                                                              </Button>
                                                          </>
                                                      ) : (
                                                          <>
                                                              <Button
                                                                  className="btn-table"
                                                                  variant="secondary"
                                                                  onClick={() => {
                                                                      if (data.lvl == "2" || data.lvl == "1") {
                                                                          axios.post(
                                                                              `${server}/api/orgAuth/deleteAll`,
                                                                              {
                                                                                  sessionid:
                                                                                      sessionStorage.getItem("SESSION"),
                                                                                  userid: checkUser,
                                                                              }
                                                                          );
                                                                      }
                                                                      axios
                                                                          .post(`${server}/api/orgAuth/insert`, {
                                                                              sessionid:
                                                                                  sessionStorage.getItem("SESSION"),
                                                                              userid: checkUser,
                                                                              deptcd: data.deptcd,
                                                                          })
                                                                          .then((data) => {
                                                                              if (data) {
                                                                                  setAlertMessage("I002");

                                                                                  refreshState
                                                                                      ? dispatch({
                                                                                            type: "refreshFalse",
                                                                                        })
                                                                                      : dispatch({
                                                                                            type: "refreshTrue",
                                                                                        });
                                                                                  inquiryHandler();
                                                                              }
                                                                          });
                                                                  }}
                                                              >
                                                                  미사용
                                                              </Button>
                                                          </>
                                                      )}
                                                  </td>
                                              </tr>
                                          </>
                                      );
                                  })
                                : null}
                        </tbody>

                        {inquiryResultData2 ? (
                            <>
                                <tfoot>
                                    <tr>
                                        <td colSpan="6" style={{ borderBottom: "none" }}>
                                            <PageBtn
                                                data={inquiryResultData2 ? inquiryResultData2 : 0}
                                                page={page}
                                                setPage={setPage}
                                                count={count}
                                                btnRefresh={btnRefresh}
                                            />
                                        </td>
                                    </tr>
                                </tfoot>
                            </>
                        ) : null}
                    </Table>
                </div>
            </div>
        </>
    );
}

export default OrgAuth;
