import React from "react";
import "../../css/User.css";
import "../../css/userModal.css";
import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2"; // .env 파일에서 가져온 db_config
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../cmm/cmmGetData";
import PageBtn from "../../cmm/cmmPageBtn";

//Modal
import AddDept from "./OrgDeptModal/AddDept";
import ChangeSeq from "./OrgDeptModal/ChangeSeq";
import ChagnePmenu from "./OrgDeptModal/ChagnePmenu";
import cmmGetUser from "../../cmm/cmmGetUser";

function OrgDept() {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    /*
  .
  .
  .
  .
  공통
  */
    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    const [modalShowAddDept, setModalShowAddDept] = useState(false);
    const [modalShowChangePmenu, setModalShowChangePmenu] = useState(false);
    const [modalShowChangeSeq, setModalShowChangeSeq] = useState(false);

    let dispatch = useDispatch(); //redux 값 변경을 위한 dispatch 사용을 위해
    // 페이지 버튼 리셋
    const [btnRefresh, setBtnRefresh] = useState(true);
    //config
    const orgidOptions = cmmGetData("orgidY"); // 조직도 id 가져오기

    const [orgCheck, setOrgCheck] = useState(false);
    const [orgid, setOrgid] = useState(null); // 조직도 id 저장

    //조회 결과 데이터 저장
    let [inquiryResultData, setInquiryResultData] = useState(false);
    //let [inquiryResultData2, setInquiryResultData2] = useState(false);
    //조회 결과 page 수 조절 변수

    // 페이지당 보이는 갯수_pagination
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const maxPage = page * count;
    const minpage = page * count - count;

    //부서명변경
    const [deptnmUpdate, setDeptnmUpdate] = useState([]);
    const [hiddenCheck, setHiddenCheck] = useState(false);

    //업데이트시 사용할 데이터
    const [updateData, setUpdateData] = useState([
        {
            orgid: "",
        },
    ]);

    useEffect(() => {
        if (inquiryResultData) {
            inquiryResultData
                .slice(minpage, maxPage) // pagination 을 위해 사용
                .map((data, index) => {
                    let a = document.getElementsByName("deptnm");

                    a[index].value = data.deptnm;
                });
        }
    }, [page, inquiryResultData]);

    useEffect(() => {});

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용
    let updateKeyTemp = "";
    let updateKeyTemp2 = "";

    //useForm 관련
    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmitSaveBtn = async (data) => {
        if (deptnmUpdate[0]) {
            let checkDeptcd = deptnmUpdate[0];
            let updateData = [];
            let i = 0;
            for (i = 0; i < deptnmUpdate.length; i++) {
                if (checkDeptcd.deptcd !== deptnmUpdate[i].deptcd) {
                    updateData.push(deptnmUpdate[i - 1]);
                    checkDeptcd = deptnmUpdate[i];
                } else {
                }
            }
            updateData.push(deptnmUpdate[i - 1]); //맨 마지막 데이터

            axios
                .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "I005" })
                .then((response) => {
                    Swal.fire({
                        icon: "warning",
                        title: response.data[0].title,
                        text: response.data[0].msgbody,
                        showCancelButton: true,
                        confirmButtonText: "저장",
                        cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            axios
                                .post(`${server}/api/orgDept/updateDeptnm`, {
                                    sessionid: sessionStorage.getItem("SESSION"),
                                    updateData: updateData,
                                })
                                .then((res) => {
                                    if (res.data)
                                        axios.post(`${server}/api/cmm/alert`, {
                                            sessionid: sessionStorage.getItem("SESSION"),
                                            code: "I002",
                                        });
                                    else
                                        axios.post(`${server}/api/cmm/alert`, {
                                            sessionid: sessionStorage.getItem("SESSION"),
                                            code: "E001",
                                        });
                                })
                                .catch(() => {
                                    axios.post(`${server}/api/cmm/alert`, {
                                        sessionid: sessionStorage.getItem("SESSION"),
                                        code: "E001",
                                    });
                                })
                                .finally(() => {
                                    window.location.reload();
                                });
                        }
                    });
                })
                .catch((err) => {
                    axios.post(`${server}/api/cmm/alert`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        code: "E000",
                    });
                });
        }
    };

    //조회요청
    const onSubmit = async (data) => {
        if (orgCheck) {
            await axios
                .post(`${server}/api/orgDept/inquiry`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid,
                })
                .then((response) => {
                    if (response.data) {
                        setInquiryResultData(response.data);
                        setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                    } else {
                    }
                })
                .catch((err) => {});

            reset();
        } else {
            setAlertMessage("E011"); //필수항목 체크하라고 안내
        }
    };
    //값이 바뀌면 조회요청
    useEffect(() => {
        if (orgCheck) {
            axios
                .post(`${server}/api/orgDept/inquiry`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid,
                })
                .then((response) => {
                    if (response.data) {
                        setInquiryResultData(response.data);
                        setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                    } else {
                    }
                })
                .catch((err) => {});
        }
    }, [refreshState]);

    const onDeleteHandler = (e) => {
        //확정된 컬럼 삭제시

        axios
            .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "Q002" })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        axios
                            .post(`${server}/api/orgDept/delete`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                userid: ID,
                                orgid: updateKeyTemp,
                                deptcd: updateKeyTemp2,
                            })
                            .then((res) => {
                                if (res.data) {
                                    setAlertMessage("I003");
                                    refreshState
                                        ? dispatch({ type: "refreshFalse" })
                                        : dispatch({ type: "refreshTrue" });
                                } else setAlertMessage("E202");
                            })
                            .catch(() => {
                                setAlertMessage("E202");
                            });
                    }
                });
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };

    /*useEffect는
[]안에 값 선언시, [] 값이 바뀌거나,
페이지가 refresh될때 함수가 실행됨!
*/

    return (
        <>
            {" "}
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%", height: "70px" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "10%" }}>
                                    <label style={{ marginLeft: "40px" }}>조직도 버전</label>
                                </td>
                                <td style={{ width: "40%" }}>
                                    <Select
                                        className="selector-doubleTr"
                                        //   defaultValue={{ value: lvlConfig[0].value, label: lvlConfig[0].label }}
                                        options={orgidOptions}
                                        defaultValue={{
                                            label: "*선택",
                                            value: null,
                                        }}
                                        onChange={(e) => {
                                            setOrgid(e.value);
                                            setOrgCheck(true);
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div align="right" style={{ margin: "-13px 0 0 9px", width: "100%" }}>
                    {inquiryResultData ? (
                        <div style={{ marginRight: "10px" }}>
                            <Button
                                className="btn btn-add mx-1"
                                type="button"
                                onClick={() => {
                                    setModalShowAddDept(true);
                                }}
                                style={{ marginTop: "14px" }}
                            >
                                추가
                            </Button>
                            <Button
                                className="btn btn-add mx-1"
                                type="button"
                                onClick={() => {
                                    onSubmitSaveBtn();
                                }}
                                style={{ marginTop: "14px" }}
                            >
                                저장
                            </Button>
                            <Button
                                className="btn btn-search mx-1"
                                variant="secondary"
                                type="submit"
                                style={{ marginTop: "14px" }}
                            >
                                <FontAwesomeIcon icon={faSearch} /> 조회
                            </Button>
                        </div>
                    ) : (
                        <div style={{ marginRight: "10px" }}>
                            <Button
                                className="btn btn-search mx-1"
                                variant="secondary"
                                type="submit"
                                style={{ marginTop: "14px" }}
                            >
                                <FontAwesomeIcon icon={faSearch} /> 조회
                            </Button>
                        </div>
                    )}
                </div>
            </form>
            <Table bordered hover style={{ overflow: "scroll" }}>
                <thead className="style-tHeader">
                    <tr>
                        <th>순번</th>
                        <th>부서코드</th>
                        <th>부서명</th>
                        <th>기관코드</th>
                        <th style={{ width: "240px" }}>지역코드</th>
                        <th>단계</th>
                        <th>순서</th>
                        <th style={{ width: "7%" }}>상위부서변경</th>
                        <th style={{ width: "7%" }}>순서변경</th>
                        <th style={{ width: "7%" }}>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                    {inquiryResultData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                        ? inquiryResultData.slice(minpage, maxPage).map((data, index) => {
                              // Slice 된 만큼 반복문 실행

                              return (
                                  <>
                                      <tr
                                          onClick={() => {
                                              updateKeyTemp = data.orgid;
                                              updateKeyTemp2 = data.deptcd;
                                              //setState는 비동기성이라서, data 는 state로 저장(수정 기능 중 default value에 사용할 값_화면 전환이 일어나서 비동기 처리가 이루어짐)
                                              setUpdateData(data);
                                          }}
                                      >
                                          <td>{minpage + index + 1}</td>
                                          <td>{data.deptcd}</td>

                                          <td>
                                              <input
                                                  class="input-flexible"
                                                  name="deptnm"
                                                  placeholder={data.orgnm}
                                                  onChange={(e) => {
                                                      setDeptnmUpdate([
                                                          ...deptnmUpdate,
                                                          {
                                                              userid: ID,
                                                              orgid: data.orgid,
                                                              deptcd: data.deptcd,
                                                              deptnm: e.target.value,
                                                          },
                                                      ]);
                                                  }}
                                                  defaultValue={data.deptnm}
                                                  type="text"
                                                  maxLength="30"
                                              ></input>
                                          </td>

                                          <td>{data.numorg}</td>
                                          <td>{data.numregion}</td>
                                          <td>{data.lvl}</td>
                                          <td>{data.seq}</td>

                                          <td>
                                              {data.lvl > 3 ? (
                                                  <>
                                                      <Button
                                                          className="btn-table"
                                                          variant="secondary"
                                                          onClick={() => {
                                                              setUpdateData(data);
                                                              setModalShowChangePmenu(true);
                                                          }}
                                                      >
                                                          변경
                                                      </Button>
                                                  </>
                                              ) : null}
                                          </td>
                                          <td>
                                              {" "}
                                              <Button
                                                  className="btn-table"
                                                  variant="secondary"
                                                  onClick={() => {
                                                      setUpdateData(data);
                                                      setModalShowChangeSeq(true);
                                                  }}
                                              >
                                                  변경
                                              </Button>
                                          </td>
                                          <td>
                                              {" "}
                                              <Button
                                                  className="btn-table_delete"
                                                  variant="secondary"
                                                  onClick={() => {
                                                      setUpdateData(data);
                                                      onDeleteHandler();
                                                  }}
                                              >
                                                  삭제
                                              </Button>
                                          </td>
                                      </tr>
                                  </>
                              );
                          })
                        : null}
                </tbody>

                <tfoot>
                    <tr>
                        <td colSpan="10" style={{ borderBottom: "none" }}>
                            <PageBtn
                                data={inquiryResultData ? inquiryResultData : 0}
                                page={page}
                                setPage={setPage}
                                count={count}
                                btnRefresh={btnRefresh}
                            />
                        </td>
                    </tr>
                </tfoot>
            </Table>
            <div>
                {/*reudx 를 통해 불러온 값이 true면 AddWeight 띄우고 아니면 null을 띄움*/}
                {modalShowAddDept ? (
                    <AddDept show={modalShowAddDept} setShow={setModalShowAddDept} data={updateData} orgid={orgid} />
                ) : null}
                {modalShowChangeSeq ? (
                    <ChangeSeq
                        data={updateData}
                        show={modalShowChangeSeq}
                        setShow={setModalShowChangeSeq}
                        allData={inquiryResultData}
                        orgid={orgid}
                    />
                ) : null}
                {modalShowChangePmenu ? (
                    <ChagnePmenu
                        data={updateData}
                        show={modalShowChangePmenu}
                        setShow={setModalShowChangePmenu}
                        allData={inquiryResultData}
                        orgid={orgid}
                    />
                ) : null}
            </div>
        </>
    );
}

export default OrgDept;
