import React from "react";
import "../../css/User.css";
import "../../css/userModal.css";
import "../../css/manage.css";
import { Table, Button } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2"; // .env 파일에서 가져온 db_config

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

//공통함수

import cmmGetData from "../../cmm/cmmGetData";
import cmmGetUser from "../../cmm/cmmGetUser";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기

//조직개편

function OrgHis() {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    /*
  .
  .
  .
  .
  공통
  */
    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    const statuscdOptions = [
        { value: "", label: "전체" },
        { value: "Y", label: "확정" },
        { value: "P", label: "미확정" },
    ]; // 유저 상태 config
    let [statuscd, setStatuscd] = useState(""); // 유저 상태

    const orgidOptions = cmmGetData("orgid"); // 조직도 id 가져오기
    let [orgid, setOrgid] = useState("");

    //조회 결과 데이터 저장
    let [inquiryResultData, setInquiryResultData] = useState(false);
    //조회 결과 page 수 조절 변수
    let [page, setPage] = useState(1);

    let minpage = page * 10 - 10;

    let [orgnmUpdate, setOrgnmUpdate] = useState([]);

    //업데이트시 사용할 데이터
    let [updateData, setUpdateData] = useState([
        {
            begindt: "",
            enddt: "",
            useterm: "",
            copyrightterm: "",
            perinfoterm: "",
        },
    ]);

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용
    let updateKeyTemp = "";

    //useForm 관련
    const { handleSubmit, watch } = useForm();

    const onSubmit = async (data) => {
        await axios
            .post(`${server}/api/orgHis/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                userid: ID,
                orgid: orgid,
                statuscd: statuscd,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                } else {
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        onSubmit(watch);
    }, [refreshState]);

    /*useEffect는
[]안에 값 선언시, [] 값이 바뀌거나,
페이지가 refresh될때 함수가 실행됨!
*/

    //삭제 버튼 이벤트 처리
    const onDeleteHandler = (e) => {
        //확정된 컬럼 삭제시

        axios
            .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "Q002" })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        axios
                            .post(`${server}/api/orgHis/deleteOrg`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                userid: ID,
                                orgid: updateKeyTemp,
                            })
                            .then((res) => {
                                if (res.data) {
                                    setAlertMessage("I003");
                                    refreshState
                                        ? dispatch({ type: "refreshFalse" })
                                        : dispatch({ type: "refreshTrue" });
                                } else setAlertMessage("E202");
                            })
                            .catch(() => {
                                setAlertMessage("E202");
                            });
                    }
                });
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };

    //확정버튼  이벤트 처리
    const onCheckHandler = (event) => {
        //확정안된 컬럼
        axios
            .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "I100" })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "승인",
                    cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        onSubmitSaveBtn();
                        axios
                            .post(`${server}/api/orgHis/confirm`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                userid: ID,
                                orgid: updateKeyTemp,
                            })
                            .then((res) => {
                                if (res.data) {
                                    setAlertMessage("I008");
                                    refreshState
                                        ? dispatch({ type: "refreshFalse" })
                                        : dispatch({ type: "refreshTrue" });
                                } else setAlertMessage("E200");
                            })
                            .catch(() => {
                                setAlertMessage("E200");
                            });
                    }
                });
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };

    const onCopyHandler = (data) => {
        let deleteData = [];
        let deleteCount = 0;

        for (let i = 0; i < inquiryResultData.length; i++) {
            if (inquiryResultData[i].statuscd === "P") {
                deleteData[deleteCount++] = inquiryResultData[i];
            }
        }
        axios
            .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "Q003" })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    html: `
                    <pre style ="
                    overflow-y: initial !important;
                    white-space: pre-wrap;
                  
                    text-align: left;
                    font-family: 'NotoSansKR-Demilight';
                    font-size : 20px;
                    font-weight: bold;
                  
                    padding-left : 30px;
                    ">${response.data[0].msgbody}
                    </pre>`,
                    //   text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "확인",
                    cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        axios
                            .post(`${server}/api/orgHis/copy`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                userid: ID,
                                deleteData: deleteData,
                            })
                            .then((res) => {
                                setAlertMessage("I012");
                                refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
                            })
                            .catch(() => {
                                setAlertMessage("E201");
                            });
                    }
                });
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };

    const onSubmitSaveBtn = async (data) => {
        if (orgnmUpdate)
            axios.post(`${server}/api/orgHis/updateOrgnm`, {
                sessionid: sessionStorage.getItem("SESSION"),
                updateData: orgnmUpdate,
            });

        window.location.reload();
    };

    return (
        <>
            {" "}
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td style={{ width: "12%" }}>
                                    <label style={{ marginTop: "5px" }}>조직 개편 버전</label>
                                </td>
                                <td style={{ width: "600px" }}>
                                    <Select
                                        className="selector-soloTr"
                                        defaultValue={{
                                            label: orgidOptions[0].label,
                                            value: orgidOptions[0].value,
                                        }}
                                        options={orgidOptions}
                                        onChange={(e) => {
                                            setOrgid(e.value);
                                        }}
                                    />
                                </td>
                                <td style={{ width: "12%" }}>
                                    {/* backgroundColor: "#eee",  */}
                                    <label style={{ marginTop: "5px" }}>상태</label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-soloTr"
                                        //   defaultValue={{ value: lvlConfig[0].value, label: lvlConfig[0].label }}
                                        options={statuscdOptions}
                                        onChange={(e) => {
                                            setStatuscd(e.value);
                                        }}
                                        defaultValue={{
                                            value: statuscdOptions[0].value,
                                            label: statuscdOptions[0].label,
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <Button
                        className="btn-add"
                        variant="secondary"
                        type="button"
                        onClick={onCopyHandler}
                        style={{ fontSize: "15px", marginRight: "110px" }}
                    >
                        복제
                    </Button>
                    <Button
                        className="btn-saveTriple"
                        variant="secondary"
                        type="button"
                        onClick={onSubmitSaveBtn}
                        style={{ marginRight: "50px" }}
                    >
                        저장
                    </Button>
                    <Button className="btn-search" variant="secondary" type="submit" style={{ fontSize: "15px" }}>
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
            </form>
            <Table bordered hover style={{ overflow: "scroll" }}>
                <thead className="style-tHeader">
                    <tr>
                        <th>순번</th>
                        <th>생성일시</th>
                        <th>조직개편 명칭</th>
                        <th>상태</th>
                        <th>확정시각</th>
                        <th>확정자ID</th>
                        <th>확정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                    {inquiryResultData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                        ? inquiryResultData.map((data, index) => {
                              // Slice 된 만큼 반복문 실행

                              return (
                                  <>
                                      <tr
                                          onClick={() => {
                                              updateKeyTemp = data.orgid;
                                              //setState는 비동기성이라서, data 는 state로 저장(수정 기능 중 default value에 사용할 값_화면 전환이 일어나서 비동기 처리가 이루어짐)
                                              setUpdateData(data);
                                          }}
                                      >
                                          <td>{minpage + index + 1}</td>
                                          <td>{data.createtm}</td>
                                          <td style={{ fontSize: "14.3px" }}>
                                              {refreshState ? (
                                                  data.cfmid ? (
                                                      data.orgnm
                                                  ) : (
                                                      <>
                                                          <input name="1" type="hidden"></input>
                                                          <input
                                                              class="input-flexible"
                                                              style={{ fontSize: "15px" }}
                                                              name="orgnm"
                                                              placeholder={data.orgnm}
                                                              onChange={(e) => {
                                                                  setOrgnmUpdate([
                                                                      ...orgnmUpdate,
                                                                      {
                                                                          userid: ID,
                                                                          orgid: data.orgid,
                                                                          statuscd: data.statuscd,
                                                                          cfmid: data.cfmid,
                                                                          orgnm: e.target.value,
                                                                      },
                                                                  ]);
                                                              }}
                                                              defaultValue={data.orgnm}
                                                              type="text"
                                                              maxLength="100"
                                                          ></input>
                                                      </>
                                                  )
                                              ) : data.cfmid ? (
                                                  data.orgnm
                                              ) : (
                                                  <>
                                                      <input name="2" type="hidden"></input>
                                                      <input name="3" type="hidden"></input>
                                                      <input
                                                          class="input-flexible"
                                                          style={{ fontSize: "15px" }}
                                                          name="orgnm"
                                                          placeholder={data.orgnm}
                                                          onChange={(e) => {
                                                              setOrgnmUpdate([
                                                                  ...orgnmUpdate,
                                                                  {
                                                                      userid: ID,
                                                                      orgid: data.orgid,
                                                                      statuscd: data.statuscd,
                                                                      cfmid: data.cfmid,
                                                                      orgnm: e.target.value,
                                                                  },
                                                              ]);
                                                          }}
                                                          defaultValue={data.orgnm}
                                                          type="text"
                                                      ></input>
                                                  </>
                                              )}
                                          </td>
                                          <td>{data.statuscd === "P" ? "미확정" : "확정"}</td>
                                          <td>{data.cfmtm}</td>
                                          <td>{data.cfmid}</td>
                                          {data.cfmid ? null : (
                                              <>
                                                  <td>
                                                      <Button
                                                          className="btn-table"
                                                          variant="secondary"
                                                          onClick={() => {
                                                              onCheckHandler();
                                                          }}
                                                      >
                                                          확정
                                                      </Button>
                                                  </td>
                                                  <td>
                                                      <Button
                                                          className="btn-table_delete"
                                                          variant="secondary"
                                                          onClick={() => {
                                                              onDeleteHandler();
                                                          }}
                                                      >
                                                          삭제
                                                      </Button>
                                                  </td>
                                              </>
                                          )}
                                      </tr>
                                  </>
                              );
                          })
                        : null}
                </tbody>
            </Table>
        </>
    );
}

export default OrgHis;
