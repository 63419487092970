import React, { useEffect, useRef } from "react";
//메뉴 관리
import "../../../css/User.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from "react-select";
import cmmGetUser from "../../../cmm/cmmGetUser";

//공통함수

//메뉴추가
function AddMenu(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);

    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const [selectResult, setSelectResult] = useState("1");
    const [selectResult2, setSelectResult2] = useState("");

    const onSubmit = async (data) => {
        axios.post(`${server}/api/menuInfo/addMenuLvl1`, {
            sessionid: sessionStorage.getItem("SESSION"),
            menuid: data.menuid,
            menunm: data.menunm,
            url: data.url,
            userid: ID,
        });

        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
        props.setShow(false);
        window.location.reload();
        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
    };

    const onSubmit2 = async (data) => {
        axios.post(`${server}/api/menuInfo/addMenuLvl2`, {
            sessionid: sessionStorage.getItem("SESSION"),
            pmenuid: selectResult2,
            menunm: data.menunm,
            url: data.url,
            userid: ID,
        });
        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
        props.setShow(false);
        window.location.reload();
        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
    };

    return (
        <>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                {" "}
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 메뉴 추가 </span>
                        {/* <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">계정 상태 변경</Modal.Title>
                    </Modal.Header> */}
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <>
                            <div className="form-group">
                                <label>단계 </label>
                                <Select
                                    name="selectLvl"
                                    className="selector-single2"
                                    options={[
                                        { value: "1", label: "1단계" },
                                        { value: "2", label: "2단계" },
                                    ]}
                                    onChange={(e) => {
                                        setSelectResult(e.value);
                                    }}
                                    defaultValue={{
                                        label: "1단계",
                                        value: "1",
                                    }}
                                ></Select>
                            </div>
                            {selectResult ? (
                                selectResult === "1" ? (
                                    <>
                                        <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-group">
                                                <label>메뉴 ID</label>
                                                <input
                                                    {...register("menuid", {
                                                        required: "필수정보입니다.",
                                                        pattern: {
                                                            value: /^[A-Z0-9]+$/,
                                                            message:
                                                                " 알파벳 대문자만을 사용하세요. (숫자,소문자,한글,특수기호, 공백 사용 불가)",
                                                        },
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="메뉴ID를 입력해주세요"
                                                    maxLength="20"
                                                />
                                            </div>
                                            {errors.menuid && <span className="span-box">{errors.menuid.message}</span>}

                                            <div className="form-group">
                                                <label>메뉴명</label>
                                                <input
                                                    {...register("menunm", {
                                                        required: "필수정보입니다.",
                                                        pattern: {
                                                            value: /^[가-힣a-zA-Z]+$/,
                                                            message:
                                                                "한글과 알파벳 대 소문자만을 사용하세요. (숫자,한글,특수기호, 공백 사용 불가)",
                                                        },
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="메뉴명을 입력해주세요"
                                                    maxLength="30"
                                                />
                                            </div>
                                            {errors.menunm && <span className="span-box">{errors.menunm.message}</span>}

                                            <div className="form-group">
                                                <label>URL</label>
                                                <input
                                                    {...register("url", {
                                                        required: "필수정보입니다.",
                                                        pattern: {
                                                            value: /^[a-zA-Z]+$/,
                                                            message:
                                                                "알파벳 대 소문자만을 사용하세요. (숫자,한글,특수기호, 공백 사용 불가)",
                                                        },
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="URL을 입력해주세요"
                                                    maxLength="50"
                                                />
                                            </div>
                                            {errors.url && <span className="span-box">{errors.url.message}</span>}

                                            <div className="button-group">
                                                <Button className="btn-save" type="submit" onClick={() => {}}>
                                                    저장
                                                </Button>

                                                <Button
                                                    className="btn-cancel"
                                                    variant="secondary"
                                                    onClick={() => {
                                                        reset();
                                                        props.setShow(false);
                                                    }}
                                                >
                                                    취소
                                                </Button>
                                            </div>
                                        </form>
                                    </>
                                ) : (
                                    //2단계 메뉴 추가
                                    <>
                                        <form className="LoginBox" onSubmit={handleSubmit(onSubmit2)}>
                                            <div className="form-group">
                                                <label>상위 메뉴 </label>
                                                <Select
                                                    className="selector-single2"
                                                    options={props.lvlcd}
                                                    onChange={(e) => {
                                                        setSelectResult2(e.value);
                                                    }}
                                                    defaultValue={{ label: "선택" }}
                                                ></Select>
                                            </div>
                                            <div className="form-group">
                                                <label>메뉴명</label>
                                                <input
                                                    {...register("menunm", {
                                                        required: "필수정보입니다.",
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="메뉴명을 입력해주세요"
                                                    maxLength="20"
                                                />
                                            </div>
                                            {errors.menunm && <span className="span-box">{errors.menunm.message}</span>}

                                            <div className="form-group">
                                                <label>URL</label>
                                                <input
                                                    {...register("url", {
                                                        required: "필수정보입니다.",
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9]+$/,
                                                            message:
                                                                "영문 대 소문자를 사용하세요. (특수기호, 공백 사용 불가)",
                                                        },
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="URL을 입력해주세요"
                                                    maxLength="50"
                                                />
                                            </div>
                                            {errors.url && <span className="span-box">{errors.url.message}</span>}

                                            <div className="button-group">
                                                <Button className="btn-save" type="submit" onClick={() => {}}>
                                                    저장
                                                </Button>

                                                <Button
                                                    className="btn-cancel"
                                                    variant="secondary"
                                                    onClick={() => {
                                                        reset();
                                                        props.setShow(false);
                                                    }}
                                                >
                                                    취소
                                                </Button>
                                            </div>
                                        </form>
                                    </>
                                )
                            ) : (
                                <>
                                    <div className="button-group">
                                        <Button
                                            className="btn-cancel"
                                            variant="secondary"
                                            onClick={() => {
                                                reset();
                                                props.setShow(false);
                                            }}
                                        >
                                            취소
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default AddMenu;
