import React, { useEffect, useState } from "react";
import "../../css/bulletIn.css";
import { Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import OrgBulletin from "./BulletinModal/DeptSelect";
import cmmGetUser from "../../cmm/cmmGetUser";
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기

function BulletinAdd(props) {
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);

    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    const dispatch = useDispatch();

    //현재날짜 불러오기
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    let date = today.getDate(); // 날짜

    date = parseInt(date) < 10 ? "0" + date : date;
    let current = year + "-" + month + "-" + date;
    let temp = new Date(year, month - 1, date);
    temp.setDate(temp.getDate() + 365);

    year = temp.getFullYear(); // 년도
    month = temp.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    date = temp.getDate(); // 날짜
    date = parseInt(date) < 10 ? "0" + date : date;
    let fature = year + "-" + month + "-" + date;

    // 게시물 추가 모달창 true/false
    const orgBulletinState = useSelector((state) => state.orgBulletinModalReducer);
    // 현재 접속된 아이디
    const id = ID;
    // 부서
    const [lvl, setLvl] = useState({ lvl: false, label: "*필수 선택" });

    // 버튼 유형 타입
    const [type, setType] = useState({ value: "Y", label: "공지" }, { value: "I", label: "문의" });
    const [typeCheck, setTypeCheck] = useState(false);
    const [privateyn, setPrivateyn] = useState("N");

    const { register, handleSubmit } = useForm();

    const bulletinType = () => {
        let type = [{ value: "null", label: "null" }];

        axios
            .post(`${server}/api/bulletin/typeNotice`, { sessionid: sessionStorage.getItem("SESSION") })
            .then((response) => {
                response.data.map((data) => {
                    type.push({
                        label: data.itemnm,
                        value: data.itemcd,
                    });
                });
                type.shift(); // 에러방지값 pop()
            })
            .catch((err) => {});
        return type;
    };

    const onSubmit = async (data) => {
        //필수 항목을 선택해야함
        if (typeCheck) {
            if (type === "Y") {
                if (lvl.lvl) {
                    axios
                        .post(`${server}/api/bulletin/insertWeb`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            title: data.title,
                            content: data.msgbody,
                            privateyn: privateyn,
                            type: type,
                            noticedeptcd: lvl.lvl,
                            begindt: data.begindt,
                            enddt: data.enddt,
                            userid: id,
                        })
                        .then((response) => {
                            window.location.replace("./bulletin");
                        })
                        .catch((err) => {});
                } else {
                    //공지 대상을 설정해주세요
                    setAlertMessage("E902");
                }
            } else if (type !== "Y") {
                axios
                    .post(`${server}/api/bulletin/insertWeb`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        title: data.title,
                        content: data.msgbody,
                        privateyn: privateyn,
                        type: type,
                        noticedeptcd: lvl.lvl,
                        begindt: data.begindt,
                        enddt: data.enddt,
                        userid: id,
                    })
                    .then((response) => {
                        window.location.replace("./bulletin");
                    })
                    .catch((err) => {});
            } else {
                setAlertMessage("E900"); //빈칸없이 입력해주세요
            }
        } else {
            setAlertMessage("E900"); //빈칸없이 입력해주세요
        }
    };

    return (
        <>
            <p className="manage-title">게시판 신규 글쓰기</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                <label>제목</label>
                            </td>
                            <td colSpan="5">
                                <input
                                    {...register("title", {
                                        required: "필수정보입니다.",
                                    })}
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    style={{ width: "99%", marginTop: "8px", marginBottom: "8px" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                <label>작성자</label>
                            </td>
                            <td style={{ width: "25%" }}>{id}</td>
                            <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                <label>상태</label>
                            </td>
                            <td style={{ width: "25%" }}>
                                <Select
                                    options={bulletinType()}
                                    onChange={(e) => {
                                        setTypeCheck(true);
                                        setType(e.value);
                                    }}
                                />
                            </td>

                            {type === "Y" ? (
                                <>
                                    {" "}
                                    <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                        <label>비밀여부</label>
                                    </td>
                                    <td style={{ width: "25%" }}>
                                        <Select
                                            defaultValue={{ value: "N", label: "아니요" }}
                                            options={[{ value: "N", label: "아니요" }]}
                                            onChange={(e) => {
                                                setPrivateyn(e.value);
                                            }}
                                        />
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                        <label>비밀여부</label>
                                    </td>
                                    <td style={{ width: "25%" }}>
                                        <Select
                                            defaultValue={{ value: "N", label: "아니요" }}
                                            options={[
                                                { value: "Y", label: "예" },
                                                { value: "N", label: "아니요" },
                                            ]}
                                            onChange={(e) => {
                                                setPrivateyn(e.value);
                                            }}
                                        />
                                    </td>
                                </>
                            )}
                        </tr>
                        {type === "Y" ? (
                            <>
                                <tr>
                                    <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                        <label>공지설정</label>
                                    </td>
                                    <td colSpan="1">
                                        <div style={{ display: "flex" }}>
                                            <input
                                                id="begindt"
                                                {...register("begindt", {})}
                                                type="date"
                                                //value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                                defaultValue={current}
                                                className="input-date1"
                                            />
                                            <p style={{ marginTop: "20px", marginLeft: "5px" }}> - </p>
                                            <input
                                                id="enddt"
                                                {...register("enddt", {})}
                                                type="date"
                                                // value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                                defaultValue={fature}
                                                className="input-date2"
                                            />
                                        </div>
                                    </td>
                                    <td style={{ backgroundColor: "#eee", width: "10%" }}>공지 대상</td>
                                    <td>
                                        <Button
                                            className="btn"
                                            variant="secondary"
                                            type="button"
                                            onClick={() => dispatch({ type: "orgBulletinModalTrue" })}
                                        >
                                            조직도 선택
                                        </Button>
                                    </td>
                                    <td colSpan="2">
                                        <label>{lvl.lvl == "A" ? "전체" : lvl.label}</label>
                                    </td>
                                </tr>
                            </>
                        ) : null}
                        <tr>
                            <td colSpan="6" style={{ backgroundColor: "#eee" }}>
                                <label>본문</label>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="6" style={{ backgroundColor: "#eee" }}>
                                <textarea
                                    className="contentBox-TextArea"
                                    maxLength="2000"
                                    autofocus="autofocus"
                                    {...register("msgbody", {
                                        required: "필수정보입니다.",
                                    })}
                                    style={{ width: "100%" }}
                                    rows="20"
                                ></textarea>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-group" style={{ float: "right" }}>
                    <Button className="btn-cancel2-c2" type="submit">
                        저장
                    </Button>
                    <Button
                        className="btn-cancel2-c1"
                        variant="secondary"
                        onClick={() => window.location.replace("./bulletin")}
                    >
                        취소
                    </Button>
                </div>
            </form>
            {/*reudx 를 통해 불러온 값이 true면 OrgBulletin 띄우고 아니면 null을 띄움*/}
            {orgBulletinState ? <OrgBulletin setLvl={setLvl} /> : null}
        </>
    );
}
export default BulletinAdd;
