//Moudle
import "../../../css/userModal.css";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../../cmm/cmmGetData"; //Config 가져오기
import cmmGetUser from "../../../cmm/cmmGetUser";
function UpdateInfo(props) {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST; // .env 파일에서 가져온 db_config

    const mdmcdOptions = cmmGetData("mdmcd"); // mdm 기관 config
    const usergrpcdOptions = cmmGetData("usergrpcd"); // 권한그룹 기관 config
    const orgcdOptions = cmmGetData("orgcd"); //소속기관

    let [orgcd, setOrgcd] = useState(props.updateUserData.orgcd); // 소속기관
    let [mdmcd, setMdmcd] = useState(props.updateUserData.mdmtypecd); // MDM
    let [expertcd, setExpertcd] = useState(props.updateUserData.expertyn); // expert
    let [usergrpcd, setUsergrpcd] = useState(props.updateUserData.usergrpcd); // 권한그룹

    //redux 불러오기

    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    const dispatch = useDispatch();
    //form hook
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const refLvl2 = useRef();
    const refLvl3 = useRef();
    const refLvl4 = useRef();

    // 부서코드
    const [lvl, SetLvl] = useState(props.updateUserData.deptcd);
    const [lvl3, SetLvl3] = useState();

    const onSubmit = async (data, e) => {
        await axios
            .post(`${server}/api/userInfo/updateUser`, {
                sessionid: sessionStorage.getItem("SESSION"),
                usernm: data.usernm,
                cellphone: data.cellphone,
                email: data.email,
                birthdt: data.birthdt,
                usergrpcd: usergrpcd, //Select box 값
                orgcd: orgcd, //Select box 값
                deptcd: orgcd === "A" ? "A" : lvl,
                userid: data.userid,
                expertcd: expertcd,
                mdmtypecd: mdmcd,
            })
            .then((response) => {
                if (response.data) {
                    setAlertMessage("S003");
                } else {
                    setAlertMessage("E010");
                }
            })
            .catch(() => {
                setAlertMessage("Q100");
                reset();
            });
        e.preventDefault();
        props.setShow(false);
        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
    };

    return (
        <>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 계정 수정 </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="userid" class="required">
                                    ID
                                </label>
                                <input
                                    {...register("userid", {
                                        required: "필수정보입니다.",
                                        pattern: {
                                            value: /^[a-zA-Z0-9]{6,8}$/,
                                            message:
                                                "6~8 자리의 영문 대 소문자, 숫자를 사용하세요. (특수기호, 공백 사용 불가)",
                                        },
                                    })}
                                    defaultValue={props.updateUserData.userid}
                                    type="text"
                                    className="form-control"
                                    // placeholder="영문과 숫자만을 포함한 6 ~ 8 자리 아이디를 입력해주세요"
                                    readonly="true"
                                />
                            </div>
                            {errors.userid && <span className="span-box">{errors.userid.message}</span>}

                            <div className="form-group">
                                <label class="required">성명</label>
                                <input
                                    {...register("usernm", {
                                        required: "필수정보입니다.",
                                        pattern: {
                                            value: /^[가-힣a-zA-Z]+$/,
                                            message: "한글과 영문 대 소문자를 사용하세요. (특수기호, 공백 사용 불가)",
                                        },
                                    })}
                                    defaultValue={props.updateUserData.usernm}
                                    type="text"
                                    className="form-control"
                                    maxLength="20"
                                    // placeholder="성명을 입력해주세요"
                                />
                            </div>
                            {errors.usernm && <span className="span-box">{errors.usernm.message}</span>}
                            <div className="form-group">
                                <label class="required">소속</label>
                                <Select
                                    className="selector-single2"
                                    options={orgcdOptions}
                                    onChange={(e) => {
                                        setOrgcd(e.value);
                                        SetLvl(null);
                                        if (refLvl3.current) {
                                            if (refLvl3.current.state.value) {
                                                refLvl3.current.state.value.label = "*필수선택";
                                                SetLvl(null);
                                            }
                                        }
                                        if (refLvl4.current) {
                                            if (refLvl4.current.state.value) {
                                                refLvl4.current.state.value.label = "*필수선택";
                                                SetLvl(null);
                                            }
                                        }
                                    }}
                                    ref={refLvl2}
                                    defaultValue={{
                                        value: props.updateUserData.orgcd,
                                        label: props.updateUserData.orgnm,
                                    }}
                                />
                            </div>
                            {props.updateUserData.orgcd && orgcd !== "A" ? (
                                props.updateUserData.deptcd === "A" || props.updateUserData.orgcd !== orgcd ? (
                                    <>
                                        <div className="form-group">
                                            <label class="required">부서</label>
                                            <Select
                                                className="selector-double2"
                                                options={cmmGetData("deptcdAll4", 3, orgcd)}
                                                onChange={(e) => {
                                                    SetLvl3(e.value);

                                                    if (refLvl4.current) {
                                                        if (refLvl4.current.state.value) {
                                                            refLvl4.current.state.value.label = "*필수선택";
                                                            SetLvl(null);
                                                        }
                                                    }
                                                }}
                                                defaultValue={{
                                                    label: "*필수선택",
                                                }}
                                                ref={refLvl3}
                                            />

                                            <Select
                                                className="selector-double"
                                                options={cmmGetData("deptcdAll4", 4, lvl3)}
                                                onChange={(e) => {
                                                    SetLvl(e.value);
                                                }}
                                                ref={refLvl4}
                                                defaultValue={{
                                                    label: "*필수선택",
                                                }}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="form-group">
                                            <label class="required">부서</label>
                                            <Select
                                                className="selector-double2"
                                                options={cmmGetData("deptcdAll4", 3, orgcd)}
                                                onChange={(e) => {
                                                    SetLvl3(e.value);
                                                    if (refLvl4.current) {
                                                        if (refLvl4.current.state.value) {
                                                            refLvl4.current.state.value.label = "*필수선택";
                                                            SetLvl(null);
                                                        }
                                                    }
                                                }}
                                                defaultValue={{
                                                    value: props.updateUserData.pdeptcd,
                                                    label: props.updateUserData.pdeptnm,
                                                }}
                                                ref={refLvl3}
                                            />

                                            <Select
                                                className="selector-double"
                                                options={cmmGetData("deptcdAll4", 4, lvl3)}
                                                onChange={(e) => {
                                                    SetLvl(e.value);
                                                }}
                                                defaultValue={{
                                                    value: props.updateUserData.deptcd,
                                                    label: props.updateUserData.deptnm,
                                                }}
                                                ref={refLvl4}
                                            />
                                        </div>
                                    </>
                                )
                            ) : null}

                            <div className="form-group">
                                <label class="required">이메일</label>
                                <input
                                    {...register("email", {
                                        required: "필수정보입니다.",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "올바른 형식의 이메일을 입력하세요",
                                        },
                                    })}
                                    defaultValue={props.updateUserData.email}
                                    type="email"
                                    className="form-control"
                                    placeholder="메일 주소를 입력해주세요"
                                    maxLength="50"
                                />
                            </div>
                            {errors.email && <span className="span-box">{errors.email.message}</span>}
                            <div className="form-group">
                                <label class="required">핸드폰 번호</label>
                                <input
                                    {...register("cellphone", {
                                        required: "필수정보입니다.",
                                        pattern: {
                                            value: /^[0-9]{11}$/,
                                            message: "올바른 형식의 전화번호를 입력하세요",
                                        },
                                    })}
                                    defaultValue={props.updateUserData.cellphone}
                                    type="text"
                                    className="form-control"
                                    placeholder=" - 없이 핸드폰 번호를 입력해주세요"
                                />
                            </div>
                            {errors.cellphone && <span className="span-box">{errors.cellphone.message}</span>}
                            <div className="form-group">
                                <label class="required">생년월일</label>
                                <input
                                    {...register("birthdt", {
                                        required: "필수정보입니다.",
                                        pattern: {
                                            value: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
                                            message: "올바른 형식의 생년월일을 입력하세요",
                                        },
                                    })}
                                    defaultValue={props.updateUserData.birthdt}
                                    type="date"
                                    className="form-control"
                                    placeholder="1900-01-01 형식으로 생년월일을 입력해주세요"
                                />
                            </div>
                            {errors.birthdt && <span className="span-box">{errors.birthdt.message}</span>}
                            <div className="form-group">
                                <label class="required">권한그룹</label>
                                <Select
                                    className="selector-single2"
                                    options={usergrpcdOptions}
                                    onChange={(e) => {
                                        setUsergrpcd(e.value);
                                    }}
                                    defaultValue={{
                                        value: props.updateUserData.usergrpcd,
                                        label: props.updateUserData.usergrpnm,
                                    }}
                                />
                            </div>

                            {props.updateUserData.expertyn === "Y" ? (
                                <>
                                    <div className="form-group">
                                        <label class="required">전문가여부</label>
                                        <input
                                            type="radio"
                                            value="Y"
                                            name="expertYN"
                                            onChange={() => {
                                                setExpertcd("Y");
                                            }}
                                            checked
                                            className="expert-positive"
                                        />{" "}
                                        <span className="txt-expert">예</span>
                                        <input
                                            type="radio"
                                            value="N"
                                            name="expertYN"
                                            onChange={() => {
                                                setExpertcd("N");
                                            }}
                                            className="expert-negative"
                                        />{" "}
                                        <span className="txt-expert">아니오</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="form-group">
                                        <label class="required">전문가여부</label>
                                        <input
                                            type="radio"
                                            value="Y"
                                            name="expertYN"
                                            onChange={() => {
                                                setExpertcd("Y");
                                            }}
                                            className="expert-positive"
                                        />{" "}
                                        <span className="txt-expert">예</span>
                                        <input
                                            type="radio"
                                            value="N"
                                            name="expertYN"
                                            onChange={() => {
                                                setExpertcd("N");
                                            }}
                                            checked
                                            className="expert-negative"
                                        />{" "}
                                        <span className="txt-expert">아니오</span>
                                    </div>
                                </>
                            )}

                            <div className="form-group">
                                <label class="required">MDM </label>
                                <Select
                                    className="selector-single2"
                                    options={mdmcdOptions}
                                    onChange={(e) => {
                                        setMdmcd(e.value);
                                    }}
                                    defaultValue={{
                                        value: props.updateUserData.mdmtypecd,
                                        label: props.updateUserData.mdmtypenm,
                                    }}
                                />
                            </div>

                            <div className=" btn-wrapper">
                                <Button
                                    className="btn-resetPW"
                                    variant="secondary"
                                    onClick={() => {
                                        axios
                                            .post(`${server}/api/userInfo/updateUserPwd`, {
                                                sessionid: sessionStorage.getItem("SESSION"),
                                                userid: props.updateUserData.userid,
                                                cellphone: props.updateUserData.cellphone,
                                                updateid: ID,
                                                eamil: props.updateUserData.email,
                                            })
                                            .then((response) => {
                                                if (response.data) {
                                                    setAlertMessage("S002");
                                                    refreshState
                                                        ? dispatch({ type: "refreshFalse" })
                                                        : dispatch({ type: "refreshTrue" });
                                                } else {
                                                    setAlertMessage("E010");
                                                }
                                                dispatch({
                                                    type: "updateUserModalFalse",
                                                });
                                            })
                                            .catch(() => {
                                                setAlertMessage("E998");
                                                reset();
                                            });
                                    }}
                                >
                                    비밀번호 초기화
                                </Button>
                                <Button
                                    className="btn-save"
                                    variant="secondary"
                                    type="submit"
                                    style={{ margin: "20px 10px 70px 0" }}
                                >
                                    저장
                                </Button>
                                <Button
                                    className="btn-cancel"
                                    variant="secondary"
                                    onClick={() => {
                                        props.setShow(false);
                                    }}
                                    style={{ margin: "20px 130px 70px 0" }}
                                >
                                    취소
                                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}

export default UpdateInfo;
