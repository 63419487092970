import axios from "axios";
import React, { useEffect, useState } from "react";
import cmmGetUser from "../../cmm/cmmGetUser";

//부서 권한 관리
function Logout() {
    const server = process.env.REACT_APP_API_SERVER_HOST; // .env 파일에서 가져온 db_config

    useEffect(() => {
        axios.post(`${server}/api/sign/logout`, { sessionid: sessionStorage.getItem("SESSION") });
        window.location.replace("/");
        sessionStorage.clear();
    }, []);

    return <></>;
}

export default Logout;
