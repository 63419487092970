import axios from "axios";
const server = process.env.REACT_APP_API_SERVER_HOST;

const cmmGetUser = async () => {
    let user = null;

    await axios
        .post(`${server}/api/cmm/getUser`, { sessionid: sessionStorage.getItem("SESSION") })
        .then((response) => {
            user = response.data;
        })
        .catch((err) => {});

    return user;
};

export default cmmGetUser;
