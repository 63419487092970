//Moudle
import "../../../css/User.css";
import "../../../css/manage.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import cmmGetUser from "../../../cmm/cmmGetUser";

//신규 약관 추가
function AddTerms(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    let [use, setUse] = useState("");
    let [copy, setCopy] = useState("");
    let [per, setPer] = useState("");
    const { register, handleSubmit, reset } = useForm();

    const onChangeHanler = (e) => {
        switch (e.target.id) {
            case "use":
                //이용약관
                setUse(e.target.value);
                break;
            case "copy":
                //저작권
                setCopy(e.target.value);
                break;
            case "per":
                //개인정보
                setPer(e.target.value);
                break;

            default:
                break;
        }
    };

    const onSubmit = async (data) => {
        axios.post(`${server}/api/useTerms/addTerms`, {
            sessionid: sessionStorage.getItem("SESSION"),
            useterm: use,
            copyrightterm: copy,
            perinfoterm: per,
            begindt: data.begindt,
            userid: ID,
        });
        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
        props.setShow(false);
    };
    return (
        <Modal
            {...props}
            show={props.show}
            size="lg"
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            {" "}
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 신규 약관 생성 </span>
                </div>
                <hr className="headerline-lg"></hr>
                <Modal.Body>
                    <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <div className="form-group" style={{ height: "80px" }}>
                                <label style={{ marginTop: "12px" }}>적용 개시일</label>
                                <input
                                    id="begindt"
                                    {...register("begindt", {
                                        required: "필수정보입니다.",
                                    })}
                                    required
                                    type="date"
                                    className="form-control"
                                    style={{ marginLeft: "36px", marginBottom: "20px", width: "400px" }}
                                />
                            </div>
                            <div className="form-group" style={{ width: "117%", height: "240px" }}>
                                <label>이용 약관</label>
                                <textarea id="use" onChange={onChangeHanler} maxLength="2000"></textarea>
                            </div>

                            <div className="form-group" style={{ width: "117%", height: "240px" }}>
                                <label>저작권 보호정책</label>
                                <textarea id="copy" onChange={onChangeHanler} maxLength="2000"></textarea>
                            </div>

                            <div className="form-group" style={{ width: "117%", height: "240px" }}>
                                <label>개인정보 이용방침</label>
                                <textarea id="per" onChange={onChangeHanler} maxLength="2000"></textarea>
                            </div>

                            <div className="button-group" style={{ marginRight: "-75px" }}>
                                <Button className="btn-save" variant="secondary" type="submit">
                                    생성
                                </Button>
                                <Button
                                    className="btn-cancel"
                                    variant="secondary"
                                    onClick={() => {
                                        reset();
                                        props.setShow(false);
                                    }}
                                >
                                    취소
                                </Button>
                            </div>
                        </>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
}
export default AddTerms;
