import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-contexify/dist/ReactContexify.css";
import "../../css/ScrollBar.css";
import TypeSelect from "./LabelToolModal/TypeSelect";
import axios from "axios";
//이용 약관 관리
import cmmGetUser from "../../cmm/cmmGetUser";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기

function LabelTool(props) {
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기

    const [img, setImg] = useState();
    const [json, setJson] = useState();
    useEffect(async () => {
        // 이미지 가져오기
        await axios
            .post(`${server}/api/labelTool/img`, {
                sessionid: sessionStorage.getItem("SESSION"),
                imgPath: props.data.imgpath,
            })
            .then((res) => {
                setImg(res.data);
            });
        // 라벨 가져오기
        await axios
            .post(`${server}/api/labelTool/label`, {
                sessionid: sessionStorage.getItem("SESSION"),
                specimenid: props.data.specimenid,
                pseq: props.data.pseq,
                seq: props.data.seq,
                weightid: props.loadData.id,
            })
            .then((res) => {
                setJson(res.data);
            });
    }, []);

    const width = 1280;
    const height = 720;
    const margin = 100;
    const svgRef = useRef();
    const canvasWriteRef = useRef();
    const canvasReadRef = useRef();
    const textareaRef = useRef();
    const [label, setLabel] = useState("wire");
    const [resultLabelJSON, setResultLabelJSON] = useState([]);
    const [notNULLLabelJSON, setNotNULLLabelJSON] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [modeTF, setModeTF] = useState(false);
    const [modeTF2, setModeTF2] = useState(false);
    const [menuID, setMenuID] = useState();
    const [menuType, setMenuType] = useState();
    const [itemTag, setItemTag] = useState();
    const [typeSelectModal, setTypeSelectModal] = useState(false);
    const [modalResult, setModalResult] = useState();
    const [change, setChange] = useState(false);
    const [changeNum, setChangeNum] = useState();
    let labelJSON = resultLabelJSON[0] ? resultLabelJSON : [];
    let createLabelJSON = [];


    const reDrawSvg = () => {
        const count = labelJSON.length - 1;
        for (let i = 0; i < labelJSON.length; i++) {
            svgRef.current.removeChild(document.getElementById("label" + i));
        }
        for (let i = 0; i < labelJSON.length; i++) {
            svgRef.current.innerHTML += `<g id="label${i}" />`;
        }
        for (let j = 0; j < labelJSON.length; j++) {
            for (let i = 0; i < labelJSON[j].points.length; i++) {
                if (i + 1 === labelJSON[j].points.length) {
                    document.getElementById(
                        "label" + j
                    ).innerHTML += `<line x1="${labelJSON[j].points[i][0]}" y1="${labelJSON[j].points[i][1]}" x2="${labelJSON[j].points[0][0]}" y2="${labelJSON[j].points[0][1]}" stroke-width="3" stroke="lightgreen" id="index${j}line${i}" key="${i}" />`;
                } else {
                    document.getElementById("label" + j).innerHTML += `<line x1="${labelJSON[j].points[i][0]}" y1="${
                        labelJSON[j].points[i][1]
                    }" x2="${labelJSON[j].points[i + 1][0]}" y2="${
                        labelJSON[j].points[i + 1][1]
                    }" stroke-width="3" stroke="lightgreen" id="index${j}line${i}" key="${i}" />`;
                }
            }
            // 점 생성
            labelJSON[j].points.map((point, index) => {
                document.getElementById(
                    "label" + count
                ).innerHTML += `<circle cx="${point[0]}" cy="${point[1]}" r="${Math.PI}" fill="red" id="index${count}point${index}" key="${index}" onMouseOver="this.style.cursor='pointer'" />`;
            });
        }

        labelJSON.map((shape, count) => {
            if (shape != null) {
                shape.points.map((point, index) => {
                    if (!!document.getElementById(`index${count}point${index}`)) {
                        document
                            .getElementById(`index${count}point${index}`)
                            .addEventListener("mousedown", dragElement);
                    }
                });
            }
        });
    };

    const dragElement = (e) => {
        const elmnt = e.target;
        const index = elmnt.id.split("index")[1].split("point")[0];
        const point = elmnt.id.split("index")[1].split("point")[1];

        const mouseDown = (e) => {
            svgRef.current.onmouseup = mouseUp;
            document.onmouseup = mouseUp;
            svgRef.current.onmousemove = mouseMove;
        };

        const mouseMove = (e) => {
            const offsetX = e.offsetX;
            const offsetY = e.offsetY;
            const X = offsetX <= margin ? margin : offsetX >= width + margin ? width + margin : offsetX;
            const Y = offsetY <= margin ? margin : offsetY >= height + margin ? height + margin : offsetY;
            if (document.getElementById(`index${index}line${point - 1}`) === null) {
                // 실시간 선 재설정
                document.getElementById(`index${index}line${labelJSON[index].points.length - 1}`).x2.baseVal.value = X;
                document.getElementById(`index${index}line${labelJSON[index].points.length - 1}`).y2.baseVal.value = Y;
            } else {
                document.getElementById(`index${index}line${point - 1}`).x2.baseVal.value = X;
                document.getElementById(`index${index}line${point - 1}`).y2.baseVal.value = Y;
            }
            document.getElementById(`index${index}line${point}`).x1.baseVal.value = X;
            document.getElementById(`index${index}line${point}`).y1.baseVal.value = Y;
            // 실시간 점 재설정
            elmnt.cx.baseVal.value = X;
            elmnt.cy.baseVal.value = Y;
            labelJSON[index].points[point][0] = X - margin;
            labelJSON[index].points[point][1] = Y - margin;

            // 실시간 JSON 재설정
            setResultLabelJSON(labelJSON);
            setRefresh((prevState) => !prevState);
        };

        const mouseUp = (e) => {
            svgRef.current.onmouseup = null;
            document.onmouseup = null;
            svgRef.current.onmousemove = null;
            setResultLabelJSON(labelJSON);
            setRefresh((prevState) => !prevState);
        };

        mouseDown(e);
    };

    const mouseOverFill = (data) => {
        const canvas = canvasReadRef.current;
        const ctx = canvas.getContext("2d");
        ctx.globalAlpha = 0.4;
        ctx.fillStyle = "#392f31";
        ctx.beginPath();
        ctx.moveTo(data.points[0][0], data.points[0][1]);
        data.points.map((point) => {
            ctx.lineTo(point[0], point[1]);
        });
        ctx.closePath();
        ctx.fill();
    };

    const mouseOutReset = () => {
        const canvas = canvasReadRef.current;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, width, height);
        ctx.globalAlpha = 1;
        ctx.fillStyle = "lightgreen";
    };

    const labelDelete = (index) => {
        setAlertMessage("Q002").then((data) => {
            if (data) {
                mouseOutReset();
                const labelJSON = resultLabelJSON;
                labelJSON[index] = null;
                svgRef.current.removeChild(document.getElementById("label" + index));
                setResultLabelJSON(labelJSON);
                let notNULLJSON = [];
                labelJSON.map((data) => {
                    if (data != null) {
                        notNULLJSON.push(data);
                    }
                });
                setNotNULLLabelJSON(notNULLJSON);
            }
        });
    };

    useEffect(() => {
        if (json) {
            // 기존데이터 가져오기
            json.shapes.map((shape, count) => {
                svgRef.current.innerHTML += `<g id="label${count}" />`;
                // 선 생성
                for (let i = 0; i < shape.points.length; i++) {
                    if (i + 1 === shape.points.length) {
                        document.getElementById("label" + count).innerHTML += `<line x1="${
                            shape.points[i][0] + margin
                        }" y1="${shape.points[i][1] + margin}" x2="${shape.points[0][0] + margin}" y2="${
                            shape.points[0][1] + margin
                        }" stroke-width="3" stroke="lightgreen" id="index${count}line${i}" key="${i}" />`;
                    } else {
                        document.getElementById("label" + count).innerHTML += `<line x1="${
                            shape.points[i][0] + margin
                        }" y1="${shape.points[i][1] + margin}" x2="${shape.points[i + 1][0] + margin}" y2="${
                            shape.points[i + 1][1] + margin
                        }" stroke-width="3" stroke="lightgreen" id="index${count}line${i}" key="${i}" />`;
                    }
                }
                // 점 생성
                shape.points.map((point, index) => {
                    document.getElementById("label" + count).innerHTML += `<circle cx="${point[0] + margin}" cy="${
                        point[1] + margin
                    }" r="${
                        Math.PI
                    }" fill="red" id="index${count}point${index}" key="${index}" onMouseOver="this.style.cursor='pointer'" />`;
                });
                labelJSON.push({
                    label: label ? label : "",
                    points: shape.points.map((point) => [point[0], point[1]]),
                    group_id: null,
                    shape_type: "polygon",
                    flags: {},
                    type: shape.type,
                });
            });
            json.shapes.map((shape, count) => {
                shape.points.map((point, index) => {
                    document.getElementById(`index${count}point${index}`).addEventListener("mousedown", dragElement);
                });
            });

            setResultLabelJSON(labelJSON);
            let notNULLJSON = [];
            labelJSON.map((data) => {
                if (data != null) {
                    notNULLJSON.push(data);
                }
            });
            setNotNULLLabelJSON(labelJSON);
        }
    }, [json]);

    // 첫번째 점에 마우스포인터 변경
    const pointerDot = useCallback(
        (e) => {
            if (createLabelJSON.length >= 1) {
                if (
                    createLabelJSON[0][0] + Math.PI >= e.offsetX &&
                    createLabelJSON[0][0] - Math.PI <= e.offsetX &&
                    createLabelJSON[0][1] + Math.PI >= e.offsetY &&
                    createLabelJSON[0][1] - Math.PI <= e.offsetY
                ) {
                    document.body.style.cursor = "pointer";
                } else {
                    document.body.style.cursor = "default";
                }
            }
        },
        [labelJSON]
    );

    const quitDrawing = () => {
        if (createLabelJSON.length >= 3) {
            setModeTF2(false);
            // setTypeSelectModal(true);
            const canvas = canvasWriteRef.current;
            const ctx = canvas.getContext("2d");
            // 캔버스 초기화
            let type = props.data.firetypecd;

            if (type === "12") {
                type = "electric";
            } else if (type === "3") {
                type = "flame";
            } else if (type === "9") {
                type = "indistinct";
            }

            ctx.clearRect(0, 0, width, height);
            labelJSON.push({
                label: label ? label : "",
                points: createLabelJSON,
                group_id: null,
                shape_type: "polygon",
                flags: {},
                type: type,
            });
            setResultLabelJSON(labelJSON);
            let notNULLJSON = [];
            labelJSON.map((data, index) => {
                // 갯수 제한걸기
                // if (data != null && index === 0) {
                notNULLJSON.push(data);
                // }
            });
            setNotNULLLabelJSON(notNULLJSON);
            setModalResult({ type: type, labelLength: labelJSON.length });
            const count = labelJSON.length - 1;
            svgRef.current.innerHTML += `<g id="label${count}" />`;
            for (let i = 0; i < labelJSON[count].points.length; i++) {
                if (i + 1 === labelJSON[count].points.length) {
                    document.getElementById("label" + count).innerHTML += `<line x1="${
                        labelJSON[count].points[i][0] + margin
                    }" y1="${labelJSON[count].points[i][1] + margin}" x2="${
                        labelJSON[count].points[0][0] + margin
                    }" y2="${
                        labelJSON[count].points[0][1] + margin
                    }" stroke-width="3" stroke="lightgreen" id="index${count}line${i}" key="${i}" />`;
                } else {
                    document.getElementById("label" + count).innerHTML += `<line x1="${
                        labelJSON[count].points[i][0] + margin
                    }" y1="${labelJSON[count].points[i][1] + margin}" x2="${
                        labelJSON[count].points[i + 1][0] + margin
                    }" y2="${
                        labelJSON[count].points[i + 1][1] + margin
                    }" stroke-width="3" stroke="lightgreen" id="index${count}line${i}" key="${i}" />`;
                }
            }
            // 점 생성
            labelJSON[count].points.map((point, index) => {
                document.getElementById("label" + count).innerHTML += `<circle cx="${point[0] + margin}" cy="${
                    point[1] + margin
                }" r="${
                    Math.PI
                }" fill="red" id="index${count}point${index}" key="${index}" onMouseOver="this.style.cursor='pointer'" />`;
            });
            labelJSON.map((shape, count) => {
                if (shape != null) {
                    shape.points.map((point, index) => {
                        document
                            .getElementById(`index${count}point${index}`)
                            .addEventListener("mousedown", dragElement);
                    });
                }
            });

            createLabelJSON = [];
            ctx.save();
        } else {
            alert("점이나 선은 라벨을 완성할 수 없습니다.");
        }
    };

    const createLabel = useCallback(
        (e) => {
            setModeTF2(true);
            const canvas = canvasWriteRef.current;
            const ctx = canvas.getContext("2d");
            ctx.globalAlpha = 1;
            ctx.lineWidth = 3;
            ctx.fillStyle = "red";
            ctx.strokeStyle = "lightgreen";

            if (createLabelJSON.length >= 1) {
                if (
                    createLabelJSON[0][0] + Math.PI >= e.offsetX &&
                    createLabelJSON[0][0] - Math.PI <= e.offsetX &&
                    createLabelJSON[0][1] + Math.PI >= e.offsetY &&
                    createLabelJSON[0][1] - Math.PI <= e.offsetY
                ) {
                    document.body.style.cursor = "default";
                    quitDrawing();
                } else {
                    createLabelJSON.push([e.offsetX, e.offsetY]);
                    // 캔버스 초기화
                    ctx.clearRect(0, 0, width, height);

                    // 선 그리기
                    ctx.beginPath();
                    for (let i = 0; i < createLabelJSON.length - 1; i++) {
                        ctx.moveTo(createLabelJSON[i][0], createLabelJSON[i][1]);
                        ctx.lineTo(createLabelJSON[i + 1][0], createLabelJSON[i + 1][1]);
                    }
                    ctx.closePath();
                    ctx.stroke();

                    // 점 그리기
                    createLabelJSON.map((point) => {
                        ctx.beginPath();
                        ctx.arc(point[0], point[1], Math.PI, 0, Math.PI * 2, true);
                        ctx.closePath();
                        ctx.fill();
                    });
                }
            } else {
                // 캔버스 초기화
                ctx.clearRect(0, 0, width, height);
                createLabelJSON.push([e.offsetX, e.offsetY]);
                ctx.beginPath();
                ctx.arc(e.offsetX, e.offsetY, Math.PI, 0, Math.PI * 2, true);
                ctx.closePath();
                ctx.fill();
            }
        },
        [labelJSON]
    );

    const quitLabel = useCallback(
        (e) => {
            quitDrawing();
        },
        [labelJSON]
    );

    const deleteDot = useCallback(
        (e) => {
            if (e.key === "Backspace" || e.key === "Delete") {
                if (createLabelJSON.length > 0) {
                    const canvas = canvasWriteRef.current;
                    const ctx = canvas.getContext("2d");
                    createLabelJSON.pop();
                    // 선들 초기화
                    ctx.clearRect(0, 0, width, height);

                    // 선 그리기
                    ctx.beginPath();
                    for (let i = 0; i < createLabelJSON.length - 1; i++) {
                        ctx.moveTo(createLabelJSON[i][0], createLabelJSON[i][1]);
                        ctx.lineTo(createLabelJSON[i + 1][0], createLabelJSON[i + 1][1]);
                    }
                    ctx.closePath();
                    ctx.stroke();

                    // 점 그리기
                    createLabelJSON.map((point) => {
                        ctx.beginPath();
                        ctx.arc(point[0], point[1], Math.PI, 0, Math.PI * 2, true);
                        ctx.closePath();
                        ctx.fill();
                    });
                }
            }
        },
        [createLabelJSON]
    );

    const labelSave = () => {
        if (notNULLLabelJSON[0]) {
            let arr = [];
            notNULLLabelJSON.map((data) => {
                if (!data.type) {
                    arr.push(false);
                } else {
                    arr.push(true);
                }
            });

            arr = new Set(arr);
            arr = [...arr];

            if (arr[0] === true && arr.length === 1) {
                axios
                    .post(`${server}/api/labelTool/labelSave`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        json: textareaRef.current.innerHTML,
                        specimenid: props.data.specimenid,
                        seq: props.data.seq,
                        pseq: props.data.pseq,
                        weightid: props.weightid,
                        type: notNULLLabelJSON[notNULLLabelJSON.length - 1].type,
                        id: ID,
                    })
                    .then(() => {
                        axios
                            .post(`${server}/api/fileStorageMenu/searchCopy`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                weightid: props.loadData.id,
                            })
                            .then((res) => {
                                props.setSearchCopy(props.dataJSON({ res: res }));
                            });
                        setAlertMessage("I002").then(() => {
                            props.setLabelToolModal(false);
                            props.setModalCheck(true);
                            props.imgTarget.click();
                        });
                    });
            }
        } else {
            setAlertMessage("E050");
        }
    };

    useEffect(() => {
        const canvas = canvasWriteRef.current;
        const ctx = canvas.getContext("2d");
        // 캔버스 초기화
        ctx.clearRect(0, 0, width, height);
        if (modeTF) {
            canvasWriteRef.current.style.visibility = "visible";
            canvasWriteRef.current.addEventListener("click", createLabel);
            canvasWriteRef.current.addEventListener("dblclick", quitLabel);
            canvasWriteRef.current.addEventListener("mousemove", pointerDot);
            canvasWriteRef.current.addEventListener("keydown", deleteDot);
        } else {
            canvasWriteRef.current.style.visibility = "hidden";
            canvasWriteRef.current.removeEventListener("click", createLabel);
            canvasWriteRef.current.removeEventListener("dblclick", quitLabel);
            canvasWriteRef.current.removeEventListener("mousemove", pointerDot);
            canvasWriteRef.current.removeEventListener("keydown", deleteDot);
        }
    }, [modeTF]);

    useEffect(() => {
        if (modalResult && notNULLLabelJSON && modalResult.type) {
            if (change) {
                notNULLLabelJSON[changeNum].type = modalResult.type;
            } else {
                notNULLLabelJSON[modalResult.labelLength - 1].type = modalResult.type;
            }
        }
    }, [modalResult, notNULLLabelJSON]);

    return (
        <Modal
            show={props.labelToolModal}
            fullscreen={true}
            onHide={() => props.setLabelToolModal(false)}
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="manage-title">라벨툴</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ display: "inline" }}>
                    <div
                        style={{
                            display: "block",
                            width: "20%",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                height: height * 0.7 + margin,
                                border: "1px solid black",
                                float: "left",
                                display: "block",
                                padding: "20px",
                                overflow: "auto",
                            }}
                            className="scrollDiv"
                        >
                            <label style={{ textAlign: "center", width: "100%" }}>
                                <h4>Labels</h4>
                            </label>
                            <br />
                            <div style={{ paddingTop: "20px" }}>
                                <hr />
                            </div>
                            <div>
                                {resultLabelJSON
                                    ? resultLabelJSON.map((data, index) => {
                                          if (data != null) {
                                              return (
                                                  <>
                                                      <Button
                                                          onMouseOver={() => {
                                                              mouseOverFill(data);
                                                          }}
                                                          onMouseOut={() => {
                                                              mouseOutReset();
                                                          }}
                                                          onClick={() => {
                                                              labelDelete(index);
                                                          }}
                                                          style={{
                                                              width: "100%",
                                                              margin: "0px 20px 20px 0px",
                                                          }}
                                                          className="btn-add"
                                                          variant="secondary"
                                                      >
                                                          {notNULLLabelJSON[index]
                                                              ? notNULLLabelJSON[index].type === "flame"
                                                                  ? "열흔"
                                                                  : notNULLLabelJSON[index].type === "electric"
                                                                  ? "단락흔"
                                                                  : notNULLLabelJSON[index].type === "indistinct"
                                                                  ? "판별불가"
                                                                  : "미정"
                                                              : null}
                                                      </Button>
                                                      {/* <Button
                                                          className="btn-search"
                                                          variant="secondary"
                                                          style={{ width: "20%", margin: "0px 0px 20px 0px" }}
                                                          onClick={() => {
                                                              setTypeSelectModal(true);
                                                              setChange(true);
                                                              setChangeNum(index);
                                                          }}
                                                      >
                                                          변경
                                                      </Button> */}
                                                  </>
                                              );
                                          }
                                      })
                                    : null}
                            </div>
                        </div>

                        <div
                            style={{
                                width: "100%",
                                height: height * 0.3 + margin,
                                border: "1px solid black",
                                float: "left",
                                padding: "20px",
                            }}
                        >
                            <label style={{ textAlign: "center", width: "100%" }}>
                                <h4>Options</h4>
                            </label>
                            <br />
                            <div style={{ paddingTop: "20px" }}>
                                <hr />
                            </div>
                            <Button
                                onClick={() => setModeTF((prevState) => !prevState)}
                                disabled={modeTF2}
                                style={{
                                    width: "100%",
                                    margin: "0px 0px 20px 0px",
                                }}
                                className="btn-add"
                                variant="secondary"
                            >
                                {modeTF ? "편집" : "추가"}
                            </Button>
                            <Button
                                onClick={() => {
                                    labelSave();
                                }}
                                disabled={modeTF2}
                                style={{
                                    width: "100%",
                                    margin: "0px 0px 20px 0px",
                                }}
                                className="btn-add"
                                variant="secondary"
                            >
                                저장
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: "80%", WebkitUserSelect: "none" }}>
                        {/* <img src={data} style={{ position: "absolute" }} /> */}
                        <img
                            src={img}
                            width={width}
                            height={height}
                            style={{
                                position: "absolute",
                                // border: "1px solid black",
                                margin: margin,
                            }}
                        />
                        <div>
                            {/* 보이는 캔버스 */}
                            <canvas
                                ref={canvasReadRef}
                                width={width}
                                height={height}
                                style={{ position: "absolute", margin: margin }}
                                tabindex="1"
                            />
                            <svg
                                style={{
                                    height: height + margin * 2,
                                    width: width + margin * 2,
                                    position: "absolute",
                                    border: "1px solid black",
                                    // margin: margin,
                                }}
                                ref={svgRef}
                            />
                            {/* 그리는 캔버스 */}
                            <canvas
                                ref={canvasWriteRef}
                                width={width}
                                height={height}
                                style={{ position: "absolute", visibility: "hidden", margin: margin }}
                                tabindex="1"
                            />
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: height + 60 }}>
                    <textarea
                        ref={textareaRef}
                        rows="6"
                        value={JSON.stringify({
                            version: "4.5.9",
                            flags: {},
                            shapes: notNULLLabelJSON ? notNULLLabelJSON : [],
                            imagePath: props.data.imgrawpath,
                            imageHeight: height,
                            imageWidth: width,
                        })}
                        style={{ display: "none" }}
                        disabled
                        maxLength="2000"
                    />
                </div>
                <div>
                    <TypeSelect
                        typeSelectModal={typeSelectModal}
                        setTypeSelectModal={setTypeSelectModal}
                        setModalResult={setModalResult}
                        modalResult={modalResult}
                        labelLength={labelJSON.length}
                        setChange={setChange}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default LabelTool;
