import "../../css/userModal.css";
import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState, useRef } from "react";
import { Button, Table } from "react-bootstrap";
// chartjs
import { Pie, Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

// 공통함수
import { cmmDateLabels, DatePickerMonthly } from "../../cmm/cmmDateFunction";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import SelectOrg from "../../cmm/cmmSelectOrg";
import axios from "axios";
import cmmGetUser from "../../cmm/cmmGetUser";

// 인쇄용
import ReactToPrint from 'react-to-print';


// 시설별 통계
const StatFacility = () => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    const [lvl, setLvl] = useState();                       // 부서
    const [date, setDate] = useState();                     // 날짜
    const [monthlabels, setMonthlabels] = useState([]);     // 공통: 개월수
    const [colwidth, setColwidth] = useState("7%");     // 공통: 개월수

    const [linedataTot, setLinedataTot] = useState([]);     // 좌측 상단 차트 데이터
    const [linedata12, setLinedata12] = useState([]);       // 판별 결과 데이터: 단락흔 
    const [linedata3, setLinedata3] = useState([]);         // 판별 결과 데이터: 열흔
    const [linedata9, setLinedata9] = useState([]);         // 판별 결과 데이터: 열흔
    const [pieChartData, setPieChartData] = useState([]);   // 점유율 데이터
    
    const [tableheader, setTableheader] = useState([]);     // 하단 표 헤더 (연도 span용)
    const [tablesum, setTablesum] = useState([]);           // 하단 표 합계
    const [tabledata, setTabledata] = useState([]);         // 하단 표 개별 줄
    const labelName = ["단락흔", "열흔", "판별불가"];
    
    ////////////// 인쇄 버튼 관련 /////////////////////////////////
    const bodyRef = useRef();
    
    const getPrintBtn = () =>{
        return (
            <Button className="btn-searchStat">
                <FontAwesomeIcon icon={faPrint} /> 출력
            </Button>
        );
    }

    /////////////// 업무 로직 ///////////////////////////////////////////
    const Chart = () => {
        axios
            .post(`${server}/api/stat/statFacility`, {
                sessionid: sessionStorage.getItem("SESSION"),
                startDate: date.begindt,
                endDate: date.enddt,
                lvl: lvl.lvl,
                id: ID
            })
            .then((res) => {
                if (!res.data.data) {
                    setAlertMessage("I004");
                } else {
                    
                    let tableheader = res.data.monthlabels;
                    setTableheader(tableheader);

                    let newmonths = [];
                    let newColwidth="0%";
                    if (res.data.monthlabels.length>0){
                        newColwidth = Math.ceil(84/res.data.monthlabels.length*10)/10+"%";
                        for (let i =0; i < res.data.monthlabels.length; i++){
                            if (res.data.monthlabels[i].rn==1){
                                newmonths.push(res.data.monthlabels[i].yr+"년\n"+res.data.monthlabels[i].mm+"월");
                            } else {
                                newmonths.push(res.data.monthlabels[i].mm);
                            }
                        }
                    }
                    setMonthlabels(newmonths);
                    setColwidth(newColwidth);
                    
                    if (res.data.data.length>0){
                        let newtotal = [];
                        for (let i=0;i<res.data.monthlabels.length;i++) newtotal.push(res.data.data[0]["cnt"+i]);
                        setLinedataTot(newtotal);
                        
                        let newTableSum = res.data.data[0];
                        setTablesum(newTableSum);

                        let newTableData = Array.from(res.data.data).splice(1)  //// Array.from 안 쓰면 원본 res.data.data 손상됨 (shallow copy에서 splice된 [0]만 남음)
                        setTabledata(newTableData);
                    }
                    
                    if (res.data.data.length>1){
                        let newdata12 = [];
                        for (let i=0;i<res.data.monthlabels.length;i++) newdata12.push(res.data.data[1]["cnt"+i]);
                        setLinedata12(newdata12);
                    }
    
                    if (res.data.data.length>2){
                        let newdata3 = [];
                        for (let i=0;i<res.data.monthlabels.length;i++) newdata3.push(res.data.data[2]["cnt"+i]);
                        setLinedata3(newdata3);
                    }
                    
                    if (res.data.data.length>3){
                        let newdata9 = [];
                        for (let i=0;i<res.data.monthlabels.length;i++) newdata9.push(res.data.data[3]["cnt"+i]);
                        setLinedata9(newdata9);
                    }

                    let newPieChartData = new Array();
                    for (let i=1;i<res.data.data.length;i++){
                        newPieChartData.push(res.data.data[i].cntsum);
                    }
                    setPieChartData(newPieChartData);
                }
            });
    };

    const linedata = {
        labels: monthlabels,
        datasets: [
            {
                label: "판별건수",
                data: linedataTot,
                yAxisID: "A",
                backgroundColor: "#BA55D3",
                borderColor: "#BA55D3",
                fill: false,
                pointHoverRadius: 20,
                pointHoverBorderWidth: 5,
                type: "line",
                order: 0,
                tension: 0,
            },
        ],
    };

    const lineOption = {
        animation: {
            duration: 600,
        },
        scales: {
            yAxes: [
                {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "A",
                    ticks: {
                        min: 0,
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: {
            display: false,
        },
        tooltips: {
            displayColors: true,
            callbacks: {
                mode: "single",
                labelColor: function (tooltipItem, chart) {
                    return {
                        borderColor: "transparent",
                        backgroundColor: linedata.datasets[tooltipItem.datasetIndex].backgroundColor,
                    };
                },
                label: function (tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${
                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === null
                            ? 0
                            : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    }`;
                },
            },
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                display: "",
            },
        },
    };

    const line2Data = {
        labels: monthlabels,
        datasets: [
            {
                label: "단락흔",
                data: linedata12,
                // yAxisID: "B",
                backgroundColor: "rgba(87, 121, 234, 1)",
                borderColor: "rgba(87, 121, 234, 1)",
                fill: false, pointHoverRadius: 20, pointHoverBorderWidth: 5, type: "line", order: 0, tension: 0,
            },
            {
                label: "열흔",
                data: linedata3,
                backgroundColor: "rgba(255, 127, 0, 1)",
                borderColor: "rgba(255, 127, 0, 1)",
                fill: false, pointHoverRadius: 20, pointHoverBorderWidth: 5, type: "line", order: 0, tension: 0,
            },
            {
                label: "판별불가",
                data: linedata9,
                backgroundColor: "rgba(136, 136, 136, 1)",
                borderColor: "rgba(136, 136, 136, 1)",
                fill: false, pointHoverRadius: 20, pointHoverBorderWidth: 5, type: "line", order: 0, tension: 0,
            },
        ],
    };

    const line2Option = {
        animation: {
            duration: 600,
        },
        scales: {
            yAxes: [
                {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "A",
                    ticks: {
                        min: 0,
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
            position: "bottom",
        },
        tooltips: {
            displayColors: true,
            callbacks: {
                mode: "single",
                labelColor: function (tooltipItem, chart) {
                    return {
                        borderColor: "transparent",
                        backgroundColor: line2Data.datasets[tooltipItem.datasetIndex].backgroundColor,
                    };
                },
                label: function (tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${
                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === null
                            ? 0
                            : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    }`;
                },
            },
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                display: "",
            },
        },
    };

    const pieData = {
        labels: labelName,
        datasets: [
            {
                data: pieChartData,
                backgroundColor: ["rgba(87, 121, 234, 1)", "rgba(255, 127, 0, 1)", "rgba(192, 192, 192, 1)"],
                order: 0,
                tension: 0,
            },
        ],
    };

    const pieOption = {
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: false,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        display: false,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
            position: "bottom",
        },
        tooltips: {
            displayColors: true,
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;

                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                        let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                        let percentage =
                            Math.round((value / sum) * 100) > 0 ? Math.round((value / sum) * 100) + "%" : null;
                        return percentage;
                    }
                },
                font: {
                    weight: "bold",
                    size: 16,
                },
                color: "#fff",
            },
        },
    };

    useEffect(() => {
        if (date && ID) {
            Chart();
        }
    }, [date, lvl, ID]);

    return (
        <div ref={bodyRef}>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <div style={{ backgroundColor: "#F5F5F5" }}>
                <SelectOrg setLvl={setLvl} type="table" />
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ paddingTop: "40px" }}>
                            <tr>
                                <td className="style-td">
                                    기간 &nbsp;&nbsp;
                                </td>
                                <td>
                                    <DatePickerMonthly setDate={setDate} date={date} />
                                </td>
                                <td>
                                    <ReactToPrint
                                        trigger={() => getPrintBtn()}
                                        content={() => bodyRef.current}
                                    />                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div style={{ display: "flex" }}>
                <div style={{ width: "33%" }}>
                    <h4 className="style-h4">감정 건수</h4>
                    <div className="graph-stat">
                        <Line data={linedata} options={lineOption}/>
                    </div>
                </div>
                <div style={{ width: "33%" }}>
                    <h4 className="style-h4">감정 결과</h4>
                    <div className="graph-stat">
                        <Line data={line2Data} options={line2Option}/>
                    </div>
                </div>
                {/* 원형 차트 */}
                <div style={{ width: "33%" }}>
                    <h4 className="style-h4">
                        점유율 <b style={{ fontSize: "12px", color: "#ccc" }}>(0.5%미만 출력X)</b>
                    </h4>
                    <div className="graph-stat">
                        <Pie data={pieData} options={pieOption}/>
                    </div>
                </div>
            </div>

            <br />
            <br />

            <Table triped bordered responsive>
                <thead className="style-tHeader">
                    <tr style={{ backgroundColor: "#f7f9fa" }}>
                        <th rowSpan="2" style={{ width: "5%" }}>구분</th>
                        <th rowSpan="2" style={{ width: "5%" }}>합계</th>
                        <th rowSpan="2" style={{ width: "4%" }}>비율</th>
                        {tableheader.map((n, index) => (
                            tableheader[index].rn==1 ? 
                            <th key={index} colSpan={tableheader[index].span} style={{ width: colwidth }}>
                                {tableheader[index].yr}년
                            </th>
                            :""
                        ))}
                    </tr>
                    <tr style={{ backgroundColor: "#f7f9fa" }}>
                        {tableheader.map((n, index) => (
                            <th key={index} style={{ width: "5%" }}>
                                {tableheader[index].mm}월
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr key="0">
                        <td>합계</td>
                        <td>{tablesum.cntsum}건</td>
                        <td>100.00%</td>
                        {monthlabels.map((n, index) => (
                            <td>{tablesum["cnt"+index]}&nbsp;건</td>
                        ))}
                    </tr>
                    {tabledata.map((n, index) => (
                        <>
                            <tr key={index+1}>
                                <td>{tabledata[index].firetypenm}</td>
                                <td>{tabledata[index].cntsum}&nbsp;건</td>
                                <td>{(Math.round(tabledata[index].cntsum / tablesum.cntsum * 10000)/100).toFixed(2)}%</td>
                                {monthlabels.map((n, index2) => (
                                    <td>{tabledata[index]["cnt"+index2]}&nbsp;건</td>
                                ))}
                            </tr>
                        </>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default StatFacility;
