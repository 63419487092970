import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import cmmGetData from "./cmmGetData";
import { Table } from "react-bootstrap";
import cmmGetUser from "./cmmGetUser";
import axios from "axios";

const SelectOrgUser = (props) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    const refLvl2 = useRef();
    const refLvl3 = useRef();
    const refLvl4 = useRef();
    const refUserid = useRef();

    const [label, SetLabel] = useState();

    // 부서코드
    const [lvl, SetLvl] = useState("A");
    const [lvl3, SetLvl3] = useState();
    const [lvl4, SetLvl4] = useState();
    const [userid, SetUserid] = useState();
    const [num, SetNum] = useState();

    const [lvlAllData, setLvlAllData] = useState([]);

    useEffect(() => {
        axios
            .post(`${server}/api/cmm/searchAll3`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                setLvlAllData(res.data);
            });
    }, []);


    // 본인부서 (관리자 A는 전체 조회)
    const selectOption2 = (num, deptcd) => {
        let filterData = lvlAllData.filter((data) => data.lvl === num).filter((data) => data.pdeptcd === deptcd);
        let returnData = [];
        if (deptcd === "A" && sessionData ? !(sessionData.orgcd === "A") : false) {
            filterData = filterData.filter((data) => data.deptcd === sessionData.orgcd);
        } else if (deptcd === "A" && (sessionData ? sessionData.orgcd === "A" : false) && num === 2) {
            // 관리자 전용 전체 조회
            returnData.push({ label: "선택", value: "A" });
        }

        filterData.map((data) => {
            returnData.push({ label: data.deptnm, value: data.deptcd });
        });
        return returnData;
    };

    useEffect(() => {
        props.setLvl({ lvl: lvl, label: label, num:num });
    }, [lvl]);


    // all2 대비 setUserid 추가
    const selectOneAll3 = () => {
        return (
            <Select
                className="selector-soloQuad"
                options={selectOption2(2, "A")}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetLvl3(e.value);
                        SetLvl4(e.value);
                        SetUserid(e.value);
                        SetNum(2);
                        if (refLvl3.current) {
                            if (refLvl3.current.state.value) {
                                refLvl3.current.state.value.label = "선택";
                            }
                        }

                        if (refLvl4.current) {
                            if (refLvl4.current.state.value) {
                                refLvl4.current.state.value.label = "선택";
                            }
                        }

                        if (refUserid.current) {
                            if (refUserid.current.state.value) {
                                refUserid.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl2}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectTwoAll3 = () => {
        return (
            <Select
                className="selector-soloQuad"
                options={selectOption2(3, lvl3)}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetLvl4(e.value);
                        SetUserid(e.value);
                        SetNum(3);
                        if (refLvl4.current) {
                            if (refLvl4.current.state.value) {
                                refLvl4.current.state.value.label = "선택";
                            }
                        }
                        if (refUserid.current) {
                            if (refUserid.current.state.value) {
                                refUserid.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl3}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectThreeAll3 = () => {
        return (
            <Select
                className="selector-soloQuad"
                options={selectOption2(4, lvl4)}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetUserid(e.value);
                        SetNum(4);
                        if (refUserid.current) {
                            if (refUserid.current.state.value) {
                                refUserid.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl4}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectUser = () =>{
        return (
            <Select
                className="selector-soloQuad"
                options={selectOption2(5, userid)}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetNum(5);
                    }
                }}
                ref={refUserid}
                defaultValue={{ label: "선택" }}
            />
        )
    }

    // 사용자별 통계
    const rv = () => {
        return (
            <div className="style-searchBox_soloLine">
                <table style={{ width: "100%", marginBottom: "-30px" }}>
                    <tbody>
                        <tr style={{paddingTop:"12px",height: "100px"}}>
                            <td style={{width: "280px"}}>조직/소속/사용자</td>
                            <td>{selectOneAll3()}</td>
                            <td>{selectTwoAll3()}</td>
                            <td>{selectThreeAll3()}</td>
                            <td>{selectUser()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    return rv();
};

export default SelectOrgUser;
