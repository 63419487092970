import "../../../css/userModal.css";
//Moudle
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
//공통함수
import cmmGetUser from "../../../cmm/cmmGetUser";
import SelectOrg from "../../../cmm/cmmSelectOrg";

const DeptSelect = (props) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const orgBulletinState = useSelector((state) => state.orgBulletinModalReducer);
    // 부서
    const [lvl, setLvl] = useState();
    const dispatch = useDispatch();
    return (
        <Modal
            {...props}
            size="lg"
            centered
            show={orgBulletinState}
            onHide={() => {
                dispatch({ type: "orgBulletinModalFalse" });
                //props.setLvl({ lvl: "A", label: "전체" });
            }}
        >
            <Modal.Header>
                <Modal.Title>조직도 선택</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectOrg setLvl={setLvl} type="bulletin" />
                <div style={{ float: "right" }}>
                    <Button
                        className="btn"
                        variant="secondary"
                        type="submit"
                        onClick={() => {
                            dispatch({ type: "orgBulletinModalFalse" });
                            props.setLvl(lvl);
                        }}
                        style={{ marginRight: "15px" }}
                    >
                        저장
                    </Button>
                    <Button
                        className="btn"
                        variant="secondary"
                        type="button"
                        onClick={() => {
                            dispatch({ type: "orgBulletinModalFalse" });
                            // props.setLvl({ lvl: "A", label: "전체" });
                        }}
                    >
                        취소
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeptSelect;
