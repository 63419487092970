import React, { useEffect } from "react";
//시스템 관리자 계정 신청 관리
//Moudle
import "../../css/manage.css";
import "../../css/userModal.css";
import { Table, Button } from "react-bootstrap";

import axios from "axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from "react-select";

//공통함수
import cmmGetData from "../../cmm/cmmGetData"; //Config 가져오기
import PageBtn from "../../cmm/cmmPageBtn";

//Modal
import UpdateStatus from "./UserApplyModal/UpdateStatus";
import RevokeMsg from "./UserApplyModal/RevokeMsg";
import ShowRevokeMsg from "./UserApplyModal/ShowRevokeMsg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import cmmGetUser from "../../cmm/cmmGetUser";
import { now } from "jquery";

//계정 신청 관리

function UserApply() {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기

    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const [modalShowStatus, setModalShowStatus] = useState(false);
    const [modalShowRevoke, setModalShowRevoke] = useState(false);
    const [modalShowRevokeMsg, setModalShowRevokeMsg] = useState(false);

    const statuscdApplyOptions = cmmGetData("statuscdApply"); // 유저 상태 config
    let [statuscdApply, setStatuscdApply] = useState(""); // 유저 상태
    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let statusnm = "";

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;

    //현재날짜 불러오기
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;

    let date = today.getDate(); // 날짜
    date = parseInt(date) < 10 ? "0" + date : date;
    let current = year + "-" + month + "-" + date;

    let temp = new Date(year, month - 1, date);

    temp.setDate(temp.getDate() - 3);

    year = temp.getFullYear(); // 년도
    month = temp.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    date = temp.getDate(); // 날짜

    date = parseInt(date) < 10 ? "0" + date : date;
    let past = year + "-" + month + "-" + date;

    //조회 요청할 데이터 저장
    let [inquiryData, setInquiryData] = useState({
        begindt: "",
        enddt: "",
        usernm: "",
        statuscd: "",
        email: "",
        cellphone: "",
    });
    //조회 결과 데이터 저장
    let [inquiryResultData, setInquiryResultData] = useState(false);

    //update Querry에 사용할 데이터 초기화

    let [updateUserData, setUpdateUserData] = useState([
        {
            begindt: "",
            enddt: "",
            usernm: "",
            statuscd: "",
            email: "",
            cellphone: "",
        },
    ]);

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용

    //useForm 관련
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = async (data) => {
        setInquiryData({
            begindt: data.begindt,
            enddt: data.enddt,
            usernm: data.usernm,
            statuscd: statuscdApply,
            email: data.email,
            cellphone: data.cellphone,
        });

        await axios
            .post(`${server}/api/userApply/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                begindt: data.begindt,
                enddt: data.enddt,
                usernm: data.usernm,
                statuscd: statuscdApply,
                email: data.email,
                cellphone: data.cellphone,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                } else {
                }
            })
            .catch((err) => {});
    };

    /*useEffect는
[]안에 값 선언시, [] 값이 바뀌거나,
페이지가 refresh될때 함수가 실행됨!
*/

    useEffect(() => {
        axios
            .post(`${server}/api/userApply/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                begindt: inquiryData.begindt,
                enddt: inquiryData.enddtt,
                usernm: inquiryData.usernm,
                statuscd: inquiryData.statuscd,
                email: inquiryData.email,
                cellphone: inquiryData.cellphone,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                } else {
                }
            })
            .catch((err) => {});
    }, [refreshState]);

    return (
        <>
            {" "}
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="userid" className="label-search_trUp">
                                        신청기간
                                    </label>
                                </td>
                                <td>
                                    <div style={{ display: "flex" }}>
                                        <input
                                            id="begindt"
                                            {...register("begindt", {})}
                                            defaultValue={past}
                                            type="date"
                                            className="input-date1"
                                        />
                                        <p style={{ marginTop: "20px", marginLeft: "5px" }}>-</p>
                                        <input
                                            id="enddt"
                                            {...register("enddt", {})}
                                            defaultValue={current}
                                            type="date"
                                            className="input-date2"
                                        />
                                    </div>
                                </td>

                                <td>
                                    <label className="label-search_trUp">신청자</label>
                                </td>
                                <td>
                                    <input
                                        {...register("usernm", {})}
                                        type="text"
                                        className="style-input_up"
                                        maxLength="20"
                                    />
                                </td>

                                <td>
                                    <label className="label-search_trUp">처리상태</label>
                                </td>

                                <td>
                                    <Select
                                        className="selector-tripleUp"
                                        options={statuscdApplyOptions}
                                        onChange={(e) => {
                                            setStatuscdApply(e.value);
                                        }}
                                        defaultValue={{
                                            label: "신청",
                                            value: "A",
                                        }}
                                        style={{ marginLeft: "10px" }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label className="label-search_trDown">이메일</label>
                                </td>
                                <td>
                                    <input
                                        {...register("email", {})}
                                        type="email"
                                        className="style-input_down"
                                        maxLength="50"
                                    />
                                </td>

                                <td>
                                    <label className="label-search_trDown">핸드폰번호</label>
                                </td>
                                <td>
                                    <input
                                        {...register("cellphone", {})}
                                        type="text"
                                        className="style-input_down"
                                        maxLength="50"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <Button className="btn-search" variant="secondary" type="submit">
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
            </form>
            <Table bordered hover>
                <thead className="style-tHeader">
                    <tr>
                        <th>순번</th>
                        <th>ID</th>
                        <th>성명</th>
                        <th>소속기관</th>
                        <th>부서</th>
                        <th>E-Mail</th>
                        <th>핸드폰번호</th>
                        <th>생년월일</th>
                        <th>상태</th>
                        <th>신청시각</th>
                        <th>처리시각</th>
                        <th>승인</th>
                        <th>반려</th>
                    </tr>
                </thead>
                <tbody>
                    {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                    {inquiryResultData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                        ? inquiryResultData
                              .slice(minpage, maxPage) // pagination 을 위해 사용
                              .map((data, index) => {
                                  // Slice 된 만큼 반복문 실행
                                  switch (data.statuscd) {
                                      case "A":
                                          statusnm = "신청";
                                          break;
                                      case "Y":
                                          statusnm = "승인";
                                          break;
                                      case "N":
                                          statusnm = "반려";
                                          break;
                                      default:
                                          statusnm = "에러";
                                          break;
                                  }
                                  return (
                                      <>
                                          <tr
                                              onClick={() => {
                                                  //setState는 비동기성이라서, updateKeyTemp 는 변수로(사용 중단 기능에 사용할 PK값)

                                                  //setState는 비동기성이라서, data 는 state로 저장(수정 기능 중 default value에 사용할 값_화면 전환이 일어나서 비동기 처리가 이루어짐)
                                                  setUpdateUserData(data);
                                              }}
                                          >
                                              <td>{minpage + index + 1}</td>
                                              <td>{data.userid}</td>
                                              <td>{data.usernm}</td>
                                              <td>{data.orgnm}</td>
                                              <td>{data.deptnm}</td>
                                              <td>{data.email}</td>
                                              <td>
                                                  {" "}
                                                  {(data.cellphone + "").substring(0, 3) +
                                                      "-" +
                                                      (data.cellphone + "").substring(3, 7) +
                                                      "-" +
                                                      (data.cellphone + "").substring(7, 11)}
                                              </td>
                                              <td>{data.birthdt}</td>
                                              <td>{statusnm}</td>
                                              <td>{data.applytm}</td>
                                              <td>{data.handletm}</td>
                                              <td>
                                                  {data.statuscd === "A" ? (
                                                      <Button
                                                          className="btn-status"
                                                          variant="secondary"
                                                          onClick={() => {
                                                              setModalShowStatus(true);
                                                          }}
                                                          style={{ width: "100%", height: "100%" }}
                                                      >
                                                          승인
                                                      </Button>
                                                  ) : null}
                                              </td>
                                              <td>
                                                  {data.statuscd === "N" ? (
                                                      <Button
                                                          className="btn-status"
                                                          variant="secondary"
                                                          onClick={() => {
                                                              setModalShowRevokeMsg(true);
                                                          }}
                                                          style={{ width: "100%", height: "100%" }}
                                                      >
                                                          의견 보기
                                                      </Button>
                                                  ) : data.statuscd === "A" ? (
                                                      <Button
                                                          className="btn-status_error"
                                                          // variant="danger"
                                                          onClick={() => {
                                                              setModalShowRevoke(true);
                                                          }}
                                                          style={{ width: "100%", height: "100%" }}
                                                      >
                                                          반려
                                                      </Button>
                                                  ) : null}

                                                  {}
                                              </td>
                                          </tr>
                                      </>
                                  );
                              })
                        : null}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="13" style={{ borderBottom: "none" }}>
                            <PageBtn
                                className="btn-pageNumber"
                                data={inquiryResultData ? inquiryResultData : 0}
                                page={page}
                                setPage={setPage}
                                count={count}
                                btnRefresh={btnRefresh}
                            />
                        </td>
                    </tr>
                </tfoot>
            </Table>
            <div className="login-box">
                <UpdateStatus
                    show={modalShowStatus}
                    setShow={setModalShowStatus}
                    onHide={() => setModalShowStatus(false)}
                    updateUserData={updateUserData}
                ></UpdateStatus>

                <RevokeMsg
                    show={modalShowRevoke}
                    setShow={setModalShowRevoke}
                    onHide={() => setModalShowRevoke(false)}
                    updateUserData={updateUserData}
                ></RevokeMsg>

                <ShowRevokeMsg
                    show={modalShowRevokeMsg}
                    setShow={setModalShowRevokeMsg}
                    onHide={() => setModalShowRevokeMsg(false)}
                    updateUserData={updateUserData}
                ></ShowRevokeMsg>
            </div>
        </>
    );
}

export default UserApply;
