import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { combineReducers, createStore } from "redux";

//화면 refreshCheck
function refreshReducer(state, action) {
    let show = false;
    if (action.type === "refreshTrue") {
        show = true;
    } else if (action.type === "refreshFalse") {
        show = false;
    }

    return show;
}

// 게시물 추가 조직도 모달
const orgBulletinModalReducer = (state = false, action) => {
    if (action.type === "orgBulletinModalTrue") {
        state = true;
    } else if (action.type === "orgBulletinModalFalse") {
        state = false;
    }
    return state;
};

const ALertMessageCheckReducer = (state = false, action) => {
    if (action.type === "ALertMessageChecklTrue") {
        state = true;
    } else if (action.type === "ALertMessageCheckFalse") {
        state = false;
    }
    return state;
};

const userStateReducer = (state = { id: "", auth: "" }, action) => {
    if (action.type === "userState") {
        state.id = action.id;
        state.auth = action.auth;
    }

    return state;
};

let store = createStore(
    combineReducers({
        refreshReducer,
        orgBulletinModalReducer,
        ALertMessageCheckReducer,
        userStateReducer,
    })
);
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
