import React, { useEffect, useRef } from "react";
//메뉴 관리
import "../../../css/User.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from "react-select";
import cmmGetUser from "../../../cmm/cmmGetUser";
import setAlertMessage from "../../../cmm/cmmAlertMessage";

//공통함수

//메뉴추가
function AddFile(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/

    const [weightData, setWeightData] = useState([]);
    const [returnData, setReturnData] = useState();

    useEffect(() => {
        axios
            .post(`${server}/api/fileStorageMenu/searchWeight`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                let arr = [];
                res.data.map((data) => {
                    arr.push({
                        label: data.filename,
                        value: data.weightid,
                    });
                });
                setWeightData(arr);
            })
            .catch((err) => {});
    }, []);

    const submit = () => {
        if (returnData) {
            axios
                .post(`${server}/api/fileStorageMenu/searchWeight2`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    weightid: returnData,
                })
                .then((res) => {
                    props.setLoadingShow(true);
                    props.setShow(false);
                    props.setLoadData({ data: res.data, id: returnData });
                });
        }
    };

    return (
        <>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 불러오기 </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <div style={{ width: "80%", margin: "10px 35px 100px" }}>
                            <Select
                                dir="ltr"
                                options={weightData}
                                defaultValue={{
                                    label: "엔진 불러오기",
                                    value: null,
                                }}
                                onChange={(e) => {
                                    setReturnData(e.value);
                                }}
                            />
                        </div>
                        <div style={{ float: "right", margin: "0px 20px 30px", width: "10%" }}>
                            <Button className="btn-search" variant="secondary" onClick={() => submit()}>
                                선택
                            </Button>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default AddFile;
