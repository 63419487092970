//Moudle
import "../../css/manage.css";
import "../../css/userModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Modal } from "react-bootstrap";
import UpdateInfo from "./UserInfoModal/UpdateInfo";
import UpdateStatus from "./UserInfoModal/UpdateStatus";
import AddUser from "./UserInfoModal/AddUser";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기

//공통함수

import cmmGetData from "../../cmm/cmmGetData";
import cmmGetUser from "../../cmm/cmmGetUser";

import PageBtn from "../../cmm/cmmPageBtn";

function UserInfo(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기

    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    //search
    const orgcdOptions = cmmGetData("orgcd"); // 소속 기관 config
    const statuscdOptions = cmmGetData("statuscd"); // 유저 상태 config
    let [orgcd, setOrg] = useState(""); // 소속 기관
    let [statuscd, setStatuscd] = useState(""); // 유저 상태

    //ModalShow
    const [modalShowStatus, setModalShowStatus] = useState(false);
    const [modalShowAdd, setModalShowAdd] = useState(false);
    const [modalShowInfo, setModalShowInfo] = useState(false);

    //pageNation
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;

    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    //조회 결과 데이터 저장
    let [inquiryResultUserData, setInquiryResultUserData] = useState(false);

    //변화 생기면 바로 조회되도록
    const [searchData, setSearchData] = useState({
        userid: "",
        usernm: "",
        orgcd: "",
        email: "",
        cellphone: "",
        statuscd: "",
    });

    //Props로 보낼 데이터(column)
    let [updateUserData, setUpdateUserData] = useState([
        {
            updatekey: "", // PK값
            userid: "",
            usernm: "",
            orgcd: "", //Select box 값
            deptnm: "",
            email: "",
            cellphone: "",
            birthdt: "",
            statuscd: "", //Select box 값
            mdmcd: "",
            usergrpcd: "",
        },
    ]);

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용

    //useForm 관련
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = (data) => {
        setSearchData({
            userid: data.userid,
            usernm: data.usernm,
            orgcd: orgcd,
            email: data.email,
            cellphone: data.cellphone,
            statuscd: statuscd,
        });

        axios
            .post(`${server}/api/userInfo/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                userid: data.userid,
                usernm: data.usernm,
                orgcd: orgcd,
                email: data.email,
                cellphone: data.cellphone,
                statuscd: statuscd,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultUserData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                } else {
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        axios
            .post(`${server}/api/userInfo/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                userid: searchData.userid,
                usernm: searchData.usernm,
                orgcd: searchData.orgcd,
                email: searchData.email,
                cellphone: searchData.cellphone,
                statuscd: searchData.statuscd,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultUserData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                } else {
                }
            })
            .catch((err) => {});
    }, [refreshState]);

    return (
        <div>
            {" "}
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="userid" className="label-search_trUp">
                                        ID
                                    </label>
                                </td>
                                <td>
                                    <input
                                        id="userid"
                                        {...register("userid", {})}
                                        type="text"
                                        className="style-input_up"
                                        maxLength="50"
                                    />
                                </td>
                                <td>
                                    <label className="label-search_trUp" style={{ marginRight: "-30px" }}>
                                        이메일
                                    </label>
                                </td>
                                <td>
                                    <input
                                        {...register("email", {})}
                                        type="text"
                                        className="style-input_up"
                                        maxLength="50"
                                    />
                                </td>

                                <td>
                                    <label className="label-search_trUp" style={{ marginRight: "1px" }}>
                                        소속
                                    </label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-tripleUp"
                                        options={orgcdOptions}
                                        onChange={(e) => {
                                            setOrg(e.value);
                                        }}
                                        defaultValue={{ label: "선택" }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label className="label-search_trDown">성명</label>
                                </td>
                                <td>
                                    <input
                                        {...register("usernm", {})}
                                        type="text"
                                        className="style-input_down"
                                        style={{ width: "100%" }}
                                        maxLength="50"
                                    />
                                </td>

                                <td>
                                    <label className="label-search_trDown" style={{ marginRight: "-30px" }}>
                                        핸드폰번호
                                    </label>
                                </td>
                                <td>
                                    <input
                                        {...register("cellphone", {})}
                                        type="text"
                                        className="style-input_down"
                                        style={{ width: "100%" }}
                                        maxLength="50"
                                    />
                                </td>

                                <td>
                                    <label className="label-search_trDown" style={{ marginRight: "1px" }}>
                                        상태
                                    </label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-tripleDown"
                                        options={statuscdOptions}
                                        onChange={(e) => {
                                            setStatuscd(e.value);
                                        }}
                                        defaultValue={{ label: "선택" }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <Button
                        className="btn-add"
                        onClick={() => {
                            setModalShowAdd(true);
                        }}
                    >
                        추가
                    </Button>
                    <Button className="btn-search" variant="secondary" type="submit">
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
            </form>
            <Table bordered hover>
                <thead className="style-tHeader">
                    <tr>
                        <th width="4%">순번</th>
                        <th width="8%">ID</th>
                        <th width="8%">성명</th>
                        <th width="12%">소속</th>
                        <th width="12%">부서</th>
                        <th width="12%">E-Mail</th>
                        <th width="9%">핸드폰 번호</th>
                        <th width="9%">생년월일</th>
                        <th width="6%">권한그룹</th>
                        <th width="5%">전문가</th>
                        <th width="5%">MDM</th>

                        <th width="5%" className="table-wrap-button">
                            상태
                        </th>
                        <th width="5%" className="table-wrap-button">
                            수정
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                    {inquiryResultUserData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                        ? inquiryResultUserData
                              .slice(minpage, maxPage) // pagination 을 위해 사용
                              .map((data, index) => {
                                  // Slice 된 만큼 반복문 실행
                                  return (
                                      <>
                                          <tr
                                              onClick={() => {
                                                  //업데이트할때 보낼 데이터
                                                  setUpdateUserData(data);
                                              }}
                                              style={
                                                  data.statuscd !== "Y" && data.statuscd !== "Y1"
                                                      ? { color: "#d11f37", fontstyle: "bold" }
                                                      : null
                                              }
                                          >
                                              <td>{minpage + index + 1}</td>
                                              <td>{data.userid}</td>
                                              <td>{data.usernm}</td>
                                              <td>{data.orgnm}</td>
                                              <td>{data.statuscd === "E2" ? "부서 이상" : data.deptnm}</td>
                                              <td>{data.email}</td>
                                              <td>
                                                  {(data.cellphone + "").substring(0, 3) +
                                                      "-" +
                                                      (data.cellphone + "").substring(3, 7) +
                                                      "-" +
                                                      (data.cellphone + "").substring(7, 11)}
                                              </td>
                                              <td>{data.birthdt}</td>
                                              <td>{data.usergrpnm}</td>
                                              <td>
                                                  {data.expertyn === "Y" ? (
                                                      <input type="checkBox" checked={true} />
                                                  ) : (
                                                      <input type="checkBox" checked={false} />
                                                  )}
                                              </td>
                                              <td>{data.mdmtypenm}</td>
                                              <td>
                                                  <Button
                                                      className={
                                                          data.statuscd !== "Y" && data.statuscd !== "Y1"
                                                              ? "btn-status_error"
                                                              : "btn-status"
                                                      }
                                                      onClick={() => {
                                                          if (data.freecol1 === "Y" || data.statuscd === "Y1")
                                                              setModalShowStatus(true);
                                                          else {
                                                              axios
                                                                  .post(`${server}/api/cmm/alert`, {
                                                                      code: data.freecol2,
                                                                      sessionid: sessionStorage.getItem("SESSION"),
                                                                  })
                                                                  .then((response) => {
                                                                      Swal.fire({
                                                                          icon: "warning",
                                                                          title: response.data[0].title,
                                                                          text: response.data[0].msgbody,
                                                                          showCancelButton: true,
                                                                          confirmButtonText: "확인",
                                                                          cancelButtonText: "취소",
                                                                      }).then((res) => {
                                                                          /* Read more about isConfirmed, isDenied below */
                                                                          if (res.isConfirmed) {
                                                                              axios
                                                                                  .post(
                                                                                      `${server}/api/userInfo/updateUserPwd`,
                                                                                      {
                                                                                          statuscd: data.statuscd,
                                                                                          userid: data.userid,
                                                                                          sessionid:
                                                                                              sessionStorage.getItem(
                                                                                                  "SESSION"
                                                                                              ),
                                                                                      }
                                                                                  )
                                                                                  .then((res) => {
                                                                                      if (res.data) {
                                                                                          setAlertMessage("I013");
                                                                                          refreshState
                                                                                              ? dispatch({
                                                                                                    type: "refreshFalse",
                                                                                                })
                                                                                              : dispatch({
                                                                                                    type: "refreshTrue",
                                                                                                });
                                                                                      } else setAlertMessage("E203");
                                                                                  })
                                                                                  .catch(() => {
                                                                                      setAlertMessage("E203");
                                                                                  });
                                                                          } else {
                                                                              setAlertMessage("I009");
                                                                          }
                                                                      });
                                                                  })
                                                                  .catch((err) => {
                                                                      setAlertMessage("E200");
                                                                  });
                                                          }
                                                      }}
                                                      name={data.freecol2}
                                                  >
                                                      {data.statusnm}
                                                  </Button>
                                              </td>

                                              <td>
                                                  {data.statuscd === "N2" || data.statuscd === "N1" ? null : (
                                                      <span
                                                          className={
                                                              data.statuscd !== "Y" && data.statuscd !== "Y1"
                                                                  ? "btn-edit_error"
                                                                  : "btn-edit"
                                                          }
                                                          onClick={() => {
                                                              setModalShowInfo(true);
                                                          }}
                                                          style={{ width: "7px" }}
                                                      >
                                                          수정
                                                      </span>
                                                  )}
                                              </td>
                                          </tr>
                                      </>
                                  );
                              })
                        : null}
                    <tr style={{ color: "black" }}>
                        <td colSpan="13" style={{ margin: "0px", padding: "0px" }}></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="13" style={{ borderBottom: "none" }}>
                            <PageBtn
                                className="btn-pageNumber"
                                data={inquiryResultUserData ? inquiryResultUserData : 0}
                                page={page}
                                setPage={setPage}
                                count={count}
                                btnRefresh={btnRefresh}
                            />
                        </td>
                    </tr>
                </tfoot>
            </Table>
            <div className="modal-box">
                {/*reudx 를 통해 불러온 값이 true면 AddUser을 띄우고 아니면 null을 띄움*/}
                {modalShowAdd ? <AddUser show={modalShowAdd} setShow={setModalShowAdd}></AddUser> : null}
                {modalShowInfo ? (
                    <UpdateInfo
                        show={modalShowInfo}
                        setShow={setModalShowInfo}
                        updateUserData={updateUserData}
                    ></UpdateInfo>
                ) : null}
                {modalShowStatus ? (
                    <UpdateStatus
                        show={modalShowStatus}
                        setShow={setModalShowStatus}
                        updateUserData={updateUserData}
                    ></UpdateStatus>
                ) : null}
            </div>
        </div>
    );
}

export default UserInfo;
