import React, { useEffect } from "react";
//메뉴 관리
import "../../../css/User.css";
import { Table, Modal, Button } from "react-bootstrap";
import { useState } from "react";
import cmmGetUser from "../../../cmm/cmmGetUser";
import PageBtn3 from "../../../cmm/cmmPageBtn3";

import axios from "axios";

//공통함수

//메뉴추가
function AddImg(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    const inferenceServer = process.env.REACT_APP_INFERENCE2_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const [pageUser, setPageUser] = useState(1);
    const [countUser, setCountUser] = useState(5);
    const [btnRefreshUser, setBtnRefreshUser] = useState(true);
    const maxPageUser = pageUser * countUser;
    const minpageUser = pageUser * countUser - countUser;
    const [detailImgs, setDetailImgs] = useState([]);
    const handleImageUpload = (e) => {
        setBtnRefreshUser((val) => !val);
        const fileArr = e.target.files;

        let fileURLs = [];

        let file;
        let maxFile = 1000;
        let filesLength = fileArr.length > maxFile ? maxFile : fileArr.length;

        if (fileArr.length > maxFile) alert(`한번에 추가 가능한 사진은 최대 ${maxFile}장 까지 입니다.`);

        for (let i = 0; i < filesLength; i++) {
            file = fileArr[i];

            if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
                setDetailImgs([]);
                alert(`JPG 사진 파일만 가능합니다.`);
                break;
            } else {
                let reader = new FileReader();

                reader.onload = () => {
                    fileURLs[i] = reader.result;

                    setDetailImgs([...fileURLs]);
                };
                reader.readAsDataURL(file);
            }
        }
    };
    function fillZero(width, str) {
        return str.length >= width ? str : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
    }

    const division = (array, n) => {
        const length = array.length;
        const divide = Math.floor(length / n) + (Math.floor(length % n) > 0 ? 1 : 0);
        const newArray = [];

        for (let i = 0; i <= divide; i++) {
            // 배열 0부터 n개씩 잘라 새 배열에 넣기
            newArray.push(array.splice(0, n));
        }

        return newArray;
    };

    const inference = async (type) => {
        const sendcount = detailImgs.length;
        if (sendcount > 0) {
            let numbering = "000000001";

            props.setLoadingShow(true);
            let detailImgsArray = division(detailImgs, 200);

            for (let i = 0; i < detailImgsArray.length; i++) {
                await axios
                    .post(`${server}/api/fileStorageMenu/specimenID`, { sessionid: sessionStorage.getItem("SESSION") })
                    .then((res) => {
                        numbering = res.data[0]
                            ? fillZero(9, String(Number(res.data[0].specimenid.substr(-9)) + 1))
                            : numbering;
                    });
                const specimenid = `웹-파일-${numbering}`;
                if (i === detailImgsArray.length - 2)
                    await axios
                        .post(`${inferenceServer}/api/inferenceWeb`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            specimenid: specimenid,
                            detailImgs: detailImgsArray[i],
                            sendcount: sendcount,
                            type: type,
                        })
                        .then((res) => {
                            if (res) {
                                props.setStart((val) => !val);
                                props.setShow(false);
                                props.setLoadingShow(false);
                            }
                        })
                        .catch(() => {
                            alert("서버와 연결이 끊어졌습니다.");
                        });
                else {
                    await axios
                        .post(`${inferenceServer}/api/inferenceWeb`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            specimenid: specimenid,
                            detailImgs: detailImgsArray[i],
                            sendcount: sendcount,
                            type: type,
                        })
                        .then((res) => {})
                        .catch(() => {
                            alert("서버와 연결이 끊어졌습니다.");
                        });
                }
            }
        } else {
            alert("추가할 사진이 없습니다.");
        }
    };

    return (
        <>
            <Modal
                {...props}
                show={props.show}
                onHide={() => {
                    props.setShow(false);
                    setDetailImgs([]);
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit-add" //modal outbox CustomCSSTag 크기 조정
            >
                <div className="outbox-add">
                    <div className="txt-title-add">
                        <span> 사진 추가 </span>
                    </div>
                    <hr className="headerline-long-add"></hr>
                    <Modal.Body>
                        <div style={{ width: "100%", height: "1000px" }}>
                            <div style={{ width: "96%", marginLeft: "2%", marginRight: "2%", height: "600px" }}>
                                <Table bordered responsive style={{ height: "99%" }}>
                                    <thead className="style-tHeader">
                                        <tr style={{ verticalAlign: "middle" }}>
                                            <th width="20%"> No.</th>
                                            <th width="80%">사진</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {detailImgs
                                            ? detailImgs.slice(minpageUser, maxPageUser).map((data, index) => {
                                                  return (
                                                      <tr key={index}>
                                                          <td>{minpageUser + index + 1} </td>
                                                          <td height="140">
                                                              {data ? (
                                                                  <img
                                                                      style={{
                                                                          width: "300px",
                                                                          height: "140px",
                                                                      }}
                                                                      src={data}
                                                                  ></img>
                                                              ) : null}
                                                          </td>
                                                      </tr>
                                                  );
                                              })
                                            : null}
                                    </tbody>

                                    {detailImgs ? (
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2" style={{ borderBottom: "none" }}>
                                                    <PageBtn3
                                                        dataUser={detailImgs ? detailImgs : 0}
                                                        pageUser={pageUser}
                                                        setPageUser={setPageUser}
                                                        countUser={countUser}
                                                        btnRefreshUser={btnRefreshUser}
                                                    />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    ) : null}
                                </Table>
                            </div>
                            <div style={{ display: "flex", marginTop: "346px" }}>
                                <div style={{ marginLeft: "10px" }}>
                                    <Button
                                        className="btn-add-long"
                                        variant="secondary"
                                        style={{ margin: "0px 5px" }}
                                        onClick={() => inference("E")}
                                    >
                                        단락흔으로 추가
                                    </Button>
                                    <Button
                                        className="btn-add-long"
                                        variant="secondary"
                                        style={{ margin: "0px 5px" }}
                                        onClick={() => inference("F")}
                                    >
                                        열흔으로 추가
                                    </Button>
                                </div>

                                <center style={{ marginLeft: "50px" }}>
                                    <Button
                                        className="btn-add"
                                        variant="secondary"
                                        style={{ margin: "0px 5px" }}
                                        onClick={() => inference("I")}
                                    >
                                        추론
                                    </Button>

                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        type="button"
                                        style={{ margin: "0px 5px" }}
                                        onClick={() => {
                                            setDetailImgs([]);
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </center>

                                <div style={{ marginLeft: "85px" }}>
                                    <input
                                        type="file"
                                        multiple
                                        style={{ display: "none" }}
                                        id="photo_file"
                                        name="photo_file"
                                        accept=".jpg"
                                        onChange={handleImageUpload}
                                    />

                                    <label className="btn btn-secondary border-0 bg_grey" for="photo_file">
                                        사진 업로드
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default AddImg;
