import "../../css/userModal.css";
import "../../css/Login.css";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import setAlertMessage from "../../cmm/cmmAlertMessage";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import cmmGetData from "../../cmm/cmmGetData"; //Config 가져오기
import { useDispatch, useSelector } from "react-redux";
import cmmGetUser from "../../cmm/cmmGetUser";

function SignIn() {
  /*=====사용자 인증====*/
  const [sessionData, setSessionData] = useState();

  const ID = sessionData ? sessionData.user : null;
  const AUTH = sessionData ? sessionData.usergrpcd : null;
  /*=============*/
  const server = process.env.REACT_APP_API_SERVER_HOST;
  const btnRef = useRef();
  const userState = useSelector((state) => state.userStateReducer);

  //redux 값 변경을 위한 dispatch 사용을 위해
  const dispatch = useDispatch();

  const [modalShowID, setModalShowID] = useState(false);
  const [modalShowPW, setModalShowPW] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (inputData) => {
    axios
      .post(`${server}/api/sign/in`, {
        sessionid: sessionStorage.getItem("SESSION"),
        userid: inputData.userid,
        pwd: inputData.pwd,
        useMode: "W",
      })
      .then((res) => {
        // 조직도 아이디
        if (res.data.okyn === "Y") {
          //로그인 성공
          sessionStorage.setItem("SESSION", res.data.sessionID);
          window.location.replace("/statOrg");
        } else if (res.data.okyn === "N") {
          //okyn ==="N" 로그인 실패
          setAlertMessage(res.data.msgid).then((res) => {
            if (res) {
              axios
                .post(`${server}/api/sign/dormant`, {
                  userid: inputData.userid,
                  pwd: inputData.pwd,
                })
                .then((res2) => {
                  if (res2.data) {
                    setAlertMessage("I006");
                  } else {
                    //DB 연결 에러 발생
                    setAlertMessage("E001");
                  }
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  if (AUTH) {
    /* Home Page */
    window.location.replace("/statOrg");
  } else {
    return (
      <center style={{ width: "102%", height: "100%", marginLeft: "-10px", marginRight: "-10px" }}>
        {ID ? (
          window.location.replace("/statOrg")
        ) : (
          <div
            className="main"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + "/logo/bg@2x.jpg"})`,
              margin: " -5px 0 0 -20px",
            }}
          >
            <div className="Bg">
              <img
                className="logo"
                src={process.env.PUBLIC_URL + "/logo/logo.png"}
                alt="copy url"
                width={280}
                height={80}
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  className="form-wrap-one"
                  {...register("userid", {
                    required: "필수 입력 사항입니다.",
                    pattern: {
                      value: /^[a-zA-Z0-9]{6,8}$/,
                      message: "6~8자리의 올바른 아이디를 입력해주세요.",
                    },
                  })}
                  style={{ width: "320px" }}
                  type="text"
                  placeholder="아이디"
                />
                <input
                  className="form-wrap-two"
                  {...register("pwd", {
                    required: "필수 입력 사항입니다.",

                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                      message: "1개 이상의 특수문자를 포함 8 ~ 16 자로 입력해주세요",
                    },
                  })}
                  type="password"
                  placeholder="비밀번호"
                />
                <div style={{ marginLeft: "160px" }}>
                  <span
                    className="span-wrap-findIdPw"
                    variant="secondary"
                    onClick={() => {
                      setModalShowID(true);
                    }}
                  >
                    아이디 찾기 ・
                  </span>
                  <span
                    className="span-wrap-findIdPw"
                    variant="secondary"
                    onClick={() => {
                      setModalShowPW(true);
                    }}
                  >
                    비밀번호 찾기
                  </span>
                </div>
                {errors.userid
                  ? errors.userid && <span className="span-error-box">{errors.userid.message}</span>
                  : errors.pwd && <span className="span-error-box">{errors.pwd.message}</span>}

                <div
                  className="btn-wrap-one"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    btnRef.current.click();
                  }}
                >
                  <div className="span-wrap-two" style={{ fontSize: "20px" }}>
                    로그인
                  </div>
                </div>
                <button type="submit" style={{ display: "hidden", border: "none" }} ref={btnRef} />
                <span
                  className="span-wrap-applyAccount"
                  variant="secondary"
                  onClick={() => {
                    window.location.replace("/signUp");
                  }}
                >
                  계정 신청
                </span>
              </form>
              <FindID show={modalShowID} onHide={() => setModalShowID(false)} />
              <FindPW show={modalShowPW} onHide={() => setModalShowPW(false)} />
            </div>
          </div>
        )}
      </center>
    );
  }
}

function FindID(props) {
  const server = process.env.REACT_APP_API_SERVER_HOST; // .env 파일에서 가져온 db_config
  const orgcdOptions = cmmGetData("orgcd");
  let [orgcd, setOrg] = useState(orgcdOptions[0].value); // 소속기관

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    await axios
      .post(`${server}/api/sign/findId`, {
        sessionid: sessionStorage.getItem("SESSION"),
        //userid: data.userid,
        usernm: data.usernm,
        cellphone: data.cellphone,
        email: data.email,
        orgcd: orgcd,
        birthdt: data.birthdt,
      })
      .then((response) => {
        if (response.data.okyn === "Y") {
          setAlertMessage("S004", response.data.userid);
          reset();
        } else {
          setAlertMessage("E009");
        }
      })
      .catch(() => {
        //데이터 베이스 연결 에러시,
        setAlertMessage("E000");
      });
  };
  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        centered
      >
        {" "}
        <div className="outbox-addUsers">
          <div className="txt-title">
            <span> 아이디 찾기 </span>
          </div>
          <hr className="headerline-long"></hr>
          <Modal.Body>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="form-group">
                    <label class="required">성명</label>
                    <input
                      {...register("usernm", {
                        required: "필수정보입니다.",
                        pattern: {
                          value: /^[가-힣a-zA-Z]+$/,
                          message: "한글과 영문 대 소문자를 사용하세요. (특수기호, 공백 사용 불가)",
                        },
                      })}
                      type="text"
                      className="form-control"
                      maxLength={20}
                    />
                  </div>
                  {errors.usernm && <span className="span-box">{errors.usernm.message}</span>}
                  <div className="form-group">
                    <label class="required">핸드폰 번호</label>
                    <input
                      {...register("cellphone", {
                        required: "필수정보입니다.",
                        pattern: {
                          value: /^[0-9]{11}$/,
                          message: "올바른 형식의 전화번호를 입력하세요",
                        },
                      })}
                      type="Number"
                      className="form-control"
                    />
                  </div>
                  {errors.cellphone && <span className="span-box">{errors.cellphone.message}</span>}
                  <div className="form-group">
                    <label class="required">이메일</label>
                    <input
                      {...register("email", {
                        required: "필수정보입니다.",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "올바른 형식의 이메일을 입력하세요",
                        },
                      })}
                      type="email"
                      className="form-control"
                      maxLength={100}
                    />
                  </div>
                  {errors.email && <span className="span-box">{errors.email.message}</span>}

                  <div className="form-group">
                    <label class="required">소속</label>
                    <Select
                      className="selector-single2"
                      options={orgcdOptions}
                      onChange={(e) => {
                        setOrg(e.value);
                      }}
                      defaultValue={{ label: "선택" }}
                    />
                  </div>
                  <div className="form-group">
                    <label class="required">생년월일</label>
                    <input
                      {...register("birthdt", {
                        required: "필수정보입니다.",
                        pattern: {
                          value: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
                          message: "올바른 형식의 생년월일을 입력하세요",
                        },
                      })}
                      type="date"
                      className="form-control"
                    />
                  </div>
                  {errors.birthdt && <span className="span-box">{errors.birthdt.message}</span>}
                </div>

                <div className="button-group">
                  <Button className="btn-save" type="submit">
                    찾기
                  </Button>
                  <Button
                    className="btn-cancel"
                    onClick={() => {
                      props.onHide();
                    }}
                  >
                    취소
                  </Button>
                </div>
              </form>
            </>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}
function FindPW(props) {
  const server = process.env.REACT_APP_API_SERVER_HOST; // .env 파일에서 가져온 db_config
  const orgcdOptions = cmmGetData("orgcd");
  let [orgcd, setOrg] = useState(orgcdOptions[0].value); // 소속기관

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    await axios
      .post(`${server}/api/sign/findPw`, {
        sessionid: sessionStorage.getItem("SESSION"),
        userid: data.userid,
        usernm: data.usernm,
        cellphone: data.cellphone,
        email: data.email,
        orgcd: orgcd,
        birthdt: data.birthdt,
      })
      .then((response) => {
        if (response.data) {
          setAlertMessage("S002");
          reset(); //비밀번호 찾기 성공
        } else {
          //비밀번호 찾기 실패시 화면 유지 & 입력된 데이터 유지
          setAlertMessage("E009");
        }
      })
      .catch(() => {
        //데이터 베이스 연결 에러시, 경고 창 띄운 후 데이터 보존
        setAlertMessage("E000");
      });
  };
  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        centered
      >
        {" "}
        <div className="outbox-addUsers">
          <div className="txt-title">
            <span> 비밀번호 찾기 </span>
          </div>
          <hr className="headerline-long"></hr>
          <Modal.Body>
            <div className="inbox-limit">
              <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="userid" class="required">
                    ID
                  </label>
                  <input
                    {...register("userid", {
                      required: "필수정보입니다.",
                      pattern: {
                        value: /^[a-zA-Z0-9]{6,8}$/,
                        message: "6~8 자리의 영문 대 소문자, 숫자를 사용하세요. (특수기호, 공백 사용 불가)",
                      },
                    })}
                    type="text"
                    className="form-control"
                    // placeholder="영문과 숫자만을 포함한 6 ~ 8 자리 아이디를 입력해주세요"
                  />
                </div>
                {errors.userid && <span className="span-box">{errors.userid.message}</span>}
                <div className="form-group">
                  <label class="required">성명</label>
                  <input
                    {...register("usernm", {
                      required: "필수정보입니다.",
                      pattern: {
                        value: /^[가-힣a-zA-Z]+$/,
                        message: "한글과 영문 대 소문자를 사용하세요. (특수기호, 공백 사용 불가)",
                      },
                    })}
                    type="text"
                    className="form-control"
                    maxLength={20}
                    // placeholder="성명을 입력해주세요"
                  />
                </div>
                {errors.usernm && <span className="span-box">{errors.usernm.message}</span>}

                <div className="form-group">
                  <label class="required">핸드폰 번호</label>
                  <input
                    {...register("cellphone", {
                      required: "필수정보입니다.",
                      pattern: {
                        value: /^[0-9]{11}$/,
                        message: "올바른 형식의 전화번호를 입력하세요",
                      },
                    })}
                    type="Number"
                    className="form-control"
                    // placeholder=" - 없이 핸드폰 번호를 입력해주세요"
                  />
                </div>
                {errors.cellphone && <span className="span-box">{errors.cellphone.message}</span>}
                <div className="form-group">
                  <label class="required">이메일</label>
                  <input
                    {...register("email", {
                      required: "필수정보입니다.",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "올바른 형식의 이메일을 입력하세요",
                      },
                    })}
                    type="email"
                    className="form-control"
                    maxLength={100}
                    // placeholder="메일 주소를 입력해주세요"
                  />
                </div>
                {errors.email && <span className="span-box">{errors.email.message}</span>}

                <div className="form-group">
                  <label class="required">소속</label>
                  <Select
                    className="selector-single2"
                    options={orgcdOptions}
                    onChange={(e) => {
                      setOrg(e.value);
                    }}
                    defaultValue={{ label: "선택" }}
                  />
                </div>
                <div className="form-group">
                  <label class="required">생년월일</label>
                  <input
                    {...register("birthdt", {
                      required: "필수정보입니다.",
                      pattern: {
                        value: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
                        message: "올바른 형식의 생년월일을 입력하세요",
                      },
                    })}
                    type="date"
                    className="form-control"
                    // placeholder="1900-01-01 형식으로 생년월일을 입력해주세요"
                  />
                </div>
                {errors.birthdt && <span className="span-box">{errors.birthdt.message}</span>}

                <div className="button-group">
                  <Button className="btn-save" type="submit">
                    찾기
                  </Button>
                  <Button
                    className="btn-cancel"
                    onClick={() => {
                      props.onHide();
                    }}
                  >
                    취소
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}

export default SignIn;
