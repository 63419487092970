import axios from "axios";
const server = process.env.REACT_APP_API_SERVER_HOST;

const cmmGetMenuName = async (url) => {
    let menuName = [];
    if (url === "/") {
        url = "/statOrg";
    }
    await axios
        .post(`${server}/api/cmm/getMenuName`, { sessionid: sessionStorage.getItem("SESSION") })
        .then((res) => {
            const menu = res.data.filter((data) => data.url === url.replace("/", ""))[0];
            const menu2 = res.data.filter((data) => data.menuid === menu.pmenuid)[0];
            menuName = [menu.menunm, menu2.menunm];
        })
        .catch((err) => {});

    return menuName;
};

export default cmmGetMenuName;
