import "../../css/manage.css";
import "../../css/userModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSleigh } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Table, Button } from "react-bootstrap";
import axios from "axios";

import Swal from "sweetalert2";
import VerifyWeight from "./WeightModal/VerifyWeight";

import cmmchangeFormat from "../../cmm/cmmchangeFormat"; // 시간 포맷
import setAlertMessage from "../../cmm/cmmAlertMessage";
import cmmGetUser from "../../cmm/cmmGetUser";

import jQuery from "jquery";
import { Link } from "react-router-dom";
import PageBtn from "../../cmm/cmmPageBtn";
import LoadingModal from "../../cmm/cmmLoadingModal";
import cmmAlertMessage from "../../cmm/cmmAlertMessage";
window.$ = window.jQuery = jQuery;
//엔진 등록
const WeightInfo = () => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;
    // 구분 타입
    const [type, setType] = useState("");
    // 조회 결과
    const [searchResult, setSearchResult] = useState([]);

    // 현재 접속된 아이디
    const userId = ID;

    // BinFile, ModelFile, SwerverlFile 파일

    // input type="file" 정보

    // 파일명 정보
    const binLabeleRef = useRef([]);

    const [passTF, setPassTF] = useState(false);

    // 새로고침
    const [refresh, setRefresh] = useState(true);

    const { register, watch, handleSubmit } = useForm();

    //현재날짜 불러오기
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    let date = today.getDate(); // 날짜
    date = parseInt(date) < 10 ? "0" + date : date;
    let current = year + "-" + month + "-" + date;
    let temp = new Date(year, month - 1, date);

    temp.setDate(temp.getDate() - 365);
    year = temp.getFullYear(); // 년도
    month = temp.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    date = temp.getDate(); // 날짜

    date = parseInt(date) < 10 ? "0" + date : date;
    let past = year + "-" + month + "-" + date;

    /* Modal 창 띄우기 */
    const [modalShowVerify, setModalShowVerify] = useState(false);
    const [modalShowLoading, setModalShowLoading] = useState(false);
    useEffect(() => {
        axios
            .post(`${server}/api/weight/search`, {
                sessionid: sessionStorage.getItem("SESSION"),
                tf: passTF,
            })
            .then((res) => {
                setSearchResult(res.data);
            })
            .catch((err) => {});
    }, []);

    // 조회
    const onSubmit = async (data) => {
        axios
            .post(`${server}/api/weight/search`, {
                sessionid: sessionStorage.getItem("SESSION"),
                tf: passTF,
                begindt: data.begindt,
                enddt: data.enddt,
            })
            .then((res) => {
                if (!res.data[0]) {
                    setAlertMessage("I004");
                }
                setSearchResult(res.data);
            })
            .catch((err) => {});
    };

    // db에서 경로를 불러와 이름만 추출
    const reName = (name) => {
        let rnm = name.split("/");
        return rnm[rnm.length - 1];
    };

    /*확정*/
    const onConfirmHandler = (weightid) => {
        let engineYN = "";
        for (let i = 0; i < searchResult.length; i++) {
            if (searchResult[i].weightid == weightid) {
                engineYN = searchResult[i].engineYN;
            }
        }

        //엔진이 생성된 경우만 확정
        if (engineYN === "Y") {
            cmmAlertMessage("Q702").then((res) => {
                if (res) {
                    setModalShowLoading(true);
                    axios
                        .post(`${process.env.REACT_APP_INFERENCE1_SERVER_HOST}/api/confirm`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            weightid: weightid,
                        })
                        .then((res3) => {
                            if (res3.data) {
                                // 1000  = 1초
                                setTimeout(() => {
                                    onSubmit(watch());
                                    setModalShowLoading(false);
                                    cmmAlertMessage("S701");
                                }, 10000);
                            } else setAlertMessage("E200");
                        })
                        .catch((err) => {
                            setAlertMessage("E200");
                        });
                } else {
                    setAlertMessage("I009");
                }
            });
        } else {
            /* 엔진 미 생성시 검증 먼저 하라는 에러 메시지 출력*/
            axios
                .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "Q703" })
                .then((response) => {
                    Swal.fire({
                        icon: "warning",
                        title: response.data[0].title,
                        text: response.data[0].msgbody,
                        confirmButtonText: "확인",
                    });
                })
                .catch((err) => {
                    setAlertMessage("E200");
                });
        }
    };

    /*삭제*/
    const onDeleteHandler = (weightid) => {
        axios
            .post(`${server}/api/cmm/alert`, { sessionid: sessionStorage.getItem("SESSION"), code: "Q700" })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                })
                    .then((res) => {
                        if (res.isConfirmed) {
                            axios
                                .post(`${server}/api/weight/delete`, {
                                    sessionid: sessionStorage.getItem("SESSION"),
                                    weightid: weightid,
                                })
                                .then((res) => {
                                    if (res.data) {
                                        setAlertMessage("I003");
                                        onSubmit(watch());
                                    } else {
                                        setAlertMessage("I009");
                                    }
                                })
                                .catch((err) => {
                                    setAlertMessage("E202");
                                });
                        } else {
                            setAlertMessage("I009");
                        }
                    })
                    .catch((err) => {
                        setAlertMessage("E202");
                    });
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };

    return (
        <>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>

            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td>
                                    <label style={{ marginTop: "5px", paddingLeft: "40px" }}>생성시각</label>
                                </td>
                                <td>
                                    <div style={{ display: "flex", paddingLeft: "-20px" }}>
                                        <input
                                            id="begindt"
                                            {...register("begindt", {})}
                                            type="date"
                                            className="input-date1"
                                            defaultValue={past}
                                        />
                                        <b style={{ margin: "18px 0 0 5px" }}>-</b>
                                        <input
                                            id="enddt"
                                            {...register("enddt", {})}
                                            type="date"
                                            className="input-date2"
                                            defaultValue={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <Button
                        className="btn-saveTriple"
                        variant="secondary"
                        type="button"
                        onClick={() => {
                            setModalShowVerify(true);
                        }}
                        style={{ marginRight: "50px" }}
                    >
                        검증
                    </Button>
                    <Button className="btn-search" variant="secondary" type="submit" style={{ fontSize: "15px" }}>
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
            </form>

            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%" }}>
                    <p style={{ fontWeight: "bold" }}>◎ List </p>
                    <Table bordered responsive>
                        <thead className="style-tHeader">
                            <tr style={{ verticalAlign: "middle" }}>
                                <th>ID</th>
                                <th>WeightFile</th>
                                <th>정확도</th>
                                <th>생성시각</th>
                                <th>확정일</th>
                                <th>확정자ID</th>
                                <th>확정</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResult.slice(minpage, maxPage).map((data, index) => {
                                return (
                                    <>
                                        <tr key={index} style={data.confirmYN === "Y" ? { color: "red" } : null}>
                                            <td width="8%">{data.weightid}</td>

                                            <td>
                                                {data.classificationfileurl
                                                    ? reName(data.classificationfileurl)
                                                    : "파일이 없습니다."}
                                            </td>
                                            <td width="8%">{data.accuracy ? data.accuracy + "%" : "학습 미완료"}</td>

                                            <td width="15%">
                                                <pre
                                                    style={{
                                                        fontFamily: "NotoSansKR-DemiLight",
                                                        fontSize: "16px",
                                                        margin: "0px",
                                                    }}
                                                >
                                                    {/* {data.updatetm
                                                ? cmmchangeFormat(data.updatetm, "yy-MM-DD / hh:mm:ss").replace(
                                                      "/",
                                                      "\n\n"
                                                  )
                                                : 0} */}
                                                    {data.createtm
                                                        ? cmmchangeFormat(data.createtm, "yy-MM-DD HH:MM:ss")
                                                        : "2000-01-01"}
                                                </pre>
                                            </td>
                                            <td width="15%">
                                                <pre
                                                    style={{
                                                        fontFamily: "NotoSansKR-DemiLight",
                                                        fontSize: "16px",
                                                        margin: "0px",
                                                    }}
                                                >
                                                    {/* {data.updatetm
                                                ? cmmchangeFormat(data.updatetm, "yy-MM-DD / HH:mm:ss").replace(
                                                      "/",
                                                      "\n\n"
                                                  )
                                                : 0} */}
                                                    {data.cfmtm
                                                        ? cmmchangeFormat(data.cfmtm, "yy-MM-DD HH:MM:ss")
                                                        : null}
                                                </pre>
                                            </td>
                                            <td width="8%">{data.cfmid}</td>

                                            <td width="8%">
                                                {data.confirmYN === "Y" || !data.classificationfileurl ? null : (
                                                    <Button
                                                        className="btn-table"
                                                        variant="secondary"
                                                        type="button"
                                                        onClick={() => {
                                                            onConfirmHandler(data.weightid);
                                                            // onSubmit(watch());
                                                        }}
                                                    >
                                                        확정
                                                    </Button>
                                                )}
                                            </td>
                                            <td width="8%">
                                                {data.confirmYN === "Y" ? null : (
                                                    <Button
                                                        className="btn-table_delete"
                                                        variant="secondary"
                                                        type="button"
                                                        onClick={() => {
                                                            onDeleteHandler(data.weightid);
                                                            //onSubmit(watch());
                                                        }}
                                                    >
                                                        삭제
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="12" style={{ borderBottom: "none" }}>
                                    <PageBtn
                                        data={searchResult ? searchResult : 0}
                                        page={page}
                                        setPage={setPage}
                                        count={count}
                                        btnRefresh={btnRefresh}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </div>
            <div className="modal-box">
                {/*reudx 를 통해 불러온 값이 true면 AddUser을 띄우고 아니면 null을 띄움*/}
                {modalShowVerify ? (
                    <VerifyWeight show={modalShowVerify} setShow={setModalShowVerify}></VerifyWeight>
                ) : null}

                {modalShowLoading ? (
                    <LoadingModal show={modalShowLoading} setShow={setModalShowLoading}></LoadingModal>
                ) : null}
            </div>
        </>
    );
};

export default WeightInfo;
