//Moudle
import React, { useEffect, useRef, useState } from "react";
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal, Table } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../../cmm/cmmGetData"; //Config 가져오기
import cmmGetUser from "../../../cmm/cmmGetUser";

function ChangeSeq(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST;
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);

    const refLvl1 = useRef();
    const refLvl2 = useRef();

    const pmenuOptions = cmmGetData(
        "deptcdPmenu",
        parseInt(props.data.lvl) - 1,
        props.data.pdeptcd,
        props.orgid,
        props.data.pdeptnm
    ); //상위 부서

    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const [selectPmenuSeq, setSelectPmenuSeq] = useState("");
    const [selectPmenuSeqCheck, setSelectPmenuSeqCheck] = useState(false);

    const onSubmit = async (data) => {
        if (selectPmenuSeqCheck) {
            axios
                .post(`${server}/api/orgDept/updateSeq`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid: props.data.orgid,
                    buildid: props.data.buildid,
                    allData: props.allData,
                    lvl: props.data.lvl,
                    seq: props.data.seq,
                    seqChange: selectPmenuSeq,
                    userid: ID,
                })
                .then(() => {})
                .catch((err) => {})
                .finally(() => {
                    refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
                    props.setShow(false);
                });
        } else {
            setAlertMessage("E011");
        }
    };
    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show}
                onHide={() => props.setShow(false)}
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 부서내 순서 변경 </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <>
                            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label>상위 부서</label>
                                    <input
                                        {...register("pdeptnm", {})}
                                        type="text"
                                        readOnly
                                        value={props.data.pdeptnm}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>현 부서명</label>
                                    <input
                                        {...register("pmenuid", {})}
                                        value={props.data.deptnm}
                                        readOnly
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <Table
                                    striped
                                    bordered
                                    hover
                                    style={{ width: "510px", marginLeft: "40px", overflow: "scroll" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>순서</th>
                                            <th>메뉴명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.allData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                                            ? props.allData.map((data, index) => {
                                                  // Slice 된 만큼 반복문 실행
                                                  return (
                                                      <>
                                                          {props.data.pdeptnm === data.pdeptnm ? (
                                                              <tr key={index} onClick={() => {}}>
                                                                  {props.data.deptnm === data.deptnm ? (
                                                                      <>
                                                                          <td style={{ width: "30%", color: "red" }}>
                                                                              {" "}
                                                                              {data.seq}
                                                                          </td>
                                                                          <td style={{ color: "red" }}>
                                                                              {data.deptnm}
                                                                          </td>
                                                                      </>
                                                                  ) : (
                                                                      <>
                                                                          <td style={{ width: "30%" }}> {data.seq}</td>
                                                                          <td>{data.deptnm}</td>
                                                                      </>
                                                                  )}
                                                              </tr>
                                                          ) : null}
                                                      </>
                                                  );
                                              })
                                            : null}
                                    </tbody>
                                </Table>
                                <div className="form-group">
                                    <label>변경할 순서</label>

                                    <Select
                                        className="selector-single"
                                        options={cmmGetData("deptcdPmenuSeq2", "", props.data.pdeptcd, props.orgid)}
                                        onChange={(e) => {
                                            setSelectPmenuSeq(e.value);
                                            setSelectPmenuSeqCheck(true);
                                        }}
                                        defaultValue={{ value: null, label: "*선택한 부서와 순서가 변경됩니다." }}
                                        ref={refLvl2}
                                    ></Select>
                                </div>

                                <div style={{ marginTop: "30px" }}>
                                    <Button
                                        className="btn-positive"
                                        variant="secondary"
                                        type="submit"
                                        onClick={() => {}}
                                    >
                                        저장
                                    </Button>

                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        </>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default ChangeSeq;
