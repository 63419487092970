//Moudle
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetUser from "../../../cmm/cmmGetUser";

const UpdateMsgInfo = (props) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    // 버튼 유형 타입
    const [type, setType] = useState(props.data.btntype);

    const [titleValues, setTitleValues] = useState(props.data.title);
    const [remarksValues, setRemarksValues] = useState(props.data.remarks);

    const msgBtnType = () => {
        let type = [{ value: "null", label: "null" }];
        axios
            .post(`${server}/api/msgInfo/type`, { sessionid: sessionStorage.getItem("SESSION") })
            .then((response) => {
                response.data.map((data) => {
                    type.unshift({
                        label: data.itemnm,
                        value: data.itemcd,
                    });
                });
                type.pop(); // 에러방지값 pop()
            })
            .catch((err) => {});
        return type;
    };

    const { register, handleSubmit } = useForm();
    const onSubmit = async (data) => {
        axios
            .post(`${server}/api/msgInfo/update`, {
                sessionid: sessionStorage.getItem("SESSION"),
                type,
                id: ID,
                data,
                msgid: props.data.msgid,
            })
            .then((response) => {
                if (response.data.errno) {
                    setAlertMessage("E800");
                } else {
                    setAlertMessage("S810");
                    props.setUpdateMsgInfoModal(false);
                }
            })
            .catch((err) => {});
    };
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.updateMsgInfoModal}
            onHide={() => props.setUpdateMsgInfoModal(false)}
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 메시지 수정 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>ID</label>
                            <label
                                className="form-control"
                                style={{ marginRight: "30px", width: "800px", backgroundColor: "#eee" }}
                            >
                                {props.data.msgid}
                            </label>
                        </div>
                        <div className="form-group">
                            <label>헤더</label>

                            <input
                                {...register("title", {})}
                                type="text"
                                className="form-control"
                                value={titleValues}
                                onChange={(e) => {
                                    setTitleValues(e.value);
                                }}
                                maxLength="100"
                            />
                        </div>
                        <div className="form-group">
                            <label>본문</label>

                            <textarea
                                {...register("msgbody", {})}
                                className="form-control"
                                rows="3"
                                style={{ height: "170px" }}
                                maxLength="500"
                            >
                                {props.data.msgbody}
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label>버튼유형</label>

                            <Select
                                className="selector-single"
                                defaultValue={{ value: props.data.btntype, label: props.data.itemnm }}
                                options={msgBtnType()}
                                onChange={(e) => {
                                    setType(e.value);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label>비고</label>

                            <input
                                {...register("remarks", {})}
                                type="text"
                                className="form-control"
                                value={remarksValues}
                                onChange={(e) => {
                                    setRemarksValues(e.value);
                                }}
                                maxLength="100"
                            />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Button className="btn-positive" variant="secondary" type="submit">
                                수정
                            </Button>
                            <Button
                                className="btn-negative"
                                variant="secondary"
                                type="button"
                                onClick={() => props.setUpdateMsgInfoModal(false)}
                            >
                                취소
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default UpdateMsgInfo;
