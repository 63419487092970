import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "../../css/bulletIn.css";
import axios from "axios";
import Select from "react-select";
import OrgBulletin from "./BulletinModal/DeptSelect";
import cmmGetUser from "../../cmm/cmmGetUser";
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import { DatePickerTwo } from "../../cmm/cmmDateFunction";

function BulletinModify(props) {
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);

    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    const dispatch = useDispatch();

    //현재날짜 불러오기
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    let date = today.getDate(); // 날짜

    date = parseInt(date) < 10 ? "0" + date : date;
    let current = year + "-" + month + "-" + date;
    let temp = new Date(year, month - 1, date);

    const substrsize = props.location.state.data.statuscd === "N" ? 3 : 5; //제목 자르기 DB에서 concat 부분 바뀌면 여기 바꿔줘야함
    temp.setDate(temp.getDate() + 365);

    year = temp.getFullYear(); // 년도
    month = temp.getMonth() + 1; // 월
    date = temp.getDate(); // 날짜
    date = parseInt(date) < 10 ? "0" + date : date;
    let fature = year + "-" + month + "-" + date;

    // 게시물 추가 모달창 true/false
    const orgBulletinState = useSelector((state) => state.orgBulletinModalReducer);
    // 현재 접속된 아이디
    const id = ID;
    // 부서_팀장님께noticedeptnm 컬럼 생성 요청
    const [lvl, setLvl] = useState({
        lvl: props.location.state.data.noticedeptcd,
        label: props.location.state.data.noticedeptnm
            ? props.location.state.data.noticedeptnm
            : props.location.state.data.noticedeptcd,
    });

    // 버튼 유형 타입
    const [type, setType] = useState(props.location.state.data.statuscd);
    const [privateyn, setPrivateyn] = useState(props.location.state.data.privateyn);

    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        axios
            .post(`${server}/api/bulletin/updateWeb`, {
                sessionid: sessionStorage.getItem("SESSION"),
                wid: props.location.state.data.wid,
                title: data.title,
                content: data.msgbody,
                userid: id,
                privateyn: privateyn,
                type: type,
                begindt: data.begindt,
                enddt: data.enddt,
                noticedeptcd: lvl.lvl,
                rootid: props.location.state.data.rootid,
            })
            .then((response) => {
                window.location.replace("./bulletin");
            })
            .catch((err) => {});

        // setAlertMessage("E900");
    };

    return (
        <>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <Table bordered hover>
                    <tbody>
                        <tr>
                            <td style={{ backgroundColor: "#eee", width: "5%" }}>
                                <label>제목</label>
                            </td>
                            <td style={{ width: "52%" }}>
                                <input
                                    {...register("title", {})}
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    defaultValue={props.location.state.data.title.substr(
                                        substrsize,
                                        props.location.state.data.title.length
                                    )}
                                    style={{ width: "99%", marginTop: "8px", marginBottom: "8px" }}
                                />
                            </td>

                            {type === "Y" ? (
                                <>
                                    <td style={{ backgroundColor: "#eee", width: "5%" }}>
                                        <label>기간</label>
                                    </td>
                                    <td colSpan="3">
                                        <div style={{ display: "flex" }}>
                                            <input
                                                id="begindt"
                                                {...register("begindt", {})}
                                                type="date"
                                                //value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                                defaultValue={
                                                    props.location.state.data.noticefrdt
                                                        ? props.location.state.data.noticefrdt.substr(0, 10)
                                                        : null
                                                }
                                                className="input-date1"
                                            />
                                            <p style={{ marginTop: "20px", marginLeft: "5px" }}> - </p>
                                            <input
                                                id="enddt"
                                                {...register("enddt", {})}
                                                type="date"
                                                // value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                                defaultValue={
                                                    props.location.state.data.noticetodt
                                                        ? props.location.state.data.noticetodt.substr(0, 10)
                                                        : null
                                                }
                                                className="input-date2"
                                                style={{ marginRight: "10%" }}
                                            />
                                        </div>
                                    </td>
                                    <td style={{ backgroundColor: "#eee", width: "5%" }}>
                                        <Button
                                            className="btn"
                                            variant="secondary"
                                            type="button"
                                            onClick={() => dispatch({ type: "orgBulletinModalTrue" })}
                                        >
                                            대상
                                        </Button>
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        <label>{lvl.lvl == "A" ? "전체" : lvl.label}</label>
                                    </td>
                                </>
                            ) : type === "I" ? (
                                <>
                                    <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                        <label>비밀여부</label>
                                    </td>

                                    <td style={{ width: "25%" }}>
                                        {props.location.state.data.privateyn === "Y" ? (
                                            <>
                                                <Select
                                                    defaultValue={{ value: "Y", label: "예" }}
                                                    options={[
                                                        { value: "Y", label: "예" },
                                                        { value: "N", label: "아니요" },
                                                    ]}
                                                    onChange={(e) => {
                                                        setPrivateyn(e.value);
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Select
                                                    defaultValue={{ value: "N", label: "아니요" }}
                                                    options={[
                                                        { value: "Y", label: "예" },
                                                        { value: "N", label: "아니요" },
                                                    ]}
                                                    onChange={(e) => {
                                                        setPrivateyn(e.value);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </td>
                                </>
                            ) : null}
                        </tr>

                        <tr>
                            <td colSpan="8" style={{ backgroundColor: "#eee" }}>
                                <label>본문</label>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="8" style={{ backgroundColor: "#eee" }}>
                                <textarea
                                    className="contentBox-TextArea"
                                    autofocus="autofocus"
                                    maxLength="2000"
                                    {...register("msgbody", {
                                        required: "필수정보입니다.",
                                    })}
                                    style={{ width: "100%" }}
                                    defaultValue={props.location.state.data.content}
                                    rows="20"
                                ></textarea>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <div style={{ float: "right" }}>
                    <Button className="btn-cancel2-c2" variant="secondary" type="submit">
                        저장
                    </Button>
                    <Button
                        className="btn-cancel2-c1"
                        variant="secondary"
                        type="button"
                        onClick={() => window.location.replace("./bulletin")}
                    >
                        취소
                    </Button>
                </div>
            </form>
            {/*reudx 를 통해 불러온 값이 true면 OrgBulletin 띄우고 아니면 null을 띄움*/}
            {orgBulletinState ? <OrgBulletin setLvl={setLvl} /> : null}
        </>
    );
}
export default BulletinModify;
