//시스템 관리자 계정 신청 관리
//Moudle
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import cmmGetUser from "../../../cmm/cmmGetUser";
//반려의견 보기
function ShowRevokeMsg(props) {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    //redux 값 변경을 위한 dispatch 사용을 위해

    return (
        <Modal
            {...props}
            show={props.show}
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            {" "}
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 반려 의견 보기 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <form className="LoginBox">
                        <>
                            <div
                                className="form-group"
                                style={{ width: "500px", height: "500px", marginLeft: "45px", marginBottom: "-40px" }}
                            >
                                <textarea id="revoke" defaultValue={props.updateUserData.revokemsg} readOnly></textarea>
                            </div>
                            <div
                                className="form-group"
                                style={{ fontSize: "13px", marginTop: "5px", marginLeft: "410px" }}
                            >
                                <p>처리자 : {props.updateUserData.handlerid ? props.updateUserData.handlerid : null}</p>
                            </div>
                            <div
                                className="form-group"
                                style={{ fontSize: "13px", marginTop: "-25px", marginLeft: "410px" }}
                            >
                                <p>{props.updateUserData.handletm ? props.updateUserData.handletm : null}</p>
                            </div>

                            <div style={{ marginLeft: "250px", marginBottom: "20px" }}>
                                <Button
                                    className="btn-status_error"
                                    variant="secondary"
                                    onClick={() => {
                                        props.setShow(false);
                                    }}
                                    style={{ height: "45px" }}
                                >
                                    확인
                                </Button>
                            </div>
                        </>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default ShowRevokeMsg;
