//Moudle
import React, { useEffect, useRef, useState } from "react";
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../../cmm/cmmGetData"; //Config 가져오기
import cmmGetUser from "../../../cmm/cmmGetUser";

function ChagnePmenu(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST;
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);

    const refLvl1 = useRef();
    const refLvl2 = useRef();

    const pmenuOptions = cmmGetData(
        "deptcdPmenu",
        parseInt(props.data.lvl) - 1,
        props.data.pdeptcd,
        props.orgid,
        props.data.pdeptnm
    ); //상위 부서

    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const [selectPmenu, setSelectPmenu] = useState("");
    const [selectPmenuCheck, setSelectPmenuCheck] = useState(false);

    const onSubmit = async (data) => {
        if (selectPmenuCheck) {
            axios
                .post(`${server}/api/orgDept/updatePmenu`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    orgid: props.data.orgid,
                    deptcd: props.data.deptcd,
                    lvl: props.data.lvl,
                    pdeptcd: props.data.pdeptcd,
                    pmenuid: selectPmenu,
                    seq: cmmGetData("deptcdPmenuSeq", "", selectPmenu, props.orgid),
                    myseq: props.data.seq,
                    userid: ID,
                })
                .then((data) => {})
                .catch((err) => {})
                .finally(() => {
                    refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
                    props.setShow(false);
                });
        } else {
            setAlertMessage("E011");
        }

        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
    };

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show}
                onHide={() => props.setShow(false)}
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                {" "}
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 상위부서 변경 </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <>
                            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label>(현) 상위 부서</label>
                                    <input
                                        {...register("pdeptnm", {})}
                                        type="text"
                                        readOnly
                                        value={props.data.pdeptnm}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>현 부서명</label>
                                    <input
                                        {...register("pmenuid", {})}
                                        value={props.data.deptnm}
                                        readOnly
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>(변경) 상위 부서</label>

                                    <Select
                                        className="selector-single"
                                        options={cmmGetData(
                                            "deptcdPmenu",
                                            parseInt(props.data.lvl) - 1,
                                            props.data.pdeptcd,
                                            props.orgid,
                                            props.data.pdeptnm
                                        )}
                                        onChange={(e) => {
                                            setSelectPmenu(e.value);
                                            setSelectPmenuCheck(true);
                                            if (refLvl2.current) {
                                                if (refLvl2.current.state.value) {
                                                    refLvl2.current.state.value.label = "";
                                                }
                                            }
                                        }}
                                        defaultValue={{ value: null, label: "*선택" }}
                                        ref={refLvl1}
                                    ></Select>
                                </div>

                                <div style={{ marginTop: "30px" }}>
                                    <Button
                                        className="btn-positive"
                                        variant="secondary"
                                        type="submit"
                                        onClick={() => {}}
                                    >
                                        저장
                                    </Button>

                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        </>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default ChagnePmenu;
