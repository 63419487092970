import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Button, ProgressBar, Accordion } from "react-bootstrap";
import axios from "axios";
import cmmGetUser from "../../cmm/cmmGetUser";
import "../../css/ScrollBar.css";
import ReactTooltip from "react-tooltip";
import { DatePickerTwo } from "../../cmm/cmmDateFunction";
import LabelTool from "./LabelTool";
import LoadFile from "./FileStorageMenuModal/LoadFile";
import LoadingModal from "../../cmm/cmmLoadingModal";
import FireTypeChange from "./FileStorageMenuModal/FireTypeChange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import setAlertMessage from "../../cmm/cmmAlertMessage";
import AddImg from "./FileStorageMenuModal/AddImg";
import cmmchangeFormat from "../../cmm/cmmchangeFormat";
import "../../css/Accordion.css";
import Switch from "@mui/material/Switch";

function FileStorageMenu() {
    const [sessionData, setSessionData] = useState();
    useEffect(() => {
        (async () => {
            setSessionData(await cmmGetUser());
        })();
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    const maxFileLength = 200000;
    const canvasReadRef2 = useRef([]);
    // 날짜
    const [date, setDate] = useState();
    const [start, setStart] = useState(true);
    // Mobile File Search
    const [searchM, setSearchM] = useState([]);
    // Web File Search
    const [searchW, setSearchW] = useState([]);
    // Copy File Search
    const [searchCopy, setSearchCopy] = useState([]);
    // 마지막 선택한 데이터(웹 우측 사진)
    const [lastResult, setLastResult] = useState();
    // 불러오기 한 데이터
    const [loadData, setLoadData] = useState([]);
    const [weightInfoData, setWeightInfoData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [updateBtn, setUpdateBtn] = useState(true);
    const [labelToolModal, setLabelToolModal] = useState(false);
    const [loadFileModal, setLoadFileModal] = useState(false);
    const [addImgModal, setAddImgModal] = useState(false);
    const [fireTypeChangeModal, setFireTypeChangeModal] = useState(false);
    const [modalCheck, setModalCheck] = useState(false);
    const [selectCheck, setSelectCheck] = useState(false);
    const [loadingShow, setLoadingShow] = useState(false);
    const [imgTarget, setImgTarget] = useState(false);
    const [btnTypeMode, setBtnTypeMode] = useState(0);
    const [openImgE, setOpenImgE] = useState();
    const [loadingCount, setLoadingCount] = useState(0);
    const [weightItem, setWeightItem] = useState([]);
    const [lastTarget, setLastTarget] = useState([]);
    const [switchBtnTF, setSwitchBtnTF] = useState(false);

    // 화면에 보여줄 데이터 전처리
    const dataJSON = ({ res }) => {
        let arr = [];
        res.data.map((data) => {
            const lvl1Name = `${data.mode}-${data.createdt}`;
            const lvl2Name = `${data.specimenid}`;
            const lvl3Name = `${data.specimenid}_${data.pseq}`;
            const lvl4Name = `${data.specimenid}_${data.pseq}_${data.seq}`;
            if (data.lvl === 1) {
                arr = { ...arr, [lvl1Name]: { data: data } };
            } else if (data.lvl === 2) {
                arr[lvl1Name] = {
                    ...arr[lvl1Name],
                    [lvl2Name]: { data: data },
                };
            } else if (data.lvl === 3) {
                arr[lvl1Name][lvl2Name] = {
                    ...arr[lvl1Name][lvl2Name],
                    [lvl3Name]: { data: data },
                };
            } else if (data.lvl === 4) {
                arr[lvl1Name][lvl2Name][lvl3Name] = {
                    ...arr[lvl1Name][lvl2Name][lvl3Name],
                    [lvl4Name]: { data: data },
                };
            }
        });
        return arr;
    };

    const mobileSearch = () => {
        axios
            .post(`${server}/api/fileStorageMenu/searchMobile`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                setSearchM(dataJSON({ res: res }));
            });
    };

    const mobileSearch2 = () => {
        axios
            .post(`${server}/api/fileStorageMenu/searchMobile2`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                setSearchM(dataJSON({ res: res }));
            });
    };

    const webSearch = () => {
        axios
            .post(`${server}/api/fileStorageMenu/searchWeb`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                setSearchW(dataJSON({ res: res }));
            });
    };

    const webSearch2 = () => {
        axios
            .post(`${server}/api/fileStorageMenu/searchWeb2`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                setSearchW(dataJSON({ res: res }));
            });
    };

    // 1분마다 학습률 불러오기
    useEffect(() => {
        setInterval(async () => {
            const res = await axios.post(`${server}/api/fileStorageMenu/progress`, {
                sessionid: sessionStorage.getItem("SESSION"),
            });
            axios
                .post(`${server}/api/fileStorageMenu/weightInfo`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                })
                .then((res) => {
                    setWeightInfoData(res.data);
                });

            if (res.data) {
                setProgress(res.data);
            }
        }, 60000);
    }, []);

    // 데이터 불러오기/새로고침
    useEffect(() => {
        if (start) {
            setLoadingShow(true);
            mobileSearch();
            webSearch();
            axios
                .post(`${server}/api/fileStorageMenu/weightInfo`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                })
                .then((res) => {
                    setWeightInfoData(res.data);
                });

            axios
                .post(`${server}/api/fileStorageMenu/progress`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                })
                .then((res) => {
                    setProgress(res.data);
                });

            setStart(false);
            if (imgTarget) {
                imgTarget.click();
            }
        }
    }, [start]);

    useEffect(() => {
        if (loadData.id) {
            axios
                .post(`${server}/api/fileStorageMenu/searchFile`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    weightid: loadData.id,
                })
                .then((res) => {
                    setWeightItem(res.data);
                });
        }
    }, [loadData]);

    const dataVal = ({ searchData }) => {
        let arr = [];
        for (const [key1, value1] of Object.entries(searchData)) {
            for (const [key2, value2] of Object.entries(value1)) {
                if (key2 !== "data") {
                    for (const [key3, value3] of Object.entries(value2)) {
                        if (key3 !== "data") {
                            for (const [key4, value4] of Object.entries(value3)) {
                                if (key4 !== "data") {
                                    for (const [key5, value5] of Object.entries(value4)) {
                                        arr.push(value5);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return arr;
    };

    // 개별 이미지 클릭시
    const openImg = useCallback(
        async (e, i, wire = null) => {
            const weightid = loadData.id;
            if (e) e.target.style.textDecoration = "underline";

            // 기존 모바일/웹 데이터
            let a = dataVal({ searchData: searchM });
            let b = dataVal({ searchData: searchW });
            // 선택된 데이터
            let c = dataVal({ searchData: searchCopy });
            let data = [];
            if (i === 1) {
                data = c.filter(
                    (data) =>
                        data.specimenid === e.target.innerHTML.split("_")[0] &&
                        data.pseq === Number(e.target.innerHTML.split("_")[1]) &&
                        data.seq === Number(e.target.innerHTML.split("_")[2])
                )[0];
            } else if (i === 0) {
                data = a
                    .concat(b)
                    .filter(
                        (data) =>
                            data.specimenid === e.target.innerHTML.split("_")[0] &&
                            data.pseq === Number(e.target.innerHTML.split("_")[1]) &&
                            data.seq === Number(e.target.innerHTML.split("_")[2])
                    )[0];
            }
            setLastResult(data);
            setImgTarget(e.target);
            // 라벨 그리기
            (async () => {
                let json;
                let jsonpath;
                if (i === 1) {
                    await axios
                        .post(`${server}/api/fileStorageMenu/jsonTF2`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            weightid,
                            specimenid: data.specimenid,
                            pseq: data.pseq,
                            seq: data.seq,
                        })
                        .then((res) => {
                            jsonpath = res.data[0] ? res.data[0].jsonpath : null;
                        });
                } else {
                    await axios
                        .post(`${server}/api/fileStorageMenu/jsonTF3`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            specimenid: data.specimenid,
                            pseq: data.pseq,
                            seq: data.seq,
                        })
                        .then((res) => {
                            jsonpath = res.data[0] ? res.data[0].jsonpath : null;
                        });
                }

                const canvas = canvasReadRef2.current;
                const ctx = canvas.getContext("2d");
                const imgSize = { W: 1280, H: 720 };
                let img = new Image();
                canvas.width = json ? json.imageWidth : imgSize.W;
                canvas.height = json ? json.imageHeight : imgSize.H;
                img.src = "";
                ctx.clearRect(0, 0, json ? json.imageWidth : imgSize.W, json ? json.imageHeight : imgSize.H);
                await axios
                    .post(`${server}/api/fileStorageMenu/img`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        imgPath: weightid ? data.imgpath : data.imgresultpath,
                    })
                    .then((res) => {
                        if (!res.data.errno) {
                            img.src = res.data;
                        }
                    });

                img.onload = () => {
                    ctx.drawImage(img, 0, 0, json ? json.imageWidth : imgSize.W, json ? json.imageHeight : imgSize.H);
                    if (jsonpath) {
                        axios
                            .post(`${server}/api/fileStorageMenu/label`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                jsonPath: jsonpath,
                            })
                            .then((res2) => {
                                json = res2.data;
                                ctx.globalAlpha = 1;
                                ctx.lineWidth = 6;
                                ctx.fillStyle = "red";
                                ctx.strokeStyle = "lightgreen";

                                // 선 그리기
                                json.shapes.map((shape, index) => {
                                    ctx.beginPath();
                                    for (let i = 0; i < shape.points.length; i++) {
                                        if (i === shape.points.length - 1) {
                                            ctx.moveTo(shape.points[i][0], shape.points[i][1]);
                                            ctx.lineTo(shape.points[0][0], shape.points[0][1]);
                                        } else {
                                            ctx.moveTo(shape.points[i][0], shape.points[i][1]);
                                            ctx.lineTo(shape.points[i + 1][0], shape.points[i + 1][1]);
                                        }
                                    }
                                    ctx.closePath();
                                    ctx.stroke();
                                });

                                // 점 그리기
                                json.shapes.map((shape, index) => {
                                    shape.points.map((point) => {
                                        ctx.beginPath();
                                        ctx.arc(point[0], point[1], Math.PI * 2, 0, Math.PI * 2, true);
                                        ctx.closePath();
                                        ctx.fill();
                                    });
                                });
                            });
                    }
                };
            })();
        },
        [searchW, searchM, searchCopy, lastTarget]
    );

    // ====================== checkbox 로직 ============================
    const checkFn = (e) => {
        const this_checkbox = e.target;
        const class_name = this_checkbox.className;
        const checked = this_checkbox.checked;
        const scrollDivNode = document.getElementById("scrollDiv").querySelectorAll(`[class^='${class_name}-']`);
        const scrollDiv1Node = document.getElementById("scrollDiv1").querySelectorAll(`[class^='${class_name}-']`);
        [...scrollDivNode].concat([...scrollDiv1Node]).forEach((data) => (data.checked = checked));
        checkbox_checked(class_name, checked);
    };

    function checkbox_checked(class_name, checked) {
        if (class_name.indexOf("-") == -1) return;

        const parent_class_name = class_name.substr(0, class_name.lastIndexOf("-"));
        const friend_class = class_name.substr(0, class_name.lastIndexOf("-") + 1);
        const parent_class_name_node = document.querySelectorAll("." + parent_class_name);
        if (checked) {
            //체크일경우
            const regex = new RegExp(`${friend_class}[0-9]$`);
            const Mdata = document.getElementById("scrollDiv").querySelectorAll("input[type=checkbox]");
            const Wdata = document.getElementById("scrollDiv1").querySelectorAll("input[type=checkbox]");
            const node2 = [...Mdata].concat([...Wdata]).filter((data) => regex.test(data.className));
            if (node2.length == node2.filter((data) => data.checked).length) {
                parent_class_name_node.forEach((data) => (data.checked = true));
            }
        } //해제일경우
        else {
            //하위단원을 체크 해제했을경우 상위단원 체크 해제 부분
            if (parent_class_name != "category") {
                parent_class_name_node.forEach((data) => (data.checked = false));
            }
        }
        checkbox_checked(parent_class_name, checked);
    }
    // ==================================================================

    const createLbel2 = ({ content, cnt, id, mode = null, lvl = null }) => {
        let cntnm = cnt;
        if (!cntnm) {
            cntnm = "";
        } else {
            cntnm = `(${cntnm})`;
        }
        return (
            <>
                <label
                    className="accordion"
                    id={id}
                    style={{
                        width: `calc(95% - 30px - ${lvl === 4 ? "10px" : "0px"})`,
                        padding: `0px 0px 0px 5%`,
                        cursor: "pointer",
                        textAlign: "left",
                    }}
                    onClick={async (e) => {
                        if (lvl === 4) {
                            setLastTarget((val) => [...val, e.target]);
                            if (mode === "COPY") {
                                openImg(e, 1, null);
                                setUpdateBtn(false);
                            } else {
                                openImg(e, 0, null);
                                setUpdateBtn(true);
                            }
                        }
                    }}
                >
                    {`${content} ${cntnm}`}
                </label>
                <label style={{ marginRight: "10px", color: "#666" }}>
                    {lvl === 4 && mode === "W" ? (
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={(e) => {
                                imgDelete(content);
                                e.stopPropagation();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    ) : null}
                    {lvl === 2 && mode === "W" ? (
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={(e) => {
                                imgDelete2(content);
                                e.stopPropagation();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    ) : null}
                </label>
            </>
        );
    };

    // 선택된 데이터 스타일 주기
    useEffect(() => {
        if (lastTarget.length >= 2) {
            if (lastTarget[0] !== lastTarget[1]) {
                lastTarget[0].style.textDecoration = "none";
            }
            let arr = lastTarget;
            arr.shift();
            setLastTarget(arr);
        }
    }, [lastTarget]);

    const fileStorage4 = ({ searchData, mode }) => {
        if (Object.keys(searchData).length > 0) {
            return Object.keys(searchData).map((data, index) => {
                if (Object.keys(searchData).length - 1 === index && !switchBtnTF) setLoadingCount((val) => val + 1);
                const modeNum = mode === "M" ? 0 : mode === "W" ? 1 : 2;
                return (
                    <Accordion defaultActiveKey={["1"]} alwaysOpen>
                        <Accordion.Item>
                            <Accordion.Header style={{ padding: "0px" }}>
                                {mode !== "COPY" ? (
                                    <input
                                        type={"checkbox"}
                                        name={`${searchData[data]["data"].mode}-${searchData[data]["data"].createdt}`}
                                        className={`category-${modeNum}-${index}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            checkFn(e);
                                        }}
                                        id="lvl1"
                                        value={JSON.stringify(searchData[data]["data"])}
                                        style={{ zoom: "1.3" }}
                                    />
                                ) : (
                                    <input
                                        type={"checkbox"}
                                        name="category_id"
                                        className={`category-${modeNum}-${index}`}
                                        id="lvl1"
                                        value={JSON.stringify(searchData[data]["data"])}
                                        defaultChecked={true}
                                        disabled
                                        style={{ zoom: "1.3" }}
                                    />
                                )}
                                {createLbel2({
                                    content: data,
                                    cnt: searchData[data]["data"].cnt,
                                    id: `label-${modeNum}-${index}`,
                                })}
                            </Accordion.Header>
                            <Accordion.Body style={{ padding: "6px", backgroundColor: "#f7f7f7" }}>
                                {Object.keys(searchData[data]).map((data2, index2) => {
                                    if (data2 !== "data") {
                                        return (
                                            <Accordion defaultActiveKey={["1"]} alwaysOpen>
                                                <Accordion.Item>
                                                    <Accordion.Header style={{ padding: "0px" }}>
                                                        {mode !== "COPY" ? (
                                                            <input
                                                                type={"checkbox"}
                                                                name={searchData[data][data2]["data"].specimenid}
                                                                className={`category-${modeNum}-${index}-${index2}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    checkFn(e);
                                                                }}
                                                                id="lvl2"
                                                                value={JSON.stringify(searchData[data][data2]["data"])}
                                                                style={{ zoom: "1.3" }}
                                                            />
                                                        ) : (
                                                            <input
                                                                type={"checkbox"}
                                                                name="category_id"
                                                                className={`category-${modeNum}-${index}-${index2}`}
                                                                id="lvl2"
                                                                value={JSON.stringify(searchData[data][data2]["data"])}
                                                                style={{ zoom: "1.3" }}
                                                                defaultChecked={true}
                                                                disabled
                                                            />
                                                        )}
                                                        {createLbel2({
                                                            content: data2,
                                                            cnt: searchData[data][data2]["data"].cnt,
                                                            id: `label-${modeNum}-${index}-${index2}`,
                                                            mode: mode,
                                                            lvl: 2,
                                                        })}
                                                    </Accordion.Header>
                                                    <Accordion.Body
                                                        style={{ padding: "6px", backgroundColor: "#f0f0f0" }}
                                                    >
                                                        {Object.keys(searchData[data][data2]).map((data3, index3) => {
                                                            if (data3 !== "data") {
                                                                return (
                                                                    <Accordion defaultActiveKey={["1"]} alwaysOpen>
                                                                        <Accordion.Item>
                                                                            <Accordion.Header
                                                                                style={{ padding: "0px" }}
                                                                            >
                                                                                {mode !== "COPY" ? (
                                                                                    <input
                                                                                        type={"checkbox"}
                                                                                        name={`${searchData[data][data2][data3]["data"].specimenid}-${searchData[data][data2][data3]["data"].pseq}`}
                                                                                        className={`category-${modeNum}-${index}-${index2}-${index3}`}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            checkFn(e);
                                                                                        }}
                                                                                        id="lvl3"
                                                                                        value={JSON.stringify(
                                                                                            searchData[data][data2][
                                                                                                data3
                                                                                            ]["data"]
                                                                                        )}
                                                                                        style={{ zoom: "1.3" }}
                                                                                    />
                                                                                ) : (
                                                                                    <input
                                                                                        type={"checkbox"}
                                                                                        name="category_id"
                                                                                        className={`category-${modeNum}-${index}-${index2}-${index3}`}
                                                                                        id="lvl3"
                                                                                        value={JSON.stringify(
                                                                                            searchData[data][data2][
                                                                                                data3
                                                                                            ]["data"]
                                                                                        )}
                                                                                        style={{ zoom: "1.3" }}
                                                                                        defaultChecked={true}
                                                                                        disabled
                                                                                    />
                                                                                )}
                                                                                {createLbel2({
                                                                                    content: data3,
                                                                                    cnt: searchData[data][data2][data3][
                                                                                        "data"
                                                                                    ].cnt,
                                                                                    id: `label-${modeNum}-${index}-${index2}-${index3}`,
                                                                                })}
                                                                            </Accordion.Header>
                                                                            <Accordion.Body
                                                                                style={{
                                                                                    padding: "6px",
                                                                                    backgroundColor: "#e7e7e7",
                                                                                }}
                                                                            >
                                                                                {Object.keys(
                                                                                    searchData[data][data2][data3]
                                                                                ).map((data4, index4) => {
                                                                                    if (data4 !== "data") {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            "#e0e0e0",
                                                                                                        borderRadius:
                                                                                                            "3px",
                                                                                                        padding:
                                                                                                            "7px 20px",
                                                                                                        border: "1px solid #c7c7c7",
                                                                                                        marginBottom: `${
                                                                                                            Object.keys(
                                                                                                                searchData[
                                                                                                                    data
                                                                                                                ][
                                                                                                                    data2
                                                                                                                ][data3]
                                                                                                            ).length -
                                                                                                                1 ===
                                                                                                            index4
                                                                                                                ? "0px"
                                                                                                                : "2px"
                                                                                                        }`,
                                                                                                    }}
                                                                                                >
                                                                                                    {mode !== "COPY" ? (
                                                                                                        <input
                                                                                                            type={
                                                                                                                "checkbox"
                                                                                                            }
                                                                                                            name={`${searchData[data][data2][data3][data4]["data"].specimenid}-${searchData[data][data2][data3][data4]["data"].pseq}-${searchData[data][data2][data3][data4]["data"].seq}`}
                                                                                                            className={`category-${modeNum}-${index}-${index2}-${index3}-${index4}`}
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                e.stopPropagation();
                                                                                                                checkFn(
                                                                                                                    e
                                                                                                                );
                                                                                                            }}
                                                                                                            id="lvl4"
                                                                                                            value={JSON.stringify(
                                                                                                                searchData[
                                                                                                                    data
                                                                                                                ][
                                                                                                                    data2
                                                                                                                ][
                                                                                                                    data3
                                                                                                                ][
                                                                                                                    data4
                                                                                                                ][
                                                                                                                    "data"
                                                                                                                ]
                                                                                                            )}
                                                                                                            style={{
                                                                                                                zoom: "1.3",
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <input
                                                                                                            type={
                                                                                                                "checkbox"
                                                                                                            }
                                                                                                            name="category_id"
                                                                                                            className={`category-${modeNum}-${index}-${index2}-${index3}-${index4}`}
                                                                                                            id="lvl4"
                                                                                                            value={JSON.stringify(
                                                                                                                searchData[
                                                                                                                    data
                                                                                                                ][
                                                                                                                    data2
                                                                                                                ][
                                                                                                                    data3
                                                                                                                ][
                                                                                                                    data4
                                                                                                                ][
                                                                                                                    "data"
                                                                                                                ]
                                                                                                            )}
                                                                                                            style={{
                                                                                                                marginLeft:
                                                                                                                    "10px",
                                                                                                                zoom: "1.3",
                                                                                                            }}
                                                                                                            defaultChecked={
                                                                                                                true
                                                                                                            }
                                                                                                            disabled
                                                                                                        />
                                                                                                    )}
                                                                                                    {createLbel2({
                                                                                                        content: data4,
                                                                                                        id: `label-${modeNum}-${index}-${index2}-${index3}-${index4}`,
                                                                                                        lvl: 4,
                                                                                                        mode: mode,
                                                                                                    })}
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </Accordion>
                                                                );
                                                            }
                                                        })}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        );
                                    }
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                );
            });
        } else {
            setLoadingShow(false);
        }
    };

    // 모바일/웹 데이터 로딩되었을경우 해제
    useEffect(() => {
        if (loadingCount === 2) {
            setLoadingCount(0);
            setLoadingShow(false);
        }
    }, [loadingCount]);

    // 체크된 데이터 전처리
    const addFile3 = async ({ Mdata, Wdata }) => {
        let arr = [];
        [...Mdata].concat([...Wdata]).forEach((datas) => {
            if (datas.checked) {
                let data = JSON.parse(datas.value);
                const lvl1Name = `${data.mode}-${data.createdt}`;
                const lvl2Name = `${data.specimenid}`;
                const lvl3Name = `${data.specimenid}_${data.pseq}`;
                const lvl4Name = `${data.specimenid}_${data.pseq}_${data.seq}`;
                if (data.lvl === 1) {
                    arr = {
                        ...arr,
                        [lvl1Name]: { data: data },
                    };
                } else if (data.lvl === 2) {
                    if (!arr[lvl1Name]) {
                        let data2 = [...Mdata]
                            .concat([...Wdata])
                            .filter(
                                (datas2) =>
                                    JSON.parse(datas2.value).createdt === data.createdt &&
                                    JSON.parse(datas2.value).lvl === 1 &&
                                    JSON.parse(datas2.value).mode === data.mode
                            )[0].value;
                        arr = {
                            ...arr,
                            [`${JSON.parse(data2).mode}-${JSON.parse(data2).createdt}`]: {
                                data: JSON.parse(data2),
                            },
                        };
                    }
                    arr[lvl1Name] = {
                        ...arr[lvl1Name],
                        [lvl2Name]: { data: data },
                    };
                } else if (data.lvl === 3) {
                    if (!arr[lvl1Name]) {
                        let data2 = [...Mdata]
                            .concat([...Wdata])
                            .filter(
                                (datas2) =>
                                    JSON.parse(datas2.value).createdt === data.createdt &&
                                    JSON.parse(datas2.value).lvl === 1 &&
                                    JSON.parse(datas2.value).mode === data.mode
                            )[0].value;
                        arr = {
                            ...arr,
                            [`${JSON.parse(data2).mode}-${JSON.parse(data2).createdt}`]: {
                                data: JSON.parse(data2),
                            },
                        };
                    }
                    if (!arr[lvl1Name][lvl2Name]) {
                        let data2 = [...Mdata]
                            .concat([...Wdata])
                            .filter(
                                (datas2) =>
                                    JSON.parse(datas2.value).createdt === data.createdt &&
                                    JSON.parse(datas2.value).specimenid === data.specimenid &&
                                    JSON.parse(datas2.value).lvl === 2
                            )[0].value;
                        arr[lvl1Name] = {
                            ...arr[lvl1Name],
                            [JSON.parse(data2).specimenid]: { data: JSON.parse(data2) },
                        };
                    }
                    arr[lvl1Name][lvl2Name] = {
                        ...arr[lvl1Name][lvl2Name],
                        [lvl3Name]: { data: data },
                    };
                } else if (data.lvl === 4) {
                    if (!arr[lvl1Name]) {
                        let data2 = [...Mdata]
                            .concat([...Wdata])
                            .filter(
                                (datas2) =>
                                    JSON.parse(datas2.value).createdt === data.createdt &&
                                    JSON.parse(datas2.value).lvl === 1 &&
                                    JSON.parse(datas2.value).mode === data.mode
                            )[0].value;
                        arr = {
                            ...arr,
                            [`${JSON.parse(data2).mode}-${JSON.parse(data2).createdt}`]: {
                                data: JSON.parse(data2),
                            },
                        };
                    }
                    if (!arr[lvl1Name][lvl2Name]) {
                        let data2 = [...Mdata]
                            .concat([...Wdata])
                            .filter(
                                (datas2) =>
                                    JSON.parse(datas2.value).createdt === data.createdt &&
                                    JSON.parse(datas2.value).specimenid === data.specimenid &&
                                    JSON.parse(datas2.value).lvl === 2
                            )[0].value;
                        arr[lvl1Name] = {
                            ...arr[lvl1Name],
                            [JSON.parse(data2).specimenid]: { data: JSON.parse(data2) },
                        };
                    }
                    if (!arr[lvl1Name][lvl2Name][lvl3Name]) {
                        let data2 = [...Mdata]
                            .concat([...Wdata])
                            .filter(
                                (datas2) =>
                                    JSON.parse(datas2.value).createdt === data.createdt &&
                                    JSON.parse(datas2.value).specimenid === data.specimenid &&
                                    JSON.parse(datas2.value).pseq === data.pseq &&
                                    JSON.parse(datas2.value).lvl === 3
                            )[0].value;
                        arr[lvl1Name][lvl2Name] = {
                            ...arr[lvl1Name][lvl2Name],
                            [`${data.specimenid}_${JSON.parse(data2).pseq}`]: { data: JSON.parse(data2) },
                        };
                    }
                    arr[lvl1Name][lvl2Name][lvl3Name] = {
                        ...arr[lvl1Name][lvl2Name][lvl3Name],
                        [lvl4Name]: { data: data },
                    };
                }
            }
        });
        return arr;
    };

    const addFile2 = async () => {
        const Mdata = document.getElementById("scrollDiv").querySelectorAll("input[type=checkbox]");
        const MdLength = document
            .getElementById("scrollDiv")
            .querySelectorAll("input[type=checkbox]:checked#lvl4").length;
        const Wdata = document.getElementById("scrollDiv1").querySelectorAll("input[type=checkbox]");
        const WdLength = document
            .getElementById("scrollDiv1")
            .querySelectorAll("input[type=checkbox]:checked#lvl4").length;
        if (MdLength + WdLength > maxFileLength) {
            setSelectCheck(false);
            alert(`${maxFileLength}개수를 넘었습니다.\n선택된 개수 (${MdLength + WdLength})`);
        } else {
            let arr = [];
            if (loadData.id) {
                const Mdata2 = document
                    .getElementById("scrollDiv")
                    .querySelectorAll("input[type=checkbox]:checked:not(:disabled)");
                const Wdata2 = document
                    .getElementById("scrollDiv1")
                    .querySelectorAll("input[type=checkbox]:checked:not(:disabled)");
                if ([...Mdata2].concat([...Wdata2]).length > 0) {
                    arr = await addFile3({ Mdata: Mdata, Wdata: Wdata });
                    setSearchCopy(arr);
                    setLoadingShow(false);
                } else {
                    await axios
                        .post(`${server}/api/fileStorageMenu/searchCopy`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            weightid: loadData.id,
                        })
                        .then((res) => {
                            setSearchCopy(dataJSON({ res: res }));
                            setLoadingShow(false);
                        });
                }
            } else {
                arr = await addFile3({ Mdata: Mdata, Wdata: Wdata });
                setSearchCopy(arr);
                if (!switchBtnTF) setLoadingShow(false);
            }
        }
    };

    // 전선이 아닌데이터 초록색 글씨 변경
    const notCable = async () => {
        const lvl4Va = document.getElementById("scrollDiv").querySelectorAll("input#lvl4");
        const lvl4Va1 = document.getElementById("scrollDiv1").querySelectorAll("input#lvl4");
        const lvl4Va2 = document.getElementById("scrollDiv2").querySelectorAll("input#lvl4");
        const va = document.getElementById("scrollDiv").querySelectorAll("label.accordion");
        const va1 = document.getElementById("scrollDiv1").querySelectorAll("label.accordion");
        [...va, ...va1].forEach((data) => {
            data.style.color = "#000";
        });
        // 초록색 글씨로 변경
        [...lvl4Va, ...lvl4Va1].forEach((data) => {
            const labelnm = data.className.replace("category", "label");
            const reLabelnm = labelnm.split("-");
            if (JSON.parse(data.value).cableYN === "N") {
                reLabelnm.reduce((a, b, i) => {
                    if (i >= 2) {
                        document.getElementById(a + "-" + b).style.color = "#2ca718";
                    }
                    if (i === 0) {
                        return (a = b);
                    } else {
                        return (a = a + "-" + b);
                    }
                }, "");
            }
        });
    };

    useEffect(() => {
        if (Object.keys(searchM).length > 0 && Object.keys(searchW).length > 0) {
            notCable();
        }
    }, [searchM, searchW]);

    // json 경로가 없으면 빨간색으로 변경
    const notJson = async () => {
        const lvl4Va2 = document.getElementById("scrollDiv2").querySelectorAll("input#lvl4");
        const va = document.getElementById("scrollDiv2").querySelectorAll("label.accordion");
        // 검정색 글씨로 초기화
        va.forEach((data) => {
            data.style.color = "#000";
        });
        // 빨간색 글씨로 변경
        lvl4Va2.forEach((data) => {
            const labelnm = data.className.replace("category", "label");
            const reLabelnm = labelnm.split("-");
            if (!JSON.parse(data.value).jsonpath) {
                reLabelnm.reduce((a, b, i) => {
                    if (i >= 2) {
                        document.getElementById(a + "-" + b).style.color = "#D11F37";
                    }
                    if (i === 0) {
                        return (a = b);
                    } else {
                        return (a = a + "-" + b);
                    }
                }, "");
            }
        });
    };

    const imgInfo2 = ({ searchData }) => {
        let imgPath = [];
        let imgName = [];
        let jsonPath = [];
        let confscore = [];
        let classid = [];
        let firetypecd = [];
        let type12score = [];
        let type3score = [];
        let copytm = [];
        let mode = [];
        Object.keys(searchData).forEach((data) => {
            Object.keys(searchData[data]).forEach((data2) => {
                if (data2 !== "data") {
                    Object.keys(searchData[data][data2]).forEach((data3) => {
                        if (data3 !== "data") {
                            Object.keys(searchData[data][data2][data3]).forEach((data4) => {
                                if (data4 !== "data") {
                                    const dataVal = searchData[data][data2][data3][data4]["data"];
                                    imgPath.push(dataVal.imgresultpath);
                                    imgName.push(dataVal.specimenid + "_" + dataVal.pseq + "_" + dataVal.seq);
                                    jsonPath.push(dataVal.jsonpath);
                                    confscore.push(dataVal.confscore);
                                    classid.push(dataVal.classid);
                                    firetypecd.push(dataVal.firetypecd);
                                    type12score.push(dataVal.type12score);
                                    type3score.push(dataVal.type3score);
                                    copytm.push(cmmchangeFormat(dataVal.createtm, "yyyy-MM-DD HH:mm:ss"));
                                    mode.push(dataVal.mode);
                                }
                            });
                        }
                    });
                }
            });
        });
        return { imgPath, imgName, jsonPath, confscore, classid, firetypecd, type12score, type3score, copytm, mode };
    };

    const loadData2 = (weightid) => {
        axios
            .post(`${server}/api/fileStorageMenu/searchWeight2`, {
                sessionid: sessionStorage.getItem("SESSION"),
                weightid: weightid,
            })
            .then((res) => {
                setLoadData({ data: res.data, id: weightid, filename: res.filename });
            })
            .catch((err) => {});
    };

    // 데이터 저장
    const saveFile = () => {
        const scrollDiv2 = document.getElementById("scrollDiv2");
        if (scrollDiv2.children.length) {
            const {
                imgPath,
                imgName,
                jsonPath,
                confscore,
                classid,
                firetypecd,
                type12score,
                type3score,
                copytm,
                mode,
            } = imgInfo2({
                searchData: searchCopy,
            });
            axios
                .post(`${server}/api/fileStorageMenu/saveFile`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    ID,
                    weightid: loadData.id,
                    imgPath,
                    imgName,
                    jsonPath,
                    confscore,
                    classid,
                    firetypecd,
                    type12score,
                    type3score,
                    copytm,
                    mode,
                })
                .then((res) => {
                    loadData2(res.data);
                    setBtnTypeMode("save");
                    setAlertMessage("S205");
                })
                .catch((err) => {
                    setAlertMessage("E205");
                });
        } else {
            alert("저장할 데이터가 없습니다.");
        }
    };

    // 선택된데이터 생성버튼 누를시 작동
    const createFile = () => {
        const scrollDiv2 = document.getElementById("scrollDiv2");
        if (scrollDiv2.children.length) {
            const {
                imgPath,
                imgName,
                jsonPath,
                confscore,
                classid,
                firetypecd,
                type12score,
                type3score,
                copytm,
                mode,
            } = imgInfo2({
                searchData: searchCopy,
            });
            axios
                .post(`${server}/api/fileStorageMenu/createFile`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    ID,
                    imgPath,
                    imgName,
                    jsonPath,
                    confscore,
                    classid,
                    firetypecd,
                    type12score,
                    type3score,
                    copytm,
                    mode,
                })
                .then(async (res) => {
                    setBtnTypeMode("create");
                    await axios
                        .post(`${server}/api/fileStorageMenu/weightInfo`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                        })
                        .then((res) => {
                            setWeightInfoData(res.data);
                        });
                    loadData2(res.data);
                })
                .catch((err) => {
                    setAlertMessage("E204");
                });
        } else {
            alert("저장할 데이터가 없습니다.");
            setLoadingShow(false);
        }
    };

    // 복제
    const copyFile = () => {
        if (loadData.id) {
            setAlertMessage("Q201").then((res) => {
                if (res) {
                    axios
                        .post(`${server}/api/fileStorageMenu/copyFile`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            ID,
                            weightid: loadData.id,
                        })
                        .then((res) => {
                            setAlertMessage("I012").then(async () => {
                                await axios
                                    .post(`${server}/api/fileStorageMenu/weightInfo`, {
                                        sessionid: sessionStorage.getItem("SESSION"),
                                    })
                                    .then((res) => {
                                        setWeightInfoData(res.data);
                                    });
                                loadData2(res.data);
                            });
                        })
                        .catch((err) => {
                            setAlertMessage("E201");
                        });
                } else {
                    setAlertMessage("I009");
                }
            });
        }
    };

    // 체크된것들 전부 해제
    const resetCheckBox = () => {
        const checkedData = [
            ...document.getElementById("scrollDiv").querySelectorAll(`input[type=checkbox]:checked`),
        ].concat([...document.getElementById("scrollDiv1").querySelectorAll(`input[type=checkbox]:checked`)]);
        checkedData.forEach((data) => {
            data.checked = false;
        });
    };

    // type에 따라 글씨체 bold/normal 설정
    const boldReplace = ({ type }) => {
        switch (type) {
            case "set":
                const lvl4Val = document.getElementById("scrollDiv").querySelectorAll("input#lvl4:checked");
                const lvl4Val1 = document.getElementById("scrollDiv1").querySelectorAll("input#lvl4:checked");
                [...lvl4Val].concat([...lvl4Val1]).forEach((data) => {
                    const labelnm = data.className.replace("category", "label");
                    const reLabelnm = labelnm.split("-");
                    reLabelnm.reduce((a, b, i) => {
                        if (i >= 2) {
                            document.getElementById(a + "-" + b).style.fontWeight = "bold";
                        }
                        if (i === 0) {
                            return (a = b);
                        } else {
                            return (a = a + "-" + b);
                        }
                    }, "");
                });
                break;

            case "remove":
                const notlvl4Val = document.getElementById("scrollDiv").querySelectorAll("input#lvl4:not(:checked)");
                const notlvl4Val1 = document.getElementById("scrollDiv1").querySelectorAll("input#lvl4:not(:checked)");
                [...notlvl4Val].concat([...notlvl4Val1]).forEach((data) => {
                    const labelnm = data.className.replace("category", "label");
                    const reLabelnm = labelnm.split("-");
                    reLabelnm.reduce((a, b, i) => {
                        if (i >= 2) {
                            document.getElementById(a + "-" + b).style.fontWeight = "normal";
                        }
                        if (i === 0) {
                            return (a = b);
                        } else {
                            return (a = a + "-" + b);
                        }
                    }, "");
                });
                break;

            default:
                break;
        }
    };

    // 학습 버튼 눌렀을때 학습서버에 weightid 넘기기
    const learning = () => {
        const weightid = loadData.id;

        axios
            .post(`${server}/api/fileStorageMenu/jsonTF`, {
                sessionid: sessionStorage.getItem("SESSION"),
                weightid: weightid,
            })
            .then((res) => {
                if (res.data[0].jsonpath) {
                    const lvl4Val = document.getElementById("scrollDiv2").querySelectorAll("input[type=checkbox]#lvl4");
                    // let arr = [];
                    const fireType9 = [...lvl4Val].filter((input) => {
                        const data = JSON.parse(input.value);
                        // if (data.firetypecd === "9") arr.push(`${data.specimenid}-${data.pseq}-${data.seq}`);
                        return data.firetypecd === "9";
                    });
                    // setAlertMessage("Q202", `<br>(${arr.join(", ")})`, null, null, fireType9.length).then((res) => {
                    setAlertMessage("Q202", ``, null, null, fireType9.length).then((res) => {
                        if (res) {
                            axios
                                .post(`${server}/api/fileStorageMenu/train`, {
                                    sessionid: sessionStorage.getItem("SESSION"),
                                    weightid,
                                })
                                .then((res) => {
                                    if (res) {
                                        setAlertMessage("I006");
                                    }
                                });
                        } else {
                            setAlertMessage("I009");
                        }
                    });
                } else {
                    setAlertMessage("E911");
                }
            });
    };

    // 학습 중지 버튼 눌렀을때 학습서버에 weightid 넘기기
    const learningStop = () => {
        const weightid = loadData.id;
        setAlertMessage("Q704").then((res) => {
            if (res) {
                axios.post(`${server}/api/fileStorageMenu/learningStop`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    weightid: weightid,
                });
            } else {
                setAlertMessage("I009");
            }
        });
    };

    // 자동라벨링 버튼 눌렀을때 라벨링후 새로고침
    const autoLabeling = () => {
        const weightid = loadData.id;

        axios
            .post(`${server}/api/fileStorageMenu/autolabel`, {
                sessionid: sessionStorage.getItem("SESSION"),
                weightid: weightid,
            })
            .then(async (res) => {
                await axios
                    .post(`${server}/api/fileStorageMenu/searchCopy`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        weightid: loadData.id,
                    })
                    .then((res) => {
                        setSearchCopy(dataJSON({ res: res }));
                    });
                if (imgTarget) imgTarget.click();
                loadData2(weightid);
                await notJson();
                setBtnTypeMode(null);
                setLoadingShow(false);
                await setAlertMessage("S207");
            });
    };

    // 파일추가 버튼 눌렀을때 모달창 띄우기
    const imgAdd = () => {
        setAddImgModal(true);
    };

    // 사용중인 이미지가 있는지 확인후 삭제 (개별)
    const imgDelete = (content) => {
        const [specimenid, pseq, seq] = content.split("_");
        setAlertMessage("Q002").then((res) => {
            if (res) {
                axios
                    .post(`${server}/api/fileStorageMenu/imgDelete`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        specimenid,
                        pseq,
                        seq,
                    })
                    .then(async (res) => {
                        if (res.data[0]) {
                            await setAlertMessage("E772", `<br>(weight ID : ${res.data.map((data) => data.weightid)})`);
                        } else {
                            setStart((val) => !val);
                            setAlertMessage("I003");
                        }
                    });
            } else {
                setAlertMessage("I009");
            }
        });
    };

    // 사용중인 이미지가 있는지 확인후 삭제 (specimenid별)
    const imgDelete2 = (content) => {
        const specimenid = content.split(" ")[0];
        setAlertMessage("Q002").then((res) => {
            if (res) {
                axios
                    .post(`${server}/api/fileStorageMenu/imgDelete2`, {
                        sessionid: sessionStorage.getItem("SESSION"),
                        specimenid,
                    })
                    .then(async (res) => {
                        if (res.data[0]) {
                            await setAlertMessage(
                                "E772",
                                `<br>(weight ID : ${[...new Set(res.data.map((data) => data.weightid))]})`
                            );
                        } else {
                            setStart((val) => !val);
                            setAlertMessage("I003");
                        }
                    });
            } else {
                setAlertMessage("I009");
            }
        });
    };

    useEffect(() => {
        if (searchCopy && loadData.id) {
            let lvl1Name = Object.keys(searchCopy);
            let lvl2Name = [];
            let lvl3Name = [];
            let lvl4Name = [];
            Object.keys(searchCopy).forEach((data) => {
                Object.keys(searchCopy[data]).forEach((data2) => {
                    if (data2 !== "data") {
                        lvl2Name.push(data2);
                        Object.keys(searchCopy[data][data2]).forEach((data3) => {
                            if (data3 !== "data") {
                                lvl3Name.push(data3);
                                Object.keys(searchCopy[data][data2][data3]).forEach((data4) => {
                                    if (data4 !== "data") {
                                        lvl4Name.push(data4);
                                    }
                                });
                            }
                        });
                    }
                });
            });
            lvl4Name.forEach((data) => {
                const lvl4Checkbox = document.getElementsByName(data.replaceAll("_", "-"))[0];
                if (lvl4Checkbox) lvl4Checkbox.checked = true;
            });
            lvl3Name.forEach((data) => {
                const lvl3Checkbox = document.getElementsByName(data.replaceAll("_", "-"))[0];
                if (lvl3Checkbox) {
                    const scrollDivNode = document
                        .getElementById("scrollDiv")
                        .querySelectorAll(`[class^='${lvl3Checkbox.className}-']#lvl4`);
                    const scrollDiv1Node = document
                        .getElementById("scrollDiv1")
                        .querySelectorAll(`[class^='${lvl3Checkbox.className}-']#lvl4`);
                    let checkedTF = [];
                    [...scrollDivNode].concat([...scrollDiv1Node]).forEach((data) => {
                        checkedTF.push(data.checked);
                    });
                    if ([...new Set(checkedTF)][0] === true && [...new Set(checkedTF)].length === 1)
                        lvl3Checkbox.checked = true;
                }
            });
            lvl2Name.forEach((data) => {
                const lvl2Checkbox = document.getElementsByName(data)[0];
                if (lvl2Checkbox) {
                    const scrollDivNode = document
                        .getElementById("scrollDiv")
                        .querySelectorAll(`[class^='${lvl2Checkbox.className}-']#lvl3`);
                    const scrollDiv1Node = document
                        .getElementById("scrollDiv1")
                        .querySelectorAll(`[class^='${lvl2Checkbox.className}-']#lvl3`);
                    let checkedTF = [];
                    [...scrollDivNode].concat([...scrollDiv1Node]).forEach((data) => {
                        checkedTF.push(data.checked);
                    });
                    if ([...new Set(checkedTF)][0] === true && [...new Set(checkedTF)].length === 1)
                        lvl2Checkbox.checked = true;
                }
            });
            lvl1Name.forEach((data) => {
                const lvl1Checkbox = document.getElementsByName(data)[0];
                if (lvl1Checkbox) {
                    const scrollDivNode = document
                        .getElementById("scrollDiv")
                        .querySelectorAll(`[class^='${lvl1Checkbox.className}-']#lvl2`);
                    const scrollDiv1Node = document
                        .getElementById("scrollDiv1")
                        .querySelectorAll(`[class^='${lvl1Checkbox.className}-']#lvl2`);
                    let checkedTF = [];
                    [...scrollDivNode].concat([...scrollDiv1Node]).forEach((data) => {
                        checkedTF.push(data.checked);
                    });
                    if ([...new Set(checkedTF)][0] === true && [...new Set(checkedTF)].length === 1)
                        lvl1Checkbox.checked = true;
                }
            });
        }
    }, [searchCopy]);

    useEffect(async () => {
        if (weightItem[0]) {
            const scrollDiv = document.getElementById("scrollDiv");
            const scrollDiv1 = document.getElementById("scrollDiv1");
            if (btnTypeMode === "load") {
                const disabledData = [...scrollDiv.querySelectorAll(`input[type=checkbox]:disabled`)].concat([
                    ...scrollDiv1.querySelectorAll(`input[type=checkbox]:disabled`),
                ]);
                disabledData.forEach((data) => {
                    data.disabled = false;
                });
                resetCheckBox();
                boldReplace({ type: "remove" });
                await addFile2();
                const checkedData = [...scrollDiv.querySelectorAll(`input[type=checkbox]:checked`)].concat([
                    ...scrollDiv1.querySelectorAll(`input[type=checkbox]:checked`),
                ]);
                checkedData.forEach((data) => {
                    data.disabled = true;
                });
                boldReplace({ type: "set" });
                notJson();
                setLoadingShow(false);
                // 정상적 불러오기 메시지 표시
                await setAlertMessage("S206");
                // }
            } else if (btnTypeMode === "create") {
                const checkedData = [...scrollDiv.querySelectorAll(`input[type=checkbox]:checked`)].concat([
                    ...scrollDiv1.querySelectorAll(`input[type=checkbox]:checked`),
                ]);
                checkedData.forEach((data) => {
                    data.disabled = true;
                });
                boldReplace({ type: "set" });
                notJson();
                setLoadingShow(false);
                setAlertMessage("S204");
            }
        }
    }, [weightItem]);

    useEffect(() => {
        if (modalCheck) {
            notJson();
            openImg(openImgE, 1);
        }
    }, [modalCheck]);

    // 전체 데이터 보여줄지 전선만 보여줄지 스위치
    const switchBtn = async ({ checked, type }) => {
        setSwitchBtnTF(true);
        const scrollDiv = document.getElementById("scrollDiv");
        const scrollDiv1 = document.getElementById("scrollDiv1");
        const disabledData = [...scrollDiv.querySelectorAll(`input[type=checkbox]:disabled`)].concat([
            ...scrollDiv1.querySelectorAll(`input[type=checkbox]:disabled`),
        ]);
        disabledData.forEach((data) => {
            data.disabled = false;
        });
        resetCheckBox();
        boldReplace({ type: "remove" });
        if (type === "W" && checked) {
            webSearch2();
        } else {
            webSearch();
        }

        if (type === "M" && checked) {
            mobileSearch2();
        } else {
            mobileSearch();
        }

        setTimeout(async () => {
            await addFile2();
        }, 1000);

        const checkedData = [...scrollDiv.querySelectorAll(`input[type=checkbox]:checked`)].concat([
            ...scrollDiv1.querySelectorAll(`input[type=checkbox]:checked`),
        ]);
        checkedData.forEach((data) => {
            data.disabled = true;
        });
        boldReplace({ type: "set" });
        setLoadingShow(false);
    };

    return (
        <>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <div style={{ height: 800 + "px" }}>
                <div
                    style={{
                        width: "100%",
                        border: "1px solid rgba(33,33,33,0.5)",
                        height: "10%",
                        padding: "10px",
                    }}
                >
                    <div style={{ display: "none" }}>
                        <DatePickerTwo setDate={setDate} date={date} margin="0" />
                    </div>
                    <div style={{ height: "100%" }}>
                        <div style={{ float: "left", marginTop: "10px" }}>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px", width: "110px" }}
                                onClick={() => {
                                    imgAdd();
                                }}
                            >
                                사진 추가
                            </Button>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px" }}
                                onClick={() => {
                                    setLoadingShow(true);
                                    setTimeout(() => {
                                        addFile2();
                                        setSelectCheck(true);
                                    }, 1);
                                }}
                                id="addFileBtn"
                            >
                                선택
                            </Button>

                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px" }}
                                disabled={loadData.id ? true : false}
                                onClick={() => {
                                    setLoadingShow(true);
                                    createFile();
                                    setSelectCheck(false);
                                }}
                            >
                                생성
                            </Button>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px" }}
                                onClick={() => copyFile()}
                                disabled={loadData.id ? false : true}
                            >
                                복제
                            </Button>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px", width: "90px" }}
                                onClick={() => {
                                    setBtnTypeMode("load");
                                    setLoadFileModal(true);
                                    setSelectCheck(false);
                                }}
                            >
                                불러오기
                            </Button>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px" }}
                                disabled={loadData.id ? false : true}
                                onClick={() => {
                                    saveFile();
                                    setSelectCheck(false);
                                }}
                            >
                                저장
                            </Button>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px", width: "110px" }}
                                disabled={loadData.id ? false : true}
                                onClick={() => {
                                    autoLabeling();
                                    setLoadingShow(true);
                                }}
                            >
                                배경 식별
                            </Button>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px" }}
                                disabled={
                                    loadData.id
                                        ? weightInfoData.filter((data) => data.trainingYN === "Y").length === 0
                                            ? weightInfoData.filter((data) => loadData.id === data.weightid)[0]
                                                  ?.accuracy
                                                ? true
                                                : false
                                            : true
                                        : true
                                }
                                onClick={() => {
                                    learning();
                                }}
                            >
                                학습
                            </Button>
                            <Button
                                className="btn-add"
                                variant="secondary"
                                style={{ margin: "0px 6px" }}
                                disabled={
                                    weightInfoData.filter((data) => data.trainingYN === "Y").length === 0 ? true : false
                                }
                                onClick={() => {
                                    learningStop();
                                }}
                            >
                                중지
                            </Button>
                            <label style={{ marginLeft: "50px" }}>
                                <span style={{ fontSize: "22px" }}>
                                    <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" type="info">
                                        <label>
                                            - 전선이 아니라고 판단된 데이터는{" "}
                                            <span style={{ color: "#37d11f" }}>초록색</span> 으로 표시됩니다.
                                        </label>
                                        <br />
                                        <label>
                                            - 라벨이 없는 데이터는 <span style={{ color: "red" }}>빨간색</span> 으로
                                            표시됩니다.
                                        </label>
                                        <br />
                                        <label>
                                            - 체크한 데이터는 <span style={{ fontWeight: "bold" }}>굵게</span>{" "}
                                            표시됩니다.
                                        </label>
                                        <br />
                                        <label>
                                            - 마지막으로 누른 데이터는{" "}
                                            <span style={{ textDecoration: "underline" }}>밑줄</span> 로 표시됩니다.
                                        </label>
                                    </ReactTooltip>
                                </span>
                            </label>
                            <label style={{ marginLeft: "30px" }}>
                                <p style={{ color: "#888", fontSize: "16px" }}>
                                    Weight ID :
                                    {loadData.data
                                        ? loadData.data[0]?.specimenid
                                            ? loadData.data[0]?.filename
                                            : "미선택"
                                        : loadData.type === "pass"
                                        ? loadData.data[0]?.filename
                                        : "미선택"}
                                </p>
                            </label>
                            <label style={{ marginLeft: "60px" }}>
                                <b style={{ color: "#888", fontSize: "16px" }}>
                                    학습 :
                                    {weightInfoData[0]
                                        ? weightInfoData.filter((data) => data.trainingYN === "Y")[0]
                                            ? " 진행중 (ID " +
                                              weightInfoData.filter((data) => data.trainingYN === "Y")[0].weightid +
                                              ")"
                                            : " 대기중"
                                        : " 대기중"}
                                </b>
                            </label>
                            <label style={{ width: "200px", marginLeft: "50px" }}>
                                <ProgressBar animated now={progress} variant="success" label={`${progress}%`} />
                            </label>
                        </div>
                    </div>
                </div>
                <div style={{ float: "left", width: "60%", height: "100%" }}>
                    <div style={{ height: "90%", width: 100 / 3 + "%", float: "left" }}>
                        <div
                            style={{
                                height: "7%",
                                borderBottom: "1px solid rgba(33,33,33,0.5)",
                                borderLeft: "1px solid rgba(33,33,33,0.5)",
                            }}
                        >
                            <h5 style={{ marginBottom: "15px", padding: "7px" }}>
                                <Switch
                                    onChange={(e) => {
                                        setLoadingShow(true);
                                        setTimeout(() => {
                                            switchBtn({ checked: e.target.checked, type: "M" });
                                        }, 1);
                                    }}
                                    data-tip
                                    data-for="registerTip2"
                                />
                                ◎ USB 카메라 파일 - ({" "}
                                {document.getElementById("scrollDiv")
                                    ? document.getElementById("scrollDiv").querySelectorAll("input[type=checkbox]#lvl4")
                                          .length
                                    : 0}{" "}
                                )
                            </h5>
                        </div>
                        <div
                            style={{
                                border: "1px solid rgba(33,33,33,0.5)",
                                overflow: "auto",
                                height: "93%",
                                borderTop: "0px",
                                borderRight: "0px",
                            }}
                            id="scrollDiv"
                            className="scrollDiv"
                        >
                            {useMemo(() => fileStorage4({ searchData: searchM, mode: "M" }), [searchM])}
                        </div>
                    </div>
                    <div style={{ height: "90%", width: 100 / 3 + "%", float: "left" }}>
                        <div
                            style={{
                                height: "7%",
                                borderBottom: "1px solid rgba(33,33,33,0.5)",
                                borderLeft: "1px solid rgba(33,33,33,0.5)",
                            }}
                        >
                            <h5 style={{ marginBottom: "15px", padding: "7px" }}>
                                <Switch
                                    onChange={(e) => {
                                        setLoadingShow(true);
                                        setTimeout(() => {
                                            switchBtn({ checked: e.target.checked, type: "W" });
                                        }, 1);
                                    }}
                                    data-tip
                                    data-for="registerTip2"
                                />
                                ◎ 추가 업로드 파일 - ({" "}
                                {document.getElementById("scrollDiv1")
                                    ? document
                                          .getElementById("scrollDiv1")
                                          .querySelectorAll("input[type=checkbox]#lvl4").length
                                    : 0}{" "}
                                )
                            </h5>
                        </div>
                        <div
                            style={{
                                border: "1px solid rgba(33,33,33,0.5)",
                                overflow: "auto",
                                height: "93%",
                                borderTop: "0px",
                                borderRight: "0px",
                                padding: "5px",
                            }}
                            id="scrollDiv1"
                            className="scrollDiv"
                        >
                            {useMemo(() => fileStorage4({ searchData: searchW, mode: "W" }), [searchW])}
                        </div>
                    </div>
                    <div style={{ height: "90%", width: 100 / 3 + "%", float: "left" }}>
                        <div
                            style={{
                                height: "7%",
                                borderBottom: "1px solid rgba(33,33,33,0.5)",
                                borderLeft: "1px solid rgba(33,33,33,0.5)",
                                borderRight: "1px solid rgba(33,33,33,0.5)",
                            }}
                        >
                            <h5 style={{ marginBottom: "15px", padding: "10px" }}>
                                ◎ 학습 대상 파일 - ({" "}
                                {document.getElementById("scrollDiv2")
                                    ? document
                                          .getElementById("scrollDiv2")
                                          .querySelectorAll("input[type=checkbox]#lvl4:checked").length
                                    : 0}{" "}
                                / {maxFileLength} )
                            </h5>
                        </div>
                        <div
                            style={{
                                border: "1px solid rgba(33,33,33,0.5)",
                                overflow: "auto",
                                height: document.getElementById("scrollDiv")
                                    ? document.getElementById("scrollDiv").offsetHeight + "px"
                                    : null,
                                width: document.getElementById("scrollDiv")
                                    ? document.getElementById("scrollDiv").offsetWidth + "px"
                                    : null,
                                borderTop: "0px",
                                order: "-1",
                                position: "absolute",
                            }}
                            id="scrollDiv2"
                            className="scrollDiv"
                        >
                            {useMemo(() => fileStorage4({ searchData: searchCopy, mode: "COPY" }), [searchCopy])}
                        </div>
                        <div>
                            <div
                                style={{
                                    position: "absolute",
                                    height: document.getElementById("scrollDiv")
                                        ? document.getElementById("scrollDiv").offsetHeight + "px"
                                        : null,
                                    width: document.getElementById("scrollDiv")
                                        ? document.getElementById("scrollDiv").offsetWidth + "px"
                                        : null,
                                    verticalAlign: "middle",
                                    opacity: "0.5",
                                    backgroundColor: "#eee",
                                    border: "1px solid rgba(33,33,33,0.25)",
                                    display: selectCheck ? "block" : "none",
                                }}
                            >
                                <label
                                    style={{
                                        verticalAlign: "baseline",
                                        height: "100%",
                                        width: "100%",
                                        textAlign: "center",
                                        lineHeight: document.getElementById("scrollDiv")
                                            ? document.getElementById("scrollDiv").offsetHeight + "px"
                                            : null,
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    생성 또는 저장버튼을 눌러주세요.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "100%", width: "40%", float: "left" }}>
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid rgba(33,33,33,0.5)",
                            float: "left",
                            height: "80%",
                            borderLeft: "0px",
                            borderBottom: "0px",
                            borderTop: "0px",
                        }}
                        id="scrollDiv3-content"
                        className="scrollDiv"
                    >
                        <div style={{ width: "90%", height: "80%", marginLeft: "5%", marginTop: "5%" }}>
                            <canvas
                                ref={canvasReadRef2}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid black",
                                }}
                                tabIndex="1"
                            />
                        </div>
                        {lastResult ? (
                            <p style={{ marginLeft: "5%" }}>
                                {lastResult ? (
                                    <>
                                        <label style={{ margin: "20px 25px 0px 2%" }}>
                                            열흔 : {lastResult.type3score ? lastResult.type3score.toFixed(5) : 0}%
                                        </label>
                                        <label>
                                            단락흔 : {lastResult.type12score ? lastResult.type12score.toFixed(5) : 0}%
                                        </label>
                                    </>
                                ) : null}
                                <label style={{ margin: "0px 15px 0px 15px" }}>|</label>
                                <b>
                                    <label>
                                        화재 유형 :{" "}
                                        {lastResult.firetypecd === "12"
                                            ? "단락흔"
                                            : lastResult.firetypecd === "3"
                                            ? "열흔"
                                            : "판별불가"}
                                    </label>
                                </b>
                            </p>
                        ) : null}
                    </div>
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid rgba(33,33,33,0.5)",
                            float: "left",
                            height: "10%",
                            borderLeft: "0px",
                        }}
                        id="scrollDiv3-footer"
                        className="scrollDiv"
                    >
                        <Button
                            className="btn-search"
                            variant="secondary"
                            style={{ margin: "15px 50px 15px 15px", float: "right", width: "110px" }}
                            disabled={updateBtn}
                            onClick={() => setLabelToolModal(true)}
                        >
                            라벨 수정
                        </Button>
                        <Button
                            className="btn-search"
                            variant="secondary"
                            style={{ margin: "15px 50px 15px 30px", float: "right", width: "140px" }}
                            disabled={updateBtn}
                            onClick={() => setFireTypeChangeModal(true)}
                        >
                            화재 유형 변경
                        </Button>
                    </div>
                    <div>
                        <ReactTooltip id="registerTip2" place="right" effect="solid" type="info">
                            <label>
                                - 전선이 아니라고 판단된 데이터를 안보이게 선택합니다.
                                <br /> (default: 보임)
                            </label>
                        </ReactTooltip>
                        {labelToolModal ? (
                            <LabelTool
                                labelToolModal={labelToolModal}
                                setLabelToolModal={setLabelToolModal}
                                data={lastResult}
                                loadData={loadData}
                                weightid={loadData.id}
                                setModalCheck={setModalCheck}
                                imgTarget={imgTarget}
                                dataJSON={dataJSON}
                                setSearchCopy={setSearchCopy}
                            />
                        ) : null}
                        {loadFileModal ? (
                            <LoadFile
                                show={loadFileModal}
                                setShow={setLoadFileModal}
                                setLoadData={setLoadData}
                                setLoadingShow={setLoadingShow}
                            />
                        ) : null}
                        <FireTypeChange
                            show={fireTypeChangeModal}
                            setShow={setFireTypeChangeModal}
                            loadData={loadData}
                            data={lastResult}
                            setStart={setStart}
                            imgTarget={imgTarget}
                            dataJSON={dataJSON}
                            setSearchCopy={setSearchCopy}
                        />
                        <LoadingModal show={loadingShow} />
                        <AddImg
                            show={addImgModal}
                            setShow={setAddImgModal}
                            setLoadingShow={setLoadingShow}
                            setStart={setStart}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FileStorageMenu;
