import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const PageBtn3 = (props) => {
    //조회 결과 page 수 조절 변수
    const [minpage, setMinpage] = useState(0);
    const [maxPage, setMaxPage] = useState(50);
    const [countPage, setCountPage] = useState(0);

    // 조회 버튼 눌렀을시 페이지 리셋
    useEffect(() => {
        setMinpage(0);
        setMaxPage(50);
        props.setPageUser(1);
        setCountPage(0);
    }, [props.btnRefreshUser]);

    return (
        <div className="page-index">
            <div className="page-index-wrap">
                {minpage > 0 ? (
                    <Button
                        className="page-index-wrap-btn"
                        onClick={() => {
                            setMinpage(minpage - 50);
                            setMaxPage(minpage);
                            setCountPage(countPage - 1);
                            props.setPageUser((minpage / 50 - 1) * 10 + 10);
                        }}
                        style={{ backgroundColor: "lightgrey", color: "black" }}
                    >
                        {"<"}
                    </Button>
                ) : null}

                {
                    //page index button
                    props.dataUser
                        ? props.dataUser.slice(minpage, maxPage).map((data, index) => {
                              return (
                                  //순번을 나눈 결과가 정수일 경우에만 실행 0(true) 0.1(false) 0.2 0.3 0.4 ... 1(10번째)
                                  Number.isInteger(index / props.countUser) ? (
                                      <Button
                                          className="page-index-wrap-btn"
                                          variant="outline-grey"
                                          onClick={() => {
                                              props.setPageUser(index / props.countUser + 1 + 10 * countPage);
                                          }}
                                          style={
                                              index / props.countUser + 10 * countPage === props.pageUser - 1
                                                  ? {
                                                        backgroundColor: "rgb(68, 67, 67)",
                                                        color: "white",
                                                    }
                                                  : null
                                          }
                                          key={index}
                                      >
                                          {index / props.countUser + 1 + 10 * countPage}
                                      </Button>
                                  ) : null
                              );
                          })
                        : null
                }
                {maxPage < props.dataUser.length ? (
                    <Button
                        className="page-index-wrap-btn"
                        onClick={() => {
                            setMinpage(maxPage);
                            setMaxPage(maxPage + 50);
                            setCountPage(countPage + 1);
                            props.setPageUser(maxPage / 5 + 1);
                        }}
                        style={{ backgroundColor: "lightgrey", color: "black" }}
                    >
                        {">"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default PageBtn3;
