import React, { useEffect } from "react";
//시스템 관리자 계정 신청 관리
//Moudle
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import cmmGetUser from "../../../cmm/cmmGetUser";

//반려의견 작성
function RevokeMsg(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    let [revoke, setRevoke] = useState("");

    const { handleSubmit, reset } = useForm();

    const onChangeHanler = (e) => {
        setRevoke(e.target.value);
    };

    const onSubmit = async (data) => {
        axios.post(`${server}/api/userApply/revoke`, {
            sessionid: sessionStorage.getItem("SESSION"),
            revokemsg: revoke,
            handlerid: ID,
            userid: props.updateUserData.userid,
        });
        refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
        props.setShow(false);
    };
    return (
        <Modal
            {...props}
            show={props.show}
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            {" "}
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 반려 의견 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <div style={{ width: "500px", height: "600px", marginLeft: "45px", marginBottom: "-40px" }}>
                                <textarea id="revoke" onChange={onChangeHanler} maxLength="500"></textarea>
                            </div>

                            <div style={{ marginTop: "15px" }}>
                                <Button className="btn-positive" type="submit">
                                    반려
                                </Button>
                                <Button
                                    className="btn-negative"
                                    variant="secondary"
                                    onClick={() => {
                                        reset();
                                        props.setShow(false);
                                    }}
                                >
                                    취소
                                </Button>
                            </div>
                        </>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
}
export default RevokeMsg;
