import "../../css/userModal.css";
import "../../css/manage.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetUser from "../../cmm/cmmGetUser";
import PageBtn from "../../cmm/cmmPageBtn";
//게시판
function Bulletin(props) {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(() => {
        (async () => {
            setSessionData(await cmmGetUser());
        })();
    }, []);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    // 게시물 추가 모달창 true/false
    const addBulletinState = useSelector((state) => state.addBulletinModalReducer);

    // 게시판 조회결과 값
    const [bulletData, setBulletinData] = useState([]);

    const { register, watch, handleSubmit } = useForm();

    //조회 결과 page 수 조절 변수

    //pageNation
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;

    //현재날짜 불러오기
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    let date = today.getDate(); // 날짜
    date = parseInt(date) < 10 ? "0" + date : date;
    let current = year + "-" + month + "-" + date;
    let temp = new Date(year, month - 1, date);

    temp.setDate(temp.getDate() - 365);
    year = temp.getFullYear(); // 년도
    month = temp.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    date = temp.getDate(); // 날짜

    date = parseInt(date) < 10 ? "0" + date : date;
    let past = year + "-" + month + "-" + date;

    // 조회
    const onSubmit = async (data) => {
        axios
            .post(`${server}/api/bulletin/showListWeb`, {
                sessionid: sessionStorage.getItem("SESSION"),
                begindt: data.begindt,
                enddt: data.enddt,
                userid: ID,
                auth: AUTH,
                title: data.title,
                content: data.content,
            })
            .then((res) => {
                if (res.data[0]) {
                    //데이터 조회값이 있을 경우
                    setBulletinData(res.data);
                } else {
                    //데이터 조획값이 없을 경우
                    setAlertMessage("I004");
                }
            });
    };

    useEffect(() => {
        onSubmit(watch());
    }, [addBulletinState]);

    return (
        <>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox2">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <label className="label-search_trUp">제목</label>
                                </td>

                                <td>
                                    <input
                                        {...register("title", {})}
                                        type="text"
                                        className="style-input_up"
                                        maxLength={100}
                                    />
                                </td>
                                <td>
                                    <label className="label-search_trUp">본문</label>
                                </td>
                                <td>
                                    <input
                                        {...register("content", {})}
                                        type="text"
                                        className="style-input_up"
                                        maxLength={100}
                                    />
                                </td>

                                <td>
                                    <label className="label-search_trDown">등록일</label>
                                </td>
                                <td>
                                    <div style={{ display: "flex", marginTop: "-11px" }}>
                                        <input
                                            id="begindt"
                                            {...register("begindt", {})}
                                            type="date"
                                            //value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                            defaultValue={past}
                                            className="input-date1"
                                        />
                                        <p style={{ marginTop: "20px", marginLeft: "5px" }}> - </p>
                                        <input
                                            id="enddt"
                                            {...register("enddt", {})}
                                            type="date"
                                            // value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                            defaultValue={current}
                                            className="input-date2"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div align="right" style={{ marginRight: "10px" }}>
                    <Button
                        className="btn-cancel2-c2"
                        variant="secondary"
                        type="button"
                        onClick={() => {
                            window.location.replace("./bulletinAdd");
                        }}
                        style={{ width: "115px", marginTop: "10px", marginBottom: "20px" }}
                    >
                        추가(글쓰기)
                    </Button>
                    <Button
                        className="btn-cancel2-c1"
                        variant="secondary"
                        type="submit"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
            </form>
            <Table bordered hover style={{ overflow: "scroll" }}>
                <thead className="style-tHeader">
                    <tr>
                        <th>
                            <label>No.</label>
                        </th>
                        <th>
                            <label>제목</label>
                        </th>
                        <th>
                            <label>작성자</label>
                        </th>
                        <th style={{ width: "240px" }}>
                            <label>작성일</label>
                        </th>
                        <th>
                            <label>조회</label>
                        </th>
                    </tr>
                </thead>
                <tbody className="text-forum">
                    {bulletData
                        ? bulletData.slice(minpage, maxPage).map((data, index) => {
                              return (
                                  <tr
                                      key={index + minpage}
                                      style={
                                          data.noticealiveyn === "Y" ? { color: "#d11f37", fontstyle: "bold" } : null
                                      }
                                  >
                                      <td>{index + 1 + (page - 1) * 10}</td>
                                      <td style={{ textAlign: "left" }}>
                                          <Link
                                              to={{
                                                  pathname:
                                                      data.privateyn === "N"
                                                          ? "/bulletinContent"
                                                          : data.createid === ID || AUTH === "SU"
                                                          ? "/bulletinContent"
                                                          : "/bulletin",
                                                  state: { data: data },
                                              }}
                                              style={
                                                  data.noticealiveyn === "Y"
                                                      ? { color: "#d11f37", fontFamily: "NotoSansKR-Bold" }
                                                      : { color: "black", fontstyle: "bold" }
                                              }
                                          >
                                              <label style={{ cursor: "pointer", marginLeft: "10px" }}>
                                                  {data.privateyn === "Y" ? (
                                                      <>
                                                          <FontAwesomeIcon icon={faLock} />

                                                          <span
                                                              style={{ margin: `0px 0px 0px ${(data.lvl + 1) * 13}px` }}
                                                          >
                                                              {"\t" + data.title}
                                                          </span>
                                                      </>
                                                  ) : (
                                                      <span style={{ margin: `0px 0px 0px ${(data.lvl + 1) * 30}px` }}>
                                                          {"\t" + data.title}
                                                      </span>
                                                  )}
                                              </label>
                                          </Link>
                                      </td>
                                      <td>{data.createid}</td>
                                      <td>{data.createtm}</td>
                                      <td>{data.viewcnt}</td>
                                  </tr>
                              );
                          })
                        : null}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="13" style={{ borderBottom: "none" }}>
                            <PageBtn
                                className="btn-pageNumber"
                                data={bulletData ? bulletData : 0}
                                page={page}
                                setPage={setPage}
                                count={count}
                                btnRefresh={btnRefresh}
                            />
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
}

export default Bulletin;
