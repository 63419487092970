import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
//공통함수
import cmmchangeFormat from "../../cmm/cmmchangeFormat"; // 시간 포맷
import AppraisalOutput from "./ReviewModal/AppraisalOutput";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../css/slick.css";
import cmmGetUser from "../../cmm/cmmGetUser";
//감정서 조회
function ReviewReport({ location }) {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);

    const ID = sessionData ? sessionData.user : null;
    const EXPERT = sessionData ? sessionData.expertyn : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;

    if (document.getElementById("selectyn")) {
        if (sessionData ? sessionData.expertyn !== "Y" : false) {
            document.getElementById("selectyn").style.pointerEvents = "none";
            document.getElementById("selectyn").style.opacity = "0.6";
        }
    }
    /*=============*/

    const server = process.env.REACT_APP_API_SERVER_HOST;
    const [result, setResult] = useState(location.state.data.firetypecd2 ? location.state.data.firetypecd2 : "9");
    const [appraisalModal, setAppraisalModal] = useState(false);
    const [appraisalModalData, setAppraisalModalData] = useState(false);
    const [imgPath, setImgPath] = useState([]);
    const [cellphone, setCellphone] = useState();
    const [deptData, setDeptData] = useState([]);
    const [itemData, setItemData] = useState([]);

    useEffect(() => {
        axios
            .post(`${server}/api/reviewReport/cellphone`, {
                sessionid: sessionStorage.getItem("SESSION"),
                id: location.state.data.createid,
            })
            .then((res) => {
                setCellphone(res.data[0] ? res.data[0].cellphone : " ");
            });
        axios
            .post(`${server}/api/reviewReport/img`, {
                sessionid: sessionStorage.getItem("SESSION"),
                specimenid: location.state.data.specimenid,
            })
            .then((res) => {
                setImgPath(res.data);
            });
    }, [location, ID]);
    useEffect(() => {
        // itemnm 가져오기
        axios
            .post(`${server}/api/cmm/getItemName`, { sessionid: sessionStorage.getItem("SESSION") })
            .then((res) => {
                setItemData(res.data);
            })
            .catch((err) => {});

        axios
            .post(`${server}/api/cmm/getDeptName`, { sessionid: sessionStorage.getItem("SESSION") })
            .then((res) => {
                setDeptData(res.data);
            })
            .catch((err) => {});
    }, []);
    if (location.state) {
        const itemName = (item, type = null) => {
            let itemnm = "";
            if (type === "firetypecd2") {
                if (location.state.data.firetypecd2 != null) {
                    itemData.map((data) => {
                        if (data.itemcd === item) {
                            itemnm = data.itemnm;
                        }
                    });
                } else {
                    itemnm = "판별불가";
                }
            } else {
                itemData.map((data) => {
                    if (data.itemcd === item) {
                        itemnm = data.itemnm;
                    }
                });
            }
            return itemnm;
        };
        const deptName = (dept) => {
            let deptnm = "";

            deptData.map((data) => {
                if (data.deptcd === dept) {
                    deptnm = data.deptnm;
                }
            });

            return deptnm;
        };

        // 결과값
        const reviewResult = () => {
            let type = [{ value: "null", label: "null" }];
            axios
                .post(`${server}/api/reviewExpert/result`, { sessionid: sessionStorage.getItem("SESSION") })
                .then((response) => {
                    response.data.map((data) => {
                        type.unshift({
                            label: data.itemnm,
                            value: data.itemcd,
                        });
                    });
                    type.pop(); // 에러방지값 pop()
                })
                .catch((err) => {});
            return type;
        };

        const confirmed = (confirmyn) => {
            axios
                .post(`${server}/api/reviewReport/update`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    confirmyn,
                    result,
                    itemnm: itemName(result),
                    contents: document.getElementById("content").value,
                    userid: ID,
                    statuscd: "EY",
                    specimenid: location.state.data.specimenid,
                    addr1: location.state.data.addrtxt1,
                    addr2: location.state.data.addrtxt2,
                })
                .then((res) => {
                    window.location.replace("./reviewExpert");
                })
                .catch((err) => {});
        };

        const btnType = (statuscd) => {
            let result = [];
            if (statuscd === "EI") {
                result.push(
                    <Button
                        className="btn-search"
                        variant="secondary"
                        style={{ margin: "0px 15px 0px 0px", width: "93px" }}
                        onClick={() => confirmed(false)}
                        disabled={EXPERT != "Y" ? true : false}
                    >
                        임시저장
                    </Button>
                );
                result.push(
                    <Button
                        className="btn-add"
                        variant="secondary"
                        style={{ margin: "0px 15px 0px 0px" }}
                        onClick={() => confirmed(true)}
                        disabled={EXPERT != "Y" ? true : false}
                    >
                        확정
                    </Button>
                );
            }
            if (statuscd === "AY" || statuscd === "EY") {
                result.push(
                    <Button
                        className="btn-search"
                        variant="secondary"
                        onClick={() => {
                            setAppraisalModalData(location.state.data);
                            setAppraisalModal(true);
                        }}
                        style={{ margin: "0px 15px 0px 0px", width: "115px" }}
                    >
                        감정서 출력
                    </Button>
                );
            }
            return result;
        };

        const caseFIAIAXAY = () => {
            return (
                <>
                    <thead className="style-tHeader" style={{ height: "10%" }}>
                        <tr style={{ verticalAlign: "middle" }}>
                            <th>인공지능</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ verticalAlign: "middle" }}>
                            <td>{location.state.data.firetypecd1 ? itemName(location.state.data.firetypecd1) : "X"}</td>
                        </tr>
                    </tbody>
                </>
            );
        };
        const caseEI = () => {
            return (
                <>
                    <thead className="style-tHeader" style={{ height: "42px" }}>
                        <tr>
                            <th>인공지능</th>
                            <th>전문가</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: "90px" }}>
                        <tr>
                            <td>{location.state.data.firetypecd1 ? itemName(location.state.data.firetypecd1) : "X"}</td>
                            <td>
                                <Select
                                    options={reviewResult()}
                                    onChange={(e) => {
                                        setResult(e.value);
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                    id="selectyn"
                                    defaultValue={[
                                        {
                                            value: location.state.data.firetypecd2
                                                ? location.state.data.firetypecd2
                                                : "9",
                                            label:
                                                location.state.data.firetypecd2 === "3"
                                                    ? "열흔"
                                                    : location.state.data.firetypecd2 === "12"
                                                    ? "단락흔"
                                                    : "판별불가",
                                        },
                                    ]}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <thead className="style-tHeader" style={{ height: "42px" }}>
                        <tr>
                            <th colSpan="2">전문가의견</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: "100%" }}>
                        <tr>
                            <td colSpan="2">
                                <textarea
                                    rows="4"
                                    style={{ width: "100%", resize: "none" }}
                                    // onChange={(e) => {
                                    //     setContents(e.target.value);
                                    // }}
                                    id="content"
                                    defaultValue={location.state.data.cfmexpertop}
                                    disabled={EXPERT != "Y" ? true : false}
                                    maxLength="2000"
                                ></textarea>
                            </td>
                        </tr>
                    </tbody>
                </>
            );
        };
        const caseEY = () => {
            return (
                <>
                    <thead className="style-tHeader" style={{ height: "42px" }}>
                        <tr>
                            <th>인공지능</th>
                            <th>전문가</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: "90px" }}>
                        <tr>
                            <td>{location.state.data.firetypecd1 ? itemName(location.state.data.firetypecd1) : "X"}</td>
                            <td>
                                {location.state.data.firetypecd2
                                    ? itemName(location.state.data.firetypecd2, "firetypecd2")
                                    : "X"}
                            </td>
                        </tr>
                    </tbody>
                    <thead className="style-tHeader" style={{ height: "42px" }}>
                        <tr>
                            <th colSpan="2">전문가의견</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: "100%" }}>
                        <tr>
                            <td colSpan="2">
                                {location.state.data.cfmexpertop ? location.state.data.cfmexpertop : "X"}
                            </td>
                        </tr>
                    </tbody>
                </>
            );
        };

        const caseResult = () => {
            switch (location.state.data.statuscd) {
                case "FI":
                case "AI":
                case "AX":
                case "AY":
                // return caseFIAIAXAY();

                case "EY":
                    return caseEY();

                case "EI":
                    return caseEI();

                default:
                    break;
            }
        };

        const settings = {
            dots: true, // 캐러셀이미지가 몇번째인지 알려주는 점을 보여줄지 정한다.
            infinite: true, // loop를 만들지(마지막 이미지-처음 이미지-중간 이미지들-마지막 이미지)
            speed: 500, // 애미메이션의 속도, 단위는 milliseconds
            slidesToShow: 1, // 한번에 몇개의 슬라이드를 보여줄 지
            slidesToScroll: 1, // 한번 스크롤시 몇장의 슬라이드를 넘길지
            autoplay: true,
            autoplaySpeed: 5000, // 자동넘기기 시간
            pauseOnHover: true, // 마우스를 위에 올릴경우 자동넘기기 안됨
            centerMode: true,
            centerPadding: "0px", // 0px 하면 슬라이드 끝쪽 이미지가 안잘림
        };

        return (
            <>
                <p className="manage-title">감정서 조회</p>
                <hr />
                <h4>● 사건 정보</h4>
                <Table bordered style={{ tableLayout: "fixed" }}>
                    <tbody>
                        <tr style={{ verticalAlign: "middle" }}>
                            <th style={{ backgroundColor: "#eee", width: "10%", height: "50px" }}>화재시점</th>
                            <td>
                                {location.state
                                    ? cmmchangeFormat(location.state.data.createtm, "yy-MM-DD hh:mm:ss")
                                    : null}
                            </td>
                            <th style={{ backgroundColor: "#eee", width: "10%", height: "50px" }}>주소지</th>
                            <td>
                                {/* {`${addrName(location.state.data.addrgrp1)} ${addrName(location.state.data.addrgrp2)}`} */}
                                {location.state ? location.state.data.addrtxt1 : null}
                                <br></br>
                                {location.state ? location.state.data.addrtxt2 : null}
                            </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                            <th style={{ backgroundColor: "#eee", width: "10%", height: "50px" }}>요청자</th>
                            <td>
                                {location.state ? deptName(location.state.data.orgcd) : null}
                                <br />
                                {location.state ? location.state.data.createid : null}
                            </td>
                            <th style={{ backgroundColor: "#eee", width: "10%", height: "50px" }}>연락처</th>
                            <td>
                                {cellphone
                                    ? cellphone.substr(0, 3) +
                                      "-" +
                                      cellphone.substr(3, 4) +
                                      "-" +
                                      cellphone.substr(7, 4)
                                    : null}
                            </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                            <th style={{ backgroundColor: "#eee", width: "10%", height: "50px" }}>
                                <label>의뢰 의견</label>
                            </th>
                            <td colSpan="3">
                                <textarea rows="8" style={{ resize: "none"}} disabled maxLength="2000">
                                    {location.state ? 
                                        location.state.data.is_stamp !== null ? 
                                            location.state.data.is_stamp ? 
                                                `직인 삽입 요청 합니다.\n\n${location.state.data.reqop}` : `직인 삽입 요청 하지 않습니다.\n\n${location.state.data.reqop}` 
                                        : location.state.data.reqop : null}
                                </textarea>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {/* 버튼  */}
                <div style={{ display: "flex" }}>
                    <h4>● 판정 결과</h4>
                    <div style={{ order: "2", marginLeft: "auto" }} id="btn">
                        {btnType(location.state.data.statuscd)}

                        <Link to="./reviewExpert">
                            <Button className="btn-search" variant="secondary" style={{ width: "90px", margin: "0px" }}>
                                목록으로
                            </Button>
                        </Link>
                    </div>
                </div>
                <div style={{ display: "flex", margin: "20px 0px 20px 0px" }}>
                    <div style={{ width: "40%", height: "600px" }} id="tableDiv">
                        <Table bordered className="resultTable" height="600px" style={{ tableLayout: "fixed" }}>
                            {/* 상태코드에 따라 다른 화면 */}
                            {caseResult()}
                        </Table>
                    </div>
                    {location.state.data.statuscd === "FI" ? (
                        <>
                            <div
                                style={{
                                    position: "absolute",
                                    width: document.getElementsByTagName("section")[0].offsetWidth * 0.4 + "px",
                                    height: "600px",
                                    opacity: "0.8",
                                    backgroundColor: "#ddd",
                                    border: "1px solid black",
                                    order: "-1",
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    width: document.getElementsByTagName("section")[0].offsetWidth * 0.4 + "px",
                                    height: "600px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <label
                                    style={{
                                        verticalAlign: "baseline",
                                        height: "100%",
                                        width: "100%",
                                        textAlign: "center",
                                        lineHeight: "600px",
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                        // color: "#fff",
                                    }}
                                >
                                    미제출 입니다.
                                </label>
                            </div>
                        </>
                    ) : null}

                    <div
                        style={{
                            width: "60%",
                            height: "600px",
                            backgroundColor: "#eee",
                            border: "1px solid black",
                            paddingTop: "10px",
                        }}
                    >
                        <center>
                            <div style={{ width: "90%" }}>
                                {/* 캐러셀 사진 경로 수정 */}
                                <Slider {...settings}>
                                    {imgPath[0] ? (
                                        imgPath.map((img, index) => {
                                            return (
                                                <div key={index}>
                                                    <img
                                                        src={img}
                                                        alt="사진을 불러올 수 없습니다."
                                                        style={{
                                                            margin: "0px",
                                                            padding: "0px",
                                                            height: "550px",
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div key="0">
                                            <img
                                                alt="사진이 없습니다."
                                                width="100%"
                                                height="400px"
                                            ></img>
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </center>
                    </div>
                </div>
                {appraisalModal ? (
                    <AppraisalOutput TF={appraisalModal} setTF={setAppraisalModal} data={appraisalModalData} />
                ) : null}
            </>
        );
    } else {
        window.location.replace("/");
    }
}

export default ReviewReport;
