import "../../css/userModal.css";
import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import AddMsgInfo from "./MsginfoModal/AddMsgInfo";
import UpdateMsgInfo from "./MsginfoModal/UpdateMsgInfo";
import cmmGetUser from "../../cmm/cmmGetUser";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import PageBtn from "../../cmm/cmmPageBtn";
//메시지 관리
function MsgInfo() {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;

    const dispatch = useDispatch();

    const { register, watch, handleSubmit } = useForm();

    // msgData 조회결과 값
    const [msgData, setMsgData] = useState([]);

    const [updataData, setUpdateData] = useState();

    const [addMsgInfoModal, setAddMsgInfoModal] = useState(false);
    const [updateMsgInfoModal, setUpdateMsgInfoModal] = useState(false);

    const onSubmit = (data = { msgid: null, msgbody: null, remarks: null }) => {
        axios
            .post(`${server}/api/msgInfo/search`, { data, sessionid: sessionStorage.getItem("SESSION") })
            .then((res) => {
                setMsgData(res.data);
                setPage(1);
            });
    };

    const msgDelect = (id) => {
        setAlertMessage("Q800")
            .then((res) => {
                if (res) {
                    axios
                        .post(`${server}/api/msgInfo/delete`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            id: id,
                        })
                        .then((res) => {
                            if (res.data) {
                                setAlertMessage("I003");
                                onSubmit(watch());
                            } else setAlertMessage("E202");
                        })
                        .catch((err) => {
                            setAlertMessage("E202");
                        });
                }
            })
            .catch((err) => {
                setAlertMessage("E202");
            });
    };
    // 추가 수정시 업데이트
    useEffect(() => {
        onSubmit(watch());
    }, [addMsgInfoModal, updateMsgInfoModal]);

    return (
        <>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%", height: "65px" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "5%" }}>
                                    <label className="label-soloTr" style={{ marginLeft: "20px" }}>
                                        ID
                                    </label>
                                </td>
                                <td>
                                    <input
                                        {...register("msgid", {})}
                                        type="text"
                                        className="input-soloTr"
                                        maxLength="100"
                                    />
                                </td>
                                <td style={{ width: "5%" }}>
                                    <label className="label-soloTr">본문</label>
                                </td>
                                <td>
                                    <input
                                        {...register("msgbody", {})}
                                        type="text"
                                        className="input-soloTr"
                                        maxLength="500"
                                    />
                                </td>
                                <td style={{ width: "5%" }}>
                                    <label className="label-soloTr">비고</label>
                                </td>
                                <td>
                                    <input
                                        {...register("remarks", {})}
                                        type="text"
                                        className="input-soloTr"
                                        maxLength="100"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content" style={{ margin: "0px", marginBottom: "20px" }}>
                    <Button
                        className="btn-add"
                        variant="secondary"
                        type="button"
                        onClick={() => {
                            setAddMsgInfoModal(true);
                        }}
                        style={{ margin: "0px", marginRight: "10px" }}
                    >
                        추가
                    </Button>
                    <Button className="btn-search" variant="secondary" type="submit" style={{ margin: "0px" }}>
                        <FontAwesomeIcon icon={faSearch} />
                        조회
                    </Button>
                </div>
            </form>

            <Table bordered hover>
                <thead className="style-tHeader">
                    <tr>
                        <th>ID</th>
                        <th style={{ width: "177px" }}>헤더</th>
                        <th style={{ width: "1100px" }}>본문</th>
                        <th>버튼유형</th>
                        <th style={{ width: "181px" }}>비고</th>
                        <th>수정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {msgData
                        ? msgData.slice(minpage, maxPage).map((data, index) => {
                              return (
                                  <tr key={index}>
                                      <td>{data.msgid}</td>
                                      <td>{data.title}</td>
                                      <td style={{ textAlign: "center" }}>{data.msgbody}</td>
                                      <td>{data.itemnm}</td>
                                      <td>{data.remarks}</td>
                                      <td>
                                          <Button
                                              className="btn-table"
                                              variant="secondary"
                                              type="button"
                                              onClick={() => {
                                                  setUpdateMsgInfoModal(true);
                                                  setUpdateData({
                                                      msgid: data.msgid,
                                                      title: data.title,
                                                      msgbody: data.msgbody,
                                                      itemnm: data.itemnm,
                                                      btntype: data.btntype,
                                                      remarks: data.remarks,
                                                  });
                                              }}
                                          >
                                              수정
                                          </Button>
                                      </td>
                                      <td>
                                          <Button
                                              className="btn-table_delete"
                                              variant="secondary"
                                              type="button"
                                              onClick={() => {
                                                  msgDelect(data.msgid);
                                              }}
                                          >
                                              삭제
                                          </Button>
                                      </td>
                                  </tr>
                              );
                          })
                        : null}
                </tbody>

                <tfoot>
                    <tr>
                        <td colSpan="12" style={{ borderBottom: "none" }}>
                            <PageBtn
                                data={msgData ? msgData : 0}
                                page={page}
                                setPage={setPage}
                                count={count}
                                btnRefresh={btnRefresh}
                            />
                        </td>
                    </tr>
                </tfoot>
                {/* <tfoot>
                        <tr>
                            <td>수정불능</td>
                            <td colSpan="6">수정가능</td>
                        </tr>
                    </tfoot> */}
            </Table>

            <div>
                {/*reudx 를 통해 불러온 값이 true면 AddWeight 띄우고 아니면 null을 띄움*/}
                {addMsgInfoModal ? (
                    <AddMsgInfo addMsgInfoModal={addMsgInfoModal} setAddMsgInfoModal={setAddMsgInfoModal} />
                ) : null}
                {updateMsgInfoModal ? (
                    <UpdateMsgInfo
                        data={updataData}
                        updateMsgInfoModal={updateMsgInfoModal}
                        setUpdateMsgInfoModal={setUpdateMsgInfoModal}
                    />
                ) : null}
            </div>
        </>
    );
}

export default MsgInfo;
