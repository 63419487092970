//Moudle
import "../../../css/userModal.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetUser from "../../../cmm/cmmGetUser";

const AddMsgInfo = (props) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    // const addMsgInfoState = useSelector((state) => state.addMsgInfoModalReducer);
    // 현재 접속된 아이디
    const id = ID;

    // const dispatch = useDispatch();
    // 버튼 유형 타입
    const [type, setType] = useState("");

    const msgBtnType = () => {
        let type = [{ value: "null", label: "null" }];
        axios
            .post(`${server}/api/msgInfo/type`, { sessionid: sessionStorage.getItem("SESSION") })
            .then((response) => {
                response.data.map((data) => {
                    type.unshift({
                        label: data.itemnm,
                        value: data.itemcd,
                    });
                });
                type.pop(); // 에러방지값 pop()
            })
            .catch((err) => {});
        return type;
    };

    const { register, handleSubmit } = useForm();
    const onSubmit = async (data) => {
        data.msgbody.replaceAll("\n", "\n\r");
        if (data.msgbody !== "" && data.msgid !== "" && data.title !== "" && type !== "") {
            axios
                .post(`${server}/api/msgInfo/create`, { sessionid: sessionStorage.getItem("SESSION"), type, id, data })
                .then((response) => {
                    if (response.data.errno) {
                        setAlertMessage("E800");
                    } else {
                        setAlertMessage("S800");
                        props.setAddMsgInfoModal(false);
                        // dispatch({ type: "addMsgInfoModalFalse" });
                    }
                })
                .catch((err) => {});
        } else {
            setAlertMessage("E900");
        }
    };

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.addMsgInfoModal}
            onHide={() => props.setAddMsgInfoModal(false)}
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 메시지 추가 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>ID</label>
                            <input {...register("msgid", {})} type="text" className="form-control" maxLength="50" />
                        </div>
                        <div className="form-group">
                            <label>헤더</label>

                            <input {...register("title", {})} type="text" className="form-control" maxLength="100" />
                        </div>
                        <div className="form-group">
                            <label>본문</label>
                            <textarea
                                {...register("msgbody", {})}
                                className="form-control"
                                rows="3"
                                style={{ height: "170px" }}
                                maxLength="500"
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label>버튼유형</label>
                            <Select
                                className="selector-single"
                                options={msgBtnType()}
                                onChange={(e) => {
                                    setType(e.value);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label>비고</label>
                            <input {...register("remarks", {})} type="text" className="form-control" maxLength="100" />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Button className="btn-positive" variant="secondary" type="submit">
                                추가
                            </Button>
                            <Button
                                className="btn-negative"
                                variant="secondary"
                                type="button"
                                onClick={() => props.setAddMsgInfoModal(false)}
                            >
                                취소
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default AddMsgInfo;
