//Moudle
import "../../../css/User.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import cmmGetUser from "../../../cmm/cmmGetUser";
import { useEffect, useState } from "react";

//저작권 보호 정책 보기 창
function ShowCopyRightTerm(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    let TermValue = props.copyrightterm; //저작권보호정책가져오기
    const { reset } = useForm();

    const onChangeHanler = (e) => {
        TermValue = e.target.value;
    };
    return (
        <Modal
            {...props}
            show={props.show}
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            {" "}
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 저작권 보호정책 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <form className="LoginBox">
                        {props.cfmid ? (
                            <>
                                <div
                                    className="form-group"
                                    style={{
                                        width: "500px",
                                        height: "500px",
                                        marginLeft: "45px",
                                        marginBottom: "-40px",
                                    }}
                                >
                                    <textarea
                                        id="useterm"
                                        defaultValue={TermValue}
                                        style={{
                                            width: "100%",
                                            height: "90%",
                                            padding: "15px",
                                            fontFamily: "source-han-sans-korean, sans-serif",
                                            marginLeft: "-2px",
                                        }}
                                        readOnly
                                        onChange={onChangeHanler}
                                    ></textarea>
                                </div>

                                <div style={{ textAlign: "center" }}>
                                    <Button
                                        className="btn-solo"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className="form-group"
                                    style={{
                                        width: "500px",
                                        height: "500px",
                                        marginLeft: "45px",
                                        marginBottom: "-0px",
                                    }}
                                >
                                    <textarea
                                        id="useterm"
                                        defaultValue={TermValue}
                                        //readOnly={props.confirmdt ? "true" : "false"}
                                        //readOnly="true" // Confirmdt 채워져있으면 보기만됨, 수정 버튼 안보임
                                        onChange={onChangeHanler}
                                        maxLength="2000"
                                    ></textarea>
                                </div>

                                <div className="button-group" style={{ marginBottom: "-10px" }}>
                                    <Button
                                        className="btn-save"
                                        variant="secondary"
                                        type="button"
                                        onClick={() => {
                                            axios
                                                .post(`${server}/api/useTerms/updateCopyRight`, {
                                                    sessionid: sessionStorage.getItem("SESSION"),
                                                    copyrightterm: TermValue,
                                                    begindt: props.begindt,
                                                    userid: ID,
                                                })
                                                .then(() => {
                                                    refreshState
                                                        ? dispatch({ type: "refreshFalse" })
                                                        : dispatch({ type: "refreshTrue" });
                                                });

                                            props.setShow(false);
                                        }}
                                    >
                                        수정
                                    </Button>

                                    <Button
                                        className="btn-cancel"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </>
                        )}
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default ShowCopyRightTerm;
