import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState, useRef } from "react";
import { Button, Table } from "react-bootstrap";

// chart.js
import { Pie, Line } from "react-chartjs-2";

// 공통함수
import SelectOrgUser from "../../cmm/cmmSelectOrgUser";
import { DatePickerMonthly } from "../../cmm/cmmDateFunction";
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import axios from "axios";
import cmmGetUser from "../../cmm/cmmGetUser";

// 인쇄용
import ReactToPrint from 'react-to-print';


// 사용자별 감정서 건수
const StatUser = () => {
    
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    // 현재 아이디
    const id = ID;
    // 부서
    const [lvl, setLvl] = useState();

    const [date, setDate] = useState(); //// 검색조건 날짜
    const [monthlabels, setMonthlabels] = useState([]);
    const [monthcharts, setMonthcharts] = useState([]);

    const [linedataTot, setLinedataTot] = useState([]); // 좌측 상단 차트 데이터
    const [linedata12, setLinedata12] = useState([]); // 판별 결과 데이터: 단락흔
    const [linedata3, setLinedata3] = useState([]); // 판별 결과 데이터: 열흔
    const [linedata9, setLinedata9] = useState([]); // 판별 결과 데이터: 열흔
    const [pieChartData, setPieChartData] = useState([]); // 점유율 데이터

    const [tablesum, setTablesum] = useState([]);
    const [tabledata, setTabledata] = useState([]);
    const [colwidth, setColwidth] = useState([]);
    const labelName = ["단락흔", "열흔", "판별불가"];

    ////////////// 인쇄 버튼 관련 /////////////////////////////////
    const bodyRef = useRef();
    
    const getPrintBtn = () =>{
        return (
            <Button className="btn-searchStat">
                <FontAwesomeIcon icon={faPrint} /> 출력
            </Button>
        );
    }

    /////////////// 업무 로직 ///////////////////////////////////////////
    
    const [start, setStart] = useState(true);   //// 시작할 때 연월 표기 위해 서버 조회 태울 수 있도록 사용자 지정 안 해도 통과시키기 위해 넣음
    
    const Chart = () => {
        setMonthlabels([]);
        setTablesum([]);
        setTabledata([]);
        setColwidth("0%");

        if (start == false && lvl.num != 5) {
            setAlertMessage("E905");
            return;
        } else if (date.begindt > date.enddt) {
            setAlertMessage("E904");
            return;
        }

        if (date && lvl && ID) {
            axios
                .post(`${server}/api/stat/statUser`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    startDate: date.begindt,
                    endDate: date.enddt,
                    createid: lvl.lvl,
                    id: ID,
                })
                .then((res) => {
                    let newMonthlabels = [];
                    let newMonthcharts = [];
                    let newColwidth = "0%";
                    if (res.data.monthlabels != null) {
                        newMonthlabels = res.data.monthlabels;
                        for (let i = 0; i < res.data.monthlabels.length; i++) {
                            if (res.data.monthlabels[i].rn == 1)
                                newMonthcharts.push(
                                    res.data.monthlabels[i].yr + "년\n" + res.data.monthlabels[i].mm + "월"
                                );
                            else newMonthcharts.push(res.data.monthlabels[i].mm + "월");
                        }
                        //// 1년 기준, 12개월 +합계 1칸 = 13칸일 때 배수 되는 13*7 = 91 기준. 화재 종류 글자 짧으므로 칸수 많아질 때 줄일 수 있도록 반올림
                        newColwidth = Math.ceil((91 / (res.data.monthlabels.length + 1)) * 10) / 10 + "%";
                    }
                    setMonthlabels(newMonthlabels);
                    setMonthcharts(newMonthcharts);
                    setColwidth(newColwidth);

                    if (res.data.data == null || res.data.data.length == 0) {
                        setAlertMessage("I004");
                    } else {
                        let newdata12 = [];
                        if (res.data.data.length > 1) {
                            for (let i = 0; i < res.data.monthlabels.length; i++)
                                newdata12.push(res.data.data[1]["cnt" + i]);
                        }
                        setLinedata12(newdata12);
                        
                        let newdata3 = [];
                        if (res.data.data.length > 2) {
                            for (let i = 0; i < res.data.monthlabels.length; i++)
                                newdata3.push(res.data.data[2]["cnt" + i]);
                        }
                        setLinedata3(newdata3);

                        let newdata9 = [];
                        if (res.data.data.length > 3) {
                            for (let i = 0; i < res.data.monthlabels.length; i++)
                                newdata9.push(res.data.data[3]["cnt" + i]);
                        }
                        setLinedata9(newdata9);
                        
                        let newPieChartData = new Array();
                        for (let i = 1; i < res.data.data.length; i++) {
                            newPieChartData.push(res.data.data[i].cntsum);
                        }
                        setPieChartData(newPieChartData);

                        ////  좌상단 감정건수 + 하단 표
                        let newtotal = [];
                        let newTablesum = [];
                        let newTabledata = [];

                        if (res.data.data!=null && res.data.data.length>0){
                            
                            for (let i=0;i<res.data.monthlabels.length;i++) newtotal.push(res.data.data[0]["cnt"+i]);
                            
                            newTablesum = res.data.data[0];
                            newTabledata = Array.from(res.data.data).splice(1);
                        }

                        setLinedataTot(newtotal);
                        setTablesum(newTablesum);
                        setTabledata(newTabledata);
                    }
                });
        }
    };

    const linedata = {
        labels: monthcharts,
        datasets: [
            {
                label: "감정건수",
                data: linedataTot,
                yAxisID: "A",
                backgroundColor: "#BA55D3",
                borderColor: "#BA55D3",
                fill: false,
                pointHoverRadius: 20,
                pointHoverBorderWidth: 5,
                type: "line",
                order: 0,
                tension: 0,
            },
        ],
    };

    const lineOption = {
        animation: {
            duration: 600,
        },
        scales: {
            yAxes: [
                {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "A",
                    ticks: {
                        min: 0,
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: {
            display: false,
        },
        tooltips: {
            displayColors: true,
            callbacks: {
                mode: "single",
                labelColor: function (tooltipItem, chart) {
                    return {
                        borderColor: "transparent",
                        backgroundColor: linedata.datasets[tooltipItem.datasetIndex].backgroundColor,
                    };
                },
                label: function (tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${
                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === null
                            ? 0
                            : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    }`;
                },
            },
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                display: "",
            },
        },
    };

    const line2Data = {
        labels: monthcharts,
        datasets: [
            {
                label: "단락흔",
                data: linedata12,
                // yAxisID: "B",
                backgroundColor: "rgba(87, 121, 234, 1)",
                borderColor: "rgba(87, 121, 234, 1)",
                fill: false, pointHoverRadius: 20, pointHoverBorderWidth: 5, type: "line", order: 0, tension: 0,
            },
            {
                label: "열흔",
                data: linedata3,
                backgroundColor: "rgba(255, 127, 0, 1)",
                borderColor: "rgba(255, 127, 0, 1)",
                fill: false, pointHoverRadius: 20, pointHoverBorderWidth: 5, type: "line", order: 0, tension: 0,
            },
            {
                label: "판별불가",
                data: linedata9,
                backgroundColor: "rgba(136, 136, 136, 1)",
                borderColor: "rgba(136, 136, 136, 1)",
                fill: false, pointHoverRadius: 20, pointHoverBorderWidth: 5, type: "line", order: 0, tension: 0,
            },
        ],
    };

    const line2Option = {
        animation: {
            duration: 600,
        },
        scales: {
            yAxes: [
                {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "A",
                    ticks: {
                        min: 0,
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
            position: "bottom",
        },
        tooltips: {
            displayColors: true,
            callbacks: {
                mode: "single",
                labelColor: function (tooltipItem, chart) {
                    return {
                        borderColor: "transparent",
                        backgroundColor: line2Data.datasets[tooltipItem.datasetIndex].backgroundColor,
                    };
                },
                label: function (tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${
                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === null
                            ? 0
                            : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    }`;
                },
            },
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                display: "",
            },
        },
    };

    const pieData = {
        labels: labelName,
        datasets: [
            {
                data: pieChartData,
                backgroundColor: ["rgba(87, 121, 234, 1)", "rgba(255, 127, 0, 1)", "rgba(192, 192, 192, 1)"],
                order: 0,
                tension: 0,
            },
        ],
    };

    const pieOption = {
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: false,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        display: false,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 12,
            },
            position: "bottom",
        },
        tooltips: {
            displayColors: true,
            backgroundColor: "#fff",
            titleFontSize: 20,
            titleFontColor: "#000",
            bodyFontSize: 14,
            bodyFontColor: "#000",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 6,
            shadowColor: "rgba(0,0,0,0.4)",
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            caretSize: 0,
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;

                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                        let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                        let percentage =
                            Math.round((value / sum) * 100) > 0 ? Math.round((value / sum) * 100) + "%" : null;
                        return percentage;
                    }
                },
                font: {
                    weight: "bold",
                    size: 16,
                },
                color: "#fff",
            },
        },
    };

    useEffect(() => {
        if (date && (start == true || (start == false && lvl && lvl.num == 5)) && ID) {
            Chart();
            setStart(false);
        }
    }, [date, lvl, ID]);

    return (
        <div ref={bodyRef}>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <div style={{ backgroundColor: "#F5F5F5" }}>
                <SelectOrgUser setLvl={setLvl} />
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td className="style-td">기간</td>
                                <td>
                                    <DatePickerMonthly setDate={setDate} date={date} />
                                </td>
                                <td>
                                    <ReactToPrint
                                        trigger={() => getPrintBtn()}
                                        content={() => bodyRef.current}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <br />
            <div style={{ display: "flex" }}>
                <div style={{ width: "33%" }}>
                    <h4 className="style-h4">감정 건수</h4>
                    <div className="graph-stat">
                        <Line data={linedata} options={lineOption} />
                    </div>
                </div>
                <div style={{ width: "33%" }}>
                    <h4 className="style-h4">감정 결과</h4>
                    <div className="graph-stat">
                        <Line data={line2Data} options={line2Option} />
                    </div>
                </div>
                {/* 원형 차트 */}
                <div style={{ width: "33%" }}>
                    <h4 className="style-h4">
                        점유율 <b style={{ fontSize: "12px", color: "#ccc" }}>(0.5%미만 출력X)</b>
                    </h4>
                    <div className="graph-stat">
                        <Pie data={pieData} options={pieOption} />
                    </div>
                </div>
            </div>

            <br />
            <br />

            <Table triped bordered responsive>
                <thead className="style-tHeader" style={{ backgroundColor: "#f7f9fa" }}>
                    <tr>
                        <td rowSpan="2" style={{ width: "9%" }}>
                            구분
                        </td>
                        <td rowSpan="2" style={{ width: colwidth }}>
                            합계
                        </td>
                        {monthlabels.map((data, index) => {
                            return data.rn == "1" ? (
                                <td colSpan={data.span} key={index}>
                                    {data.yr}년
                                </td>
                            ) : (
                                ""
                            );
                        })}
                    </tr>
                    <tr>
                        {monthlabels.map((data, index) => {
                            return (
                                <td key={index} style={{ width: colwidth }}>
                                    {data.mm}월
                                </td>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>합계</td>
                        <td>{tablesum.cntsum}건</td>
                        {monthlabels.map((m, idx) => {
                            return <td key={idx}>{tablesum["cnt" + idx]}&nbsp;건</td>;
                        })}
                    </tr>
                    {tabledata.map((data) => {
                        return (
                            <tr>
                                <td>{data.firetypenm}</td>
                                <td>{data.cntsum}건</td>
                                {monthlabels.map((e, idx) => (
                                    <td key={idx}>{data["cnt" + idx]}&nbsp;건</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default StatUser;
