import React, { useEffect, useRef } from "react";
//메뉴 관리
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from "react-select";

//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetUser from "../../../cmm/cmmGetUser";

//상위메뉴 이동
function ChangePmenu(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);
    let seqcd = [{ value: "1", label: "1" }];

    const pmenucd = props.lvlcd;
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const [selectPmenu, setSelectPmenu] = useState("");
    const [selectPmenuCheck, setSelectPmenuCheck] = useState(false);

    const onSubmit = async (data) => {
        if (selectPmenuCheck) {
            axios.post(`${server}/api/menuInfo/updatePmenu`, {
                sessionid: sessionStorage.getItem("SESSION"),
                pmenuid: selectPmenu,
                menuid: props.updateData.menuid,
                userid: ID,
            });

            refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
            props.setShow(false);
            window.location.reload();
        } else {
            setAlertMessage("E011");
        }

        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
    };

    return (
        <>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                {" "}
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 상위메뉴 변경 </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <>
                            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label>현 상위 메뉴</label>
                                    <span readOnly className="form-control">
                                        {props.updateData.pmenunm}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label>메뉴명</label>
                                    <span readOnly className="form-control">
                                        {props.updateData.menunm}
                                    </span>
                                </div>

                                <div className="form-group">
                                    <label>상위 메뉴</label>

                                    <Select
                                        className="selector-single2"
                                        options={pmenucd.filter((v) => v.value !== props.updateData.pmenuid)}
                                        onChange={(e) => {
                                            setSelectPmenu(e.value);
                                            setSelectPmenuCheck(true);
                                        }}
                                        defaultValue={{ value: null, label: "*선택" }}
                                    ></Select>
                                </div>

                                <div>
                                    <Button
                                        className="btn-positive"
                                        variant="secondary"
                                        type="submit"
                                        onClick={() => {}}
                                    >
                                        저장
                                    </Button>

                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        </>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default ChangePmenu;
