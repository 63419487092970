//Moudle
import React, { useEffect, useRef, useState } from "react";
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../../cmm/cmmGetData"; //Config 가져오기

import cmmGetUser from "../../../cmm/cmmGetUser";

function AddDept(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST;
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);

    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    // 함수

    // 리턴

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const refLvl2 = useRef();
    const refLvl3 = useRef();
    const refLvl4 = useRef();

    // 부서코드
    const [lvl1, SetLvl1] = useState();
    const [lvl2, SetLvl2] = useState();
    const [lvl3, SetLvl3] = useState();

    const [lvlResult, SetLvlResult] = useState("A");

    const onSubmit = async (data) => {
        axios
            .post(`${server}/api/orgDept/checkDeptcd`, {
                sessionid: sessionStorage.getItem("SESSION"),
                orgid: props.orgid,
                deptcd: data.deptcd,
            })
            .then((check) => {
                if (check.data) {
                    axios
                        .post(`${server}/api/orgDept/add`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            deptnm: data.deptnm,
                            pdeptcd: lvlResult,
                            lvl: lvl1 ? (lvl2 ? (lvl3 ? 4 : 3) : 2) : 1,
                            orgid: props.orgid,
                            userid: ID,
                            region: data.region,
                            numorg: data.numorg,
                            deptcd: data.deptcd,
                        })
                        .then(() => {
                            props.setShow(false);
                            refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
                        })
                        .catch((err) => {});
                } else {
                    setAlertMessage("E012");
                }
            })
            .catch(() => {});

        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
    };

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show}
                onHide={() => props.setShow(false)}
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                {" "}
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 하위부서 추가 </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <>
                            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label>상위 부서</label>
                                    <Select
                                        className="selector-double2"
                                        options={cmmGetData("deptcdAllOrgid", 2, "A", props.orgid)}
                                        onChange={(e) => {
                                            SetLvl1(e.value);
                                            if (refLvl3.current) {
                                                if (refLvl3.current.state.value) {
                                                    refLvl3.current.state.value.label = "";
                                                }
                                            }
                                            if (refLvl4.current) {
                                                if (refLvl4.current.state.value) {
                                                    refLvl4.current.state.value.label = "";
                                                }
                                            }

                                            SetLvlResult(e.value);
                                        }}
                                        defaultValue={{ value: "A", label: "*미선택시 2단계" }}
                                        ref={refLvl2}
                                    />

                                    <Select
                                        className="selector-double2"
                                        options={cmmGetData("deptcdAllOrgid", 3, lvl1, props.orgid)}
                                        onChange={(e) => {
                                            SetLvl2(e.value);

                                            if (refLvl4.current) {
                                                if (refLvl4.current.state.value) {
                                                    refLvl4.current.state.value.label = "";
                                                }
                                            }
                                            SetLvlResult(e.value);
                                        }}
                                        defaultValue={{ label: "*미선택시 3단계" }}
                                        ref={refLvl3}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>부서명</label>
                                    <input
                                        {...register("deptnm", {
                                            required: "필수정보입니다.",
                                            pattern: {
                                                value: /^[가-힣a-zA-Z0-9]+$/,
                                                message:
                                                    "한글과 영문 대 소문자를 사용하세요. (숫자,특수기호, 공백 사용 불가)",
                                            },
                                        })}
                                        type="text"
                                        className="form-control"
                                        placeholder="*필수 입력"
                                        maxLength="30"
                                    />
                                </div>
                                {errors.deptnm && <span className="span-box">{errors.deptnm.message}</span>}

                                <div className="form-group">
                                    <label>부서코드</label>
                                    <input
                                        {...register("deptcd", {
                                            required: "필수정보입니다.",
                                            pattern: {
                                                value: /^[A-Z0-9]+$/,
                                                message:
                                                    "대문자 및 숫자만 사용 가능합니다.(한글 ,소문자,특수기호, 공백 사용 불가)",
                                            },
                                        })}
                                        type="text"
                                        className="form-control"
                                        placeholder="*필수 입력"
                                        maxLength="10"
                                    />
                                </div>
                                {errors.deptcd && <span className="span-box">{errors.deptcd.message}</span>}

                                <div className="form-group">
                                    <label>지역명</label>

                                    <input
                                        {...register("region", {
                                            required: "필수정보입니다.",
                                            pattern: {
                                                value: /^[가-힣]+$/,
                                                message: "지역명을 입력하세요. (영문 ,숫자,특수기호, 공백 사용 불가)",
                                            },
                                        })}
                                        type="text"
                                        className="form-control"
                                        placeholder="*필수 입력"
                                        maxLength="20"
                                    />
                                </div>
                                {errors.region && <span className="span-box">{errors.region.message}</span>}

                                <div className="form-group">
                                    <label>기관코드</label>
                                    <input
                                        {...register("numorg", {
                                            required: "필수정보입니다.",
                                            pattern: {
                                                value: /^[가-힣]{1,1}$/,
                                                message:
                                                    "기관코드 한글자를 입력하세요. (영문 ,숫자,특수기호, 공백 사용 불가)",
                                            },
                                        })}
                                        type="text"
                                        className="form-control"
                                        placeholder="*필수 입력 (EX)전기안전공사 ->  전)"
                                    />
                                </div>
                                {errors.numorg && <span className="span-box">{errors.numorg.message}</span>}

                                <div style={{ marginTop: "20px" }}>
                                    <Button className="btn-positive" variant="secondary" type="submit">
                                        저장
                                    </Button>

                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        </>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default AddDept;
