import React, { useEffect, useState } from "react";
import cmmchangeFormat from "./cmmchangeFormat";

//지난 1주
let nowDate = new Date();
let weekDate = nowDate.getTime() - 7 * 24 * 60 * 60 * 1000;
nowDate.setTime(weekDate);

//// 전문가 검토 등
export const DatePickerTwo = (props) => {
    useEffect(() => {
        props.setDate({
            begindt: cmmchangeFormat(nowDate, "yy-MM-DD"),
            enddt: cmmchangeFormat(new Date(), "yy-MM-DD"),
        });
    }, []);

    const [beginDate, setBeginDate] = useState(cmmchangeFormat(nowDate, "yy-MM-DD"));
    const [endDate, setEndDate] = useState(cmmchangeFormat(new Date(), "yy-MM-DD"));

    return (
        <>
            <div style={{ display: "flex", width: "100%" }} id="datePickerTwoDiv">
                <input
                    id="begindt"
                    type="date"
                    className="form-control"
                    defaultValue={cmmchangeFormat(nowDate, "yy-MM-DD")}
                    onChange={(e) => {
                        props.setDate({ begindt: e.target.value, enddt: props.date.enddt });
                        setBeginDate(e.target.value);
                    }}
                    style={{ margin: "5px 5px 15px 5px" }}
                    max={cmmchangeFormat(endDate, "yy-MM-DD")}
                />
                <b style={{ lineHeight: "45px" }}>~</b>
                <input
                    id="enddt"
                    type="date"
                    className="form-control"
                    defaultValue={cmmchangeFormat(new Date(), "yy-MM-DD")}
                    onChange={(e) => {
                        props.setDate({
                            begindt: props.date.begindt,
                            enddt: e.target.value,
                        });
                        setEndDate(e.target.value);
                    }}
                    min={cmmchangeFormat(beginDate, "yy-MM-DD")}
                />
            </div>
        </>
    );
};

//// 통계: 월간 기간 검색
export const DatePickerMonthly = (props) => {
    const [beginDate, setBeginDate] = useState(cmmchangeFormat(nowDate, "yy-MM"));
    const [endDate, setEndDate] = useState(cmmchangeFormat(new Date(), "yy-MM"));

    useEffect(() => {
        props.setDate({
            begindt: cmmchangeFormat(
                new Date(
                    Number(cmmchangeFormat(new Date(), "yy-MM").split("-")[0]) - 1,
                    cmmchangeFormat(new Date(), "yy-MM").split("-")[1]
                ),
                "yy-MM"
            ),
            enddt: cmmchangeFormat(new Date(), "yy-MM"),
        });
    }, []);

    return (
        <>
            <div
                style={{
                    width: "510px",
                    display: "flex",
                    marginTop: "-30px",
                    marginLeft: "-10px",
                }}
            >
                <input
                    id="begindt"
                    type="month"
                    className="input-date1"
                    defaultValue={cmmchangeFormat(
                        new Date(
                            Number(cmmchangeFormat(new Date(), "yy-MM").split("-")[0]) - 1,
                            cmmchangeFormat(new Date(), "yy-MM").split("-")[1]
                        ),
                        "yy-MM"
                    )}
                    onChange={(e) => {
                        props.setDate({
                            begindt: e.target.value,
                            enddt: props.date.enddt,
                        });
                        setBeginDate(e.target.value);
                    }}
                />
                <b style={{ margin: "20px 0 0 4px" }}>-</b>
                <input
                    id="enddt"
                    type="month"
                    className="input-date2"
                    defaultValue={cmmchangeFormat(new Date(), "yy-MM")}
                    onChange={(e) => {
                        props.setDate({
                            begindt: props.date.begindt,
                            enddt: e.target.value,
                        });
                        setEndDate(e.target.value);
                    }}
                />
            </div>
        </>
    );
};

export const cmmDateLabels = (date, cnt = 12) => {
    let labels = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    let reLabels = [];
    for (let i = 0; i < cnt; i++) {
        let yIndex = date.split("-")[1] - 1 + i >= 12 ? 1 : 0;
        let mIndex = date.split("-")[1] - 1 + i >= 12 ? 12 : 0;
        reLabels.push(Number(date.split("-")[0]) + yIndex + labels[date.split("-")[1] - 1 + i - mIndex]);
    }
    return reLabels;
};

export const cmmDateNumbers = (date) => {
    let num = [0, 0];
    if (date) {
        for (let i = 0; i < 12; i++) {
            date.split("-")[1] - 1 + i >= 12 ? (num[1] += 1) : (num[0] += 1);
        }
    }
    if (num[0] === 12) {
        return [12];
    }
    return num;
};
