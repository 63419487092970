import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import OrgBulletin from "./BulletinModal/DeptSelect";
import cmmGetUser from "../../cmm/cmmGetUser";
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import { DatePickerTwo } from "../../cmm/cmmDateFunction";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const DetailBulletin = ({ location }) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    const orgcd = sessionData ? sessionData.orgcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;
    const dispatch = useDispatch();
    // 게시물 추가 모달창 true/false
    const orgBulletinState = useSelector((state) => state.orgBulletinModalReducer);
    // 현재 접속된 아이디
    const id = ID;
    // 부서
    const [lvl, setLvl] = useState({ lvl: "A", label: "전체" });
    // 날짜
    const [date, setDate] = useState();
    // 버튼 유형 타입
    const [type, setType] = useState("");
    const [privateyn, setPrivateyn] = useState("");
    // 게시판  root id로 조회결과 값
    const [bulletData, setBulletinData] = useState();
    const substrsize = location.state.data.statuscd === "N" ? 3 : 5; //제목 자르기 DB에서 concat 부분 바뀌면 여기 바꿔줘야함

    //조회수 늘리기
    useEffect(() => {
        axios.post(`${server}/api/bulletin/updateCntWeb`, {
            wid: location.state.data.wid,
            sessionid: sessionStorage.getItem("SESSION"),
        });
    }, []);

    useEffect(() => {
        axios
            .post(`${server}/api/bulletin/showReplyList`, {
                sessionid: sessionStorage.getItem("SESSION"),
                rootid: location.state.data.rootid,
            })
            .then((res) => {
                if (res.data[1]) {
                    //데이터 조회값이 있을 경우

                    setBulletinData(res.data);
                } else {
                    //데이터 조획값이 없을 경우 별도 이벤트 처리 X
                }
            });
    }, []);

    const onSubmit = async (data) => {};

    const onDeleteHandler = () => {
        setAlertMessage("Q002")
            .then((res1) => {
                if (res1) {
                    if (res1) {
                        axios
                            .post(`${server}/api/bulletin/deleteWeb`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                wid: location.state.data.wid,
                            })
                            .then((response) => {
                                if (response) {
                                    if (response.data) {
                                        setAlertMessage("I003").then((res) => {
                                            window.location.replace("./bulletin");
                                        });
                                    } else {
                                        setAlertMessage("I202");
                                    }
                                } else {
                                    setAlertMessage("E202");
                                }
                            })
                            .catch((err) => {
                                setAlertMessage("E202");
                            });
                    } else {
                        setAlertMessage("I009");
                    }
                }
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };

    return (
        <>
            <p className="manage-title">게시판 상세 보기</p>
            <form>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                <label>제목</label>
                            </td>
                            <td style={{ textAlign: "left" }}>
                                <span style={{ marginLeft: "20px" }}>
                                    {location.state.data.title.substr(substrsize, location.state.data.title.length)}
                                </span>
                            </td>
                            <td style={{ backgroundColor: "#eee", width: "10%" }}>
                                <label>작성자</label>
                            </td>
                            <td style={{ width: "10%" }}>{location.state.data.createid}</td>
                        </tr>

                        <tr>
                            <td colSpan="6" style={{ backgroundColor: "#eee" }}>
                                <label>본문</label>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="6" style={{ textAlign: "left", height: "500px" }}>
                                <pre style={{ textAlign: "left", marginLeft: "20px" }}>
                                    {location.state.data.content}
                                </pre>
                            </td>
                        </tr>
                        {bulletData
                            ? bulletData.map((data, index) => {
                                  return (
                                      <>
                                          <tr
                                              key={index}
                                              style={
                                                  data.wid === location.state.data.wid
                                                      ? { color: "#d11f37", fontFamily: "NotoSansKR-Bold" }
                                                      : null
                                              }
                                          >
                                              <td style={{ textAlign: "left" }} colSpan={2}>
                                                  <Link
                                                      to={{
                                                          pathname: "/bulletinContent",

                                                          state: { data: data },
                                                      }}
                                                      style={
                                                          data.wid === location.state.data.wid
                                                              ? { color: "#d11f37", fontFamily: "NotoSansKR-Bold" }
                                                              : { color: "black", fontstyle: "bold" }
                                                      }
                                                  >
                                                      <label style={{ cursor: "pointer", marginLeft: "10px" }}>
                                                          <span
                                                              style={{
                                                                  margin: `0px 0px 0px ${(data.lvl + 1) * 13}px`,
                                                              }}
                                                          >
                                                              {"\t" + data.title}
                                                          </span>
                                                      </label>
                                                  </Link>
                                              </td>

                                              <td>{data.createid}</td>
                                              <td>{data.createtm}</td>
                                          </tr>
                                      </>
                                  );
                              })
                            : null}
                    </tbody>
                </Table>
                <div className="button-group">
                    {location.state.data.statuscd !== "Y" ? (
                        <>
                            <Link
                                to={{
                                    pathname: "/bulletinReply",
                                    state: { data: location.state.data },
                                }}
                            >
                                <label style={{ cursor: "pointer", marginLeft: "10px" }}>
                                    <Button className="btn-cancel2-c2" variant="secondary">
                                        답글
                                    </Button>
                                </label>
                            </Link>
                        </>
                    ) : null}

                    {location.state.data.createid === ID ? (
                        <>
                            <Button
                                className="btn-cancel2-c2"
                                variant="secondary"
                                onClick={() => {
                                    onDeleteHandler();
                                }}
                            >
                                삭제
                            </Button>

                            <Link
                                to={{
                                    pathname: "/bulletinModify",
                                    state: { data: location.state.data },
                                }}
                            >
                                <label style={{ cursor: "pointer" }}>
                                    <Button className="btn-cancel2-c1">수정</Button>
                                </label>
                            </Link>
                        </>
                    ) : orgcd === "A" ? (
                        <>
                            <Button
                                className="btn-cancel2-c2"
                                variant="secondary"
                                onClick={() => {
                                    onDeleteHandler();
                                }}
                            >
                                삭제
                            </Button>
                        </>
                    ) : null}
                    <Button
                        className="btn-cancel2-c1"
                        variant="secondary"
                        type="button"
                        onClick={() => window.location.replace("./bulletin")}
                    >
                        취소
                    </Button>
                </div>
            </form>
        </>
    );
};

export default DetailBulletin;
