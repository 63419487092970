import React, { useEffect, useRef } from "react";
//시스템 관리자 계정 신청 관리
//Moudle
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { useState } from "react";
import Select from "react-select";

//공통함수
import cmmGetData from "../../../cmm/cmmGetData"; //Config 가져오기
import cmmGetUser from "../../../cmm/cmmGetUser";

//상태변경
function UpdateStatus(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const mdmcdOptions = cmmGetData("mdmcd"); // mdm 기관 config
    const usergrpcdOptions = cmmGetData("usergrpcd"); // 권한그룹 기관 config

    const expertOptions = cmmGetData("expert"); //전문가여부
    let [mdmcd, setMdmcd] = useState("null"); // MDM
    let [expertcd, setExpertcd] = useState("null"); // expert
    let [usergrpcd, setUsergrpcd] = useState("null"); // 권한그룹

    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    const onSubmitHandler = (e) => {
        if (mdmcd !== "null" && expertcd !== "null" && usergrpcd !== "null") {
            const body = {
                sessionid: sessionStorage.getItem("SESSION"),
                userid: props.updateUserData.userid,
                handlerid: ID,
                mdmcd: mdmcd,
                expertcd: expertcd,
                usergrpcd: usergrpcd,
            };

            axios.post(`${server}/api/userApply/updateStatus`, body);
            refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
            props.setShow(false);
        } else {
            alert("모든 칸을 선택하세요!");
        }
    };

    return (
        <Modal
            {...props}
            show={props.show}
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
        >
            <div className="outbox-addUsers">
                <div className="txt-title">
                    <span> 승인 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <>
                        {" "}
                        <form>
                            <div>
                                <div className="form-group">
                                    <label>권한그룹</label>
                                    <Select
                                        required="required"
                                        className="selector-single"
                                        options={usergrpcdOptions}
                                        onChange={(e) => {
                                            setUsergrpcd(e.value);
                                        }}
                                        defaultValue={{
                                            label: "*선택",
                                            value: "null",
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>전문가여부</label>
                                    <Select
                                        required="required"
                                        className="selector-single"
                                        options={expertOptions}
                                        onChange={(e) => {
                                            setExpertcd(e.value);
                                        }}
                                        defaultValue={{
                                            label: "*선택",
                                            value: "null",
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>MDM </label>
                                    <Select
                                        required="required"
                                        className="selector-single"
                                        options={mdmcdOptions}
                                        onChange={(e) => {
                                            setMdmcd(e.value);
                                        }}
                                        defaultValue={{
                                            label: "*선택",
                                            value: "null",
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "30px" }}>
                                    <Button className="btn-positive" type="button" onClick={onSubmitHandler}>
                                        확인
                                    </Button>
                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        onClick={() => {
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default UpdateStatus;
