//Moudle
import "../../../css/userModal.css";
import React, { useEffect, useRef, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import axios from "axios";

import Select from "react-select";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../../cmm/cmmGetData"; //Config 가져오기
import cmmGetUser from "../../../cmm/cmmGetUser";
function AddUser(props) {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    //소속 기관
    const mdmcdOptions = cmmGetData("mdmcd"); // 소속 기관 config
    const usergrpcdOptions = cmmGetData("usergrpcd"); // 소속 기관 config
    const orgcdOptions = cmmGetData("orgcd");

    let [orgcd, setOrg] = useState(orgcdOptions[0].value); // 소속기관
    let [mdmcd, setMdmcd] = useState(""); // MDM
    let [expertcd, setExpertcd] = useState(""); // expert
    let [usergrpcd, setUsergrpcd] = useState(""); // 권한그룹
    const server = process.env.REACT_APP_API_SERVER_HOST; // .env 파일에서 가져온 db_config

    let [watchPwd, setWatchPwd] = useState("");
    const refLvl2 = useRef();
    const refLvl3 = useRef();
    const refLvl4 = useRef();

    // 부서코드
    const [lvl, SetLvl] = useState("");
    const [lvl3, SetLvl3] = useState("");

    //필수입력 체크
    const [orgcdCheck, setOrgcdCheck] = useState(false);

    const [usergrpcdCheck, setUsergrpcdCheck] = useState(false);
    const [mdmcdCheck, setMdmcdCheck] = useState(false);
    const [expertcdCheck, setExpertcdCheck] = useState(false);

    let refreshState = useSelector((state) => state.refreshReducer);
    const dispatch = useDispatch();
    //useForm에서 사용할 함수..? 선언
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const onSubmit = async (data) => {
        if (orgcdCheck && (lvl || orgcd == "A") && usergrpcdCheck && mdmcdCheck && expertcdCheck) {
            axios
                .post(`${server}/api/userInfo/checkId`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    userid: data.userid,
                })
                .then((check) => {
                    //아이디 중복 아님
                    if (check.data) {
                        axios
                            .post(`${server}/api/userInfo/addUser`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                userid: data.userid,
                                usernm: data.usernm,
                                cellphone: data.cellphone,
                                email: data.email,
                                orgcd: orgcd,
                                birthdt: data.birthdt,
                                pwd: data.pwd,
                                deptnm: lvl,
                                usergrpcd: usergrpcd,
                                mdmtypecd: mdmcd,
                                expertcd: expertcd,
                                createid: ID,
                            })
                            .then((response) => {
                                //회원가입 요청 성공
                                if (response.data) {
                                    setAlertMessage("I002");
                                    //ID중복 검사 및 회원가입 요청 성공시에만 reset

                                    props.setShow(false);
                                    //reset();
                                    refreshState
                                        ? dispatch({ type: "refreshFalse" })
                                        : dispatch({ type: "refreshTrue" });
                                }
                                //회원가입 요청 실패(어떠한 이유던지 쿼리 비정상 실행)
                                else {
                                    setAlertMessage("E003");
                                }
                            })
                            .catch(() => {
                                //연결 실패
                                setAlertMessage("E000");
                            });
                    }
                    //아이디 중복
                    else {
                        setAlertMessage("E002");
                    }
                })
                .catch(() => {
                    //db연결 실패
                    setAlertMessage("E000");
                });
        } else {
            setAlertMessage("E011");
        }

        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
    };
    return (
        <div>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 계정 추가 </span>
                        {/* <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">계정 상태 변경</Modal.Title>
                    </Modal.Header> */}
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="userid" class="required">
                                            ID{" "}
                                        </label>
                                        <input
                                            {...register("userid", {
                                                required: "필수정보입니다.",
                                                pattern: {
                                                    value: /^[a-zA-Z0-9]{6,8}$/,
                                                    message:
                                                        "6~8 자리의 영문 대 소문자, 숫자를 사용하세요. (특수기호, 공백 사용 불가)",
                                                },
                                            })}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    {errors.userid && <span className="span-box">{errors.userid.message}</span>}
                                    <div className="form-group">
                                        <label class="required">성명</label>
                                        <input
                                            {...register("usernm", {
                                                required: "필수정보입니다.",
                                                pattern: {
                                                    value: /^[가-힣a-zA-Z]+$/,
                                                    message:
                                                        "한글과 영문 대 소문자를 사용하세요. (특수기호, 공백 사용 불가)",
                                                },
                                            })}
                                            type="text"
                                            className="form-control"
                                            maxLength="20"
                                            // placeholder="성명을 입력해주세요"
                                        />
                                    </div>
                                    {errors.usernm && <span className="span-box">{errors.usernm.message}</span>}
                                    <div className="form-group">
                                        <label class="required">소속</label>
                                        <Select
                                            className="selector-single"
                                            options={orgcdOptions}
                                            onChange={(e) => {
                                                setOrg(e.value);
                                                setOrgcdCheck(true);
                                                if (refLvl3.current) {
                                                    if (refLvl3.current.state.value) {
                                                        refLvl3.current.state.value.label = "*선택";
                                                    }
                                                }
                                                if (refLvl4.current) {
                                                    if (refLvl4.current.state.value) {
                                                        refLvl4.current.state.value.label = "*선택";
                                                    }
                                                }
                                            }}
                                            defaultValue={{ label: "*선택" }}
                                            ref={refLvl2}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label class="required">부서</label>
                                        <Select
                                            className="selector-double"
                                            options={cmmGetData("deptcdAll4", 3, orgcd)}
                                            onChange={(e) => {
                                                SetLvl3(e.value);

                                                if (refLvl4.current) {
                                                    if (refLvl4.current.state.value) {
                                                        refLvl4.current.state.value.label = "*선택";
                                                    }
                                                }
                                            }}
                                            defaultValue={{ label: "*선택" }}
                                            ref={refLvl3}
                                        />
                                        <Select
                                            className="selector-double"
                                            options={cmmGetData("deptcdAll4", 4, lvl3)}
                                            onChange={(e) => {
                                                SetLvl(e.value);
                                            }}
                                            defaultValue={{ label: "*선택" }}
                                            ref={refLvl4}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label class="required">이메일</label>
                                        <input
                                            {...register("email", {
                                                required: "필수정보입니다.",
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "올바른 형식의 이메일을 입력하세요",
                                                },
                                            })}
                                            type="email"
                                            className="form-control"
                                            maxLength="50"
                                            // placeholder="메일 주소를 입력해주세요"
                                        />
                                    </div>
                                    {errors.email && <span className="span-box">{errors.email.message}</span>}
                                    <div className="form-group">
                                        <label class="required">핸드폰 번호</label>
                                        <input
                                            {...register("cellphone", {
                                                required: "필수정보입니다.",
                                                pattern: {
                                                    value: /^[0-9]{11}$/,
                                                    message: "올바른 형식의 전화번호를 입력하세요",
                                                },
                                            })}
                                            type="Number"
                                            className="form-control"
                                            // placeholder=" - 없이 핸드폰 번호를 입력해주세요"
                                        />
                                    </div>
                                    {errors.cellphone && <span className="span-box">{errors.cellphone.message}</span>}
                                    <div className="form-group">
                                        <label class="required">생년월일</label>
                                        <input
                                            {...register("birthdt", {
                                                required: "필수정보입니다.",
                                                pattern: {
                                                    value: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
                                                    message: "올바른 형식의 생년월일을 입력하세요",
                                                },
                                            })}
                                            type="date"
                                            // style={{fontSize:"13px"}}
                                            className="form-control"
                                        />
                                    </div>
                                    {errors.birthdt && <span className="span-box">{errors.birthdt.message}</span>}
                                    <div className="form-group">
                                        <label class="required">비밀번호</label>
                                        <input
                                            {...register("pwd", {
                                                required: "필수정보입니다.",

                                                pattern: {
                                                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                                                    message: "1개 이상의 특수문자를 포함 8 ~ 16 자로 입력해주세요",
                                                },
                                            })}
                                            onChange={() => {
                                                setWatchPwd(watch("pwd"));
                                            }}
                                            type="password"
                                            className="form-control"
                                            // placeholder="1개 이상의 특수문자를 포함 8 ~ 16 자로 입력해주세요."
                                        />
                                    </div>
                                    {errors.pwd && <span className="span-box">{errors.pwd.message}</span>}
                                    <div className="form-group">
                                        <label class="required">비밀번호 확인</label>
                                        <input
                                            {...register("checkPwd", {
                                                required: "필수정보입니다.",
                                                validate: {
                                                    check: (value) =>
                                                        value === watchPwd || "비밀번호가 일치하지 않습니다.",
                                                },
                                                pattern: {
                                                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                                                    message: "위의 비밀번호와 일치한 값을 입력하세요",
                                                },
                                            })}
                                            onChange={(e) => {
                                                setWatchPwd(watch("pwd"));
                                            }}
                                            type="password"
                                            className="form-control"
                                            // placeholder="위의 비밀번호와 일치한 값을 입력해주세요"
                                        />
                                    </div>
                                    {errors.checkPwd && <span className="span-box">{errors.checkPwd.message}</span>}
                                    <div className="form-group">
                                        <label class="required">권한그룹</label>
                                        <Select
                                            className="selector-single"
                                            options={usergrpcdOptions}
                                            onChange={(e) => {
                                                setUsergrpcdCheck(true);
                                                setUsergrpcd(e.value);
                                            }}
                                            defaultValue={{ label: "선택" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label class="required">전문가여부</label>
                                        <input
                                            type="radio"
                                            value="Y"
                                            name="expertYN"
                                            onChange={() => {
                                                setExpertcdCheck(true);
                                                setExpertcd("Y");
                                            }}
                                            className="expert-positive"
                                        />{" "}
                                        <span className="txt-expert">예</span>
                                        <input
                                            type="radio"
                                            value="N"
                                            name="expertYN"
                                            onChange={() => {
                                                setExpertcdCheck(true);
                                                setExpertcd("N");
                                            }}
                                            className="expert-negative"
                                        />{" "}
                                        <span className="txt-expert">아니오</span>
                                    </div>
                                    <div className="form-group">
                                        <label class="required">MDM </label>
                                        <Select
                                            className="selector-single"
                                            options={mdmcdOptions}
                                            onChange={(e) => {
                                                setMdmcdCheck(true);
                                                setMdmcd(e.value);
                                            }}
                                            defaultValue={{ label: "선택" }}
                                        />
                                    </div>
                                </div>

                                <div className="button-group">
                                    <Button className="btn-save" type="submit">
                                        저장
                                    </Button>
                                    <Button
                                        className="btn-cancel"
                                        variant="secondary"
                                        onClick={() => {
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        </>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
}

export default AddUser;
