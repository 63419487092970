import React, { useEffect, useRef } from "react";
//메뉴 관리
import "../../../css/User.css";
import "../../../css/userModal.css";
import { Table, Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from "react-select";

//공통함수
import setAlertMessage from "../../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetUser from "../../../cmm/cmmGetUser";

//순서변경
function ChangeSeq(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    let refreshState = useSelector((state) => state.refreshReducer);
    const makeSeq = () => {
        let tempArray = [];
        for (let i = 0; i < props.data.length; i++) {
            tempArray.push({ value: `${i + 1}`, label: `${i + 1}` });
        }
        return tempArray;
    };
    let seqcd = makeSeq();

    let [seqState, setSeqState] = useState([{ label: "1", value: "1" }]);
    const pmenucd = props.lvlcd;

    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const [selectSeq, setSelectSeq] = useState("1");
    const [selectSeqCheck, setSelectSeqCheck] = useState(false);

    const [updateSeqData, setUpdateSeqData] = useState(props.data);

    const onSubmit = async (data) => {
        if (selectSeqCheck) {
            axios.post(`${server}/api/menuInfo/updateSeq`, {
                sessionid: sessionStorage.getItem("SESSION"),
                pmenuid: props.updatedata.pmenuid,
                menuid: props.updatedata.menuid,
                beforeSeq: selectSeq,
                afterSeq: props.updatedata.seq,
                allData: props.allData,
                userid: ID,
            });

            refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
            props.setShow(false);
            window.location.reload();
        } else {
            setAlertMessage("E011");
        }

        //값이 수정됬을 경우, Management에서 값을 다시 조회하여 실시간으로 바뀐 데이터를 유지하기 위한 redux
    };

    const onSubmit2 = async (data) => {
        if (selectSeqCheck) {
            axios
                .post(`${server}/api/menuInfo/updateSeqAll`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    pmenuid: props.updatedata.pmenuid,
                    menuid: props.updatedata.menuid,
                    beforeSeq: selectSeq,
                    afterSeq: props.updatedata.seq,
                    userid: ID,
                })
                .then(() => {})
                .catch(() => {});

            refreshState ? dispatch({ type: "refreshFalse" }) : dispatch({ type: "refreshTrue" });
            props.setShow(false);
            window.location.reload();
        } else {
            setAlertMessage("E011");
        }
    };

    return (
        <>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                {" "}
                <div className="txt-title">
                    <span> 순서 변경 </span>
                </div>
                <hr className="headerline-long"></hr>
                <Modal.Body>
                    <>
                        {props.updatedata.lvl === 1 ? (
                            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                                <Table striped bordered hover style={{ width: "510px", marginLeft: "38px" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "30%" }}>상위 메뉴</td>
                                            <td>{props.updatedata.pmenunm}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "30%" }}>현재 메뉴</td>
                                            <td>{props.updatedata.menunm}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table
                                    striped
                                    bordered
                                    hover
                                    style={{ width: "510px", marginLeft: "38px", overflow: "scroll" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>순서</th>
                                            <th>메뉴명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.allData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                                            ? props.allData.map((data, index) => {
                                                  // Slice 된 만큼 반복문 실행
                                                  return (
                                                      <>
                                                          {data.lvl === 1 ? (
                                                              <tr key={index} onClick={() => {}}>
                                                                  {props.updatedata.menunm === data.menunm ? (
                                                                      <>
                                                                          <td style={{ width: "30%", color: "red" }}>
                                                                              {" "}
                                                                              {data.seq}
                                                                          </td>
                                                                          <td style={{ color: "red" }}>
                                                                              {data.menunm}
                                                                          </td>
                                                                      </>
                                                                  ) : (
                                                                      <>
                                                                          <td style={{ width: "30%" }}> {data.seq}</td>
                                                                          <td>{data.menunm}</td>
                                                                      </>
                                                                  )}
                                                              </tr>
                                                          ) : null}
                                                      </>
                                                  );
                                              })
                                            : null}
                                    </tbody>
                                </Table>
                                <div className="form-group">
                                    <label>순서 </label>

                                    <Select
                                        className="selector-single"
                                        options={props.seq}
                                        onChange={(e) => {
                                            setSelectSeq(e.value);
                                            setSelectSeqCheck(true);
                                        }}
                                        defaultValue={{ value: null, label: "*선택" }}
                                    ></Select>
                                </div>

                                <div style={{ marginTop: "30px" }}>
                                    <Button
                                        className="btn-positive"
                                        variant="secondary"
                                        type="submit"
                                        onClick={() => {}}
                                    >
                                        저장
                                    </Button>

                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmit(onSubmit2)}>
                                <Table striped bordered hover style={{ width: "510px", marginLeft: "38px" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "30%" }}>상위 메뉴</td>
                                            <td>{props.updatedata.pmenunm}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "30%" }}>현재 메뉴</td>
                                            <td>{props.updatedata.menunm}</td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <Table
                                    striped
                                    bordered
                                    hover
                                    style={{ width: "510px", marginLeft: "38px", overflow: "scroll" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>순서</th>
                                            <th>메뉴명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                                            ? props.data.map((data, index) => {
                                                  // Slice 된 만큼 반복문 실행
                                                  return (
                                                      <>
                                                          <tr key={index} onClick={() => {}}>
                                                              {props.updatedata.menunm === data.menunm ? (
                                                                  <>
                                                                      <td style={{ width: "30%", color: "red" }}>
                                                                          {" "}
                                                                          {data.seq}
                                                                      </td>
                                                                      <td style={{ color: "red" }}>{data.menunm}</td>
                                                                  </>
                                                              ) : (
                                                                  <>
                                                                      <td style={{ width: "30%" }}> {data.seq}</td>
                                                                      <td>{data.menunm}</td>
                                                                  </>
                                                              )}
                                                          </tr>
                                                      </>
                                                  );
                                              })
                                            : null}
                                    </tbody>
                                </Table>
                                {/* 여기 수정해야함  */}

                                <div className="form-group">
                                    <label>순서 </label>

                                    <Select
                                        className="selector-single"
                                        options={seqcd}
                                        onChange={(e) => {
                                            setSelectSeq(e.value);
                                            setSelectSeqCheck(true);
                                        }}
                                        defaultValue={{ value: null, label: "*선택" }}
                                    ></Select>
                                </div>
                                <div style={{ marginTop: "30px" }}>
                                    <Button
                                        className="btn-positive"
                                        variant="secondary"
                                        type="submit"
                                        onClick={() => {}}
                                    >
                                        저장
                                    </Button>

                                    <Button
                                        className="btn-negative"
                                        variant="secondary"
                                        onClick={() => {
                                            reset();
                                            props.setShow(false);
                                        }}
                                    >
                                        취소
                                    </Button>
                                </div>
                            </form>
                        )}
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ChangeSeq;
