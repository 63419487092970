import React, { useRef } from "react";
//메뉴 관리
import "../../css/User.css";
import "../../css/userModal.css";
import "../../css/manage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2"; // .env 파일에서 가져온 db_config
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../cmm/cmmGetData";
import { ref } from "yup";
import PageBtn from "../../cmm/cmmPageBtn";

//Modal
import AddMenu from "./MenuInfoModal/AddMenu";
import ChangeSeq from "./MenuInfoModal/ChangeSeq";
import ChangePmenu from "./MenuInfoModal/ChangePmenu";
import Remarks from "./MenuInfoModal/Remarks";
import cmmGetUser from "../../cmm/cmmGetUser";

function MenuInfo(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(() => {
        (async () => {
            setSessionData(await cmmGetUser());
        })();
    }, []);

    const id = props.ID;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const [modalShowRemarks, setModalShowUseRemarks] = useState(false);
    const [modalShowAddMenu, setModalShowAddMenu] = useState(false);
    const [modalShowChangePmenu, setModalShowChangePmenu] = useState(false);
    const [modalShowChangeSeq, setModalShowChangeSeq] = useState(false);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;
    const refs = useRef([]);
    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();

    let [changeCheck, setChangeCheck] = useState(false);
    //조회 요청할 데이터 저장
    let [saveAlldata, setSaveAlldata] = useState([
        {
            menuid: "",
            menunm: "",
            url: "",
            useyn: "",
        },
    ]);

    //조회 결과 데이터 저장
    let [inquiryResultData, setInquiryResultData] = useState(false);
    let [inquiryResultData2, setInquiryResultData2] = useState(false);

    let temp;
    const [seq, setSeq] = useState(1);
    const [inquiryData, setInquiryData] = useState("");
    //업데이트시 사용할 데이터
    let [updateData, setUpdateData] = useState([
        {
            menunm: "",
            lvl: "",
            useyn: "",
        },
    ]);

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용
    let updateKeyTemp = "";

    //useForm 관련
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const onSubmit = async (data) => {
        axios
            .post(`${server}/api/menuInfo/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                menunm: data.menunm,
                lvl: lvl,
                useyn: useyn,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setInquiryResultData2(response.data);
                    setSaveAlldata(response.data); //데이터 전체 수정 할때 사용

                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                } else {
                }
            })
            .catch((err) => {});
    };

    const onSubmit2 = async (data) => {
        reset();
    };

    /*useEffect는
[]안에 값 선언시, [] 값이 바뀌거나,
페이지가 refresh될때 함수가 실행됨!
*/

    let [NavData, SetNavData] = useState([{ label: "", value: "" }]);
    let lvlcd = [{ value: "null", label: "null" }];
    let [lvlcdState, setLvlcdState] = useState([{ label: "", value: "" }]);

    useEffect(() => {
        axios
            .post(`${server}/api/cmm/getNavDataLvl1`, {
                sessionid: sessionStorage.getItem("SESSION"),
                userid: id,
            })
            .then((res) => {
                res.data.map((element, index) => {
                    lvlcd.push({
                        value: element.menuid,
                        label: element.menunm,
                    });
                });

                lvlcd.shift(); // 에러방지값 pop()
            })
            .catch((err) => {});

        setLvlcdState(lvlcd);
    }, []);

    useEffect(() => {
        onSubmit(watch());
    }, [refreshState]);

    useEffect(() => {
        if (inquiryResultData) {
            inquiryResultData
                .slice(minpage, maxPage) // pagination 을 위해 사용
                .map((data, index) => {
                    let a = document.getElementsByName("menunm");
                    // let b = document.getElementsByName("url");
                    a[index + 1].value = data.menunm;
                    //b[index].value = data.url;
                });
        }
    }, [page]);

    let lvlConfig = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
    ];
    let useynConfig = [
        { value: "A", label: "전체" },
        { value: "Y", label: "사용" },
        { value: "N", label: "미사용" },
    ];
    let [lvl, setLvl] = useState("");
    let [useyn, setUseyn] = useState("");

    const onChangeHandler = (e) => {
        if (changeCheck === false) setChangeCheck(true);
        temp = { ...saveAlldata };
        if (e.target) {
            for (var i = 0; i < saveAlldata.length; i++) {
                if (temp[i].menuid === e.target.id) {
                    switch (e.target.name) {
                        case "menunm":
                            temp[i].menunm = e.target.value;
                            break;
                        case "url":
                            temp[i].url = e.target.value;
                            break;
                        case "useyn":
                            temp[i].useyn = e.target.value;
                            break;

                        default:
                            break;
                    }
                }
            }
        } else {
        }
    };

    const onSaveBtnHandler = (e) => {
        if (changeCheck) {
            axios
                .post(`${server}/api/menuInfo/saveAll`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    saveAlldata: saveAlldata,
                    userid: id,
                })
                .then(() => {
                    //저장에 성공했을경우
                    setChangeCheck(false);
                })
                .catch((err) => {});
        }
        window.location.reload();
    };
    let useynConfig2 = [
        { value: "Y", label: "사용" },
        { value: "N", label: "미사용" },
    ];
    //enter키 눌러도 아무 반응 안하게
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    return (
        <>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td style={{ width: "15%" }}>
                                    <label style={{ marginTop: "5px" }}>메뉴명</label>
                                </td>
                                <td style={{ width: "10%" }}>
                                    <input
                                        {...register("menunm", {})}
                                        type="text"
                                        className="input-soloTr"
                                        onKeyPress={handleKeyPress}
                                        maxLength="20"
                                    ></input>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <label style={{ marginTop: "5px" }}>메뉴 단계</label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-soloTr"
                                        //   defaultValue={{ value: lvlConfig[0].value, label: lvlConfig[0].label }}
                                        options={lvlConfig}
                                        onChange={(e) => {
                                            setLvl(e.value);
                                        }}
                                        defaultValue={{ label: "선택" }}
                                    />
                                </td>

                                <td style={{ width: "12%" }}>
                                    <label style={{ marginTop: "5px", marginLeft: "-30px" }}>사용여부</label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-soloTr"
                                        options={useynConfig}
                                        defaultValue={{
                                            value: useynConfig[0].value,
                                            label: useynConfig[0].label,
                                        }}
                                        onChange={(e) => {
                                            setUseyn(e.value);
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <Button
                        className="btn-add"
                        variant="secondary"
                        onClick={() => {
                            setModalShowAddMenu(true);
                        }}
                        style={{ marginRight: "110px" }}
                    >
                        추가
                    </Button>
                    <Button
                        className="btn-saveTriple"
                        variant="secondary"
                        type="btn"
                        onClick={onSaveBtnHandler}
                        style={{ marginRight: "50px" }}
                    >
                        저장
                    </Button>
                    <Button className="btn-search" type="submit">
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
            </form>
            <form onSubmit={handleSubmit(onSubmit2)}>
                <Table bordered hover style={{ overflow: "scroll" }}>
                    <thead className="style-tHeader">
                        <tr>
                            <th>순번</th>
                            <th>메뉴ID</th>
                            <th>메뉴명</th>
                            <th>상위메뉴</th>
                            <th>메뉴단계</th>
                            <th>순서</th>
                            <th>URL</th>
                            <th>사용여부</th>
                            <th>비고</th>
                            <th>상위메뉴이동</th>
                            <th>순서변경</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                        {inquiryResultData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                            ? inquiryResultData
                                  .slice(minpage, maxPage) // pagination 을 위해 사용
                                  .map((data, index) => {
                                      // Slice 된 만큼 반복문 실행
                                      return (
                                          <>
                                              <tr
                                                  key={index}
                                                  onClick={() => {
                                                      updateKeyTemp = data;
                                                      //setState는 비동기성이라서, data 는 state로 저장(수정 기능 중 default value에 사용할 값_화면 전환이 일어나서 비동기 처리가 이루어짐)
                                                      setUpdateData(data);
                                                  }}
                                              >
                                                  <td>{minpage + index + 1}</td>
                                                  <td>{data.menuid}</td>
                                                  <td>
                                                      <input
                                                          class="input-flexible"
                                                          name="menunm"
                                                          id={data.menuid}
                                                          placeholder={data.menunm}
                                                          onChange={onChangeHandler}
                                                          // defaultValue={data.menunm}
                                                          type="text"
                                                          maxLength="20"
                                                      ></input>
                                                  </td>
                                                  <td style={{ width: "135px" }}>{data.pmenunm}</td>
                                                  <td style={{ width: "83px" }}>{data.lvl}</td>
                                                  <td style={{ width: "83px" }}>{data.seq}</td>

                                                  <td>
                                                      {data.lvl == 1 ? (
                                                          <input name="url" id={data.menuid} type="hidden"></input>
                                                      ) : (
                                                          data.url
                                                      )}
                                                  </td>
                                                  <td>
                                                      {data.useyn === "Y" ? (
                                                          <>
                                                              <Select
                                                                  name="useyn"
                                                                  id={data.menuid}
                                                                  className="basic-single"
                                                                  options={useynConfig2}
                                                                  defaultValue={{
                                                                      id: data.menuid,
                                                                      value: data.useyn,
                                                                      label: "사용",
                                                                  }}
                                                                  onChange={(e) => {
                                                                      temp = { ...saveAlldata };
                                                                      if (changeCheck === false) setChangeCheck(true);
                                                                      for (var i = 0; i < saveAlldata.length; i++) {
                                                                          if (temp[i].menuid === data.menuid) {
                                                                              temp[i].useyn = e.value;
                                                                          }
                                                                      }
                                                                  }}
                                                                  ref={(e) => (refs.current[index] = e)}
                                                              />
                                                          </>
                                                      ) : (
                                                          <>
                                                              <input type="hidden"></input>
                                                              <Select
                                                                  name="useyn"
                                                                  id={data.menuid}
                                                                  className="basic-single"
                                                                  options={useynConfig2}
                                                                  defaultValue={{
                                                                      id: data.menuid,
                                                                      value: data.useyn,
                                                                      label: "미사용",
                                                                  }}
                                                                  onChange={(e) => {
                                                                      temp = { ...saveAlldata };
                                                                      if (changeCheck === false) setChangeCheck(true);
                                                                      for (var i = 0; i < saveAlldata.length; i++) {
                                                                          if (temp[i].menuid === data.menuid) {
                                                                              temp[i].useyn = e.value;
                                                                          }
                                                                      }
                                                                  }}
                                                                  ref={(e) => (refs.current[index] = e)}
                                                              />
                                                          </>
                                                      )}
                                                  </td>

                                                  <td>
                                                      <Button
                                                          className="btn-table"
                                                          variant="secondary"
                                                          value="copyrightterm"
                                                          onClick={() => {
                                                              setUpdateData(data);
                                                              setModalShowUseRemarks(true);
                                                          }}
                                                      >
                                                          보기
                                                      </Button>
                                                  </td>
                                                  <td>
                                                      {data.lvl == "2" ? (
                                                          <Button
                                                              className="btn-table"
                                                              variant="secondary"
                                                              value="perinfoterm"
                                                              onClick={() => {
                                                                  setUpdateData(data);
                                                                  if (changeCheck === false) {
                                                                      setModalShowChangePmenu(true);
                                                                  } else {
                                                                      setAlertMessage("I005");
                                                                  }
                                                              }}
                                                          >
                                                              이동
                                                          </Button>
                                                      ) : null}
                                                  </td>

                                                  <td>
                                                      <Button
                                                          className="btn-table"
                                                          variant="secondary"
                                                          onClick={() => {
                                                              if (changeCheck === false) {
                                                                  if (data.lvl === 1) {
                                                                      axios
                                                                          .post(`${server}/api/menuInfo/selectSeq1`, {
                                                                              sessionid:
                                                                                  sessionStorage.getItem("SESSION"),
                                                                          })
                                                                          .then((res) => {
                                                                              if (res.data) {
                                                                                  let temp = [{}];
                                                                                  for (
                                                                                      let i = 0;
                                                                                      i < res.data[0].seq;
                                                                                      i++
                                                                                  ) {
                                                                                      temp[i] = {
                                                                                          value: i + 1,
                                                                                          label: i + 1,
                                                                                      };
                                                                                  }

                                                                                  setSeq(temp);
                                                                                  setModalShowChangeSeq(true);
                                                                              }
                                                                          });
                                                                  } else if (data.lvl === 2) {
                                                                      axios
                                                                          .post(`${server}/api/menuInfo/selectSeq2`, {
                                                                              sessionid:
                                                                                  sessionStorage.getItem("SESSION"),
                                                                          })
                                                                          .then((res) => {
                                                                              if (res.data) {
                                                                                  let temp = [{}];
                                                                                  for (
                                                                                      let i = 0;
                                                                                      i < res.data[0].seq;
                                                                                      i++
                                                                                  ) {
                                                                                      temp[i] = {
                                                                                          value: i + 1,
                                                                                          label: i + 1,
                                                                                      };
                                                                                  }

                                                                                  setSeq(temp);
                                                                                  axios
                                                                                      .post(
                                                                                          `${server}/api/menuInfo/inquiryPmenu`,
                                                                                          {
                                                                                              sessionid:
                                                                                                  sessionStorage.getItem(
                                                                                                      "SESSION"
                                                                                                  ),
                                                                                              pmenuid: data.pmenuid,
                                                                                          }
                                                                                      )
                                                                                      .then((res) => {
                                                                                          if (res.data) {
                                                                                              setInquiryData(res.data);
                                                                                              setModalShowChangeSeq(
                                                                                                  true
                                                                                              );
                                                                                          }
                                                                                      });
                                                                              }
                                                                          });
                                                                  }
                                                              } else {
                                                                  setAlertMessage("I005");
                                                              }
                                                          }}
                                                      >
                                                          변경
                                                      </Button>
                                                  </td>
                                                  <td>
                                                      <Button
                                                          className="btn-table_delete"
                                                          onClick={() => {
                                                              axios
                                                                  .post(`${server}/api/menuInfo/deleteMenu`, {
                                                                      sessionid: sessionStorage.getItem("SESSION"),
                                                                      menuid: data.menuid,
                                                                  })
                                                                  .then((res) => {
                                                                      if (res.data) setAlertMessage("I003");
                                                                      refreshState
                                                                          ? dispatch({ type: "refreshFalse" })
                                                                          : dispatch({ type: "refreshTrue" });
                                                                  })
                                                                  .catch(() => {});
                                                          }}
                                                      >
                                                          삭제
                                                      </Button>
                                                      {}
                                                  </td>
                                              </tr>
                                          </>
                                      );
                                  })
                            : null}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="12" style={{ borderBottom: "none" }}>
                                <PageBtn
                                    data={inquiryResultData ? inquiryResultData : 0}
                                    page={page}
                                    setPage={setPage}
                                    count={count}
                                    btnRefresh={btnRefresh}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </form>
            <AddMenu lvlcd={lvlcdState} show={modalShowAddMenu} setShow={setModalShowAddMenu} updateData={updateData} />
            <ChangePmenu
                lvlcd={lvlcdState}
                show={modalShowChangePmenu}
                setShow={setModalShowChangePmenu}
                updateData={updateData}
            />
            <ChangeSeq
                lvlcd={lvlcdState}
                seq={seq}
                data={inquiryData}
                show={modalShowChangeSeq}
                setShow={setModalShowChangeSeq}
                updatedata={updateData}
                allData={inquiryResultData}
            />
            <Remarks
                lvlcd={lvlcdState}
                show={modalShowRemarks}
                setShow={setModalShowUseRemarks}
                updateData={updateData}
            />
        </>
    );
}

export default MenuInfo;
