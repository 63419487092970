import React, { useRef } from "react";
import "../../css/User.css";
import "../../css/userModal.css";
import "../../css/manage.css";
import { Table, Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2"; // .env 파일에서 가져온 db_config

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmGetData from "../../cmm/cmmGetData";
import cmmGetUser from "../../cmm/cmmGetUser";

//메뉴 권한 관리

function MenuAuth() {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    /*
  .
  .
  .
  .
  공통
  */
    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    const usergrpcdOptions = cmmGetData("usergrpcd"); // 권한그룹 기관 config
    let [usergrpcd, setUsergrpcd] = useState("null"); // 권한그룹
    const myRef = useRef([]);
    let [useyn, setUseyn] = useState("A");
    let useynConfig = [
        { value: "A", label: "전체" },
        { value: "Y", label: "사용" },
        { value: "N", label: "미사용" },
    ];
    let checkTemp = false;
    let temp = "";
    let tempBool = false;

    let [useynUpdate, setUseynUpdate] = useState([]);
    let [changeCheckState, setChangeCheckState] = useState(1);

    let useynConfig2 = [
        { value: "Y", label: "사용" },
        { value: "N", label: "미사용" },
    ];
    let useynConfig3 = [{ value: "N", label: "변경불가" }];
    let [hiddenCheck, setHiddenCheck] = useState(false);

    let [useynCheck, setUseynCheck] = useState(false);
    let useModeConfig = [
        { value: "W", label: "웹" },
        { value: "M", label: "모바일" },
    ];
    let [useMode, setUseMode] = useState("");
    let [useModeCheck, setUseModeCheck] = useState(false);

    //조회 결과 데이터 저장
    let [inquiryResultData, setInquiryResultData] = useState(false);

    //조회 결과 데이터 저장
    let [inquiryResultDataTemp, setInquiryResultDataTemp] = useState({ useyn: "Y" });

    //조회 결과 page 수 조절 변수
    let [page, setPage] = useState(1);

    let minpage = page * 10 - 10;

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용
    let updateKeyTemp = "";

    //useForm 관련
    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        hiddenCheck ? setHiddenCheck(false) : setHiddenCheck(true);
        if (useModeCheck && useynCheck) {
            await axios
                .post(`${server}/api/menuAuth/inquiry`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    userid: ID,
                    useMode: useMode,
                    usergrpcd: usergrpcd,
                    useyn: useyn,
                })
                .then((response) => {
                    if (response.data) {
                        setInquiryResultData(response.data);
                        setInquiryResultDataTemp(response.data);
                        setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                    } else {
                    }
                })
                .catch((err) => {});
        } else {
            setAlertMessage("E011");
        }
    };

    const onSubmitSaveBtn = async (data) => {
        let insertData = [];
        let insertCount = 0;
        let deleteData = [];
        let deleteCount = 0;
        let i = 0;
        let j = 0;

        //입력받은 데이터 Y/N 구별
        for (i = 0; i < useynUpdate.length; i++) {
            if (useynUpdate[i].useyn === "N") {
                deleteData[deleteCount++] = useynUpdate[i];
            } else {
                insertData[insertCount++] = useynUpdate[i];
            }
        }

        //insertData 하위메뉴 추가
        for (i = 0; i < insertData.length; i++) {
            for (j = 0; j < inquiryResultData.length; j++) {
                if (insertData[i]) {
                    if (insertData[i].menuid === inquiryResultData[j].pmenuid) {
                        insertData[insertCount++] = inquiryResultData[j];
                    } else {
                    }
                }
            }
        }
        //쿼리 실행에 모자른 데이터 추가
        for (i = 0; i < insertData.length; i++) {
            insertData[i].usergrpcd = usergrpcd;
            insertData[i].userid = ID;
            insertData[i].usemode = useMode;
        }

        //deleteData 하위메뉴 추가
        for (i = 0; i < deleteData.length; i++) {
            for (j = 0; j < inquiryResultData.length; j++) {
                if (deleteData[i]) {
                    if (deleteData[i].menuid === inquiryResultData[j].pmenuid) {
                        deleteData[deleteCount++] = inquiryResultData[j];
                    } else {
                    }
                }
            }
        }

        //쿼리 실행에 모자른 데이터 추가
        for (i = 0; i < deleteData.length; i++) {
            deleteData[i].usergrpcd = usergrpcd;
            deleteData[i].userid = ID;
            deleteData[i].usemode = useMode;
        }

        //삭제와 추가해야할 데이터가 겹치는 경우 추가데이터를 삭제
        let deleteIndex = [];
        let deleteIndexCount = 0;
        for (i = 0; i < insertData.length; i++) {
            for (j = 0; j < deleteData.length; j++) {
                if (insertData[i].menuid === deleteData[j].menuid) {
                    deleteIndex[deleteIndexCount++] = i;
                }
            }
        }
        if (deleteIndex) {
            for (i = 0; i < deleteIndex.length; i++) {
                delete insertData[deleteIndex[i]];
            }
        }

        //post 요청
        if (insertData[0])
            axios.post(`${server}/api/menuAuth/insert`, {
                sessionid: sessionStorage.getItem("SESSION"),
                insertData: insertData,
            });
        if (deleteData[0])
            axios.post(`${server}/api/menuAuth/delete`, {
                sessionid: sessionStorage.getItem("SESSION"),
                deleteData: deleteData,
            });

        if (useynUpdate[0]) window.location.reload();
    };

    /*useEffect는
[]안에 값 선언시, [] 값이 바뀌거나,
페이지가 refresh될때 함수가 실행됨!
*/
    useEffect(() => {}, [inquiryResultData]);

    return (
        <>
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox_soloLine">
                    <table hover style={{ width: "100%" }}>
                        <tbody style={{ marginTop: "40px" }}>
                            <tr>
                                <td style={{ width: "15%" }}>
                                    <label className="label-soloTr">화면구분</label>
                                </td>
                                <td>
                                    <Select
                                        required="required"
                                        className="selector-soloTr"
                                        options={useModeConfig}
                                        onChange={(e) => {
                                            setUseMode(e.value);
                                            setUseModeCheck(true);
                                        }}
                                        defaultValue={{
                                            label: "*선택",
                                            value: "null",
                                        }}
                                    ></Select>{" "}
                                </td>
                                <td style={{ width: "10%" }}>
                                    <label className="label-soloTr">권한그룹</label>
                                </td>
                                <td>
                                    <Select
                                        required="required"
                                        className="selector-soloTr"
                                        options={usergrpcdOptions}
                                        onChange={(e) => {
                                            setUsergrpcd(e.value);
                                            setUseynCheck(true);
                                        }}
                                        defaultValue={{
                                            label: "*선택",
                                            value: "null",
                                        }}
                                    ></Select>
                                </td>
                                <td style={{ width: "10%" }}>
                                    <label className="label-soloTr">사용여부</label>
                                </td>
                                <td>
                                    <Select
                                        className="selector-soloTr"
                                        options={useynConfig}
                                        defaultValue={{ value: useynConfig[0].value, label: useynConfig[0].label }}
                                        onChange={(e) => {
                                            setUseyn(e.value);
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <Button
                        className="btn-add"
                        variant="secondary"
                        onClick={() => {
                            onSubmitSaveBtn();
                        }}
                    >
                        저장
                    </Button>
                    <Button className="btn-search" variant="secondary" type="submit">
                        <FontAwesomeIcon icon={faSearch} /> 조회
                    </Button>
                </div>
            </form>
            <Table bordered hover style={{ overflow: "scroll" }}>
                <thead className="style-tHeader">
                    <tr>
                        <th>순번</th>
                        <th>메뉴ID</th>
                        <th>메뉴명</th>
                        <th>상위ID</th>
                        <th>상위메뉴명</th>
                        <th>권한</th>
                    </tr>
                </thead>
                <tbody>
                    {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                    {inquiryResultData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                        ? inquiryResultData.map((data, index) => {
                              // Slice 된 만큼 반복문 실행

                              return (
                                  <>
                                      <tr
                                          onClick={() => {
                                              updateKeyTemp = data.begindt;
                                              //setState는 비동기성이라서, data 는 state로 저장(수정 기능 중 default value에 사용할 값_화면 전환이 일어나서 비동기 처리가 이루어짐)
                                          }}
                                      >
                                          <td>{minpage + index + 1}</td>
                                          <td>{data.menuid}</td>
                                          <td>{data.menunm}</td>
                                          <td>{data.pmenuid}</td>
                                          <td>{data.pmenunm}</td>
                                          <td>
                                              {data.useyn === "Y" ? (
                                                  <>
                                                      <input type="hidden" value="1"></input>
                                                      <input type="hidden" value="2"></input>
                                                      <Select
                                                          className="basic-single"
                                                          options={useynConfig2}
                                                          defaultValue={{
                                                              value: data.useyn,
                                                              label: "사용",
                                                          }}
                                                          onChange={(e) => {
                                                              temp = e.value;
                                                              setInquiryResultDataTemp({ useyn: e.value });
                                                              setUseynUpdate([
                                                                  ...useynUpdate,
                                                                  {
                                                                      userid: ID,
                                                                      usemode: useMode,
                                                                      lvl: data.lvl,
                                                                      pmenuid: data.pmenuid,
                                                                      usergrpcd: usergrpcd,
                                                                      menuid: data.menuid,
                                                                      useyn: e.value,
                                                                  },
                                                              ]);

                                                              for (let j = 0; j < inquiryResultData.length; j++) {
                                                                  if (myRef.current[j].props.name === data.menuid)
                                                                      if (myRef) {
                                                                          myRef.current[j].state.value.value = temp;
                                                                          myRef.current[j].state.value.label =
                                                                              temp === "Y" ? "사용" : "미사용";
                                                                      }
                                                              }
                                                          }}
                                                          name={data.pmenuid}
                                                          isDisabled={tempBool}
                                                          ref={(e) => (myRef.current[index] = e)}
                                                      />
                                                  </>
                                              ) : (
                                                  <>
                                                      <input type="hidden" value="1"></input>
                                                      <input type="hidden" value="2"></input>
                                                      <input type="hidden" value="3"></input>
                                                      <Select
                                                          className="basic-single"
                                                          options={useynConfig2}
                                                          defaultValue={{
                                                              value: data.useyn,
                                                              label: "미사용",
                                                          }}
                                                          onChange={(e) => {
                                                              temp = e.value;
                                                              setUseynUpdate([
                                                                  ...useynUpdate,
                                                                  {
                                                                      userid: ID,
                                                                      usemode: useMode,
                                                                      lvl: data.lvl,
                                                                      pmenuid: data.pmenuid,
                                                                      usergrpcd: usergrpcd,
                                                                      menuid: data.menuid,
                                                                      useyn: e.value,
                                                                  },
                                                              ]);
                                                              for (let j = 0; j < inquiryResultData.length; j++) {
                                                                  if (myRef.current[j].props.name === data.menuid)
                                                                      if (myRef) {
                                                                          myRef.current[j].state.value.value = temp;
                                                                          myRef.current[j].state.value.label =
                                                                              temp === "Y" ? "사용" : "미사용";
                                                                          if (temp === "N") {
                                                                              tempBool = true;
                                                                          } else {
                                                                              tempBool = false;
                                                                          }
                                                                      }
                                                              }
                                                          }}
                                                          isDisabled={tempBool}
                                                          name={data.pmenuid}
                                                          ref={(e) => (myRef.current[index] = e)}
                                                      />
                                                  </>
                                              )}
                                          </td>
                                      </tr>
                                  </>
                              );
                          })
                        : null}
                </tbody>
            </Table>
        </>
    );
}

export default MenuAuth;
