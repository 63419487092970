import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import cmmGetUser from "./cmmGetUser";
import axios from "axios";

const SelectOrg = (props) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    const refLvl2 = useRef();
    const refLvl3 = useRef();
    const refLvl4 = useRef();

    const [label, SetLabel] = useState();

    // 부서코드
    const [lvl, SetLvl] = useState("A");
    const [lvl3, SetLvl3] = useState();
    const [lvl4, SetLvl4] = useState();

    const [lvlAllData, setLvlAllData] = useState([]);

    useEffect(() => {
        axios
            .post(`${server}/api/cmm/searchAll3`, {
                sessionid: sessionStorage.getItem("SESSION"),
            })
            .then((res) => {
                setLvlAllData(res.data);
            });
    }, []);

    const selectOption = (num, lvl, all = null) => {
        const filterData = lvlAllData.filter((data) => data.lvl === num).filter((data) => data.pdeptcd === lvl);
        let returnData = [];
        if (all != null) {
            returnData = [{ label: "전체", value: "A" }];
        }
        filterData.map((data) => {
            returnData.push({ label: data.deptnm, value: data.deptcd });
        });

        return returnData;
    };

    // 본인부서 (관리자 A는 전체 조회)
    const selectOption2 = (num, lvl) => {
        let filterData = lvlAllData.filter((data) => data.lvl === num).filter((data) => data.pdeptcd === lvl);
        let returnData = [];
        if (lvl === "A" && num === 2) {
            // 관리자 전용 전체 조회
            returnData.push({ label: "전체", value: "A" });
        }

        filterData.map((data) => {
            returnData.push({ label: data.deptnm, value: data.deptcd });
        });
        return returnData;
    };

    useEffect(() => {
        props.setLvl({ lvl: lvl, label: label });
    }, [lvl]);

    const selectOneAll2 = () => {
        return (
            <Select
                className="selector-soloTr"
                options={selectOption2(2, "A")}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetLvl3(e.value);
                        SetLvl4(e.value);
                        if (refLvl3.current) {
                            if (refLvl3.current.state.value) {
                                refLvl3.current.state.value.label = "선택";
                            }
                        }
                        if (refLvl4.current) {
                            if (refLvl4.current.state.value) {
                                refLvl4.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl2}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectTwoAll2 = () => {
        return (
            <Select
                className="selector-soloTr"
                options={selectOption2(3, lvl3)}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetLvl4(e.value);
                        if (refLvl4.current) {
                            if (refLvl4.current.state.value) {
                                refLvl4.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl3}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectThreeAll2 = () => {
        return (
            <Select
                className="selector-soloTr"
                options={selectOption2(4, lvl4)}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                    }
                }}
                ref={refLvl4}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectOneAll = () => {
        return (
            <Select
                className="selector-soloTr"
                options={selectOption(2, "A")}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetLvl3(e.value);
                        if (refLvl3.current) {
                            if (refLvl3.current.state.value) {
                                refLvl3.current.state.value.label = "선택";
                            }
                        }
                        if (refLvl4.current) {
                            if (refLvl4.current.state.value) {
                                refLvl4.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl2}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectOneAll_bulletin = () => {
        return (
            <Select
                className="selector-soloTr"
                options={selectOption(2, "A", "A")}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetLvl3(e.value);
                        if (refLvl3.current) {
                            if (refLvl3.current.state.value) {
                                refLvl3.current.state.value.label = "선택";
                            }
                        }
                        if (refLvl4.current) {
                            if (refLvl4.current.state.value) {
                                refLvl4.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl2}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectTwoAll = () => {
        return (
            <Select
                className="selector-soloTr"
                options={selectOption(3, lvl3)}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                        SetLvl4(e.value);
                        if (refLvl4.current) {
                            if (refLvl4.current.state.value) {
                                refLvl4.current.state.value.label = "선택";
                            }
                        }
                    }
                }}
                ref={refLvl3}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    const selectThreeAll = () => {
        return (
            <Select
                className="selector-soloTr"
                options={selectOption(4, lvl4)}
                onChange={(e) => {
                    if (e) {
                        SetLabel(e.label);
                        SetLvl(e.value);
                    }
                }}
                ref={refLvl4}
                defaultValue={{ label: "선택" }}
            />
        );
    };

    // 통계 전용
    const selectTable = () => {
        return (
            <div className="style-searchBox_soloLine">
                <table style={{ width: "100%", marginBottom: "-30px" }}>
                    <tbody>
                        <tr style={{ paddingTop: "12px", height: "100px" }}>
                            <td style={{ width: "280px" }}>조직/소속</td>
                            <td>{selectOneAll2()}</td>
                            <td>{selectTwoAll2()}</td>
                            <td>{selectThreeAll2()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    //게시판 전용
    const selectBulletin = () => {
        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <div style={{ marginLeft: "30px", marginTop: "5px" }}>
                                <td>{selectOneAll_bulletin()}</td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{ marginLeft: "30px", marginTop: "5px" }}>
                                <td>{selectTwoAll()}</td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{ marginLeft: "30px", marginTop: "5px" }}>
                                <td>{selectThreeAll()}</td>
                            </div>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const selectType = () => {
        switch (props.type) {
            case "table":
                return selectTable();

            case "bulletin":
                return selectBulletin();

            // default:
            //     return selectDiv();
        }
    };

    return selectType();
};

export default SelectOrg;
