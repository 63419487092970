import React, { useEffect } from "react";
//메뉴 관리
import "../../../css/User.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import { useState } from "react";
import Select from "react-select";
import cmmGetUser from "../../../cmm/cmmGetUser";
import setAlertMessage from "../../../cmm/cmmAlertMessage";

//공통함수

//메뉴추가
function FireTypeChange(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    /*=============*/

    const [fireType, setFireType] = useState();

    const submit = () => {
        if (fireType) {
            axios
                .post(`${server}/api/fileStorageMenu/fireTypeUpdate`, {
                    sessionid: sessionStorage.getItem("SESSION"),
                    data: props.data,
                    firetypecd: fireType,
                    weightid: props.loadData.id,
                })
                .then((res) => {
                    props.setShow(false);
                    axios
                        .post(`${server}/api/fileStorageMenu/searchCopy`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            weightid: props.loadData.id,
                        })
                        .then((res) => {
                            props.setSearchCopy(props.dataJSON({ res: res }));
                        });
                    setAlertMessage("I013").then((res) => {
                        props.imgTarget.click();
                    });
                });
        } else {
            setAlertMessage("E390");
        }
    };
    const labelType = () => {
        let type = [
            { value: "3", label: "열흔" },
            { value: "12", label: "단락흔" },
            { value: "9", label: "판별불가" },
        ];
        return type;
    };

    return (
        <>
            <Modal
                {...props}
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> 화재 유형 변경 </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <div style={{ width: "80%", textAlign: "center", margin: "10px 35px 100px" }}>
                            <Select
                                className="selector-single"
                                options={labelType()}
                                onChange={(e) => {
                                    setFireType(e.value);
                                }}
                            />
                        </div>
                        <div style={{ float: "right", margin: "0px 20px 30px", width: "10%" }}>
                            <Button className="btn-search" variant="secondary" onClick={() => submit()}>
                                선택
                            </Button>
                        </div>
                        <div style={{ float: "right", margin: "0px 20px 30px", width: "10%" }}>
                            <Button className="btn-search" variant="secondary" onClick={() => props.setShow(false)}>
                                취소
                            </Button>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default FireTypeChange;
