//Moudle
import "../../css/User.css";
import "../../css/manage.css";
import "../../css/userModal.css";
import { Table, Button } from "react-bootstrap";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2"; // .env 파일에서 가져온 db_config
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import PageBtn from "../../cmm/cmmPageBtn";

//Modal
import AddTerms from "./UseTermsModal/AddTerms";
import ShowPerinfoTerm from "./UseTermsModal/ShowPerinfoTerm";
import ShowUseTerm from "./UseTermsModal/ShowUseTerm";
import ShowCopyRightTerm from "./UseTermsModal/ShowCopyRightTerm";
import cmmGetUser from "../../cmm/cmmGetUser";

//이용 약관 관리

function UseTerms() {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    //redux 불러오기
    let refreshState = useSelector((state) => state.refreshReducer);
    //redux 값 변경을 위한 dispatch 사용을 위해
    let dispatch = useDispatch();
    // 페이지당 보이는 갯수_pagination

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [btnRefresh, setBtnRefresh] = useState(true);
    const maxPage = page * count;
    const minpage = page * count - count;
    //현재날짜 불러오기
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    month = parseInt(month) < 10 ? "0" + month : month;
    let date = today.getDate(); // 날짜
    date = parseInt(date) < 10 ? "0" + date : date;
    let current = year + "-" + month + "-" + date;
    let temp = new Date(year, month - 1, date);

    temp.setDate(temp.getDate() - 7);
    year = temp.getFullYear(); // 년도
    month = temp.getMonth() + 1; // 월
    date = temp.getDate(); // 날짜

    date = parseInt(date) < 10 ? "0" + date : date;
    let past = year + "-" + month + "-" + date;

    //modalShowUseTerm,modalShowCopyRightTerm,modalShowPerinfoTerm,modalShowAddTerm
    const [modalShowUseTerm, setModalShowUseTerm] = useState(false);
    const [modalShowCopyRightTerm, setModalShowCopyRightTerm] = useState(false);
    const [modalShowPerinfoTerm, setModalShowPerinfoTerm] = useState(false);
    const [modalShowAddTerm, setModalShowAddTerm] = useState(false);
    //조회 결과 데이터 저장
    let [inquiryResultData, setInquiryResultData] = useState(false);
    //조회 결과 page 수 조절 변수

    //변화 생기면 바로 조회되도록
    const [searchData, setSearchData] = useState({
        begindt: "",
        enddt: "",
        useterm: "",
        copyrightterm: "",
        perinfoterm: "",
    });

    //업데이트시 사용할 데이터
    let [updateData, setUpdateData] = useState([
        {
            begindt: "",
            enddt: "",
            useterm: "",
            copyrightterm: "",
            perinfoterm: "",
        },
    ]);

    //state의 set 함수는 비동기성으로 작동하여, 임시 저장을 통해 set 함수를 동기적으로 사용
    let updateKeyTemp = "";

    //useForm 관련
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        // reset,
    } = useForm();

    const onSubmit = (data) => {
        setSearchData({
            begindt: data.begindt,
            enddt: data.enddt,
            useterm: data.useterm,
            copyrightterm: data.copyrightterm,
            perinfoterm: data.perinfoterm,
        });
        axios
            .post(`${server}/api/useTerms/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                begindt: data.begindt,
                enddt: data.enddt,
                useterm: data.useterm,
                copyrightterm: data.copyrightterm,
                perinfoterm: data.perinfoterm,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        axios
            .post(`${server}/api/useTerms/inquiry`, {
                sessionid: sessionStorage.getItem("SESSION"),
                begindt: searchData.begindt,
                enddt: searchData.enddt,
                useterm: searchData.useterm,
                copyrightterm: searchData.copyrightterm,
                perinfoterm: searchData.perinfoterm,
            })
            .then((response) => {
                if (response.data) {
                    setInquiryResultData(response.data);
                    setPage(1); //페이지가 바뀌면, setPage값이 이상하게 설정되는 에러 방지
                }
            })
            .catch((err) => {});
    }, [refreshState, watch]);

    //삭제 버튼 이벤트 처리
    const onDeleteHandler = (e) => {
        //확정된 컬럼 삭제시

        axios
            .post(`${server}/api/cmm/alert`, { code: "Q002", sessionid: sessionStorage.getItem("SESSION") })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        axios
                            .post(`${server}/api/useTerms/deleteTerm`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                cfmid: ID,
                                begindt: updateKeyTemp,
                            })
                            .then((res) => {
                                if (res.data) {
                                    setAlertMessage("I003");
                                    refreshState
                                        ? dispatch({ type: "refreshFalse" })
                                        : dispatch({ type: "refreshTrue" });
                                } else setAlertMessage("E202");
                            })
                            .catch(() => {
                                setAlertMessage("E202");
                            });
                    }
                });
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };
    const onDeleteUnConfirmHandler = (e) => {
        //확정안된 컬럼 삭제시

        axios
            .post(`${server}/api/cmm/alert`, { code: "Q002", sessionid: sessionStorage.getItem("SESSION") })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        axios
                            .post(`${server}/api/useTerms/deleteTerm`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                cfmid: ID,
                                begindt: updateKeyTemp,
                            })
                            .then((res) => {
                                if (res.data) {
                                    setAlertMessage("I003");
                                    refreshState
                                        ? dispatch({ type: "refreshFalse" })
                                        : dispatch({ type: "refreshTrue" });
                                } else setAlertMessage("E202");
                            })
                            .catch(() => {
                                setAlertMessage("E202");
                            });
                    }
                });
            })
            .catch((err) => {
                setAlertMessage("E200");
            });
    };

    //확정버튼  이벤트 처리
    const onCheckedHandler = (e) => {
        //확정된 컬럼
        setAlertMessage("I101");
    };
    const onCheckHandler = (event) => {
        //확정안된 컬럼
        axios
            .post(`${server}/api/cmm/alert`, { code: "I100", sessionid: sessionStorage.getItem("SESSION") })
            .then((response) => {
                Swal.fire({
                    icon: "warning",
                    title: response.data[0].title,
                    text: response.data[0].msgbody,
                    showCancelButton: true,
                    confirmButtonText: "승인",
                    cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        axios
                            .post(`${server}/api/useTerms/confirm`, {
                                sessionid: sessionStorage.getItem("SESSION"),
                                cfmid: ID,
                                begindt: updateKeyTemp,
                            })
                            .then((res) => {
                                if (res.data) {
                                    setAlertMessage("I008");
                                    refreshState
                                        ? dispatch({ type: "refreshFalse" })
                                        : dispatch({ type: "refreshTrue" });
                                } else setAlertMessage("E200");
                            })
                            .catch(() => {
                                setAlertMessage("E200");
                            });
                    }
                });
            })
            .catch((err) => {
                setAlertMessage("E203");
            });
    };

    return (
        <>
            {" "}
            <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
            <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
                <div className="style-searchBox">
                    <table style={{ width: "98%", marginLeft: "20px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="begindt" className="label-search_trUp">
                                        검색 기간
                                    </label>
                                </td>
                                <td>
                                    <div style={{ display: "flex" }}>
                                        <input
                                            id="begindt"
                                            {...register("begindt", {})}
                                            type="date"
                                            //value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                            defaultValue={past}
                                            className="input-date1"
                                        />
                                        <p style={{ marginTop: "20px", marginLeft: "5px" }}> - </p>
                                        <input
                                            id="enddt"
                                            {...register("enddt", {})}
                                            type="date"
                                            // value={cmmchangeFormat(new Date(), "yy-MM-DD")}
                                            defaultValue={current}
                                            className="input-date2"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <label className="label-search_trUp">이용약관 포함 텍스트</label>
                                </td>
                                <td>
                                    <input
                                        {...register("useterm", {})}
                                        type="text"
                                        className="style-input_up"
                                        maxLength="50"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label className="label-search_trDown">저작권 보호정책 텍스트</label>
                                </td>
                                <td>
                                    <input
                                        {...register("copyrightterm", {})}
                                        type="text"
                                        className="style-input_down"
                                        maxLength="50"
                                    />
                                </td>

                                <td>
                                    <label className="label-search_trDown">개인정보 포함 텍스트</label>
                                </td>
                                <td>
                                    <input
                                        {...register("perinfoterm", {})}
                                        type="text"
                                        className="style-input_down"
                                        maxLength="50"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="content" style={{ marginTop: "20px" }}>
                        <Button
                            className="btn-add"
                            variant="secondary"
                            onClick={() => {
                                setModalShowAddTerm(true);
                            }}
                        >
                            신규
                        </Button>
                        <Button className="btn-search" variant="secondary" type="submit">
                            <FontAwesomeIcon icon={faSearch} /> 검색
                        </Button>
                    </div>
                </div>
            </form>
            <Table bordered hover style={{ overflow: "scroll" }}>
                <thead className="style-tHeader">
                    <tr>
                        <th>순번</th>
                        <th>적용개시일</th>
                        <th>이용약관</th>
                        <th>저작권보호정책</th>
                        <th>개인정보이용방침</th>
                        <th>생성일</th>
                        <th>확정일</th>
                        <th>확정자ID</th>
                        <th>확정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {/* [pagination]조회 결과 데이터 동적으로 불러와서 테이블 만들기 */}
                    {inquiryResultData // 페이지 맨 처음 시작시 조회결과가 undefind 으로, 에러 방지
                        ? inquiryResultData
                              .slice(minpage, maxPage) // pagination 을 위해 사용
                              .map((data, index) => {
                                  // Slice 된 만큼 반복문 실행
                                  return (
                                      <>
                                          <tr
                                              onClick={() => {
                                                  updateKeyTemp = data.begindt;
                                                  //setState는 비동기성이라서, data 는 state로 저장(수정 기능 중 default value에 사용할 값_화면 전환이 일어나서 비동기 처리가 이루어짐)
                                                  setUpdateData(data);
                                              }}
                                          >
                                              <td>{minpage + index + 1}</td>

                                              <td>
                                                  {" "}
                                                  {(data.begindt + "").substring(0, 4) +
                                                      "-" +
                                                      (data.begindt + "").substring(4, 6) +
                                                      "-" +
                                                      (data.begindt + "").substring(6, 8)}
                                              </td>
                                              <td>
                                                  <Button
                                                      className="btn-table"
                                                      variant="secondary"
                                                      value="useterm"
                                                      onClick={() => {
                                                          setUpdateData(data);
                                                          setModalShowUseTerm(true);
                                                      }}
                                                  >
                                                      보기
                                                  </Button>
                                              </td>
                                              <td>
                                                  <Button
                                                      className="btn-table"
                                                      variant="secondary"
                                                      value="copyrightterm"
                                                      onClick={() => {
                                                          setUpdateData(data);
                                                          setModalShowCopyRightTerm(true);
                                                      }}
                                                  >
                                                      보기
                                                  </Button>
                                              </td>
                                              <td>
                                                  <Button
                                                      className="btn-table"
                                                      variant="secondary"
                                                      value="perinfoterm"
                                                      onClick={() => {
                                                          setUpdateData(data);
                                                          setModalShowPerinfoTerm(true);
                                                      }}
                                                  >
                                                      보기
                                                  </Button>
                                              </td>
                                              <td>{data.createtm}</td>
                                              <td>{data.cfmtm}</td>
                                              <td>{data.cfmid}</td>

                                              <td>
                                                  <Button
                                                      className="btn-table"
                                                      variant="secondary"
                                                      onClick={() => {
                                                          data.cfmid ? onCheckedHandler() : onCheckHandler();
                                                      }}
                                                  >
                                                      확정
                                                  </Button>
                                              </td>
                                              <td>
                                                  <Button
                                                      className="btn-status_error"
                                                      variant="secondary"
                                                      onClick={() => {
                                                          data.cfmid ? onDeleteHandler() : onDeleteUnConfirmHandler();
                                                      }}
                                                      style={{ width: "100%", height: "100%" }}
                                                  >
                                                      삭제
                                                  </Button>
                                              </td>
                                          </tr>
                                      </>
                                  );
                              })
                        : null}
                </tbody>

                <tfoot>
                    <tr>
                        <td colSpan="10" style={{ borderBottom: "none" }}>
                            <PageBtn
                                data={inquiryResultData ? inquiryResultData : 0}
                                page={page}
                                setPage={setPage}
                                count={count}
                                btnRefresh={btnRefresh}
                            />
                        </td>
                    </tr>
                </tfoot>
            </Table>
            <div className="modal-box">
                <ShowUseTerm
                    show={modalShowUseTerm}
                    setShow={setModalShowUseTerm}
                    //onHide={() => setModalShowUseTerm(false)}
                    begindt={updateData.begindt}
                    useterm={updateData.useterm}
                    cfmid={updateData.cfmid}
                />
                <ShowCopyRightTerm
                    show={modalShowCopyRightTerm}
                    setShow={setModalShowCopyRightTerm}
                    //onHide={() => setModalShowCopyRightTerm(false)}
                    begindt={updateData.begindt}
                    copyrightterm={updateData.copyrightterm}
                    cfmid={updateData.cfmid}
                />
                <ShowPerinfoTerm
                    show={modalShowPerinfoTerm}
                    setShow={setModalShowPerinfoTerm}
                    //onHide={() => setModalShowPerinfoTerm(false)}
                    begindt={updateData.begindt}
                    perinfoterm={updateData.perinfoterm}
                    cfmid={updateData.cfmid}
                />
                <AddTerms
                    show={modalShowAddTerm}
                    setShow={setModalShowAddTerm}
                    // onHide={() => setModalShowAddTerm(false)}
                    begindt={updateData.begindt}
                    perinfoterm={updateData.perinfoterm}
                    cfmid={updateData.cfmid}
                />
            </div>
        </>
    );
}

export default UseTerms;
