//Moudle
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
//공통함수
import cmmGetUser from "../../../cmm/cmmGetUser";
import axios from "axios";

const AppraisalOutput = (props) => {
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/
    const server = process.env.REACT_APP_API_SERVER_HOST;

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        axios
            .post(`${server}/api/reviewReport/pdf`, {
                sessionid: sessionStorage.getItem("SESSION"),
                id: ID,
                statuscd: props.data.statuscd,
                specimenid: props.data.specimenid,
            })
            .then((res) => {
                if (res.data === "update") {
                    axios
                        .post(`${server}/api/reviewReport/create`, {
                            sessionid: sessionStorage.getItem("SESSION"),
                            userid: ID,
                            specimenid: props.data.specimenid,
                        })
                        .then((res) => {
                            setRefresh(!refresh);
                        })
                        .catch((err) => {});
                } else {
                    const obj = document.getElementById("pdf_obj");
                    obj.style.width = "100%";
                    obj.style.height = "842pt";
                    obj.type = "application/pdf";
                    obj.data = res.data;
                }
            });
    }, [refresh]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.TF}
            onHide={() => {
                props.setTF(false);
            }}
        >
            <ModalBody>
                <div id="pdfHTML">
                    <object id="pdf_obj" />
                </div>
            </ModalBody>
        </Modal>
    );
};

export default AppraisalOutput;
