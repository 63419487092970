import moment from "moment";

// date 형식 변환 함수
const cmmchangeFormat = (date, format) => {
    //moment 변환을 함수로 미리 빼 두어서 사용.
    if (moment(date).isValid()) {
        return moment(date).format(format);
    } else {
        return null;
    }
};
export default cmmchangeFormat;
