import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import cmmGetUser from "../../../cmm/cmmGetUser";

// 라벨 선택
function TypeSelect(props) {
    const server = process.env.REACT_APP_API_SERVER_HOST; //Config 가져오기
    /*=====사용자 인증====*/
    const [sessionData, setSessionData] = useState();
    useEffect(async () => {
        setSessionData(await cmmGetUser());
    }, [sessionStorage.getItem("SESSION")]);
    const ID = sessionData ? sessionData.user : null;
    const AUTH = sessionData ? sessionData.usergrpcd : null;
    /*=============*/

    const labelType = () => {
        let type = [
            { value: "flame", label: "열흔" },
            { value: "electric", label: "단락흔" },
        ];
        return type;
    };

    return (
        <>
            <Modal
                {...props}
                show={props.typeSelectModal}
                // onHide={() => props.setTypeSelectModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="outbox-limit" //modal outbox CustomCSSTag 크기 조정
            >
                <div className="outbox-addUsers">
                    <div className="txt-title">
                        <span> Type </span>
                    </div>
                    <hr className="headerline-long"></hr>
                    <Modal.Body>
                        <div style={{ width: "80%", textAlign: "center", margin: "10px 35px 100px" }}>
                            <Select
                                className="selector-single"
                                options={labelType()}
                                onChange={(e) => {
                                    props.setModalResult({ type: e.value, labelLength: props.labelLength });
                                }}
                                // defaultValue={{ value: "flame", label: "열흔" }}
                            />

                            <div style={{ float: "right", margin: "80px 200px 30px", width: "10%" }}>
                                <Button
                                    className="btn-positive"
                                    variant="secondary"
                                    type="submit"
                                    onClick={() => {
                                        // if (props.modalResult) {
                                        // if (props.modalResult.type) {
                                        props.setTypeSelectModal(false);
                                        props.setChange(false);
                                        // }
                                        // }
                                    }}
                                >
                                    확인
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}
export default TypeSelect;
